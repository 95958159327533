import { Rating } from './api.types';
export const GERMAN_DATE_FORMAT = 'DD.MM.YYYY';
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const ISO_DATE_FORMAT_DASHLESS = 'YYYYMMDD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const WMG_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const PURPLE_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const GERMAN_TIME_FORMAT = 'HH:mm';

export const ALL_OPTION = '__ALL__';

export const ACCESS_TOKEN_HEADER_NAME = 'x-access-token';

export const RATING_TRANSLATION_MAP = new Map<string, Rating>([
    ['Buy', Rating.POSITIVE],
    ['Add', Rating.POSITIVE],
    ['Con', Rating.POSITIVE],
    ['Ove', Rating.POSITIVE],
    ['Kau', Rating.POSITIVE],
    ['Out', Rating.POSITIVE],
    ['Hol', Rating.NEUTRAL],
    ['Sec', Rating.NEUTRAL],
    ['Equ', Rating.NEUTRAL],
    ['Mar', Rating.NEUTRAL],
    ['Neu', Rating.NEUTRAL],
    ['Hal', Rating.NEUTRAL],
    ['Und', Rating.NEGATIVE],
    ['Red', Rating.NEGATIVE],
    ['Sel', Rating.NEGATIVE],
    ['Ver', Rating.NEGATIVE],
]);
