import { CompanyDto } from '@boersenzeitung/shared/dtos/Company.dto';
import { InputProps } from 'react-autosuggest';
import { InstrumentPicker } from '@components/instrument-picker';
import { SearchSuggestionField } from '@components/suggestion-input/search-suggestion-field';
import { TitleHeaderProps } from '@components/header/top-row/header-top-row';
import { generateCompanyUrl } from '@boersenzeitung/shared/parseUrlCompanyPart';
import { getCurrentNavigationForLocation } from '@utils/getCurrentNavigationForLocation';
import { removeSearchParamsFromQueryString } from '@utils/map-record-transformer';
import { useInstruments } from '@hooks/useInstruments';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import React, { FormEvent, useState } from 'react';
import apiClient from '@api/api.client';

export function CompanyHeaderTopRow({
    title,
    navigation,
    instrument,
}: TitleHeaderProps) {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchDisplayValue, setSearchDisplayValue] = useState<string>('');
    const instruments = useInstruments(
        navigation.filter((navigation) => navigation.isInstrumentDependent),
    );
    const navigate = useNavigate();
    const location = useLocation();
    const inputId = 'search-input-header';

    const onChange = (
        _event: FormEvent<HTMLElement>,
        { newValue }: { newValue: string },
    ) => {
        setSearchDisplayValue(newValue);
    };

    const inputProps: InputProps<string> & {
        'data-cy': string;
        'data-testid': string;
    } = {
        'placeholder': 'Unternehmen / ISIN / WKN',
        'value': searchDisplayValue,
        onChange,
        'onInput': (e: FormEvent<HTMLInputElement>) => {
            setSearchTerm(e.currentTarget.value);
            setSearchDisplayValue(e.currentTarget.value);
        },
        'onBlur': () => {
            // do nothing -> calling this function will close the suggestion box
        },
        'className':
            'p-4 pt-1 pb-0 md:pb-1 rounded-none h-[38px] w-full min-w-[210px] border border-solid focus:ring-red focus:border-red bg-light-gray border-spacer',
        'data-cy': inputId,
        'data-testid': inputId,
        'id': inputId,
    };

    const onSuggestionClicked = (company: CompanyDto) => {
        setSearchDisplayValue('');
        setSearchTerm('');
        const hrefPath = getCurrentNavigationForLocation(
            location,
            navigation,
        )!.href;
        navigate(
            `${generateCompanyUrl(
                hrefPath,
                company,
            )}${removeSearchParamsFromQueryString(location.search)}`,
        );
    };

    return (
        <>
            <h1
                className="mt-6 mb-2 font-serif break-words hyphens-auto"
                lang="de"
            >
                {title}
            </h1>
            <div className="flex md:flex-row flex-col justify-between md:space-x-3 lg:space-x-4 mb-2 md:mb-4">
                <div className="md:flex md:space-x-3 lg:space-x-4 md:items-center text-lightgray text-sm font-sourceCode mb-2 md:mb-0">
                    {instrument?.company?.isin && (
                        <div>ISIN: {instrument.company.isin}</div>
                    )}
                    {instrument?.company?.securityId && (
                        <div>WKN: {instrument.company.securityId}</div>
                    )}
                    {!!instruments.length && (
                        <InstrumentPicker instruments={instruments} />
                    )}
                </div>
                {instrument?.company && (
                    <SearchSuggestionField<CompanyDto>
                        onSuggestionClicked={onSuggestionClicked}
                        searchTerm={searchTerm}
                        inputProps={inputProps}
                        getResults={(searchString: string) =>
                            apiClient.findCompany(searchString)
                        }
                    />
                )}
            </div>
        </>
    );
}
