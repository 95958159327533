import { CompanyAppointmentDto } from '@boersenzeitung/shared/dtos/company-appointment.dto';
import { CompanyDto } from '@boersenzeitung/shared/dtos/Company.dto';
import { DatesList } from '@components/dates-list';
import { Empty } from '@components/empty';
import { TeaserText } from '@components/teaser-text';
import { TitledSeparator } from '@components/titled-separator';
import {
    datesSortFunction,
    getDateOfAppointment,
    getIsinOfAppointment,
} from '@pages/company/dates';
import React, { useEffect, useState } from 'react';
import dayjs from '@boersenzeitung/shared/dayjs';

export const OverviewAppointments = ({
    company,
    url,
}: {
    company: CompanyDto;
    url: string;
}) => {
    const [companyAppointments, setCompanyAppointments] = useState<
        CompanyAppointmentDto[]
    >([]);

    useEffect(() => {
        const appointments = company.isin
            ? company.appointments.filter(
                  (appointment) =>
                      dayjs(getDateOfAppointment(appointment)).isAfter(
                          dayjs(),
                      ) &&
                      (!getIsinOfAppointment(appointment) ||
                          getIsinOfAppointment(appointment) === company.isin),
              )
            : [];

        appointments.sort(datesSortFunction);
        setCompanyAppointments(appointments);
    }, [company.isin]);
    return (
        <div className="mb-4">
            <TitledSeparator
                title={'Termine'}
                moreActionTarget={`/aktie/termine${
                    company.isin && url ? '/' + url + '-' + company.isin : ''
                }`}
            />
            <div>
                <TeaserText>
                    Termininformationen zu Hauptversammlungen,
                    Quartalsberichten, Geschäftsberichten,
                    Bilanzpressekonferenzen, Analystenkonferenzen und weiteren
                    Finanzterminen.
                </TeaserText>
                {companyAppointments.length > 0 ? (
                    <DatesList
                        appointments={companyAppointments.slice(-3).reverse()}
                        headerVisible={false}
                    />
                ) : (
                    <Empty />
                )}
            </div>
        </div>
    );
};
