import {
    AppointmentDto,
    AppointmentType,
} from '@boersenzeitung/shared/api.types';
import { Button } from '@components/button';
import { CompanyAppointmentDto } from '@boersenzeitung/shared/dtos/company-appointment.dto';
import { NavLink, useLocation } from 'react-router-dom';
import { Overlay } from '@components/overlay';
import { buildOverlaySections } from '@components/appointment/appointment-overlays';
import { removeSearchParamsFromQueryString } from '@utils/map-record-transformer';
import React, { ReactElement, ReactNode, useState } from 'react';

export type AppointmentViewProps = {
    appointment: AppointmentDto;
};

function AppointmentBaseView({
    title,
    details,
    infoOverlayBody,
    url,
}: {
    infoOverlayBody: ReactNode;
    title: string;
    details: string;
    url?: string;
}): ReactElement {
    const [infoOverlayVisible, setInfoOverlayVisible] = useState(false);
    const displayInfo = () => setInfoOverlayVisible(!infoOverlayVisible);
    return (
        <div
            className="grid grid-cols-[90%_10%] sm:grid-cols-[68%_22%_10%] p-2 text-sm font-sans border-solid border-b border-spacer items-center"
            data-testid="appointment-entry"
        >
            <div>
                {url ? (
                    <NavLink to={url}>
                        <span className="underline hover:text-red">
                            {title}
                        </span>
                    </NavLink>
                ) : (
                    <span>{title}</span>
                )}
                <span className="ml-2 opacity-50 sm:ml-0 sm:hidden">
                    ({details})
                </span>
            </div>
            <div className="hidden sm:block pl-2 sm:pl-0">{details}</div>
            <div className="flex justify-end sm:relative">
                <Button
                    title="i"
                    onClick={displayInfo}
                    className="font-bold px-[8px] bg-white rounded-[3px] !text-red border-red hover:bg-red hover:border-white hover:!text-white"
                />
                <Overlay
                    setVisible={setInfoOverlayVisible}
                    visible={infoOverlayVisible}
                >
                    {infoOverlayBody}
                </Overlay>
            </div>
        </div>
    );
}

export function SeminarAppointmentView({
    appointment,
}: AppointmentViewProps): ReactElement {
    return (
        <AppointmentBaseView
            title={appointment.title}
            details={appointment.details}
            infoOverlayBody={
                <>
                    {buildOverlaySections(AppointmentType.SEMINAR, appointment)}
                </>
            }
        />
    );
}

export function BusinessIndicatorAppointmentView({
    appointment,
}: AppointmentViewProps): ReactElement {
    return (
        <AppointmentBaseView
            title={appointment.title}
            details={appointment.details}
            infoOverlayBody={
                <>
                    {buildOverlaySections(
                        AppointmentType.BUSINESS_INDICATOR,
                        appointment,
                    )}
                </>
            }
        />
    );
}

export function CompanyAppointmentView({
    appointment,
}: AppointmentViewProps): ReactElement {
    const companyAppointment = appointment.data as CompanyAppointmentDto;
    const params = useLocation();
    return (
        <AppointmentBaseView
            title={appointment.title}
            details={appointment.details}
            infoOverlayBody={
                <>
                    {buildOverlaySections(
                        `${AppointmentType.COMPANY}/${companyAppointment.companyAppointmentType}`,
                        appointment,
                    )}
                </>
            }
            url={`${
                companyAppointment.internalUrl
            }${removeSearchParamsFromQueryString(params.search)}`}
        />
    );
}

export function appointmentViewForType(
    type: AppointmentType,
    props: AppointmentViewProps,
): ReactElement {
    if (type === AppointmentType.SEMINAR) {
        return <SeminarAppointmentView {...props} />;
    } else if (type === AppointmentType.BUSINESS_INDICATOR) {
        return <BusinessIndicatorAppointmentView {...props} />;
    }
    return <CompanyAppointmentView {...props} />;
}
