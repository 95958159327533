import {
    AppointmentDto,
    AppointmentType,
    CompanyAppointmentType,
} from '@boersenzeitung/shared/api.types';
import { BusinessIndicatorDto } from '@boersenzeitung/shared/dtos/business-indicator.dto';
import {
    CompanyAppointmentDto,
    CompanyAppointmentsDividends,
    CompanyEvent,
    CorporateAction,
    GeneralMeetings,
    ReportsFigures,
} from '@boersenzeitung/shared/dtos/company-appointment.dto';
import {
    DATE_TIME_FORMAT,
    GERMAN_DATE_FORMAT,
} from '@boersenzeitung/shared/constants';
import { OverlaySection } from '@components/overlay';
import { SeminarDto } from '@boersenzeitung/shared/dtos/seminar.dto';
import { compact, flatten } from 'lodash';
import { formatGermanDate } from '@boersenzeitung/shared/helpers';
import React, { ReactElement, ReactNode } from 'react';
import dayjs from '@boersenzeitung/shared/dayjs';

export type OverlayInfoSection = {
    title?: string;
    content: (appointment: AppointmentDto) => string | ReactNode | undefined;
};

function LinkSection({
    title,
    href,
}: {
    title: string;
    href: string;
}): ReactElement {
    return (
        <div className="opacity-50 font-sourceCode uppercase hover:opacity-100">
            <a href={href}>{title} &gt;</a>
        </div>
    );
}

function InstrumentSection({
    companyName,
    isin,
    securityId,
}: {
    companyName: string;
    isin: string;
    securityId: string;
}): ReactElement {
    return (
        <div>
            <div>{companyName}</div>
            <div>ISIN: {isin}</div>
            <div>WKN: {securityId}</div>
        </div>
    );
}

export const OVERLAY_SECTIONS: Record<
    string,
    OverlayInfoSection[] | undefined
> = {
    [AppointmentType.SEMINAR]: [
        {
            title: 'Titel',
            content: (appointment) => appointment.title,
        },
        {
            title: 'Themen',
            content: (appointment) =>
                (appointment.data as SeminarDto).topics.join(', '),
        },
        {
            title: 'Datum',
            content: (appointment) => {
                const seminar = appointment.data as SeminarDto;
                const dateStart = formatGermanDate(seminar.dateStart);
                const dateEnd = formatGermanDate(seminar.dateEnd);
                return dateStart === dateEnd
                    ? dateEnd
                    : `${dateStart} - ${dateEnd}`;
            },
        },
        {
            title: 'Ort',
            content: (appointment) => (appointment.data as SeminarDto).location,
        },
        {
            title: 'Leiter',
            content: (appointment) => {
                const seminar = appointment.data as SeminarDto;
                return seminar.ladders.length
                    ? seminar.ladders.map((leader) => (
                          <p key={leader} className="p-0">
                              {leader}
                          </p>
                      ))
                    : undefined;
            },
        },
        {
            title: 'Referenten',
            content: (appointment) => {
                const seminar = appointment.data as SeminarDto;
                return seminar.speakers.length
                    ? seminar.speakers.map((speaker) => (
                          <p key={speaker} className="p-0">
                              {speaker}
                          </p>
                      ))
                    : undefined;
            },
        },
        {
            content: (appointment) => {
                const seminar = appointment.data as SeminarDto;
                return (
                    seminar.eventUrl && (
                        <LinkSection
                            title={seminar.organizer}
                            href={seminar.eventUrl}
                        />
                    )
                );
            },
        },
        {
            content: (appointment) => {
                const seminar = appointment.data as SeminarDto;
                return (
                    seminar.registrationUrl && (
                        <LinkSection
                            title="Anmeldung"
                            href={seminar.registrationUrl}
                        />
                    )
                );
            },
        },
    ],
    [AppointmentType.BUSINESS_INDICATOR]: [
        {
            title: 'Titel',
            content: (appointment) => (
                <>
                    <div>{appointment.title}</div>
                    <div>
                        {(appointment.data as BusinessIndicatorDto).period}
                    </div>
                </>
            ),
        },
        {
            title: 'Land/Region',
            content: (appointment) =>
                (appointment.data as BusinessIndicatorDto).country,
        },
        {
            title: 'Datum',
            content: (appointment) => {
                const businessIndicator =
                    appointment.data as BusinessIndicatorDto;
                return (
                    <div>
                        <div>
                            {dayjs(
                                businessIndicator.dateTime,
                                DATE_TIME_FORMAT,
                            ).format(GERMAN_DATE_FORMAT)}
                        </div>
                        <div>
                            Uhrzeit:{' '}
                            {dayjs(
                                businessIndicator.dateTime,
                                DATE_TIME_FORMAT,
                            ).format('HH:mm')}
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'Konsensprognose',
            content: (appointment) =>
                (appointment.data as BusinessIndicatorDto).forecast,
        },
        {
            title: 'Letzter Wert',
            content: (appointment) =>
                (appointment.data as BusinessIndicatorDto).lastValue,
        },
    ],
    [`${AppointmentType.COMPANY}/${CompanyAppointmentType.REPORTS_FIGURES}`]: [
        {
            title: 'Unternehmen',
            content: (appointment) => appointment.title,
        },
        {
            title: 'Datum',
            content: (appointment) =>
                formatGermanDate(
                    (
                        (appointment.data as CompanyAppointmentDto)
                            .data as ReportsFigures
                    ).date,
                ),
        },
        {
            content: (appointment) => {
                const reportsAndFigures = (
                    appointment.data as CompanyAppointmentDto
                ).data as ReportsFigures;
                return (
                    reportsAndFigures.irUrl && (
                        <LinkSection
                            title="Investor Relations"
                            href={reportsAndFigures.irUrl}
                        />
                    )
                );
            },
        },
    ],
    [`${AppointmentType.COMPANY}/${CompanyAppointmentType.DIVIDENDS}`]: [
        {
            title: 'Wertpapier',
            content: (appointment) => {
                const dividend = (appointment.data as CompanyAppointmentDto)
                    .data as CompanyAppointmentsDividends;
                return (
                    <InstrumentSection
                        companyName={appointment.title}
                        isin={dividend.isin}
                        securityId={dividend.securityId}
                    />
                );
            },
        },
        {
            title: 'Ex-Tag',
            content: (appointment) =>
                formatGermanDate(
                    (
                        (appointment.data as CompanyAppointmentDto)
                            .data as CompanyAppointmentsDividends
                    ).exDay,
                ),
        },
        {
            title: 'Dividende',
            content: (appointment) => {
                const dividend = (appointment.data as CompanyAppointmentDto)
                    .data as CompanyAppointmentsDividends;
                const [amount, currency, type] = compact(
                    flatten(dividend.dividends),
                );

                return (
                    <div>
                        <div>{`${amount} ${currency}`}</div>
                        <div>{type}</div>
                    </div>
                );
            },
        },
    ],
    [`${AppointmentType.COMPANY}/${CompanyAppointmentType.GENERAL_MEETINGS}`]: [
        {
            title: 'Unternehmen',
            content: (appointment) => appointment.title,
        },
        {
            title: 'Datum',
            content: (appointment) =>
                formatGermanDate(
                    (
                        (appointment.data as CompanyAppointmentDto)
                            .data as GeneralMeetings
                    ).date,
                ),
        },
    ],
    [`${AppointmentType.COMPANY}/${CompanyAppointmentType.CORPORATE_ACTIONS}`]:
        [
            {
                title: 'Wertpapier',
                content: (appointment) => {
                    const corporateAction = (
                        appointment.data as CompanyAppointmentDto
                    ).data as CorporateAction;
                    return (
                        <InstrumentSection
                            companyName={appointment.title}
                            isin={corporateAction.isin}
                            securityId={corporateAction.securityId}
                        />
                    );
                },
            },
            {
                title: 'Ex-Tag',
                content: (appointment) =>
                    formatGermanDate(
                        (
                            (appointment.data as CompanyAppointmentDto)
                                .data as CorporateAction
                        ).exDay,
                    ),
            },
            {
                title: 'Art',
                content: (appointment) => {
                    const corporateAction = (
                        appointment.data as CompanyAppointmentDto
                    ).data as CorporateAction;
                    return corporateAction.type;
                },
            },
            {
                title: 'Verhältnis',
                content: (appointment) => {
                    const corporateAction = (
                        appointment.data as CompanyAppointmentDto
                    ).data as CorporateAction;
                    return corporateAction.ratio;
                },
            },
            {
                title: 'Preis',
                content: (appointment) => {
                    const corporateAction = (
                        appointment.data as CompanyAppointmentDto
                    ).data as CorporateAction;
                    return corporateAction.priceAmount
                        ? `${corporateAction.priceAmount} ${corporateAction.currency}`
                        : undefined;
                },
            },
        ],
    [`${AppointmentType.COMPANY}/${CompanyAppointmentType.EVENTS}`]: [
        {
            title: 'Unternehmen',
            content: (appointment) => appointment.title,
        },
        {
            title: 'Datum',
            content: (appointment) =>
                formatGermanDate(
                    (
                        (appointment.data as CompanyAppointmentDto)
                            .data as CompanyEvent
                    ).date,
                ),
        },
        {
            content: (appointment) => {
                const companyEvent = (appointment.data as CompanyAppointmentDto)
                    .data as CompanyEvent;
                return (
                    companyEvent.irUrl && (
                        <LinkSection
                            title="Investor Relations"
                            href={companyEvent.irUrl}
                        />
                    )
                );
            },
        },
    ],
};

export function buildOverlaySections(
    key: string,
    appointment: AppointmentDto,
): ReactNode[] {
    const overlaySections = OVERLAY_SECTIONS[key] ?? [];
    return compact(
        overlaySections.map(({ title, content }, index) => {
            const description = content(appointment);
            return description ? (
                <OverlaySection
                    key={`${title}-${index}`}
                    title={title}
                    description={description}
                />
            ) : undefined;
        }),
    );
}
