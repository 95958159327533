import { Environment } from '../env';
import React, { useEffect, useState } from 'react';

function loadImage(url: string, callback: (showImage: boolean) => void) {
    const img = new Image();

    img.src = url;

    if (img.complete) {
        callback(true);
    } else {
        img.onload = () => callback(true);
        img.onerror = () => callback(false);
    }
}

/** SafeImage component displays an image with error handling to automatically
 * hide the image if loading fails.
 */
export const SafeImage = ({
    src,
    alt,
    ...rest
}: React.ImgHTMLAttributes<HTMLImageElement>) => {
    const [showImage, setShowImage] = useState(false);
    const url = `${Environment.FILES_CDN_URL}/${src}`;

    const hideImage = () => {
        setShowImage(false);
    };

    // onError does not always trigger if an image cannot be loaded
    // loadImage is more reliable.
    useEffect(() => {
        if (src === '') {
            return;
        }
        loadImage(url, (showImage) => setShowImage(showImage));
    }, []);

    if (!showImage) {
        return null;
    }

    return (
        <img
            src={url}
            alt={alt}
            onError={hideImage}
            className={rest.className}
        />
    );
};
