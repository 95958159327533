import { Access } from '../generated';

export interface NewsDto {
    uuid: string;

    publishedDate: string;

    category: string;

    title: string;

    teaser: string;

    cursor: string;

    slug: string;

    access: Access;

    purchased: boolean;

    contentTier?: string;

    bookmarked?: boolean;
}

export interface DocumentDto {
    id: string;

    thumbnailUrl: string;

    publicationDate: string;

    name: string;
}

export interface ResultDto {
    canLoadMore: boolean;
    data: NewsDto[] | TickerDto[];
}

export interface DocumentResultDto {
    data: DocumentDto[];
}

export interface TickerDto {
    uuid: string;
    source: TickerType;
    publishedDateTime: string;
    title: string;
    cursor: string;
    slug: string;
    access: Access;
    purchased: boolean;
    contentTier?: string;
    bookmarked?: boolean;
}

export enum TickerType {
    overview = 'overview',
    topTopic = 'top-topics',
    analysis = 'analysis',
    marketReport = 'market-reports',
    company = 'company',
    adHoc = 'ad-hoc',
}
