import { Helmet } from 'react-helmet';
import { NewsEntity, NewsList } from '@components/news-list';
import { TeaserText } from '@components/teaser-text';
import { TitledSeparator } from '@components/titled-separator';
import { useDocumentTitle } from '@hooks/useDocumentTitle';
import React, { useContext } from 'react';
import UrlDataContext from '../../contexts/urlDataContext';

export const News = () => {
    const { currentInstrument } = useContext(UrlDataContext);
    useDocumentTitle(
        currentInstrument?.company?.name
            ? `${currentInstrument.company.name} News - Nachrichten | Schlagzeilen | Neuigkeiten`
            : 'Börsen-Zeitung',
    );
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content={`Aktuelle News und Meldungen der Börsen-Zeitung zur ${currentInstrument?.company?.name}. Jetzt über die aktuellsten Nachrichten informieren!`}
                />
            </Helmet>
            <div>
                <TeaserText>
                    {`Die ${currentInstrument?.company?.name} News versorgen Sie mit allen
                relevanten Meldungen in Bezug auf das Unternehmen. Die
                Entwicklung des Aktienkurses ist von aktuellen Nachrichten und
                Neuigkeiten geprägt. Die aktuellen News der ${currentInstrument?.company?.name} sollten daher immer berücksichtigt werden, um mögliche
                Kursveränderungen abzuschätzen und Gefahren abzuwenden.`}
                </TeaserText>
                <TitledSeparator title="Aktuelle Nachrichten und Schlagzeilen" />
                <NewsList
                    isin={currentInstrument?.company?.isin}
                    newsEntity={NewsEntity.news}
                />
            </div>
        </>
    );
};
