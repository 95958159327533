import { CompanyDto } from '@boersenzeitung/shared/dtos/Company.dto';
import { InstrumentDto } from '@boersenzeitung/shared/dtos/instrument.dto';
import { TeaserText } from '@components/teaser-text';
import { TitledSeparator } from '@components/titled-separator';
import React from 'react';
import SymbolInfo from '@components/trading-view/SymbolInfo';

export const OverviewStockPrices = ({
    company,
    url,
    instrument,
}: {
    company: CompanyDto;
    url: string;
    instrument: InstrumentDto;
}) => {
    return (
        <div className="mb-4">
            <TitledSeparator
                title={'Kurse'}
                moreActionTarget={`/aktie/kurse${
                    company.isin && url ? '/' + url + '-' + company.isin : ''
                }`}
            />
            <TeaserText>
                Aktueller Kurs-Überblick aller deutschen Börsenplätze mit Kurs,
                Kurs-Performance und einem übersichtlichen Chart.
            </TeaserText>
            {company.instruments[0].germanTicker && (
                <SymbolInfo
                    colorTheme="light"
                    locale="de_DE"
                    symbol={instrument.germanTicker}
                    width="100%"
                ></SymbolInfo>
            )}
        </div>
    );
};
