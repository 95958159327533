import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Button } from '@components/button';
import { CustomIcon } from '@components/custom-icon';
import { DEFAULT_TIME_FRAME_OPTIONS } from '@utils/constants';
import { DateRangePicker, RangeKeyDict } from 'react-date-range';
import { GERMAN_DATE_FORMAT } from '@boersenzeitung/shared/constants';
import { Option } from '@components/select/types';
import { Select } from '@components/select/select';
import Modal from 'react-modal';
import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from '@boersenzeitung/shared/dayjs';
import de from 'date-fns/locale/de';

export type CustomTimeframe = {
    startDate: Date | undefined;
    endDate: Date | undefined;
};

export const buildCustomTimeFrameOptions = (
    startDate: Dayjs,
    endDate: Dayjs,
): Option => {
    return {
        label: `${startDate.format(GERMAN_DATE_FORMAT)}-${endDate.format(
            GERMAN_DATE_FORMAT,
        )}`,
        value: `${startDate.startOf('day').toISOString()} - ${endDate
            .endOf('day')
            .toISOString()}`,
    };
};

export const DateFramePicker = ({
    selectedTimeframe,
    setSelectedTimeframe,
    timeFrames,
    disabled,
}: {
    selectedTimeframe: Option | undefined;
    setSelectedTimeframe: (newOption: Option) => void;
    timeFrames?: Option[];
    disabled?: boolean;
}) => {
    const [selectedCustomTimeframe, setSelectedCustomTimeframe] =
        useState<CustomTimeframe>({
            startDate: dayjs().toDate(),
            endDate: dayjs().subtract(6, 'days').toDate(),
        });
    const [showDateRangePicker, setShowDateRangePicker] =
        useState<boolean>(false);

    const [timeframeOptions, setTimeframeOptions] = useState<Option[]>(
        timeFrames ?? DEFAULT_TIME_FRAME_OPTIONS,
    );

    useEffect(() => {
        if (!selectedTimeframe) {
            setSelectedTimeframe(timeframeOptions[0]!);
        } else if (
            !timeframeOptions.find(
                (option) => option.value === selectedTimeframe.value,
            )
        ) {
            const oldOptions = [...timeframeOptions];
            const customOption = oldOptions.pop()!;
            setTimeframeOptions([
                ...oldOptions,
                selectedTimeframe,
                customOption,
            ]);
        }
    }, [selectedTimeframe]);

    const handleTimeframeSelectionChange = (value: string | null) => {
        if (value) {
            if (value === 'custom') {
                setShowDateRangePicker(true);
            } else {
                const newOption = timeframeOptions.find(
                    (option) => option.value === value,
                );
                if (newOption) {
                    setSelectedTimeframe(newOption);
                }
            }
        }
    };

    const closeModal = () => {
        setShowDateRangePicker(false);
        const startDate = dayjs(selectedCustomTimeframe.startDate);
        const endDate = dayjs(selectedCustomTimeframe.endDate);
        const newOption = buildCustomTimeFrameOptions(startDate, endDate);
        const newOptions = timeFrames
            ? [...timeFrames]
            : [...DEFAULT_TIME_FRAME_OPTIONS];
        const customOption = newOptions.pop()!;
        newOptions.push(newOption);
        newOptions.push(customOption);
        setTimeframeOptions(newOptions);
        setSelectedTimeframe(newOption);
    };

    return (
        <>
            <Modal
                isOpen={showDateRangePicker}
                onRequestClose={() => setShowDateRangePicker(false)}
                ariaHideApp={false}
                className="absolute top-16 md:top-1/3 sm:top-1/4 mx-auto left-0 right-0 w-fit bg-background p-4"
            >
                <div className="flex flex-col" data-testid="ModalDiv">
                    <button
                        className="bg-button-black text-white rounded-sm text-sm max-w-[7.5rem] px-2 py-1 self-end mb-4"
                        onClick={() => setShowDateRangePicker(false)}
                        data-testid="ModalClose"
                    >
                        <CustomIcon
                            className="text-white"
                            iconCode={String.raw`before:content-["\ea09"]`}
                        />
                    </button>
                    <DateRangePicker
                        showDateDisplay={false}
                        ranges={[selectedCustomTimeframe]}
                        months={2}
                        staticRanges={[]}
                        dateDisplayFormat="dd.MM.yyyy"
                        inputRanges={[]}
                        locale={de}
                        onChange={(newCustomTimeframe: RangeKeyDict) => {
                            const range = newCustomTimeframe['range1'];
                            setSelectedCustomTimeframe({
                                startDate: range.startDate,
                                endDate: range.endDate,
                            });
                        }}
                    />
                    <div className="flex justify-end pt-3 mt-5 border-t border-solid border-spacer">
                        <Button
                            onClick={closeModal}
                            testId="ModalConfirm"
                            title="Übernehmen"
                            className="max-w-[7.5rem] px-4 bg-red !text-white border-red hover:bg-darkred hover:border-darkred"
                        />
                    </div>
                </div>
            </Modal>
            {selectedTimeframe && (
                <Select
                    isDisabled={disabled}
                    options={timeframeOptions}
                    value={selectedTimeframe.value}
                    onChange={handleTimeframeSelectionChange}
                    className="md:w-[250px] w-full font-sans"
                />
            )}
        </>
    );
};
