import { Button } from '@components/button';
import { DividendDto } from '@boersenzeitung/shared/dtos/dividend.dto';
import { DividendEntry } from '@components/dividend-entry';
import { Empty } from '@components/empty';
import React, { useEffect, useRef, useState } from 'react';

export const DividendList = ({ dividends }: { dividends: DividendDto[] }) => {
    const [showDividends, setShowDividends] = useState<DividendDto[]>([]);
    const [showButton, setShowButton] = useState<boolean>(false);

    const scrollRef = useRef<null | HTMLDivElement>(null);

    const scrollToDividendList = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (!showButton && showDividends.length > 1) {
            scrollToDividendList();
        }
    }, [showButton]);

    useEffect(() => {
        if (dividends.length) {
            setShowDividends([dividends[0]]);
            if (dividends.length > 1) {
                setShowButton(true);
            }
        }
    }, [dividends]);

    const loadMoreDividends = () => {
        setShowDividends(dividends);
        setShowButton(false);
    };

    return (
        <>
            {showDividends.length > 0 ? (
                <>
                    <div className="overflow-x-auto w-full">
                        <div ref={scrollRef} />
                        <table className="table-auto w-full">
                            <tbody>
                                {showDividends.map((entry) => (
                                    <DividendEntry
                                        dividend={entry}
                                        key={entry.exDay + entry.type}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {showButton && (
                        <div className="flex justify-center">
                            <div className="mt-10">
                                <Button
                                    testId="loadMoreDividendsButton"
                                    title="Weitere Ergebnisse anzeigen"
                                    onClick={() => loadMoreDividends()}
                                    canDisplayPendingState={false}
                                    className="bg-transparent"
                                />
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <Empty />
            )}
        </>
    );
};
