import {
    NotificationType,
    UserDataDto,
} from '@boersenzeitung/shared/dtos/email-notifications-user-data.dto';
import { useApiClient } from '@hooks/useApiClient';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import apiClient from '@api/api.client';
import qs from 'qs';

export const EMAIL_TOKEN_KEY = 'e-mail-notification-token';

const TOKEN_AUTHORIZED_ROUTES = ['manage', 'list'];

const BASE_EMAIL_NOTIFICATION_URL = '/email-notification';

export function useEmailToken() {
    const location = useLocation();
    const navigate = useNavigate();
    // undefined value means that we have not read the local storage yet and there might be a token
    // null value means that there is no token in the local storage
    const [emailToken, setEmailToken] = useState<string | undefined | null>(
        undefined,
    );
    const [updateCounter, setUpdateCounter] = useState<number>(0);

    const [query, cleanedQuery] = useMemo(() => {
        const query = qs.parse(location.search.replace('?', ''));
        const cleanedQuery = { ...query };
        delete cleanedQuery.token;
        return [query, cleanedQuery];
    }, [location.search]);

    useEffect(() => {
        const token = query.token as string | undefined;
        if (token) {
            setEmailToken(token);
            localStorage.setItem(EMAIL_TOKEN_KEY, token);
            navigate({
                pathname: location.pathname,
                search: qs.stringify(cleanedQuery),
            });
        }
    }, [query, cleanedQuery, location.search]);

    // get token from local storage
    useEffect(() => {
        const token = localStorage.getItem(EMAIL_TOKEN_KEY);
        setEmailToken(token);
    }, []);

    // sync token to local storage
    useEffect(() => {
        if (emailToken !== undefined) {
            if (emailToken) {
                localStorage.setItem(EMAIL_TOKEN_KEY, emailToken);
            } else {
                localStorage.removeItem(EMAIL_TOKEN_KEY);
            }
        }
    }, [emailToken]);

    // check if route requires token
    const isTokenAuthorizedRoute = useMemo(() => {
        return (
            location.pathname.includes(BASE_EMAIL_NOTIFICATION_URL) &&
            TOKEN_AUTHORIZED_ROUTES.some((route) =>
                location.pathname.includes(route),
            )
        );
    }, [location.pathname, TOKEN_AUTHORIZED_ROUTES]);

    // try to get user data if token is given and route is authorized
    const {
        data: userData,
        error,
        loading,
    } = useApiClient<UserDataDto | undefined>(async () => {
        if (emailToken && isTokenAuthorizedRoute) {
            return apiClient.getEmailNotificationPreferences(emailToken);
        } else {
            // we can't just return or else the loading mechanism will break
            await new Promise((resolve) => {
                setTimeout(() => {
                    resolve(undefined);
                }, 500);
            });
        }
    }, [emailToken, apiClient, updateCounter, isTokenAuthorizedRoute]);

    // if no token is given but route requires it, redirect to login url unless we have token expired error
    useEffect(() => {
        if (emailToken !== undefined) {
            const isNotAuthorized = isTokenAuthorizedRoute && !emailToken;
            if (isNotAuthorized) {
                const axiosError = error?.response?.data as
                    | { error: string; message: string; statusCode: number }
                    | undefined;
                if (
                    axiosError?.statusCode === 403 &&
                    axiosError.error === 'Forbidden' &&
                    axiosError.message === 'Token expired'
                ) {
                    navigate({
                        pathname: `${BASE_EMAIL_NOTIFICATION_URL}/expired`,
                        search: location.search,
                    });
                } else {
                    navigate({
                        pathname: BASE_EMAIL_NOTIFICATION_URL,
                        search: location.search,
                    });
                }
            }
        }
    }, [location.pathname, emailToken]);

    // Remove token if invalid
    useEffect(() => {
        if (emailToken !== undefined) {
            const tokenInvalid =
                !loading && (!!error || (!userData && !!emailToken));
            if (tokenInvalid) {
                setEmailToken(null);
            }
        }
    }, [emailToken, userData, error]);

    const removeAllEmailNotificationPreferences = useCallback(() => {
        if (emailToken) {
            void apiClient
                .removeAllEmailNotificationPreferences(emailToken)
                .then(() => {
                    setUpdateCounter((counter) => counter + 1);
                });
        }
    }, [apiClient, emailToken, setUpdateCounter]);

    const addEmailNotificationPreference = useCallback(
        (type: string, searchTerm: string, companyName?: string) => {
            if (type !== NotificationType.COMPANY) {
                companyName = undefined;
            }
            if (emailToken) {
                void apiClient
                    .addEmailNotificationPreference(
                        type,
                        searchTerm,
                        emailToken,
                        companyName,
                    )
                    .then(() => {
                        setUpdateCounter((counter) => counter + 1);
                    });
            }
        },
        [apiClient, emailToken, setUpdateCounter],
    );

    const setServiceActive = useCallback(
        (active: boolean) => {
            if (emailToken) {
                void apiClient
                    .updateServiceActive(active, emailToken)
                    .then(() => {
                        setUpdateCounter((counter) => counter + 1);
                    });
            }
        },
        [apiClient, emailToken, setUpdateCounter],
    );

    const deleteEmailNotificationPreference = useCallback(
        (id: string) => {
            if (emailToken) {
                void apiClient
                    .removeEmailNotificationPreference(id, emailToken)
                    .then(() => {
                        setUpdateCounter((counter) => counter + 1);
                    });
            }
        },
        [apiClient, emailToken, setUpdateCounter],
    );

    const changeName = useCallback(
        (firstName: string, lastName: string) => {
            if (emailToken) {
                void apiClient
                    .updateName(emailToken, firstName, lastName)
                    .then(() => {
                        setUpdateCounter((counter) => counter + 1);
                    });
            }
        },
        [apiClient, emailToken, setUpdateCounter],
    );

    return {
        emailToken,
        setEmailToken,
        userData,
        removeAllEmailNotificationPreferences,
        addEmailNotificationPreference,
        setServiceActive,
        deleteEmailNotificationPreference,
        changeName,
        loading,
    };
}
