import { Analysis } from '@pages/company/analysis';
import { Dates as CompanyDates } from './pages/company/dates';
import { News as CompanyNews } from './pages/company/news';
import { Overview as CompanyOverview } from './pages/company/overview';
import { News as DatesNews } from '@pages/dates/news';
import { Search as DatesSearch } from '@pages/dates/search';
import { DetailPage } from '@pages/funds/detail-page';
import { Dividend } from '@pages/company/dividend';
import { FinancialDisplays } from '@pages/funds/financial-displays';
import { FullPagePdf } from '@pages/funds/full-page-pdf';
import { News as FundNews } from '@pages/funds/news';
import { Overview as FundOverview } from '@pages/funds/overview';
import { Search as FundSearch } from '@pages/funds/search';
import { NotFoundPage, ServerErrorPage } from '@pages/company/error-page';
import {
    RouteObject,
    createMemoryRouter,
    createSearchParams,
} from 'react-router-dom';
import { StockPrices } from '@pages/company/stock-prices';
import { Ticker } from '@pages/company/ticker';
import { Top5Ultimo } from '@pages/funds/top-5-ultimo';
import { UrlDataContextWrapper } from './contexts/urlDataContextWrapper';
import { convertMapToRecord } from '@utils/map-record-transformer';
import CompanyIndex from './pages/company';
import DatesIndex from './pages/dates';
import EmailNotificationWidget from '@pages/email-notification-widget';
import ExpiredEmailToken from '@pages/email-notification-widget/expired-email-token';
import FundsIndex from '@pages/funds';
import ListDetailPage from '@pages/email-notification-widget/list-detail';
import ListPage from '@pages/email-notification-widget/list';
import LoginEmailNotification from '@pages/email-notification-widget/login';
import Manage from '@pages/email-notification-widget/manage';
import React from 'react';
import RegisterEmailNotification from '@pages/email-notification-widget/register';
import StockPricesIndex from '@pages/stock-prices';
import purpleApiAdapter from './purple/purple-api.adapter';

const routes: RouteObject[] = [
    {
        path: '/termine',
        element: <DatesIndex />,
        errorElement: <ServerErrorPage />,
        children: [
            {
                path: 'suche',
                element: <DatesSearch />,
            },
            {
                path: 'news',
                element: <DatesNews />,
            },
            {
                path: '*',
                element: <NotFoundPage />,
            },
        ],
    },
    {
        path: '/aktie',
        element: <CompanyIndex />,
        errorElement: <ServerErrorPage />,
        children: [
            {
                path: 'news/:instrumentIdentifier',
                element: <CompanyNews />,
            },
            {
                path: 'kurse/:instrumentIdentifier',
                element: <StockPrices />,
            },
            {
                path: 'ticker/:instrumentIdentifier',
                element: <Ticker />,
            },
            {
                path: 'analysen/:instrumentIdentifier',
                element: <Analysis />,
            },
            {
                path: 'dividenden/:instrumentIdentifier',
                element: <Dividend />,
            },
            {
                path: 'termine/:instrumentIdentifier',
                element: <CompanyDates />,
            },
            {
                path: ':instrumentIdentifier',
                element: <CompanyOverview />,
            },
        ],
    },
    {
        path: '/fonds',
        element: <FundsIndex />,
        errorElement: <ServerErrorPage />,
        children: [
            {
                path: 'news',
                element: <FundNews />,
            },
            {
                path: 'suche',
                element: <FundSearch />,
            },
            {
                path: 'top-5-ultimo',
                element: <Top5Ultimo />,
            },
            {
                path: 'finanzanzeigen',
                element: <FinancialDisplays />,
            },
            {
                path: 'ganzseiten-pdf',
                element: <FullPagePdf />,
            },
            {
                path: 'fond/:isin',
                element: <DetailPage />,
            },
            {
                path: '',
                element: <FundOverview />,
            },
        ],
    },
    {
        path: '/email-notification',
        element: <EmailNotificationWidget />,
        errorElement: <ServerErrorPage />,
        children: [
            { index: true, element: <LoginEmailNotification /> },
            { path: 'register', element: <RegisterEmailNotification /> },
            { path: 'expired', element: <ExpiredEmailToken /> },
            { path: 'manage', element: <Manage /> },
            { path: 'list', element: <ListPage /> },
            { path: 'list/:identifier', element: <ListDetailPage /> },
        ],
    },
    {
        path: '/daten/:identifier',
        element: <StockPricesIndex />,
        errorElement: <ServerErrorPage />,
    },
    {
        path: '*',
        element: (
            <div className="mx-auto max-w-5xl sm:px-6 lg:px-8 px-2">
                <NotFoundPage />
            </div>
        ),
    },
];

export type Section = 'termine' | 'aktie' | 'fonds' | 'email-notification';
const initialSections: Record<Section, string> = {
    'termine': '/termine/suche',
    'aktie': '/aktie/undefined',
    'fonds': '/fonds',
    'email-notification': '/email-notification',
};

export const createRouter = (initialSection: Section) => {
    const wrappedRoutes = routes.map((route) => {
        if (route.path === '*') {
            return route;
        } else {
            return {
                ...route,
                element: (
                    <UrlDataContextWrapper>
                        {route.element}
                    </UrlDataContextWrapper>
                ),
            };
        }
    });
    const router = createMemoryRouter(wrappedRoutes, {
        initialEntries: [initialSections[initialSection]],
        initialIndex: 0,
    });

    router.subscribe((state) => {
        purpleApiAdapter.sendUrlChangeToPurple(
            state.location.pathname,
            state.location.search,
        );
    });

    void purpleApiAdapter.getCurrentRoute().then((route) => {
        const path = route.path;
        const params = convertMapToRecord(route.queryParams);
        if (path && path !== '/') {
            router.navigate({
                pathname: decodeURI(path),
                search: `?${createSearchParams(params)}`,
            });
        }
    });

    purpleApiAdapter.addRouteListener((route) => {
        if (router.state.location.pathname !== route.path) {
            const params = convertMapToRecord(route.queryParams);
            router.navigate({
                pathname: decodeURI(route.path),
                search: `?${createSearchParams(params)}`,
            });
        }
    });

    return router;
};
