import { PURPLE_STATIC_LINK_TARGETS } from '@utils/constants';
import { classNames } from '@utils/classNames';
import { removeSearchParamsFromQueryString } from '@utils/map-record-transformer';
import { useLocation } from 'react-router-dom';
import React from 'react';
import purpleApiAdapter from '@purple/purple-api.adapter';

export const PurpleStaticLinks = ({ className }: { className: string }) => {
    const location = useLocation();
    return (
        <>
            {PURPLE_STATIC_LINK_TARGETS.map((staticLink) => (
                <button
                    className={classNames(className, 'border-0 rounded-none')}
                    key={staticLink.value}
                    onClick={() =>
                        purpleApiAdapter.redirectToPurpleContent(
                            staticLink.value,
                            removeSearchParamsFromQueryString(location.search),
                        )
                    }
                >
                    {staticLink.label}
                </button>
            ))}
        </>
    );
};
