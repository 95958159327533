export enum NotificationType {
    FULLTEXT = 'FULLTEXT',
    HEADLINE = 'HEADLINE',
    COMPANY = 'COMPANY',
}

export type NotificationSettingDto = {
    id: string;
    type: NotificationType;
    searchTerm: string;
    createdAt: string;
    companyName?: string;
};

export type TokenDto = {
    value: string;
    expirationDate: Date;
};

export type UserDataDto = {
    token: TokenDto | undefined;
    email: string;
    firstName: string;
    lastName: string;
    alertServiceActive: boolean;
    notificationSettings: NotificationSettingDto[];
    hubspotId?: string;
};
