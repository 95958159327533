import {
    AppointmentDto,
    AppointmentSearchResult,
    AppointmentType,
    AppointmentsByDate,
    CompanyAppointmentType,
    DateDef,
} from '@boersenzeitung/shared/api.types';
import { Button } from '@components/button';
import { CompanyAppointmentDto } from '@boersenzeitung/shared/dtos/company-appointment.dto';
import {
    GERMAN_DATE_FORMAT,
    ISO_DATE_FORMAT,
} from '@boersenzeitung/shared/constants';
import { TOPICS } from '@components/appointment/utils';
import { TitledSeparator } from '@components/titled-separator';
import { appointmentViewForType } from '@components/appointment/appointment';
import { classNames } from '@utils/classNames';
import { uniqBy } from 'lodash';
import React, {
    PropsWithChildren,
    ReactElement,
    useEffect,
    useState,
} from 'react';
import dayjs from '@boersenzeitung/shared/dayjs';

const SUBTOPIC_MAPPING: Record<string, string> = {
    [CompanyAppointmentType.GENERAL_MEETINGS]: 'Hauptversammlungen',
    [CompanyAppointmentType.DIVIDENDS]: 'Dividenden',
    [CompanyAppointmentType.REPORTS_FIGURES]: 'Berichte & Zahlen',
    [CompanyAppointmentType.EVENTS]: 'Veranstaltungen',
    [CompanyAppointmentType.CORPORATE_ACTIONS]: 'Kapitalmaßnahmen',
};

function AppointmentDateSection({
    children,
    date,
}: PropsWithChildren<{
    date: DateDef;
}>): ReactElement {
    return (
        <div className="mb-14">
            <TitledSeparator
                title={dayjs(date).format(`dd ${GERMAN_DATE_FORMAT}`)}
                className="mb-2"
            />
            <div>{children}</div>
        </div>
    );
}

function AppointmentPerTopicView({
    topic,
    subTopic,
    appointments,
}: {
    topic: AppointmentType;
    subTopic?: string;
    appointments: AppointmentDto[];
}): ReactElement {
    let appointmentsPerSubTopic: Record<string, AppointmentDto[]> = {};
    if (topic === AppointmentType.COMPANY && !subTopic) {
        appointmentsPerSubTopic = appointments.reduce<
            Record<string, AppointmentDto[]>
        >((acc, cur) => {
            const subTopic = (cur.data as CompanyAppointmentDto)
                .companyAppointmentType;
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            acc[subTopic] ??= [];
            acc[subTopic] = [...acc[subTopic], cur];
            return acc;
        }, {});
    }
    const mainTopicTitle =
        TOPICS.find((option) => option.value === topic)?.label ?? 'Unbekannt';
    const subTopicTitle = subTopic ? SUBTOPIC_MAPPING[subTopic] : undefined;
    const subTopicsOrdered = Object.keys(appointmentsPerSubTopic).sort((a, b) =>
        a.localeCompare(b),
    );
    return (
        <div
            className={classNames(
                'mb-8 sm:mb-10',
                subTopic ? 'ml-2 sm:ml-4' : '',
            )}
        >
            <div className="font-sans font-bold text-normal">
                {subTopicTitle ?? mainTopicTitle}
            </div>
            {subTopicsOrdered.length ? (
                <div>
                    {subTopicsOrdered.map((key) => (
                        <AppointmentPerTopicView
                            key={key}
                            topic={topic}
                            subTopic={key}
                            appointments={appointmentsPerSubTopic[key]}
                        />
                    ))}
                </div>
            ) : (
                <div>
                    {appointments.map((appointment: AppointmentDto) => (
                        <div key={appointment.id}>
                            {appointmentViewForType(topic, { appointment })}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

type AppointmentListingProps = {
    pageResults?: AppointmentSearchResult;
    onLoadMore: (silent: boolean) => void;
    loading: boolean;
};

export function AppointmentListing({
    pageResults,
    onLoadMore,
    loading,
}: AppointmentListingProps): ReactElement {
    const [shownAppointments, setShownAppointments] = useState<
        AppointmentDto[]
    >(pageResults?.items ?? []);

    const [bufferedAppointments, setBufferedAppointments] = useState<
        AppointmentDto[]
    >(pageResults?.items ?? []);

    useEffect(() => {
        if (pageResults?.page === 0) {
            setShownAppointments(pageResults.items);
            if (pageResults.totalCount > pageResults.items.length) {
                onLoadMore(true);
            }
        } else {
            setBufferedAppointments(pageResults?.items ?? []);
        }
    }, [pageResults]);

    const hasMoreResults =
        shownAppointments.length < (pageResults?.totalCount ?? 0);

    const canLoadMore =
        shownAppointments.length + bufferedAppointments.length <
        (pageResults?.totalCount ?? 0);

    const loadMore = () => {
        setShownAppointments(
            uniqBy([...shownAppointments, ...bufferedAppointments], 'id'),
        );
        if (canLoadMore) {
            onLoadMore(false);
        }
    };

    const appointmentsPerDate = shownAppointments.reduce<AppointmentsByDate>(
        (acc, cur) => {
            const dateFrom = dayjs(cur.dateFrom).format(ISO_DATE_FORMAT);
            const existing =
                (acc[dateFrom] as AppointmentDto[] | undefined) ?? [];
            acc[dateFrom] = [...existing, cur];
            return acc;
        },
        {} as AppointmentsByDate,
    );

    return (
        <div>
            {Object.entries(appointmentsPerDate).map(([date, appointments]) => {
                const appointmentsPerTopic = appointments.reduce<
                    Record<AppointmentType, AppointmentDto[] | undefined>
                >(
                    (
                        acc: Record<
                            AppointmentType,
                            AppointmentDto[] | undefined
                        >,
                        cur,
                    ) => {
                        const existing = acc[cur.type] ?? [];
                        acc[cur.type] = [...existing, cur];
                        return acc;
                    },
                    {} as Record<AppointmentType, AppointmentDto[]>,
                );

                return (
                    <AppointmentDateSection key={date} date={date}>
                        {Object.entries(appointmentsPerTopic).map(
                            ([topic, appointments]) => (
                                <AppointmentPerTopicView
                                    key={appointments
                                        ?.map((appointment) => appointment.id)
                                        .join('-')}
                                    topic={topic as AppointmentType}
                                    appointments={
                                        appointments?.sort((a, b) =>
                                            a.title.localeCompare(b.title),
                                        ) ?? []
                                    }
                                />
                            ),
                        )}
                    </AppointmentDateSection>
                );
            })}
            {hasMoreResults && (
                <div className="flex justify-center mt-6 sm:mt-16">
                    <Button
                        key={`${loading}`}
                        className="py-[6px] !bg-transparent"
                        title="Weitere Ergebnisse anzeigen"
                        onClick={loadMore}
                        pending={loading}
                        testId="appointment-list-can-load-more"
                    />
                </div>
            )}
        </div>
    );
}
