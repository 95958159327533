import { CustomIcon } from '@components/custom-icon';
import React from 'react';

export default function ContentTierIcon({
    contentTier,
}: {
    contentTier?: string;
}) {
    // ToDO: Wait for purple to provide right content tiers
    /*const getIconCodeForContentTier = () => {
        switch (contentTier) {
            case 'boezkompakt':
                return String.raw`before:content-["\ea1a"]`;
            case 'boezvoll':
                return String.raw`before:content-["\ea1b"]`;
            default:
                return undefined;
        }
    };*/
    const getIconCodeForContentTier = () => {
        if (contentTier === 'boezkompakt') {
            return String.raw`before:content-["\ea1a"]`;
        } else {
            return String.raw`before:content-["\ea1b"]`;
        }
    };
    return (
        <CustomIcon
            className="text-red"
            iconCode={getIconCodeForContentTier()}
        />
    );
}
