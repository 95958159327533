import { Helmet } from 'react-helmet';
import { NewsEntity, NewsList } from '@components/news-list';
import { TeaserText } from '@components/teaser-text';
import { TitledSeparator } from '@components/titled-separator';
import { useDocumentTitle } from '@hooks/useDocumentTitle';
import React, { useContext } from 'react';
import UrlDataContext from '../../contexts/urlDataContext';

export const Ticker = () => {
    const { currentInstrument } = useContext(UrlDataContext);
    useDocumentTitle(
        currentInstrument?.company?.name
            ? `${currentInstrument.company.name} Ticker - Tickermeldungen | Ad-Hoc-Meldungen`
            : 'Börsen-Zeitung',
    );
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content={`Aktuelle Ticker-/Ad-Hoc-Meldungen der ${currentInstrument?.company?.name}. Jetzt informieren und auf dem neuesten Stand bleiben!`}
                />
            </Helmet>
            <div>
                <TeaserText>
                    {`Nach dem deutschen Wertpapierhandelsgesetz müssen Aktienunternehmen kursrelevante Informationen publizieren und der breiten Öffentlichkeit bekannt machen. Auf dieser Seite sehen sie alle aktuellen Ticker-/Ad-Hoc-Meldungen der ${currentInstrument?.company?.name} in chronologischer Reihenfolge.`}
                </TeaserText>
                <TitledSeparator title="Aktuelle Ticker- und Ad-hoc-Meldungen" />
                <NewsList
                    isin={currentInstrument?.company?.isin}
                    newsEntity={NewsEntity.ticker}
                />
            </div>
        </>
    );
};
