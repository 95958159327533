import { QueryParams } from '@purple/purple-experience-router.type';
import { createSearchParams } from 'react-router-dom';
import qs, { ParsedQs } from 'qs';

export const convertMapToRecord = (
    map: QueryParams | undefined,
): Record<string, string> | undefined => {
    if (!map) {
        return undefined;
    }
    return Object.fromEntries(map);
};

export const convertRecordToMap = (
    record:
        | Record<string, string | string[]>
        | ParsedQs
        | ParsedQs[]
        | undefined,
): Map<string, string | string[]> | undefined => {
    if (!record) {
        return undefined;
    }
    const map = new Map<string, string | string[]>();
    Object.entries(record).forEach(([key, value]) => {
        if (
            typeof value === 'string' ||
            (Array.isArray(value) && typeof value[0] === 'string')
        ) {
            map.set(key, value as string | string[]);
        } else {
            console.error('non supported purple format found', value);
        }
    });
    return map;
};

export const removeSearchParamsFromQueryString = (
    queryString: string,
): string => {
    const paramsMap = convertRecordToMap(
        qs.parse(queryString.replace('?', '')),
    );
    paramsMap?.delete('AppointmentType');
    paramsMap?.delete('Filterdate');
    paramsMap?.delete('Text');
    paramsMap?.delete('Type');
    paramsMap?.delete('Wertpapier');
    paramsMap?.delete('Firma');
    paramsMap?.delete('Kategorie');
    const paramsObject = convertMapToRecord(paramsMap);
    return `?${createSearchParams(paramsObject)}`;
};
