import { InstrumentDto } from '@boersenzeitung/shared/dtos/instrument.dto';
import { NavigationType } from '@hooks/useInstruments';
import { Option } from '@components/select/types';
import { PURPLE_STATIC_LINK_TARGETS } from '@utils/constants';
import { Select } from '@components/select/select';
import { generateCompanyUrl } from '@boersenzeitung/shared/parseUrlCompanyPart';
import { removeSearchParamsFromQueryString } from '@utils/map-record-transformer';
import { splitAtLastOccurrence } from '@utils/split-at-last-occurrence';
import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import purpleApiAdapter from '@purple/purple-api.adapter';

export default function MobileNavBar({
    instrument,
    navigation,
    showPurpleStaticLinks,
}: {
    instrument?: InstrumentDto;
    navigation: NavigationType;
    showPurpleStaticLinks: boolean;
}) {
    const location = useLocation();
    const navigate = useNavigate();
    const route = instrument
        ? splitAtLastOccurrence(location.pathname, '/')[0]
        : location.pathname;

    const mapNavigationToOptions = (navigation: NavigationType): Option[] => {
        return navigation.map((entry) => ({
            label: entry.name,
            value: entry.href,
        }));
    };

    const navigateToPage = (value: string | null) => {
        if (!value) {
            return;
        }
        if (
            PURPLE_STATIC_LINK_TARGETS.map((target) => target.value).includes(
                value,
            )
        ) {
            purpleApiAdapter.redirectToPurpleContent(
                value,
                removeSearchParamsFromQueryString(location.search),
            );
        } else {
            const path = instrument
                ? generateCompanyUrl(value, instrument.company, instrument)
                : value;

            navigate(`${path}${location.search}`);
        }
    };
    const options = [...mapNavigationToOptions(navigation)];

    if (showPurpleStaticLinks) {
        options.push(...PURPLE_STATIC_LINK_TARGETS);
    }

    return (
        <Select
            options={options}
            value={route}
            onChange={(value) => navigateToPage(value)}
            className="md:hidden w-full font-sans"
        />
    );
}
