import { FundGraphData } from '@boersenzeitung/shared/dtos/fund.dto';
import { classNames } from '@utils/classNames';
import { getColorForNumericValue } from '@pages/funds/utils';
import React from 'react';

export const FundAnalysisDiagram = ({
    graphData,
}: {
    graphData: FundGraphData;
}) => {
    const yearlyPerformance = parseFloat(graphData.performanceYtd);
    const oneYearPerformance = parseFloat(graphData.performanceOneYear);
    const threeYearPerformance = parseFloat(graphData.performanceThreeYear);
    const fiveYearPerformance = parseFloat(graphData.performanceFiveYear);

    return (
        <div className="flex w-full justify-center">
            <div className="flex flex-col w-full md:w-2/3 space-y-2">
                <ChartEntry label="YTD" performanceValue={yearlyPerformance} />
                <ChartEntry
                    label="1 Jahr"
                    performanceValue={oneYearPerformance}
                />
                <ChartEntry
                    label="3 Jahre"
                    performanceValue={threeYearPerformance}
                />
                <ChartEntry
                    label="5 Jahre"
                    performanceValue={fiveYearPerformance}
                />
            </div>
        </div>
    );
};

const ChartEntry = ({
    label,
    performanceValue,
}: {
    label: string;
    performanceValue: number;
}) => {
    if (isNaN(performanceValue)) {
        return <></>;
    }

    return (
        <div className="flex text-sm leading-4">
            <span className="w-[25%] md:w-[12%] font-bold text-secondary">
                {label}
            </span>
            <div className="flex w-[50%] md:w-[76%]">
                <div className="flex justify-end w-1/2">
                    <div
                        className={classNames(
                            performanceValue < 0 && 'bg-negativeRed',
                            'h-4',
                        )}
                        style={{
                            width: `${Math.abs(performanceValue)}%`,
                        }}
                    ></div>
                </div>
                <div className="w-1/2">
                    <div
                        className={classNames(
                            performanceValue > 0 && 'bg-positiveGreen',
                            'h-4',
                        )}
                        style={{
                            width: `${Math.abs(performanceValue)}%`,
                        }}
                    ></div>
                </div>
            </div>
            <span
                className={classNames(
                    getColorForNumericValue(performanceValue.toString()),
                    'text-right w-[25%] md:w-[12%]',
                )}
            >
                {`${performanceValue.toLocaleString('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })} %`}
            </span>
        </div>
    );
};
