import { InstrumentDto } from '@boersenzeitung/shared/dtos/instrument.dto';
import React from 'react';

const UrlDataContext = React.createContext<{
    loading: boolean;
    currentInstrument?: InstrumentDto;
    currentTickerId?: string;
    currentFundIsin?: string;
    setCurrentInstrument: (company: InstrumentDto | undefined) => void;
    setCurrentTickerId: (tickerId: string | undefined) => void;
    setCurrentFundIsin: (fundIsin: string | undefined) => void;
}>({
    loading: true,
    currentInstrument: undefined,
    currentTickerId: undefined,
    currentFundIsin: undefined,
    setCurrentInstrument: () => {
        // do nothing
    },
    setCurrentTickerId: () => {
        // do nothing
    },
    setCurrentFundIsin: () => {
        // do nothing
    },
});
export const UrlDataProvider = UrlDataContext.Provider;
export default UrlDataContext;
