import { classNames } from '@utils/classNames';
import React from 'react';

const RADIO_CHECKED_SVG_CSS_STRING =
    "checked:!bg-[url('data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%20standalone%3D%22no%22%20%3F%3E%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0A%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20version%3D%221.1%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cdesc%3ECreated%20with%20Fabric.js%205.2.4%3C%2Fdesc%3E%0A%3Cdefs%3E%3C%2Fdefs%3E%0A%3Cg%20transform%3D%22matrix%281%200%200%201%208%208%29%22%20id%3D%22b26f7b88-319b-4067-850e-b849a25c000d%22%20%20%3E%0A%3Crect%20style%3D%22stroke%3A%20none%3B%20stroke-width%3A%201%3B%20stroke-dasharray%3A%20none%3B%20stroke-linecap%3A%20butt%3B%20stroke-dashoffset%3A%200%3B%20stroke-linejoin%3A%20miter%3B%20stroke-miterlimit%3A%204%3B%20fill%3A%20rgb%28255%2C255%2C255%29%3B%20fill-rule%3A%20nonzero%3B%20opacity%3A%201%3B%20visibility%3A%20hidden%3B%22%20vector-effect%3D%22non-scaling-stroke%22%20%20x%3D%22-8%22%20y%3D%22-8%22%20rx%3D%220%22%20ry%3D%220%22%20width%3D%2216%22%20height%3D%2216%22%20%2F%3E%0A%3C%2Fg%3E%0A%3Cg%20transform%3D%22matrix%281%200%200%201%208%208%29%22%20id%3D%22b9ad1d45-bf74-48aa-b6c4-347dd0082702%22%20%20%3E%0A%3C%2Fg%3E%0A%3Cg%20transform%3D%22matrix%281%200%200%201%208%208%29%22%20id%3D%224c92a5b2-f4e3-4a8a-b12d-3057bb06e33f%22%20%20%3E%0A%3Ccircle%20style%3D%22stroke%3A%20rgb%28255%2C255%2C255%29%3B%20stroke-width%3A%202%3B%20stroke-dasharray%3A%20none%3B%20stroke-linecap%3A%20butt%3B%20stroke-dashoffset%3A%200%3B%20stroke-linejoin%3A%20miter%3B%20stroke-miterlimit%3A%204%3B%20fill%3A%20rgb%280%2C0%2C0%29%3B%20fill-rule%3A%20nonzero%3B%20opacity%3A%201%3B%22%20vector-effect%3D%22non-scaling-stroke%22%20%20cx%3D%220%22%20cy%3D%220%22%20r%3D%226%22%20%2F%3E%0A%3C%2Fg%3E%0A%3C%2Fsvg%3E')]";

export default function RadioInput({
    checked,
    setValue,
    type = 'radio',
    disabled = false,
    placeholder,
    key,
    className,
    testId,
}: {
    checked?: boolean;
    setValue: () => void;
    type?: string;
    disabled?: boolean;
    placeholder?: string;
    className?: string;
    key?: string;
    testId?: string;
}) {
    return (
        <input
            value={checked ? 'true' : 'false'}
            type={type}
            checked={checked}
            onChange={setValue}
            disabled={disabled}
            placeholder={placeholder}
            key={key}
            className={classNames(
                className,
                'h-4 w-4 !border-black !bg-white rounded-full border-solid',
                'hover:!border-primary-color hover:enabled:!bg-secondary-color',
                'disabled:!bg-secondary-color disabled:!border-[#EAECF0] disabled:cursor-not-allowed',
                'checked:!bg-contain',
                RADIO_CHECKED_SVG_CSS_STRING,
            )}
            data-testid={testId}
        />
    );
}
