import { Access } from '@boersenzeitung/shared/generated';
import { CustomIcon } from '@components/custom-icon';
import {
    GERMAN_DATE_FORMAT,
    ISO_DATE_FORMAT,
} from '@boersenzeitung/shared/constants';
import { NewsDto } from '@boersenzeitung/shared/dtos/news.dto';
import { useLocation } from 'react-router-dom';
import ContentTierIcon from '@components/content-tier-icon';
import React, { useState } from 'react';
import apiClient from '@api/api.client';
import dayjs from '@boersenzeitung/shared/dayjs';
import purpleApiAdapter from '@purple/purple-api.adapter';

export function NewsCard({ newsItem }: { newsItem: NewsDto }) {
    const [isBookmarked, setIsBookmarked] = useState<boolean | undefined>(
        newsItem.bookmarked,
    );
    const location = useLocation();
    return (
        <div
            key={newsItem.uuid}
            className="flex flex-col bg-white p-5"
            data-testid="newsCard"
        >
            <div className="w-full text-left">
                <div className="flex flex-row justify-between text-lightgray text-xs font-sourceCode">
                    <div className="flex flex-row space-x-4">
                        <div className="font-bold">
                            {dayjs(
                                newsItem.publishedDate,
                                ISO_DATE_FORMAT,
                            ).format(GERMAN_DATE_FORMAT)}
                        </div>
                        <div className="uppercase font-semibold tracking-[.125rem]">
                            {newsItem.category}
                        </div>
                        {newsItem.access === Access.Purchasable &&
                            !newsItem.purchased && (
                                <ContentTierIcon
                                    contentTier={newsItem.contentTier}
                                />
                            )}
                    </div>
                    {isBookmarked !== undefined && (
                        <button
                            onClick={() => {
                                if (isBookmarked) {
                                    void apiClient
                                        .removeBookmark(newsItem.uuid)
                                        .then(() => setIsBookmarked(false));
                                } else {
                                    void apiClient
                                        .addBookmark(newsItem.uuid)
                                        .then(() => setIsBookmarked(true));
                                }
                            }}
                        >
                            <CustomIcon
                                className={isBookmarked ? 'text-red' : ''}
                                iconCode={
                                    isBookmarked
                                        ? String.raw`before:content-["\ea05"]`
                                        : String.raw`before:content-["\ea06"]`
                                }
                            />
                        </button>
                    )}
                </div>
                <button
                    onClick={() =>
                        purpleApiAdapter.redirectToPurpleContent(
                            newsItem.slug,
                            location.search,
                        )
                    }
                    className="text-left hover:cursor-pointer hover:text-red"
                >
                    <div className="text-xl font-semibold font-serif">
                        {newsItem.title}
                    </div>
                    <div className="text-secondary text-[.9375rem] leading-5 font-sans">
                        {newsItem.teaser}
                    </div>
                </button>
            </div>
        </div>
    );
}
