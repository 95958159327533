import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import React, { useCallback } from 'react';
import TextInput from '@pages/email-notification-widget/input-fields/text-input';
import apiClient from '@api/api.client';
import emailValidator from 'email-validator';

export default function RegisterEmailNotification() {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = React.useState<string | null>(null);
    const [firstname, setFirstname] = React.useState<string | null>(null);
    const [lastname, setLastname] = React.useState<string | null>(null);
    const [success, setSuccess] = React.useState<boolean | null>(null);

    const register = useCallback(() => {
        if (email && firstname && lastname) {
            apiClient
                .registerEmailNotification(email, firstname, lastname)
                .then(() => {
                    setSuccess(true);
                })
                .catch(() => {
                    setSuccess(false);
                });
        }
    }, [email, firstname, lastname]);

    return (
        <>
            <p className="mb-4">
                Hier können Sie sich für E-Mail-Alerts registrieren. Falls Sie
                bereits registriert sind, können Sie sich{' '}
                <button
                    onClick={() =>
                        navigate(`/email-notification${location.search}`)
                    }
                    className="underline"
                >
                    hier anmelden.
                </button>{' '}
            </p>
            {success === true && (
                <p className="mb-4 text-positive-color">
                    Wir haben ihnen eine E-Mail gesendet um Ihre Registrierung
                    abzuschließen.
                </p>
            )}
            {success === false && (
                <p className="mb-4 text-negative-color">
                    Ihre Registrierung konnte nicht abgeschlossen werden.
                </p>
            )}
            <div className="flex flex-col gap-2 mb-2">
                <TextInput
                    value={email ?? ''}
                    setValue={setEmail}
                    type="email"
                    placeholder="E-Mail-Adresse"
                    testId="email-input-register"
                />
                <TextInput
                    value={firstname ?? ''}
                    setValue={setFirstname}
                    placeholder="Vorname"
                    testId="firstname-input-register"
                />
                <TextInput
                    value={lastname ?? ''}
                    setValue={setLastname}
                    placeholder="Nachname"
                    testId="lastname-input-register"
                />
            </div>
            <button
                onClick={register}
                disabled={
                    !(
                        email &&
                        firstname &&
                        lastname &&
                        emailValidator.validate(email)
                    )
                }
                className="bg-primary-color disabled:bg-secondary-color !text-button-text-color px-6 hover:cursor-pointer py-2"
                data-testid="register-email-notification-button"
            >
                Registrieren
            </button>
        </>
    );
}
