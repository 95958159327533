import { classNames } from '@utils/classNames';
import React, { ReactElement } from 'react';

export type Props = {
    onEnter?: (value: string) => void;
    defaultValue?: string;
    disabled?: boolean;
    className?: string;
};

export const Input = ({
    onEnter,
    defaultValue,
    disabled,
    className,
}: Props): ReactElement => {
    const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onEnter?.(event.target.value as string);
        }
    };

    return (
        <input
            disabled={disabled}
            onKeyUp={handleEnter}
            defaultValue={defaultValue}
            placeholder="Suchbegriff..."
            className={classNames(
                'py-2 px-3 font-sans text-sm border border-solid border-spacer focus:ring-red focus:border-red active:ring-red active:border-red outline-none rounded-[2px]',
                className,
            )}
        />
    );
};
