import { Empty } from '@components/empty';
import { INITIAL_NEWS_BATCH_SIZE, NEWS_BATCH_SIZE } from '@utils/constants';
import { LoadMoreButton } from '@components/load-more-button';
import { NewsCard } from '@components/news-card';
import { NewsDto } from '@boersenzeitung/shared/dtos/news.dto';
import { NewsSearchResultDto } from '@boersenzeitung/shared/dtos/email-notification-seatch-term.dto';
import { NotificationType } from '@boersenzeitung/shared/dtos/email-notifications-user-data.dto';
import { ReactComponent as Spinner } from '@components/spinner.svg';
import { useEmailToken } from '@hooks/useEmailToken';
import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo } from 'react';
import apiClient from '@api/api.client';

export default function ListDetailPage() {
    const { emailToken, userData } = useEmailToken();
    const { identifier } = useParams();
    const [shownNews, setShownNews] = React.useState<NewsDto[]>([]);
    const [bufferedNews, setBufferedNews] = React.useState<NewsDto[]>([]);
    const [after, setAfter] = React.useState<string | undefined>();
    const [canLoadMore, setCanLoadMore] = React.useState(true);
    const [shouldLoadMore, setShouldLoadMore] = React.useState(true);
    const [loading, setLoading] = React.useState(false);

    const showLoadingOrEmpty = useCallback(() => {
        return loading ? (
            <div>
                <Spinner />
            </div>
        ) : (
            <Empty />
        );
    }, [loading, Spinner, Empty]);

    const notificationSetting = useMemo(() => {
        return userData?.notificationSettings.find(
            (setting) => setting.id === identifier,
        );
    }, [userData, identifier]);

    useEffect(() => {
        if (emailToken && identifier && shownNews.length === 0) {
            setLoading(true);
            apiClient
                .getSearchTermResults(
                    emailToken,
                    identifier,
                    undefined,
                    INITIAL_NEWS_BATCH_SIZE,
                )
                .then((newsSearchResults: NewsSearchResultDto) => {
                    setCanLoadMore(newsSearchResults.hasNextPage);
                    if (newsSearchResults.posts.length > 0) {
                        setAfter(
                            newsSearchResults.posts[
                                newsSearchResults.posts.length - 1
                            ].cursor,
                        );
                    }
                    if (newsSearchResults.posts.length > NEWS_BATCH_SIZE) {
                        const initialShownNews = newsSearchResults.posts.slice(
                            0,
                            NEWS_BATCH_SIZE,
                        );
                        setShownNews(initialShownNews);
                        const bufferedNews =
                            newsSearchResults.posts.slice(NEWS_BATCH_SIZE);
                        setBufferedNews(bufferedNews);
                        setShouldLoadMore(true);
                    } else {
                        setShownNews(newsSearchResults.posts);
                        setBufferedNews([]);
                        setShouldLoadMore(false);
                    }
                })
                .catch(() => {
                    setCanLoadMore(false);
                    setShouldLoadMore(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [emailToken, identifier]);

    const loadNextBatchOfNews = useCallback(() => {
        if (emailToken && identifier) {
            setLoading(true);
            setShouldLoadMore(canLoadMore);
            setShownNews((previouslyShownNews) => [
                ...previouslyShownNews,
                ...bufferedNews,
            ]);
            if (canLoadMore) {
                apiClient
                    .getSearchTermResults(
                        emailToken,
                        identifier,
                        after,
                        NEWS_BATCH_SIZE,
                    )
                    .then((newsSearchResults: NewsSearchResultDto) => {
                        setBufferedNews(newsSearchResults.posts);
                        setCanLoadMore(newsSearchResults.hasNextPage);
                        setAfter(
                            newsSearchResults.posts[
                                newsSearchResults.posts.length - 1
                            ].cursor,
                        );
                    })
                    .catch(() => {
                        setCanLoadMore(false);
                        setShouldLoadMore(false);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                setLoading(false);
            }
        }
    }, [
        emailToken,
        identifier,
        after,
        canLoadMore,
        shouldLoadMore,
        setLoading,
        setShouldLoadMore,
        setShownNews,
        setBufferedNews,
        setCanLoadMore,
        loading,
        apiClient,
    ]);

    const searchString = useMemo(() => {
        switch (notificationSetting?.type) {
            case NotificationType.HEADLINE:
                return 'Gefundene Artikel für Suchbegriff in Überschrift:';
            case NotificationType.FULLTEXT:
                return 'Gefundene Artikel für Suchbegriff in Volltext:';
            case NotificationType.COMPANY:
                return 'Gefundene Artikel für Unternehmen:';
            default:
                return '';
        }
    }, [notificationSetting]);

    return (
        <div>
            <h2 className="mb-4 flex gap-1 text-2xl">
                {' '}
                <p>{searchString}</p>
                <p className="font-bold">
                    {notificationSetting?.type === NotificationType.COMPANY
                        ? notificationSetting.companyName ??
                          notificationSetting.searchTerm
                        : notificationSetting?.searchTerm ?? ''}
                </p>
            </h2>
            {shownNews.length > 0 ? (
                <>
                    <div className="flex flex-col space-y-6">
                        {shownNews.map((newsItem) => {
                            return (
                                <NewsCard
                                    newsItem={newsItem}
                                    key={newsItem.uuid}
                                />
                            );
                        })}
                    </div>
                    {shouldLoadMore && (
                        <LoadMoreButton
                            loadMore={() => loadNextBatchOfNews()}
                            testId="loadMoreEmailNewsButton"
                            disabled={loading}
                        />
                    )}
                </>
            ) : (
                showLoadingOrEmpty()
            )}
        </div>
    );
}
