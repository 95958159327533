import { FundGraphData } from '@boersenzeitung/shared/dtos/fund.dto';
import { classNames } from '@utils/classNames';
import { getColorForNumericValue } from '@pages/funds/utils';
import React from 'react';

export const FundPerformanceTable = ({
    title,
    graphData,
}: {
    title: string;
    graphData: FundGraphData;
}) => {
    const performanceLabels = ['YTD', '1 Jahr', '3 Jahre', '5 Jahre'];
    const performanceValues = [
        parseFloat(graphData.performanceYtd),
        parseFloat(graphData.performanceOneYear),
        parseFloat(graphData.performanceThreeYear),
        parseFloat(graphData.performanceFiveYear),
    ];

    return (
        <div className="w-full">
            <p className="text-center font-bold mb-2">{title}*</p>
            <div className="h-[1px] bg-black" />
            <table className="w-full table-auto text-sm">
                <thead className="font-bold text-secondary">
                    <tr>
                        {performanceLabels.map((label) => {
                            return <th key={label}>{label}</th>;
                        })}
                    </tr>
                </thead>
                <tbody className="text-center">
                    <tr>
                        {performanceValues.map((value, index) => {
                            if (isNaN(value)) {
                                return <></>;
                            }

                            return (
                                <td
                                    key={`${value}${performanceLabels[index]}`}
                                    className={classNames(
                                        getColorForNumericValue(
                                            value.toString(),
                                        ),
                                    )}
                                >
                                    {`${value.toLocaleString('de-DE', {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                    })} %`}
                                </td>
                            );
                        })}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export const FundPriceTable = ({
    title,
    graphData,
    currency,
}: {
    title: string;
    graphData: FundGraphData;
    currency: string;
}) => {
    const priceLabels = ['Ausgabe', 'Rücknahme', 'NAV'];
    const priceValues = [
        parseFloat(graphData.issuePrice),
        parseFloat(graphData.repurchasePrice),
        parseFloat(graphData.netAssetValue),
    ];

    return (
        <div className="w-full">
            <p className="text-center font-bold mb-2">{title}*</p>
            <div className="h-[1px] bg-black" />
            <table className="w-full table-fixed text-sm">
                <thead className="font-bold text-secondary">
                    <tr>
                        {priceLabels.map((label) => {
                            return <th key={label}>{label}</th>;
                        })}
                    </tr>
                </thead>
                <tbody className="text-center">
                    <tr>
                        {priceValues.map((value, index) => {
                            if (isNaN(value)) {
                                return;
                            }

                            const valueDigits = value
                                .toString()
                                .split('.')[1].length;

                            return (
                                <td key={`${value}${priceLabels[index]}`}>
                                    {`${value.toLocaleString('de-DE', {
                                        minimumFractionDigits:
                                            valueDigits > 2 ? valueDigits : 2,
                                    })} ${currency}`}
                                </td>
                            );
                        })}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
