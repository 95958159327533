import {
    CompanyAppointmentDto,
    CompanyAppointmentsDividends,
    CompanyEvent,
    CorporateAction,
} from '@boersenzeitung/shared/dtos/company-appointment.dto';
import { CompanyAppointmentType } from '@boersenzeitung/shared/api.types';
import { DatesList } from '@components/dates-list';
import { Empty } from '@components/empty';
import { Helmet } from 'react-helmet';
import { TeaserText } from '@components/teaser-text';
import { TitledSeparator } from '@components/titled-separator';
import { useDocumentTitle } from '@hooks/useDocumentTitle';
import React, { useContext, useEffect, useState } from 'react';
import UrlDataContext from '../../contexts/urlDataContext';

export const getDateOfAppointment = (
    companyAppointment: CompanyAppointmentDto,
): string => {
    switch (companyAppointment.companyAppointmentType) {
        case CompanyAppointmentType.REPORTS_FIGURES:
        case CompanyAppointmentType.GENERAL_MEETINGS:
        case CompanyAppointmentType.EVENTS:
            return (companyAppointment.data as CompanyEvent).date;
        case CompanyAppointmentType.DIVIDENDS:
            return (companyAppointment.data as CompanyAppointmentsDividends)
                .exDay;
        case CompanyAppointmentType.CORPORATE_ACTIONS:
            return (companyAppointment.data as CorporateAction).exDay;
    }
};

export const getIsinOfAppointment = (appointment: CompanyAppointmentDto) =>
    Object.prototype.hasOwnProperty.call(appointment.data, 'isin')
        ? (Reflect.get(appointment.data!, 'isin') as string | undefined)
        : undefined;

export const datesSortFunction = (
    appointmentA: CompanyAppointmentDto,
    appointmentB: CompanyAppointmentDto,
) => {
    return getDateOfAppointment(appointmentB).localeCompare(
        getDateOfAppointment(appointmentA),
    );
};

export const Dates = () => {
    const { currentInstrument } = useContext(UrlDataContext);

    useDocumentTitle(
        currentInstrument?.company?.name
            ? `${currentInstrument.company.name} Termine - Quartalszahlen | Zwischenberichte`
            : 'Börsen-Zeitung',
    );

    const [companyAppointments, setCompanyAppointments] = useState<
        CompanyAppointmentDto[]
    >([]);

    useEffect(() => {
        const appointments: CompanyAppointmentDto[] = [];
        if (currentInstrument?.company?.isin) {
            const allowEntriesWithoutIsin =
                currentInstrument.company.isin === currentInstrument.isin;

            currentInstrument.company.appointments.forEach((entry) => {
                const isin = getIsinOfAppointment(entry);
                if (isin) {
                    if (isin === currentInstrument.isin) {
                        appointments.push(entry);
                    }
                } else if (allowEntriesWithoutIsin) {
                    appointments.push(entry);
                }
            });
        }

        appointments.sort(datesSortFunction);
        setCompanyAppointments(appointments);
    }, [currentInstrument?.company?.isin, currentInstrument]);

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content={`Alle Termine der  ${currentInstrument?.company?.name}. Quartals- & Geschäftsberichte, Stichtage, Hauptversammlung, uvm. Jetzt informieren!`}
                />
            </Helmet>
            <div>
                <TeaserText>
                    {`Alle Termine der ${currentInstrument?.company?.name} im Überblick: Quartalszahlen, Geschäftsberichte, Stichtage, Hauptversammlung und vieles mehr. Die Daten werden tagesaktuell geprüft und bei Änderungen aktualisiert, sodass Sie immer auf dem neusten Stand sind.`}
                </TeaserText>
                <TitledSeparator title="Quartalszahlen, Geschäftsberichte und Hauptversammlung" />
                {companyAppointments.length > 0 ? (
                    <DatesList appointments={companyAppointments} />
                ) : (
                    <Empty />
                )}
            </div>
        </>
    );
};
