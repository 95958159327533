import { CompanyDto } from '@boersenzeitung/shared/dtos/Company.dto';
import { DividendDto } from '@boersenzeitung/shared/dtos/dividend.dto';
import { DividendEntry } from '@components/dividend-entry';
import { Empty } from '@components/empty';
import { InstrumentDto } from '@boersenzeitung/shared/dtos/instrument.dto';
import { TeaserText } from '@components/teaser-text';
import { TitledSeparator } from '@components/titled-separator';
import React, { useEffect, useState } from 'react';

export const OverviewDividends = ({
    company,
    url,
    instrument,
}: {
    company: CompanyDto;
    url: string;
    instrument: InstrumentDto;
}) => {
    const [dividendResults, setDividendResult] = useState<
        DividendDto[] | undefined
    >();
    useEffect(() => {
        if (company.isin) {
            setDividendResult(instrument.dividends);
        }
    }, [company.isin]);

    return (
        <div className="mb-4">
            <TitledSeparator
                title={'Dividenden'}
                moreActionTarget={`/aktie/dividenden${
                    company.isin && url ? '/' + url + '-' + company.isin : ''
                }`}
            />
            <div>
                <TeaserText>
                    Aktuelle sowie historische Informationen zu Dividenden mit
                    Ex-Tag und Zeitraum tabellarisch aufbereitet.
                </TeaserText>
                {dividendResults?.length ? (
                    <div className="overflow-x-auto">
                        <table className="table-auto w-full">
                            <tbody>
                                <DividendEntry dividend={dividendResults[0]} />
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <Empty />
                )}
            </div>
        </div>
    );
};
