import { Button } from '@components/button';
import React from 'react';

export const LoadMoreButton = ({
    loadMore,
    testId,
    title,
    pending,
    disabled,
}: {
    loadMore: () => void;
    testId?: string;
    title?: string;
    pending?: boolean;
    disabled?: boolean;
}) => {
    return (
        <div className="flex justify-center">
            <div className="mt-10 ">
                <Button
                    testId={testId}
                    title={title ?? 'Weitere Artikel anzeigen'}
                    onClick={() => loadMore()}
                    canDisplayPendingState={false}
                    className="bg-transparent"
                    pending={pending}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};
