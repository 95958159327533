import { Bookmark } from './types';
import {
    RouteState,
    RouteStateChanged,
    changeRouteListener,
    communicationListener,
} from '@purple/purple-experience-router.type';
import { convertRecordToMap } from '@utils/map-record-transformer';
import { purple } from './purple-api';
import qs from 'qs';

export type PurpleWindow = {
    purple?: typeof purple;
} & typeof window;

export class PurpleApiAdapter {
    private readonly purpleApi: typeof purple;

    private routeListeners: changeRouteListener[];

    private communicationListeners: communicationListener[] = [];

    private purpleListener = (event: RouteStateChanged) => {
        this.routeListeners.forEach((listener) => {
            listener(event);
        });
    };

    constructor() {
        const purpleWindow = window as PurpleWindow;
        if (!purpleWindow.purple) {
            console.log('did not find purple, using debug client');
            purpleWindow.purple = purple;
        }
        this.purpleApi = purpleWindow.purple;
        this.routeListeners = [];

        void purpleWindow.purple.experience.router.addRouteChangeListener(
            this.purpleListener,
        );
    }

    getCurrentRoute = async (): Promise<RouteState> => {
        return await this.purpleApi.experience.router.getCurrentRoute();
    };

    getBookmarks(): Bookmark[] {
        return [];
    }

    createBookmark(_bookmark: Bookmark): void {
        //do Nothing
    }

    addRouteListener(listenerFn: changeRouteListener): void {
        this.routeListeners.push(listenerFn);
    }

    addCommunicationListener(listener: communicationListener): void {
        this.communicationListeners.push(listener);
    }

    removeRouteListener(listener: changeRouteListener): void {
        this.routeListeners = this.routeListeners.filter(
            (value) => value !== listener,
        );
    }

    getEntitlementToken(): Promise<{ accessToken?: string }> {
        return this.purpleApi.entitlement.getUserData() as unknown as Promise<{
            accessToken?: string;
        }>;
    }

    sendUrlChangeToPurple(newUrl: string, queryParams: string) {
        void this.purpleApi.experience.router.changeRoute({
            path: newUrl,
            queryParams: convertRecordToMap(
                qs.parse(queryParams.replace('?', '')),
            ),
            stateSender: 'widget',
        });
        this.communicationListeners.forEach((listener) => {
            listener(newUrl);
        });
    }

    redirectToPurpleContent(slug: string, queryParams: string) {
        void this.purpleApi.experience.router.changeRoute({
            path: slug,
            stateSender: 'widget',
            forceReload: true,
            queryParams: convertRecordToMap(
                qs.parse(queryParams.replace('?', '')),
            ),
        });
    }
}

const purpleApiAdapter = new PurpleApiAdapter();
export default purpleApiAdapter;
