import { Empty } from '@components/empty';
import { Helmet } from 'react-helmet';
import { OverviewAnalysis } from '@components/company-overview/overview-analysis';
import { OverviewAppointments } from '@components/company-overview/overview-appointments';
import { OverviewDividends } from '@components/company-overview/overview-dividends';
import { OverviewNews } from '@components/company-overview/overview-news';
import { OverviewStockPrices } from '@components/company-overview/overview-stock-prices';
import { OverviewTicker } from '@components/company-overview/overview-ticker';
import { TeaserText } from '@components/teaser-text';
import { parseUrlCompanyNamePart } from '@boersenzeitung/shared/parseUrlCompanyPart';
import { useDocumentTitle } from '@hooks/useDocumentTitle';
import React, { useContext } from 'react';
import urlDataContext from '../../contexts/urlDataContext';

export const Overview = () => {
    const { currentInstrument } = useContext(urlDataContext);
    const url = parseUrlCompanyNamePart(currentInstrument?.company?.name);

    useDocumentTitle(
        currentInstrument?.company?.name
            ? `${currentInstrument.company.name} Aktie - News | Kurse | Analysen | Termine`
            : 'Börsen-Zeitung',
    );

    if (!currentInstrument?.company || !url) {
        return <Empty />;
    }

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content={`Alle relevanten Informationen zur Aktie der ${currentInstrument.company.name}. Aktuelle Kurse, Nachrichten, Ticker/Ad-hoc, Analysen, Dividenden & Termine.`}
                />
            </Helmet>
            <div>
                <TeaserText>
                    {`Auf dieser Seite sehen sie alle relevanten Informationen zur Aktie der ${currentInstrument.company.name}. Profitieren Sie von aktuellen Kursentwicklungen, Nachrichten, Ticker-/Ad-Hoc-Meldungen, Aktienanalysen, Dividenden und Terminen der ${currentInstrument.company.name}.`}
                </TeaserText>
                <div className="space-y-16">
                    <OverviewStockPrices
                        company={currentInstrument.company}
                        url={url}
                        instrument={currentInstrument}
                    />
                    <OverviewNews
                        company={currentInstrument.company}
                        url={url}
                    />
                    <OverviewTicker
                        company={currentInstrument.company}
                        url={url}
                    />
                    <OverviewAnalysis
                        company={currentInstrument.company}
                        url={url}
                    />
                    <OverviewDividends
                        company={currentInstrument.company}
                        url={url}
                        instrument={currentInstrument}
                    />
                    <OverviewAppointments
                        company={currentInstrument.company}
                        url={url}
                    />
                </div>
            </div>
        </>
    );
};
