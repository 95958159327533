// noinspection JSUnusedGlobalSymbols

import {
    changeRouteListener,
    ExperienceRouter,
    RouteState,
} from '@purple/purple-experience-router.type';
import { RouteChangedEvent } from '@purple/types';

/**
 * NOTE: All Promise-based APIs can throw the following error codes:
 * * ILLEGAL_ARGUMENTS (If the params object is invalid or params are missing)
 * * UNKNOWN (An unknown error has occurred, check message for details)
 */
export const purple = {
    /**
     * Changelog:
     * Version 1.0: Initial version
     * Version 1.1: New consent-API (only 5.2)
     * Version 2.0: New catalog-API, new content-API
     * Version 2.1: New consent-API, new issuePager-API, new share-API, new bookmark-API, new entitlement-API
     * Version 3.0: Remove kiosk- and storefront-API, new account-API
     */
    version: {
        major: 3,
        minor: 0,
        toString: function () {
            return this.major + '.' + this.minor;
        },
    },
    /**
     * @typedef {Object} ErrorResult
     * @property {string} code
     * @property {string} message
     */

    /**
     * @since 3.0
     */
    account: {
        /**
         * Changelog:
         * Version 1.0: Initial version
         */
        version: {
            major: 1,
            minor: 0,
            toString: function () {
                return this.major + '.' + this.minor;
            },
        },
        /**
         * @typedef {Object} AccountUserData
         *
         * @property {string} [token]
         */

        /**
         * @typedef {Object} AccountLoginParams
         *
         * @property {string} token The users access token, needs to be a JSON Web Token (JWT).
         */

        /**
         * @params {AccountLoginParams} params
         * @returns {Promise<void>}
         * @throws {ErrorResult} Possible error codes: ALREADY_LOGGED_IN
         * @since 1.0
         */
        login: function (params) {},
        /**
         * @returns {Promise<void>}
         * @throws {ErrorResult} Error codes: NOT_LOGGED_IN
         * @since 1.0
         */
        logout: function () {},
        /**
         * Get the current user data.
         *
         * @returns {Promise<AccountUserData>}
         * @throws {ErrorResult}
         * @since 1.0
         */
        getUserData: function () {},

        /**
         * @typedef {('UNASSIGNED'|'REASSIGN'|'ASSIGN')} AssignLocalPurchasesMode
         *
         * - UNASSIGNED: only assign receipts to this user that currently don't have a user assigned
         * - REASSIGN: assign receipts to this user and remove any other user assignments
         * - ASSIGN: assign receipts to this user while keeping other user assignments
         */
        /**
         * @typedef {Object} AssignLocalPurchasesParams
         *
         * @property {AssignLocalPurchasesMode} mode
         */

        /**
         * Assign local purchases to the currently set account.
         *
         * @params {AssignLocalPurchasesParams} params
         * @returns {Promise<void>}
         * @throws {ErrorResult} Possible error codes:
         * - NETWORK
         * - NOT_LOGGED_IN
         * - INVALID_ACCOUNT_TOKEN (The account token is not valid)
         * - UNSUPPORTED_ACCOUNT_TOKEN (The account token is not in a supported format)
         * @since 1.0
         */
        assignLocalPurchases: function (params) {},
    },
    /**
     * @since 1.0
     */
    appBrowser: {
        /**
         * Changelog:
         * Version 1.0: Initial version
         * Version 2.0:
         * * Deprecated getDisplayMode, isTitleBarEnabled, isControlsEnabled and isStatusBarEnabled
         * * Added getDisplayConfig-API
         */
        version: {
            major: 2,
            minor: 0,
            toString: function () {
                return this.major + '.' + this.minor;
            },
        },
        /**
         * @typedef {('EMBEDDED', 'MODAL')} DisplayMode
         */

        /**
         * @typedef {Object} DisplayConfig
         *
         * @property {DisplayMode} displayMode
         * @property {boolean} titleBarEnabled
         * @property {boolean} controlsEnabled
         * @property {boolean} statusBarEnabled
         */

        /**
         * Get information about the configuration of the current webview, e.g. if it’s displayed modally or embedded, has titlebar and controls.
         *
         * @returns {Promise<DisplayConfig>}
         * @throws {ErrorResult}
         * @since 2.0
         */
        getDisplayConfig: function () {},
    },
    /**
     * @since 1.0
     */
    app: {
        /**
         * Changelog:
         * Version 1.0: Initial version
         * Version 1.1: Add performActionUrl-API
         * Version 1.2: Add performAuthentication-API
         */
        version: {
            major: 1,
            minor: 2,
            toString: function () {
                return this.major + '.' + this.minor;
            },
        },

        /**
         * @typedef {('ONLINE'|'OFFLINE')} ConnectionState
         */

        /**
         * @typedef {('TYPE_3G'|'TYPE_WLAN')} ConnectionType
         */

        /**
         * @typedef {Object} ConnectionInfo
         *
         * @property {ConnectionState} state
         * @property {ConnectionType} [type] If not offline, the type of the current connection is provided
         */

        /**
         * Adds a listener for connection state changes.
         * The listener will be called with a {@link ConnectionInfo} object.
         * The listener will also be called with the current state right calling this method.
         *
         * @param listener
         * @since 1.0
         */
        addConnectionStateListener: function (listener) {},
        /**
         * Removes a listener for connection state changes.
         *
         * @param listener a previously registered listener
         * @since 1.0
         */
        removeConnectionStateListener: function (listener) {},

        /**
         * @typedef {('STARTED', 'RESUMED', 'PAUSED', 'STOPPED')} LifecycleEventType
         */

        /**
         * @typedef {Object} LifecycleEvent
         *
         * @property {LifecycleEventType} type
         */

        /**
         * Adds a listener for lifecycle changes.
         * The listener will be called with a {@link LifecycleEvent} object.
         * The listener will also be called with the current state after calling this method.
         *
         * @param listener
         * @since 1.0
         */
        addLifecycleListener: function (listener) {},
        /**
         * Removes a listener for lifecycle changes.
         *
         * @param listener
         * @since 1.0
         */
        removeLifecycleListener: function (listener) {},

        /**
         * Close the onboarding screen.
         *
         * NOTE: This API is only available from within the onboarding HTML.
         *
         * @param [showAgain] If true, the onboarding will be shown again on the next app start. Defaults to false.
         * @since 1.0
         */
        closeOnboarding: function (showAgain) {},

        /**
         * @typedef {Object} PerformActionUrlParams
         *
         * @property {string} actionUrl
         */

        /**
         * Execute an Action-URL.
         *
         * @param {PerformActionUrlParams} params
         * @returns {Promise<void>}
         * @throws {ErrorResult} NOT_HANDLED
         * @since 1.1
         */
        performActionUrl: function (params) {},

        /**
         * @typedef {Object} AuthenticationParams
         *
         * @property {string} url
         * @property {string} callbackParamName
         */

        /**
         * @typedef {Object} AuthenticationResult
         *
         * @property {Map<string, string>} values
         */

        /**
         * Start a generic authentication flow
         *
         * @param {AuthenticationParams} params
         * @returns {Promise<AuthenticationResult>}
         * @throws {ErrorResult} CANCELLED
         * @since 1.2
         */
        performAuthentication: function (params) {},
    },
    /**
     * @since 2.1
     */
    bookmark: {
        /**
         * Changelog:
         * Version 1.0: Initial version
         * Version 1.1:
         * - Allow creation of bookmarks from outside of content
         * - Add customData, pageLabel and pageIndex to Bookmark
         * - pageId is now nullable
         * - Removed NOT_AVAILABLE error code
         * - createBookmark will not trigger APP_BOOKMARK_ADDED event
         * - deleteBookmark will not trigger APP_BOOKMARK_DELETED event
         * Version 2.0:
         * - split Bookmark into IssueBookmark, PostBookmark, BundleBookmark
         * - add postId, bundleId, remove issueType in CreateBookmarkParams
         */
        version: {
            major: 2,
            minor: 0,
            toString: function () {
                return this.major + '.' + this.minor;
            },
        },

        /**
         * @typedef {('ARTICLE', 'ISSUE')} IssueType
         */

        /**
         * @typedef {Object} Bookmark
         *
         * @property {CatalogContentType} contentType
         * @property {string} id unique id of the bookmark
         * @property {string} title
         * @property {string} description
         * @property {string} contentId
         * @property {boolean} sharingEnabled
         * @property {string} [sharingText]
         * @property {string} [sharingURL]
         * @property {string} [thumbnailURL]
         * @property {Map<String, String>} customData
         * @property {number} createdAt date of create for the bookmark, in milliseconds since January 1, 1970, 00:00:00 GMT
         */

        /**
         * @typedef {Object} IssueBookmark
         * @extends Bookmark
         * Note: contentType is always 'ISSUE'
         *
         * @property {string} issueId NOTE: deprecated in favor of contentId
         * @property {IssueType} issueType NOTE: deprecated, always "ISSUE"
         * @property {string} [pageId]
         * @property {string} [pageLabel]
         * @property {number} [pageIndex]
         */

        /**
         * @typedef {Object} PostBookmark
         * @extends Bookmark
         * Note: contentType is always 'POST'
         */

        /**
         * @typedef {Object} BundleBookmark
         * @extends Bookmark
         * Note: contentType is always 'BUNDLE'
         *
         * @property {string} [postId]
         */

        /**
         * @typedef {Object} CreateBookmarkParams
         *
         * @property {string} title
         * @property {string} description
         * @property {string} [bundleId] Required for bookmarks of bundles
         * @property {string} [postId] Required for bookmarks of posts, can be set along with bundleId as additional info in BundleBookmark
         * @property {string} [issueId] Required unless used in issue context
         * @property {string} [thumbnailURL]
         * @property {boolean} [sharingEnabled]
         * @property {string} [sharingText] Required when sharingEnabled is set to true
         * @property {string} [sharingURL] Required when sharingEnabled is set to true
         * @property {Map<String, String>} [customData]
         */

        /**
         * @typedef {Object} CreateBookmarkResult
         *
         * @property {Bookmark} bookmark the created bookmark
         */

        /**
         * Create a new bookmark for the current page.
         *
         * @param {CreateBookmarkParams} params
         * @returns {Promise<CreateBookmarkResult>}
         * @throws {ErrorResult}
         * @since 1.0
         */
        createBookmark: function (params) {},

        /**
         * @typedef {Object} GetBookmarksResult
         *
         * @property {Bookmark[]} bookmarks
         */

        /**
         * Get currently saved bookmarks
         *
         * @returns {Promise<GetBookmarksResult>}
         * @throws {ErrorResult}
         * @since 1.0
         */
        getBookmarks: function () {},
        /**
         * @typedef {Object} DeleteBookmarkParams
         *
         * @property {string} id ID of the bookmark
         */

        /**
         * Delete a bookmark
         *
         * @param {DeleteBookmarkParams} params
         * @returns {Promise<void>}
         * @throws {ErrorResult} NOT_FOUND
         * @since 1.0
         */
        deleteBookmark: function (params) {},
    },
    /**
     * @since 2.0
     */
    catalog: {
        /**
         * Changelog:
         * Version 1.0: Initial version
         * Version 1.1:
         * - Allow filtering issues by publication date
         * - Allow filtering categories by issue metadata
         * - Allow sorting of publications by their latest issues publication date
         * - Add methods to start, pause and delete multiple issues
         * - Add sortBy option for search
         * - Allow syncing issue metadata to prepare for content.open without metadata sync
         * - getLocalIssues will now also return issues that have the state NOT_INSTALLED
         * Version 1.2:
         * - add groupId, eligibilityInfo and currentReceiptInfo to AppSubscription
         * - add language to Publication and PublicationFilter
         * Version 1.3:
         * - add getPublicationProducts-API
         * - add elementAlias to PageHit
         * - add purchased to SubscriptionFilter
         * - add type to PublicationFilter
         * - add type to IssueFilter
         * - add type to CatalogIssue
         * - add HistoricReceiptInfo to AppSubscription
         * - add discountOffers to EligibilityInfo
         * - add isTrialPeriod and isIntroOfferPeriod to CurrentReceiptInfo
         * - add getMetadata-API
         * Version 1.4:
         * - add productId to IssueFilter, SubscriptionFilter, PublicationProductFilter
         * - add getProducts-API
         * - add issue filter to getPublicationProducts-API
         * Version 1.5:
         * - add REPEATABLE_ISSUE_PURCHASE type for PublicationProduct
         * - add type to PublicationProductFilter
         * - add includesLatestIssue to PublicationProduct
         * Version 1.6:
         * - add getContents-API:
         *   - add getContents, GetContentsParams
         *   - add ContentComparator, ContentFetchOptions
         *   - add ContentFilter, ContentListFilter, ContentListContentFilter
         *   - add CatalogContent, CatalogContentType, ContentTypeFilter
         *   - add CatalogPost, PostBlock, Author, AuthorsFilter
         *   - add CatalogBundle, BundledContent
         *   - add Access, PurchaseData, AccessFilter
         *   - change CatalogIssue to extend CatalogContent
         * - change Issue API:
         *   - deprecate getIssues, GetIssuesParams
         *   - deprecate IssueFilter, IssueListFilter, IssueListContentFilter
         * - change Publication API:
         *   - add contents, deprecate issues in CatalogPublication
         *   - add contents, deprecate issues in PublicationFilter
         *   - add includeContents, deprecate includeIssues in GetPublicationsParams
         *   - add contentsParams, deprecate issuesParams in GetPublicationsParams
         * - change PublicationProducts API:
         *   - add UnlockableContent, deprecate UnlockableIssue
         *   - add unlockableContents, deprecate unlockableIssues in PublicationProductPublication
         *   - add maxUnlockableContents, deprecate maxUnlockableIssues in GetPublicationProductsParams
         *   - add unlockableContentsFilter, deprecate unlockableIssuesFilter in GetPublicationProductsParams
         *   - add includesLatestContent, deprecate includesLatestIssue in PublicationProduct
         * - change Subscription API:
         *   - add unlocksAllContentDuringPeriod in AppSubscription
         *   - deprecate unlocksAllContentDuringPeriod in PublicationSubscription
         *   - add SubscriptionComparator
         *   - add sort in GetSubscriptionsParams
         * - change Search API:
         *   - add searchPhrase, deprecate phrase in SearchParams
         *   - add contentFilter, deprecate issueFilter in SearchParams
         *   - add sort in SearchParams
         *   - add pageSort, deprecate sortPages, sortBy in SearchOptions
         *   - add IssuePageComparator, deprecate SearchResultSortCriteria
         *   - change Result of search to Promise<Page<ContentSearchResult>>
         *   - change IssueSearchResult to extend ContentSearchResult
         *   - add ContentSearchResultComparator, RelevanceComparator
         *   - add ContentSearchResult, PostSearchResult, BundleSearchResult
         *   - rename PageHit to IssuePageResult
         * - add getCollections-API:
         *   - add CatalogCollection, CollectionElement, ContentElement
         *   - add GetCollectionsParams, CollectionFilter, CollectionComparator, GetElementsParams
         * - add getTaxonomies-API
         *   - deprecate getCategories-API, tags and categories on CatalogIssue
         *   - add GetTaxonomiesParams, TaxonomyFilter, TaxonomyComparator
         *   - add CatalogTaxonomy, CatalogTaxonomySummary
         *   - add taxonomies on CatalogPost and CatalogBundle
         * - add getMenus-API
         */
        version: {
            major: 1,
            minor: 6,
            toString: function () {
                return this.major + '.' + this.minor;
            },
        },
        /**
         * @typedef {Object} Page
         *
         * @property {boolean} hasNextPage
         * @property {number} totalCount
         * @property {string} cursor cursor of last item in nodes
         * @property {T[]} nodes
         *
         * @template T
         */

        /**
         * @typedef {("KIOSK"|"CHANNEL")} PublicationType
         */

        /**
         * @typedef {Object} CatalogPublication
         * @property {string} id
         * @property {string} name
         * @property {string} description
         * @property {string} [language] Language of this publication in ISO 639-1. Value is null if no language is specified.
         * @property {PublicationType} type
         * @property {number} index
         * @property {string} [currentIssueId] NOTE: deprecated in favor of currentContentId
         * @property {string} [currentContentId]
         * @property {boolean} printSubscriptionEnabled NOTE: deprecated, always false
         * @property {boolean} freeConsumable NOTE: deprecated, always false
         * @property {Map<string, string>} properties
         * @property {Map<string, string>} thumbnails The default thumbnail is always available under the default-key. Additional thumbnails can be available under separate unique keys.
         * @property {Page<CatalogIssue>} [issues] null when not queried, else contains a page of issues for this publication. NOTE: deprecated in favor of contents. Will only be set when used with the also deprecated includeIssues.
         * @property {Page<CatalogContent>} [contents] null when not queried, else contains a page of contents for this publication
         */

        /**
         * @typedef {("APP_STORE_PURCHASE"|"APP_STORE_SUBSCRIPTION"|"ENTITLEMENT"|"SUBSCRIPTION_CODE"|"TRIAL"|"PUBLICATION_PRODUCT")} PurchaseOption
         */

        /**
         * @typedef {('ISSUE'|'POST'|'BUNDLE')} CatalogContentType
         */

        /**
         * @typedef {Object} PurchaseData
         *
         * @property {boolean} purchased
         * @property {PurchaseOption[]} purchasedBy
         */

        /**
         * @typedef {('FREE'|'HIDDEN'|'PURCHASABLE')} Access
         */

        /**
         * @typedef {Object} CatalogContent
         *
         * @property {CatalogContentType} contentType
         * @property {string} id
         * @property {string} publicationId ID of publication
         * @property {number} version
         * @property {string} name
         * @property {string} description
         * @property {string} [alias] null, if not available
         * @property {string} [externalId] null, if not available
         * @property {number} publicationDate
         * @property {number} index
         * @property {string} [productId]
         * @property {Access} access
         * @property {PurchaseData} purchaseData
         * @property {Map<string, string>} properties
         * @property {Map<string, string>} thumbnails The default thumbnail is always available under the default-key. Additional thumbnails can be available under separate unique keys.
         */

        /**
         * @typedef {Object} CatalogIssue
         * @extends CatalogContent
         *
         * @property {string} [externalIssueId] null, if not available. Deprecated, use externalId instead
         * @property {IssueType} type NOTE: deprecated, always "ISSUE"
         * @property {boolean} comingSoon NOTE: deprecated
         * @property {number} contentLength
         * @property {number} numberOfPages
         * @property {boolean} purchasable NOTE: deprecated in favor of access
         * @property {boolean} purchased NOTE: deprecated in favor of purchaseData
         * @property {PurchaseOption[]} purchasedBy NOTE: deprecated in favor of purchaseData
         * @property {CatalogPreviewIssue} [previewIssue]
         * @property {CatalogIssueState} state
         * @property {string[]} categories NOTE: deprecated, always empty
         * @property {string[]} tags NOTE: deprecated, always empty
         */

        /**
         * @typedef {Object} CatalogPreviewIssue
         *
         * @property {string} id
         * @property {number} version
         * @property {number} contentLength
         * @property {number} numberOfPages
         * @property {CatalogIssueState} state
         */

        /**
         * @typedef {Object} LocalCatalogIssue
         *
         * @property {string} id
         * @property {number} version
         * @property {string} name
         * @property {string} publicationId
         * @property {CatalogIssueState} state
         */

        /**
         * @typedef {Object} LocalCatalogPreviewIssue
         *
         * @property {string} id
         * @property {number} version
         * @property {string} publicationId ID of publication
         * @property {string} issueId ID of parent issue
         * @property {CatalogIssueState} state
         */

        /**
         * @typedef {Object} PostBlock
         *
         * @property {string} id
         * @property {string} type
         * @property {string} [parentId]
         * @property {string[]} children IDs of child blocks
         * @property {number} sequence
         * @property {number} level
         * @property {string} html
         * @property {Map<string, string>} properties
         */

        /**
         * @typedef {Object} Author
         *
         * @property {string} name
         * @property {string} [email]
         */

        /**
         * @typedef {Object} CatalogPost
         * @extends CatalogContent
         *
         * @property {string} [bundleId] content id of the bundle this post belongs to
         * @property {string} postType
         * @property {Author[]} authors
         * @property {PostBlock[]} [content] null, if not includeBlocks
         * @property {PostBlock[]} [previewContent] null, if not includeBlocks
         * @property {string} [contentHtml] null, if not includeHtml
         * @property {string} [previewContentHtml] null, if not includeHtml
         * @property {ContentResource} [resources] null, if not includeResources
         * @property {ContentResource} [previewResources] null, if not includeResources
         * @property {CatalogTaxonomySummary[]} taxonomies
         */

        /**
         * @typedef {Object} ContentResource
         *
         * @property {string} id
         * @property {ContentResourceType} type
         * @property {number} contentLength
         * @property {string} url
         * @property {Map<string, string>} properties
         */

        /**
         * @typedef {('ASSET'|'CONTENT_BUNDLE')} ContentResourceType
         */

        /**
         * @typedef {Object} BundledContent
         *
         * @property {string} id
         * @property {CatalogPost} post
         */

        /**
         * @typedef {Object} CatalogBundle
         * @extends CatalogContent
         *
         * @property {BundledContent[]} [contents] null, if not includeBundledContent
         * @property {CatalogTaxonomySummary[]} taxonomies
         * @property {Author[]} authors
         */

        /**
         * @typedef {Object} EligibilityInfo
         *
         * @property {boolean} [trial] Is user eligible for trial periods. Returns true/false if the server knows that the user can/cannot use a trial or null if not enough data is available to determine eligibility.  Trial is only available when returned in store.getPrice-API (PriceInfo).
         * @property {boolean} introductoryPricing Is user eligible for introductory pricing for this subscription. Introductory pricing is only available when returned in store.getPrice-API (PriceInfo).
         * @property {boolean} discountOffers Is user eligible for discounted pricing for this subscription. Discounted pricing is only available when returned in store.getPrice-API (PriceInfo).
         */

        /**
         * @typedef {Object} CurrentReceiptInfo
         *
         * @property {boolean} isTrialPeriod Current subscription period is a trial period.
         * @property {boolean} isIntroOfferPeriod Current subscription period is an introductory pricing period.
         * @property {number} expirationDate When purchased, the date of expiration for the current period, in milliseconds since epoch.
         * @property {number} [autoResumeDate] For paused subscription, the date when the subscription would resume, in milliseconds since epoch. NOTE: A paused subscription will have purchased set to false and a non-null value of autoResumeDate.
         * @property {boolean} autoRenewing When false the subscription was cancelled by the user and will end after the current subscription period.
         */

        /**
         * @typedef {Object} HistoricReceiptInfo
         *
         * @property {boolean} hadPurchased User has purchased this subscription in the past.
         * @property {boolean} hadIntroductoryPricing User has previously used introductory pricing for this subscription.
         * @property {boolean} hadTrial User has previously used a trial for this subscription.
         */

        /**
         * @typedef {Object} PublicationSubscription
         * @property {string} publicationId
         * @property {boolean} unlocksAllContentDuringPeriod NOTE: deprecated in favor of AppSubscription.unlocksAllContentDuringPeriod
         */

        /**
         * @typedef {('EQUAL'|'CONTAINS'|'STARTS_WITH'|'GREATER_THAN'|'GREATER_THAN_OR_EQUAL'|'LESS_THAN'|'LESS_THAN_OR_EQUAL'|'REGEX')} StringOperation
         */

        /**
         * @typedef {('GREATER_THAN'|'LESS_THAN'|'EQUAL')} DateOperation
         */

        /**
         * @typedef {('GREATER_THAN'|'LESS_THAN'|'EQUAL')} IntOperation
         */

        /**
         * @typedef {('CONTAINS'|'CONTAINS_ONLY')} ListOperation
         */

        /**
         * @typedef {Object} StringFilter
         *
         * @property {boolean} [negated] Defaults to false
         * @property {StringOperation} [operation] Defaults to EQUAL
         * @property {string} [value] Required for StringOperation.CONTAINS and StringOperation.STARTS_WITH
         */

        /**
         * @typedef {Object} BooleanFilter
         *
         * @property {boolean} value
         */

        /**
         * @typedef {Object} DateFilter
         *
         * @property {boolean} [negated] Defaults to false
         * @property {DateOperation} [operation] Defaults to EQUAL
         * @property {string} value A date to compare to.<br/>
         *                          The month, date and time components are optional. Examples: <br/>
         *                          "2019" => matches everything in 2019 (whole year) <br/>
         *                          "2019-12" => matches everything in December 2019 (whole month) <br/>
         *                          "2019-12-31" => matches everything from the 31th of December 2019 (whole day)  <br/>
         *                          "2019-12-31T14:10" => matches everything from the 31th of December 2019, at 14:10 (whole minute)  <br/>
         *                          "2019-12-31T14:10:20" => matches everything from the 31th of December 2019, at 14:10:20  <br/>
         */

        /**
         * @typedef {Object} IntFilter
         *
         * @property {boolean} [negated] Defaults to false
         * @property {IntOperation} [operation] Defaults to EQUAL
         * @property {number} value
         */

        /**
         * @typedef {Object} MapFilter
         *
         * @property {boolean} [negated] Defaults to false
         * @property {StringOperation} [keyOperation] Defaults to EQUAL
         * @property {StringOperation} [operation] Defaults to EQUAL
         * @property {string} key
         * @property {string} value
         */

        /**
         * @typedef {Object} StringListFilter
         * @description Either content or size must be provided.
         *
         * @property {boolean} [negated] Defaults to false
         * @property {StringListContentFilter} [content]
         * @property {IntFilter} [size]
         */

        /**
         * @typedef {Object} StringListContentFilter
         *
         * @property {ListOperation} [operation] Defaults to CONTAINS
         * @property {StringFilter} value
         */

        /**
         * @deprecated in favor of ContentListFilter
         * @typedef {Object} IssueListFilter
         * @description Either content or size must be provided.
         *
         * @property {boolean} [negated] Defaults to false
         * @property {IssueListContentFilter} [content]
         * @property {IntFilter} [size]
         */

        /**
         * @deprecated in favor of ContentListContentFilter
         * @typedef {Object} IssueListContentFilter
         *
         * @property {ListOperation} [operation] Defaults to CONTAINS
         * @property {IssueFilter} value
         */

        /**
         * @typedef {Object} ContentListFilter
         * @description Either content or size must be provided.
         *
         * @property {boolean} [negated] Defaults to false
         * @property {ContentListContentFilter} [content]
         * @property {IntFilter} [size]
         */

        /**
         * @typedef {Object} ContentListContentFilter
         *
         * @property {ListOperation} [operation] Defaults to CONTAINS
         * @property {ContentFilter} value
         */

        /**
         * @typedef {Object} TaxonomyListFilter
         * @description Either content or size must be provided.
         *
         * @property {boolean} [negated] Defaults to false
         * @property {TaxonomyListContentFilter} [content]
         * @property {IntFilter} [size]
         */

        /**
         * @typedef {Object} TaxonomyListContentFilter
         *
         * @property {ListOperation} [operation] Defaults to CONTAINS
         * @property {TaxonomyFilter} value
         */

        /**
         * @deprecated in favor of ContentComparator
         * @typedef {Object} IssueComparator
         * @description All properties are mutually-exclusive.
         *
         * @property {IntComparator} [index] Sort issues by their index
         * @property {DateComparator} [publicationDate] Sort issues by their publication date
         * @property {PropertyComparator} [property] Sort issues by a custom property. Issues without the
         *                                           custom property are returned after issues with the property.
         */

        /**
         * @typedef {Object} StringComparator
         *
         * @property {('ASC'|'DESC')} direction
         */

        /**
         * @typedef {Object} IntComparator
         *
         * @property {('ASC'|'DESC')} direction
         */

        /**
         * @typedef {Object} DateComparator
         *
         * @property {('ASC'|'DESC')} direction
         */

        /**
         * @typedef {Object} PropertyComparator
         *
         * @property {('ASC'|'DESC')} direction
         * @property {string} key
         * @property {('STRING'|'INT')} valueType
         */

        /**
         * @typedef {Object} LocalIssues
         *
         * @property {LocalCatalogIssue[]} issues
         * @property {LocalCatalogPreviewIssue[]} previewIssues
         */

        /**
         * @typedef {Object} IssueVersion
         *
         * @property {string} issueId
         * @property {number} issueVersion
         */

        /**
         * @typedef {PurchaseOption} PurchaseType
         */

        /**
         * @typedef {Object} CatalogMenu
         * @property {string} id
         * @property {string} name
         * @property {Map<string, string>} properties
         * @property {CatalogMenuItem[]} [items] null when not queried, else contains the menu items for this menu
         */

        /**
         * @typedef {Object} CatalogMenuItem
         * @property {string} id
         * @property {number} sortIndex
         * @property {string} [parentId]
         * @property {string} url
         * @property {string} name
         * @property {Map<string, string>} properties
         */

        /**
         * @typedef {Object} MenuFilter
         * @description Allows complex filtering of the returned menus. All properties are mutually-exclusive.
         *
         * @property {MenuFilter[]} [AND] Combine multiple MenuFilter using a logical AND operation
         * @property {MenuFilter[]} [OR] Combine multiple MenuFilter using a logical OR operation
         *
         * @property {StringFilter} [name] Filter based on the name of the menu
         * @property {MapFilter} [properties] Filter based on the custom properties of the menu
         */

        /**
         * @typedef {Object} GetMenusParams
         *
         * @property {MenuFilter} [menuFilter] a filter to limit the requested menus
         * @property {number} [first] limit number of menus returned
         * @property {string} [after] value of previously returned cursor to get next set of menus
         * @property {boolean} [includeItems] defines whether to fetch the menus with its items or not
         */

        /**
         * @param {GetMenusParams} [params]
         *
         * @returns {Promise<Page<CatalogMenu>>}
         * @throws {ErrorResult} Possible error codes: NETWORK
         * @since 1.6
         */
        getMenus: function (params) {},

        /**
         * @typedef {Object} GetCatalogMetadataResult
         *
         * @property {PurchaseType[]} activePurchaseTypes
         */

        /**
         * Get information about the state of the catalog for the current user.
         *
         * @returns {Promise<GetCatalogMetadataResult>}
         * @throws {ErrorResult} Possible error codes: NETWORK
         * @since 1.3
         */
        getMetadata: function () {},

        /**
         * @typedef {Object} PublicationTypeFilter
         *
         * @property {boolean} [negated] Defaults to false
         * @property {PublicationType} value
         */

        /**
         * @typedef {Object} PublicationFilter
         * @description Allows complex filtering of the returned publications. All properties are mutually-exclusive.
         *
         * @property {PublicationFilter[]} [AND] Combine multiple PublicationFilter using a logical AND operation
         * @property {PublicationFilter[]} [OR] Combine multiple PublicationFilter using a logical OR operation
         *
         * @property {StringFilter} [id] Filter based on the id of the publication
         * @property {PublicationTypeFilter} [type] Filter based on the type of the publication
         * @property {StringFilter} [language] Filter based on the language of the publication
         *
         * @property {MapFilter} [properties] Filter based on the custom properties of the publication
         * @property {IssueListFilter} [issues] Filter based on the issues of the publication. NOTE: deprecated in favor of contents
         * @property {ContentListFilter} [contents] Filter based on the content of the publication
         */

        /**
         * @typedef {Object} PublicationComparator
         * @description All properties are mutually-exclusive.
         *
         * @property {IntComparator} [index] Sort publications by their index
         * @property {DateComparator} [currentContentPublicationDate] Sort publications by the publication date of the current content
         * @property {DateComparator} [currentIssuePublicationDate] NOTE: deprecated in favor of currentContentPublicationDate. Sort publications by the publication date of the current issue
         * @property {PropertyComparator} [property] Sort publications by a custom property. Publications without the
         *                                           custom property are returned after publications with the property.
         */

        /**
         * @typedef {Object} GetPublicationsParams
         *
         * @property {PublicationFilter} [publicationFilter]
         * @property {PublicationComparator[]} [sort] order of returned publications, if not provided defaults to "index in ascending order"
         * @property {number} [first] limit number of publications returned
         * @property {string} [after] value of previously returned cursor to get next set of publications
         * @property {boolean} [includeIssues] NOTE: deprecated in favor of includeContents
         * @property {GetIssuesParams} [issuesParams] NOTE: deprecated in favor of contentsParams. If includeIssues and issuesParams are used, they will be mapped to includeContents and contentsParams with corresponding filters that will only return issues.
         * @property {boolean} [includeContents]
         * @property {GetContentsParams} [contentsParams]
         */

        /**
         * @param {GetPublicationsParams} [params]
         *
         * @returns {Promise<Page<CatalogPublication>>}
         * @throws {ErrorResult} promise will return this type in rejection callback
         * @since 1.0
         */
        getPublications: function (params) {},

        /**
         * @deprecated
         * @typedef {Object} IssueTypeFilter
         *
         * @property {boolean} [negated] Defaults to false
         * @property {IssueType} value
         */

        /**
         * @deprecated in favor of ContentFilter
         * @typedef {Object} IssueFilter
         * @description Allows complex filtering of the returned issues. All properties are mutually-exclusive.
         *
         * @property {IssueFilter[]} [AND] Combine multiple IssueFilter using a logical AND operation
         * @property {IssueFilter[]} [OR] Combine multiple IssueFilter using a logical OR operation
         *
         * @property {StringFilter} [id] Filter based on the id of the issue
         * @property {StringFilter} [alias] Filter based on the alias of the issue
         * @property {StringFilter} [externalIssueId] Filter based on the externalIssueId of the issue
         * @property {IssueTypeFilter} [type] Filter based on the type of the issue
         * @property {DateFilter} [publicationDate] Filter based on the publication date of the issue. The provided date will be evaluated based on the timezone of the publication.
         * @property {BooleanFilter} [purchasable] Filter based on purchasable status of the issue
         * @property {StringFilter} [productId] Filter based on product ID of the issue
         * @property {BooleanFilter} [purchased] Filter based on purchase status of the issue
         *
         * @property {PublicationFilter} [publication] Filter based on the publication of the issue
         * @property {MapFilter} [properties] Filter based on the custom properties of the issue
         * @property {StringListFilter} [categories] Filter based on the categories of the issue
         * @property {StringListFilter} [tags] Filter based on the tags of the issue
         *
         */

        /**
         * @deprecated in favor of GetContentsParams
         * @typedef {Object} GetIssuesParams
         *
         * @property {IssueFilter} [issueFilter]
         * @property {IssueComparator[]} [sort] order of returned issues, if not provided defaults to "publication date in descending order"
         * @property {number} [first] limit number of issues returned
         * @property {string} [after] value of previously returned cursor to get next set of issues
         */

        /**
         * @deprecated in favor of getContents
         * @param {GetIssuesParams} [params]
         *
         * @returns {Promise<Page<CatalogIssue>>}
         * @throws {ErrorResult} promise will return this type in rejection callback
         * @since 1.0
         */
        getIssues: function (params) {},

        /**
         * Returns a list of all locally known issues. Can be both preview or full issues.
         *
         * @returns {Promise<LocalIssues>}
         * @throws {ErrorResult} promise will return this type in rejection callback
         * @since 1.0
         */
        getLocalIssues: function () {},

        /**
         * @typedef {Object} ContentTypeFilter
         *
         * @property {boolean} [negated] Defaults to false
         * @property {CatalogContentType} value
         */

        /**
         * @typedef {Object} AccessFilter
         *
         * @property {boolean} [negated] Defaults to false
         * @property {Access} value
         */

        /**
         * @typedef {Object} AuthorsFilter
         * @description The name and email properties are mutually-exclusive.
         *
         * @property {boolean} [negated] Defaults to false
         * @property {ListOperation} [operation] Defaults to CONTAINS
         * @property {StringFilter} [name]
         * @property {StringFilter} [email]
         */

        /**
         * @typedef {Object} ContentFilter
         * @description Allows complex filtering of the returned contents. All properties are mutually-exclusive.
         *
         * @property {ContentFilter[]} [AND] Combine multiple ContentFilter using a logical AND operation
         * @property {ContentFilter[]} [OR] Combine multiple ContentFilter using a logical OR operation
         *
         * @property {StringFilter} [id] Filter based on the id of the content
         * @property {StringFilter} [alias] Filter based on the alias of the content
         * @property {StringFilter} [externalId] Filter based on the externalIssueId of the content
         * @property {ContentTypeFilter} [contentType] Filter based on the contentType of the content
         * @property {DateFilter} [publicationDate] Filter based on the publication date of the content. The provided date will be evaluated based on the timezone of the publication.
         * @property {AccessFilter} [access] Filter based on the access of the content
         * @property {BooleanFilter} [purchased] Filter based on purchase status of the content
         * @property {StringFilter} [productId] Filter based on product ID of the content
         *
         * @property {StringFilter} [postType] Filter based on the postType of the post
         * @property {AuthorsFilter} [authors] Filter based on the authors of the post
         *
         * @property {PublicationFilter} [publication] Filter based on the publication of the content
         * @property {MapFilter} [properties] Filter based on the custom properties of the content
         * @property {TaxonomyListFilter} [taxonomies] Filter based on the taxonomies of the content
         */

        /**
         * @typedef {Object} ContentComparator
         * @description All properties are mutually-exclusive.
         *
         * @property {StringComparator} [name] Sort contents by their name
         * @property {IntComparator} [index] Sort contents by their index
         * @property {DateComparator} [publicationDate] Sort contents by the publication date
         * @property {PropertyComparator} [property] Sort contents by a custom property. Contents without the
         *                                           custom property are returned after contents with the property.
         */

        /**
         * @typedef {Object} ContentFetchOptions
         *
         * @property {boolean} [includeResources] whether resources are fetched in posts, defaults to false
         * @property {boolean} [includeBlocks] whether blocks are fetched in posts, defaults to false
         * @property {boolean} [includeHtml] whether htmls are fetched in posts, defaults to false
         * @property {boolean} [includeBundledContent] whether bundledContents are fetched in bundles, defaults to false
         */

        /**
         * @typedef {Object} GetContentsParams
         *
         * @property {ContentFilter} [contentFilter]
         * @property {ContentComparator[]} [sort] order of returned contents, if not provided defaults to "publication date in descending order"
         * @property {number} [first] limit number of contents returned
         * @property {string} [after] value of previously returned cursor to get next set of contents
         * @property {ContentFetchOptions} [fetchOptions]
         */

        /**
         * @param {GetContentsParams} [params]
         *
         * @returns {Promise<Page<CatalogContent>>}
         * @throws {ErrorResult} promise will return this type in rejection callback
         * @since 1.6
         */
        getContents: function (params) {},

        /**
         * @typedef {Object} CollectionFilter
         * @description Allows complex filtering of the returned collections. All properties are mutually-exclusive.
         *
         * @property {CollectionFilter[]} [AND] Combine multiple CollectionFilter using a logical AND operation
         * @property {CollectionFilter[]} [OR] Combine multiple CollectionFilter using a logical OR operation
         *
         * @property {StringFilter} [id] Filter based on the id of the collection
         * @property {StringFilter} [name] Filter based on the name of the collection
         *
         * @property {MapFilter} [properties] Filter based on the custom properties of the collection
         */

        /**
         * @typedef {Object} CollectionComparator
         * @description All properties are mutually-exclusive.
         *
         * @property {StringComparator} [name] Sort collections by their name
         * @property {PropertyComparator} [property] Sort collections by a custom property. Collections without the
         *                                           custom property are returned after collections with the property.
         */

        /**
         * @typedef {Object} CollectionElement
         * @property {string} id
         * @property {string} type
         */

        /**
         * @typedef {Object} ContentElement
         * @extends CollectionElement
         * Note: type is always 'CONTENT'
         *
         * @property {CatalogContent} content
         */

        /**
         * @typedef {Object} CatalogCollection
         * @property {string} id
         * @property {string} name
         * @property {Map<string, string>} properties
         * @property {Page<CollectionElement>} [elements] null when not queried, else contains a page of elements for this publication
         */

        /**
         * @typedef {Object} GetElementsParams
         *
         * @property {number} [first] limit number of elements returned
         * @property {string} [after] value of previously returned cursor to get next set of elements
         * @property {ContentFetchOptions} [fetchOptions]
         */

        /**
         * @typedef {Object} GetCollectionsParams
         *
         * @property {CollectionFilter} [collectionFilter]
         * @property {CollectionComparator[]} [sort] order of returned collections, if not provided defaults to "index in ascending order"
         * @property {number} [first] limit number of collections returned
         * @property {string} [after] value of previously returned cursor to get next set of collections
         * @property {boolean} [includeElements]
         * @property {GetElementsParams} [elementsParams]
         */

        /**
         * @param {GetCollectionsParams} [params]
         *
         * @returns {Promise<Page<CatalogCollection>>}
         * @throws {ErrorResult} promise will return this type in rejection callback
         * @since 1.6
         */
        getCollections: function (params) {},
        /**
         * @deprecated in favor of TaxonomyFilter
         * @typedef {Object} CategoryFilter
         * @description Allows complex filtering of the returned categories. All properties are mutually-exclusive.
         *
         * @property {CategoryFilter[]} [AND] Combine multiple CategoryFilter using a logical AND operation
         * @property {CategoryFilter[]} [OR] Combine multiple CategoryFilter using a logical OR operation
         *
         * @property {StringFilter} [id] Filter based on the ID of the category
         * @property {StringFilter} [parentCategoryId] Filter based on the ID of the parent category
         * @property {StringFilter} [name] Filter based on the name of the category
         * @property {MapFilter} [properties] Filter based on the custom properties of the category
         *
         * @property {IssueListFilter} [issues] Filter based on the issues of the category
         */

        /**
         * @deprecated in favor of TaxonomyComparator
         * @typedef {Object} CategoryComparator
         * @description All properties are mutually-exclusive.
         *
         * @property {StringComparator} [name] Sort categories by their name
         * @property {PropertyComparator} [property] Sort categories by a custom property. Categories without the
         *                                           custom property are returned after Categories with the property.
         */

        /**
         * @deprecated in favor of GetTaxonomiesParams
         * @typedef {Object} GetCategoriesParams
         *
         * @property {CategoryFilter} [categoryFilter]
         * @property {CategoryComparator[]} [sort] order of returned categories, if not provided defaults to "internal database ID in ascending order"
         * @property {number} [first] limit number of categories returned
         * @property {string} [after] value of previously returned cursor to get next set of categories
         * @property {boolean} [includeIssues]
         * @property {GetIssuesParams} [issuesParams]
         */

        /**
         * @deprecated in favor of CatalogTaxonomy
         * @typedef {Object} CatalogCategory
         *
         * @property {string} id
         * @property {string} name
         * @property {string} [parentCategoryId]
         * @property {Map<string, string>} thumbnails The default thumbnail is always available under the default-key. Additional thumbnails can be available under separate unique keys.
         * @property {Map<string, string>} properties
         *
         * @property {Page<CatalogIssue>} [issues] null when not queried, else contains a page of issues for this category
         */

        /**
         * @deprecated use getTaxonomies instead and filter for type 'CATEGORY'
         * @param {GetCategoriesParams} [params]
         *
         * @returns {Promise<Page<CatalogCategory>>}
         * @throws {ErrorResult} promise will return this type in rejection callback
         * @since 1.0
         */
        getCategories: function (params) {},

        /**
         * @typedef {Object} CatalogTaxonomy
         *
         * @property {string} id
         * @property {string} name
         * @property {string} type
         * @property {string} [parentId]
         * @property {Map<string, string>} thumbnails The default thumbnail is always available under the default-key. Additional thumbnails can be available under separate unique keys.
         * @property {Map<string, string>} properties
         *
         * @property {Page<CatalogContent>} [contents] null when not queried, else contains a page of contents for this taxonomy
         */

        /**
         * @typedef {Object} CatalogTaxonomySummary
         *
         * @property {string} id
         * @property {string} name
         * @property {string} type
         * @property {string} [parentId]
         * @property {Map<string, string>} properties
         */

        /**
         * @typedef {Object} TaxonomyFilter
         * @description Allows complex filtering of the returned taxonomies. All properties are mutually-exclusive.
         *
         * @property {TaxonomyFilter[]} [AND] Combine multiple TaxonomyFilter using a logical AND operation
         * @property {TaxonomyFilter[]} [OR] Combine multiple TaxonomyFilter using a logical OR operation
         *
         * @property {StringFilter} [id] Filter based on the ID of the taxonomy
         * @property {StringFilter} [parentId] Filter based on the ID of the parent taxonomy
         * @property {StringFilter} [name] Filter based on the name of the taxonomy
         * @property {StringFilter} [type] Filter based on the type of the taxonomy
         * @property {MapFilter} [properties] Filter based on the custom properties of the taxonomy
         *
         * @property {ContentListFilter} [contents] Filter based on the contents of the taxonomy
         */

        /**
         * @typedef {Object} TaxonomyComparator
         * @description All properties are mutually-exclusive.
         *
         * @property {StringComparator} [id] Sort taxonomies by their id
         * @property {StringComparator} [name] Sort taxonomies by their name
         * @property {PropertyComparator} [property] Sort taxonomies by a custom property. Taxonomies without the
         *                                           custom property are returned after Taxonomies with the property.
         */

        /**
         * @typedef {Object} GetTaxonomiesParams
         *
         * @property {TaxonomyFilter} [taxonomyFilter]
         * @property {TaxonomyComparator[]} [sort] order of returned taxonomies, if not provided defaults to "internal database ID in ascending order"
         * @property {number} [first] limit number of taxonomies returned
         * @property {string} [after] value of previously returned cursor to get next set of taxonomies
         * @property {boolean} [includeContents]
         * @property {GetContentsParams} [contentsParams]
         */

        /**
         * @param {GetTaxonomiesParams} [params]
         *
         * @returns {Promise<Page<CatalogTaxonomy>>}
         * @throws {ErrorResult} promise will return this type in rejection callback
         * @since 1.0
         */
        getTaxonomies: function (params) {},
        /**
         * @typedef {Object} AppSubscriptionAdditionalBackIssueUnlocks
         *
         * @property {number} count
         * @property {('DAY'|'WEEK'|'MONTH'|'YEAR')} unit
         */

        /**
         * @typedef {('FREE'|'AUTORENEWABLE'|'NONAUTORENEWABLE')} AppSubscriptionType
         */

        /**
         * @typedef {('SEVEN_DAYS'|'ONE_MONTH'|'TWO_MONTHS'|'THREE_MONTHS'|'SIX_MONTHS'|'ONE_YEAR')} AppSubscriptionDuration
         */

        /**
         * @typedef {Object} AppSubscription
         *
         * @property {string} id
         * @property {string} name
         * @property {string} description
         * @property {AppSubscriptionType} type
         * @property {AppSubscriptionDuration} duration
         * @property {AppSubscriptionAdditionalBackIssueUnlocks} additionalUnlocks Describes how many back issues are additionally unlocked, period is starting before publication date of current issue as of purchase date.
         * @property {boolean} unlocksAllContentDuringPeriod
         * @property {boolean} hidden
         * @property {string} productId
         * @property {string} [groupId] iOS only: The group identifier for this subscription.
         * @property {boolean} purchased
         * @property {EligibilityInfo} eligibilityInfo Information about eligibility for trials, introductory pricing and discount offers.
         * @property {CurrentReceiptInfo} [currentReceiptInfo] Current or latest receipt for this subscription. If subscription lapsed it will still provide information about the last subscription period.
         * @property {HistoricReceiptInfo} historicReceiptInfo Historic data for this subscription. Contains information about the complete history of the subscription.
         * @property {Map<string, string>} thumbnails The default thumbnail is always available under the default-key. Additional thumbnails can be available under separate unique keys.
         * @property {Map<string, string>} properties
         * @property {Object<CatalogPublication.id, PublicationSubscription>} publications
         */

        /**
         * @typedef {Object} SubscriptionFilter
         * @description Allows complex filtering of the returned subscriptions. All properties are mutually-exclusive.
         *
         * @property {SubscriptionFilter[]} [AND] Combine multiple SubscriptionFilter using a logical AND operation
         * @property {SubscriptionFilter[]} [OR] Combine multiple SubscriptionFilter using a logical OR operation
         *
         * @property {StringFilter} [productId] Filter based on product ID of the subscription
         * @property {BooleanFilter} [purchased] Filter based on purchase status of the subscription
         *
         * @property {PublicationFilter} [publication] Filter based on the publication of the subscription
         * @property {MapFilter} [properties] Filter based on the custom properties of the subscription
         */

        /**
         * @typedef {Object} SubscriptionComparator
         * @description All properties are mutually-exclusive.
         *
         * @property {IntComparator} [index] Sort subscriptions by their index
         * @property {PropertyComparator} [property] Sort subscriptions by a custom property. Subscriptions without the
         *                                           custom property are returned after Subscriptions with the property.
         */

        /**
         * @typedef {Object} GetSubscriptionsParams
         *
         * @property {SubscriptionFilter} [subscriptionFilter]
         * @property {SubscriptionComparator[]} [sort] order of returned subscriptions, if not provided defaults to "internal database ID in ascending order"
         * @property {number} [first] limit number of subscriptions returned
         * @property {string} [after] value of previously returned cursor to get next set of subscriptions
         */

        /**
         * @param {GetSubscriptionsParams} [params]
         *
         * @returns {Promise<Page<AppSubscription>>}
         * @throws {ErrorResult} promise will return this type in rejection callback
         * @since 1.0
         */
        getSubscriptions: function (params) {},

        /**
         * @typedef {("BACK_ISSUE_COMPLETION"|"REPEATABLE_ISSUE_PURCHASE")} PublicationProductType
         */

        /**
         * @typedef {Object} PublicationProduct
         *
         * @property {PublicationProductType} type
         * @property {string} id
         * @property {string} name
         * @property {string} description
         * @property {boolean} hidden
         * @property {string} productId
         * @property {number} index
         * @property {number} orderIndex NOTE: deprecated in favor of index
         *
         * @property {Map<string, string>} thumbnails The default thumbnail is always available under the default-key. Additional thumbnails can be available under separate unique keys.
         * @property {Map<string, string>} properties
         *
         * @property {Object<CatalogPublication.id, PublicationProductPublication>} publications
         */

        /**
         * @typedef {Object} ContentsCompletionProduct
         * @extends PublicationProduct
         *
         * Note: type is always 'BACK_ISSUE_COMPLETION'
         *
         * @property {boolean} purchased
         * @property {boolean} includesLatestIssue If true, the latest issue before the purchase will be unlocked. Otherwise, it is excluded. NOTE: deprecated in favor of includesLatestContent
         * @property {boolean} includesLatestContent If true, the latest content before the purchase will be unlocked. Otherwise, it is excluded.
         */

        /**
         * @typedef {Object} RepeatablePurchaseProduct
         * @extends PublicationProduct
         *
         * Note: type is always 'REPEATABLE_ISSUE_PURCHASE'
         */

        /**
         * @typedef {Object} PublicationProductPublication
         * @property {string} publicationId
         * @property {UnlockableIssue[]} unlockableIssues NOTE: deprecated in favor of unlockableContent
         * @property {UnlockableContent[]} unlockableContents
         * @property {number} totalCount Count of all unlockable contents
         */

        /**
         * @typedef {Object} UnlockableContent
         *
         * @property {string} id
         * @property {string} name
         * @property {number} publicationDate
         * @property {string} [productId]
         * @property {string} publicationId
         * @property {CatalogContentType} type
         *
         * @property {Map<string, string>} thumbnails The default thumbnail is always available under the default-key. Additional thumbnails can be available under separate unique keys.
         */

        /**
         * @deprecated in favor of UnlockableContent
         * @typedef {Object} UnlockableIssue
         *
         * @property {string} id
         * @property {string} name
         * @property {number} publicationDate
         * @property {string} productId
         * @property {string} publicationId
         *
         * @property {Map<string, string>} thumbnails The default thumbnail is always available under the default-key. Additional thumbnails can be available under separate unique keys.
         */

        /**
         * @typedef {Object} PublicationProductFilter
         * @description Allows complex filtering of the returned publication products. All properties are mutually-exclusive.
         *
         * @property {PublicationProductFilter[]} [AND] Combine multiple PublicationProductFilter using a logical AND operation
         * @property {PublicationProductFilter[]} [OR] Combine multiple PublicationProductFilter using a logical OR operation
         *
         * @property {PublicationProductTypeFilter} [type] Filter based on type of the publication product.
         * @property {StringFilter} [productId] Filter based on product ID of the publication product.
         * @property {BooleanFilter} [purchased] Filter based on purchase status of the publication product.
         * @property {BooleanFilter} [hidden] Filter based on hidden status of the publication product.
         *
         * @property {MapFilter} [properties] Filter based on the custom properties of the publication product.
         */

        /**
         * @typedef {Object} PublicationProductTypeFilter
         *
         * @property {boolean} [negated] Defaults to false
         * @property {PublicationProductType} value
         */

        /**
         * @typedef {Object} GetPublicationProductsParams
         *
         * @property {PublicationProductFilter} [publicationProductFilter]
         * @property {number} [maxUnlockableIssues] limit number of unlockable issues returned per publication. NOTE: deprecated in favor of maxUnlockableContents
         * @property {IssueFilter} [unlockableIssuesFilter] filter for the unlockable issues. NOTE: deprecated in favor of unlockableContentsFilter
         * @property {number} [maxUnlockableContents] limit number of unlockable contents returned per publication
         * @property {ContentFilter} [unlockableContentsFilter] filter for the unlockable contents
         * @property {number} [first] limit number of publication products returned
         * @property {string} [after] value of previously returned cursor to get next set of publication products
         */

        /**
         * @param {GetPublicationProductsParams} [params]
         *
         * @returns {Promise<Page<PublicationProduct>>}
         * @throws {ErrorResult} promise will return this type in rejection callback
         * @since 1.3
         */
        getPublicationProducts: function (params) {},

        /**
         * @typedef {Object} GetProductsParams
         *
         * @property {string} productId
         * @property {ContentFetchOptions} [fetchOptions]
         */

        /**
         * @typedef {Object} GetProductsResult
         *
         * @property {CatalogContent[]} contents
         * @property {CatalogIssue[]} issues NOTE: deprecated in favor of contents
         * @property {AppSubscription[]} subscriptions
         * @property {PublicationProduct[]} publicationProducts
         */

        /**
         * @param {GetProductsParams} params
         *
         * @returns {Promise<GetProductsResult>}
         * @throws {ErrorResult} promise will return this type in rejection callback
         * @since 1.4
         */
        getProducts: function (params) {},

        /**
         * @deprecated in favor of IssuePageComparator
         * @typedef {('RELEVANCE', 'PUB_DATE_DESC')} SearchResultSortCriteria
         */

        /**
         * @typedef {Object} SearchOptions
         *
         * @property {boolean} [fuzzy] Phrase does not need to match exactly.
         * @property {boolean} [sortPages] Sort page results for each issue by page index. NOTE: deprecated in favor of pageSort
         * @property {boolean} [findAll] All words of the search phrase must be found in a page of an issue.
         * @property {IssuePageComparator[]} [pageSort] Order of returned pages, if not provided defaults to "relevance in descending order"
         * @property {SearchResultSortCriteria} [sortBy] Sort results based on provided criteria. Defaults to RELEVANCE. NOTE: deprecated in favor of sort in SearchParams
         */

        /**
         * @typedef {Object} IssuePageComparator
         * @description All properties are mutually-exclusive.
         *
         * @property {IntComparator} [index] Sort search results by their index
         * @property {RelevanceComparator} [relevance] Sort search results by their relevance
         */

        /**
         * @typedef {Object} ContentSearchResultComparator
         * @description All properties are mutually-exclusive.
         *
         * @property {RelevanceComparator} [relevance] Sort search results by their relevance
         * @property {DateComparator} [publicationDate] Sort search results by their publication date
         */

        /**
         * @typedef {Object} RelevanceComparator
         *
         * @property {('ASC'|'DESC')} direction
         */

        /**
         * @typedef {Object} ContentSearchResult
         *
         * @property {CatalogContentType} contentType
         */

        /**
         * @typedef {Object} PostSearchResult
         * @extends ContentSearchResult
         *
         * @property {CatalogPost} post
         * @property {string} excerpt
         */

        /**
         * @typedef {Object} BundleSearchResult
         * @extends ContentSearchResult
         *
         * @property {CatalogBundle} bundle
         * @property {PostSearchResult[]} posts
         */

        /**
         * @typedef {Object} IssueSearchResult
         * @extends ContentSearchResult
         *
         * @property {CatalogIssue} issue
         * @property {IssuePageResult[]} pageHits
         */

        /**
         * @typedef {Object} IssuePageResult
         *
         * @property {string} excerpt
         * @property {number} [pageIndex]
         * @property {number} [pageNumber]
         * @property {string} [pageLabel]
         * @property {string} [pageTitle]
         * @property {string} [elementAlias] The alias of the Storytelling element where this excerpt can be found
         */

        /**
         * @typedef {Object} SearchParams
         *
         * @property {string} [phrase] NOTE: deprecated in favor of searchPhrase. When phrase is passed, then the legacy case will be handled and only IssuePageResults will be returned
         * @property {string} [searchPhrase] The phrase to search the content for
         * @property {SearchOptions} [options] Options to refine the search
         * @property {IssueFilter} [issueFilter] Only search in issue that match the issue filter NOTE: deprecated in favor of contentFilter
         * @property {ContentFilter} [contentFilter] Only search in content that match the issue filter
         * @property {ContentSearchResultComparator[]} [sort] Order of returned search results, if not provided defaults to "relevance in descending order"
         * @property {number} [first] limit number of contents returned
         * @property {string} [after] value of previously returned cursor to get next set of contents
         * @property {ContentFetchOptions} [fetchOptions]
         *
         */

        /**
         * @param {SearchParams} params
         *
         * @returns {Promise<Page<ContentSearchResult>>}
         * @throws {ErrorResult} promise will return this type in rejection callback
         * @since 1.0
         */
        search: function (params) {},

        /**
         * @typedef {Object} GetIssueStatesParams
         *
         * @property {IssueVersion[]} issues
         */

        /**
         * @typedef {('NOT_INSTALLED'|'INCOMPLETE'|'COMPLETE')} CatalogIssueInstallState
         */

        /**
         * @typedef {('NETWORK'|'INSUFFICIENT_SPACE'|'PAYMENT_REQUIRED'|'NOT_FOUND'|'UNKNOWN')} CatalogIssueStateError
         */

        /**
         * @typedef {Object} CatalogIssueState
         *
         * @property {string} issueId
         * @property {CatalogIssueInstallState} installState
         * @property {boolean} updateAvailable
         * @property {boolean} downloading
         * @property {number} progress the download progress in percent if currently downloading, else 0
         * @property {CatalogIssueStateError} [error]
         */

        /**
         * Get states of issues.
         * For issues that the app does not know it will return NOT_INSTALLED, for issues that the app knows in a
         * different version this will return the state of the locally known version with the updateAvailable flag set.
         *
         * @param {GetIssueStatesParams} params
         *
         * @returns {Promise<Map<CatalogIssue.id, CatalogIssueState>>}
         * @throws {ErrorResult} promise will return this type in rejection callback
         * @since 1.0
         */
        getIssueStates: function (params) {},

        /**
         * @typedef {Object} SyncLocalIssuesParams
         *
         * @property {string[]} issueIds
         */

        /**
         * Sync issue metadata to local app database.
         *
         * @param {SyncLocalIssuesParams} params
         *
         * @returns {Promise<void>}
         * @throws {ErrorResult} code can have values: NETWORK, UNKNOWN
         * @since 1.1

         */
        syncLocalIssues: function (params) {},

        /**
         * @typedef {Object} DownloadControlParams
         *
         * @property {string} issueId ID of an issue or a preview issue
         */

        /**
         * @typedef {Object} StartDownloadResult
         */

        /**
         *
         * @param {DownloadControlParams} params
         * @returns {Promise<StartDownloadResult>}
         * @throws {ErrorResult} code can have values: NETWORK, INSUFFICIENT_SPACE, PAYMENT_REQUIRED, NOT_FOUND
         * @since 1.0
         */
        startDownload: function (params) {},

        /**
         * @typedef {Object} StartDownloadsParams
         *
         * @property {string[]} issueIds IDs of issues or preview issues
         */

        /**
         * @typedef {('NETWORK', 'INSUFFICIENT_SPACE', 'PAYMENT_REQUIRED', 'NOT_FOUND', 'UNKNOWN')} StartDownloadError
         */

        /**
         * @typedef {Object} StartDownloadResult
         *
         * @property {boolean} success
         * @property {StartDownloadError} [errorCode] In case of failure, the cause of the error
         */

        /**
         * @typedef {Object} StartDownloadsResult
         *
         * @property {Map<string, StartDownloadResult>} results Results grouped by issue / preview issue ID
         *
         */

        /**
         *
         * @param {StartDownloadsParams} params
         * @returns {Promise<StartDownloadsResult>}
         * @throws {ErrorResult} code can have values: NETWORK
         * @since 1.1
         */
        startDownloads: function (params) {},

        /**
         *
         * @param {DownloadControlParams} params
         * @returns {Promise<void>}
         * @throws {ErrorResult} promise will return this type in rejection callback
         * @since 1.0
         */
        pauseDownload: function (params) {},

        /**
         * @typedef {Object} PauseDownloadsParams
         *
         * @property {string[]} issueIds IDs of issues or preview issues
         */

        /**
         * @typedef {Object} PauseDownloadResult
         *
         * @property {boolean} success
         * @property {('NOT_FOUND', 'UNKNOWN')} [errorCode] In case of failure, the cause of the error
         */

        /**
         * @typedef {Object} PauseDownloadsResult
         *
         * @property {Map<string, PauseDownloadResult>} results Results grouped by issue / preview issue ID
         */

        /**
         * Pause multiple issue downloads. Can be called for all issues for which the download has been started.
         *
         * @param {PauseDownloadsParams} params
         * @returns {Promise<PauseDownloadsResult>}
         * @throws {ErrorResult} promise will return this type in rejection callback
         * @since 1.1
         */
        pauseDownloads: function (params) {},

        /**
         *
         * @param {DownloadControlParams} params
         * @returns {Promise<void>}
         * @throws {ErrorResult} promise will return this type in rejection callback
         * @since 1.0
         */
        deleteIssue: function (params) {},

        /**
         * @typedef {Object} DeleteIssuesParams
         *
         * @property {string[]} issueIds IDs of issues or preview issues
         */

        /**
         * @typedef {Object} DeleteIssueResult
         *
         * @property {boolean} success
         * @property {('NOT_FOUND', 'UNKNOWN')} [errorCode] In case of failure, the cause of the error
         */

        /**
         * @typedef {Object} DeleteIssuesResult
         *
         * @property {Map<string, DeleteIssueResult>} results Results grouped by issue / preview issue ID
         */

        /**
         * Delete multiple issues. Can be called for all issues that are have been opened or for which the
         * download has been started.
         *
         * @param {DeleteIssuesParams} params
         * @returns {Promise<DeleteIssuesResult>}
         * @throws {ErrorResult} promise will return this type in rejection callback
         * @since 1.1
         */
        deleteIssues: function (params) {},

        /**
         * @callback InvalidationCallback
         *
         */

        /**
         * Add a listener function to receive invalidation updates. An invalidation update is triggered, when the state
         * of the catalog is not considered valid anymore, e.g. when the entitlement login changed or other purchase
         * related events (purchase, subscribe, coupon codes) occurred. The consumer of this API should reload the current views to display the updated state.
         *
         * @param {InvalidationCallback} listener
         * @since 1.0
         */
        addInvalidationListener: function (listener) {},

        /**
         * Remove the previously registered listener.
         *
         * @param {InvalidationCallback} listener previously registered listener
         * @since 1.0
         */
        removeInvalidationListener: function (listener) {},

        /**
         * @typedef {Object} IssueStateListenerParams
         *
         * @property {(CatalogPublication.id)} publicationId
         * @property {(CatalogIssue.id|CatalogPreviewIssue.id)} issueId
         * @property {(CatalogIssue.version|CatalogPreviewIssue.version)} issueVersion
         */

        /**
         * @callback IssueStateListener
         *
         * @param {CatalogIssueState} issueState
         */

        /**
         * Add issue state listener
         *
         * @param {IssueStateListenerParams} params
         * @param {IssueStateListener} listener
         * @since 1.0
         */
        addIssueStateListener: function (params, listener) {},

        /**
         * Remove the previously registered listener.
         *
         * @param {IssueStateListenerParams} params
         * @param {IssueStateListener} listener
         * @since 1.0
         */
        removeIssueStateListener: function (params, listener) {},
    },
    /**
     * @since 2.1
     */
    consent: {
        /**
         * Changelog:
         * Version 1.0: Initial version
         * Version 1.1: Add showSystemPrivacyManager and getSystemConsent
         */
        version: {
            major: 1,
            minor: 1,
            toString: function () {
                return this.major + '.' + this.minor;
            },
        },
        /**
         * @typedef {Object} GetConsentParams
         *
         * @property {string} vendorId
         */

        /**
         * @typedef {Object} GetConsentResult
         *
         * @property {string} vendorId
         * @property {boolean} vendorGrant true if the vendor has been granted completely.
         * @property {Map<string, boolean>} purposeGrants Map of purposeIds to purposeGrant. If a purpose has been granted, it will have a value of true in the map.
         */

        /**
         *
         * @param {GetConsentParams} params
         * @returns {Promise<GetConsentResult>}
         * @throws {ErrorResult} UNKNOWN_VENDOR
         * @since 1.0
         */
        getConsent: function (params) {},
        /**
         * @typedef {Object} GrantConsentParams
         *
         * @property {string} vendorId
         * @property {string[]} [purposeIds] Only grant the given purposeIds, otherwise all purposes of the vendor will be granted.
         */

        /**
         *
         * @param {GrantConsentParams} params
         * @returns {Promise<void>}
         * @throws {ErrorResult} UNKNOWN_VENDOR
         * @since 1.0
         */
        grantConsent: function (params) {},
        /**
         * @returns {Promise<void>} The promise will be resolved once the privacy manager has been
         * closed. After it is resolved, getConsent will deliver the new consent data.
         * @throws {ErrorResult}
         * @since 1.0
         */
        showPrivacyManager: function () {},
        /**
         * Gets the status of the system privacy consent.
         * NOTE: Only available on iOS
         * @returns {Promise<GetSystemConsentResult>}
         * @throws {ErrorResult} NOT_AVAILABLE (Android)
         * @since 1.1
         */
        getSystemConsent: function () {},
        /**
         * @typedef {('GRANTED'|'DENIED'|'UNDETERMINED')} SystemConsentStatus
         */

        /**
         * @typedef {Object} GetSystemConsentResult
         *
         * @property {SystemConsentStatus} status
         */

        /**
         * @returns {Promise<void>} The promise will be resolved once the system privacy alert has been
         * closed. After it is resolved, getSystemConsent deliver the new consent data.
         * NOTE: Only available on iOS
         *       This function should only be called if getSystemConsent returns UNDETERMINED. Otherwise this function
         *       will resolve the promise without performing any action.
         * @throws {ErrorResult} NOT_AVAILABLE (Android)
         * @since 1.1
         */
        showSystemPrivacyManager: function () {},
    },
    /**
     * @since 2.0
     */
    content: {
        /**
         * Changelog:
         * Version 1.0: Initial version
         * Version 1.1: BREAKING CHANGE: Change initial issue configuration and add config for displaying the issues
         * Version 1.2: Allow opening content without remote issue data sync (with active Catalog API)
         * Version 1.3: content.open can now throw NETWORK error when called for locally unknown issue while offline
         * Version 1.4: Add initialElement to InitialIssuePage
         * Version 1.5: Add tracking configuration
         */
        version: {
            major: 1,
            minor: 5,
            toString: function () {
                return this.major + '.' + this.minor;
            },
        },
        /**
         * @typedef {('EMBEDDED'|'MODAL')} DisplayMode
         */
        /**
         * @typedef {('TOP_LEFT'|'TOP_CENTER'|'TOP_RIGHT'|'CENTER_LEFT'|'CENTER_CENTER'|'CENTER_RIGHT'|'BOTTOM_LEFT'|'BOTTOM_CENTER'|'BOTTOM_RIGHT')} ElementAlignment
         */

        /**
         * @typedef {Object} InitialElement
         *
         * @property {string} aliasOrId
         * @property {ElementAlignment} [alignment] Optional alignment, defaults to 'TOP_LEFT'
         */

        /**
         * @typedef {Object} InitialIssuePage
         * @description All properties are mutually-exclusive. If no values are provided, first page or soft-bookmark of issue will be opened.
         *
         * @property {string} [id] ID of initial page.
         * @property {string} [alias] Alias of initial page.
         * @property {number} [index] Index of initial page.
         * @property {InitialElement} [initialElement] Optional config for aligning an element in the viewport of the page
         */

        /**
         * @typedef {Object} InitialIssue
         *
         * @property {string} id ID of initial issue
         * @property {InitialIssuePage} [initialPage] Optional config for initial page of the initial issue.
         */

        /**
         * @typedef {Object} DisplayConfig
         *
         * @property {DisplayMode} [mode] Display the issue in a modal or embedded view.
         * @property {boolean} [titleBar] Show/Hide the title bar.
         */

        /**
         * @typedef {Object} TrackingConfig
         * @property {string} type is used as a prefix for events, e.g. <TYPE>_COLLECTION_OPENED, <TYPE>_OPENED / <TYPE>_PREVIEW_OPENED. Provided value will be automatically converted to upper-case.
         * Default value is chosen based on initial issue, if provided, or first issue from issue list.
         * @property {Map<string, string>} [optionalParams] Additional values to make available as placeholders
         * Keys will be automatically converted to upper-case.
         * NOTE: Keys may not use the same keys as native event placeholders, specifically:
         * - ISSUE_ID
         * - ISSUE_NAME
         * - PUBLICATION_ID
         * - PUBLICATION_NAME
         * - PROPERTY_*
         * - PAGE_ID
         * - PAGE_LABEL
         * - PAGE_TITLE
         * - PAGE_SECTION
         * - PAGE_ALIAS
         * - PAGE_NUMBER
         * - PAGE_INDEX
         */

        /**
         * @typedef {Object} OpenContentParams
         *
         * @property {string[]} issueIds List of issue IDs for pager
         * @property {InitialIssue} [initialIssue]
         * @property {boolean} [skipSync] Skip remote issue data sync, only locally known issues will be opened
         *
         * @property {DisplayConfig} [config] Optional config for display of issue.
         * @property {TrackingConfig} [tracking] Optional config for tracking behaviour
         */

        /**
         * This method allows opening content.
         *
         * If the device is offline it will fallback to locally known issues only. If no issues were locally available,
         * a NETWORK error is thrown.
         *
         * @param {OpenContentParams} params
         * @returns {Promise<void>}
         * @throws {ErrorResult} code can have values: NETWORK, NO_ISSUES_FOUND, UNKNOWN
         * @since 1.0
         */
        open: function (params) {},
    },
    /**
     * @since 2.1
     */
    entitlement: {
        /**
         * Changelog:
         * Version 1.0: Initial version
         * Version 1.1: Added new error codes to login failure result
         */
        version: {
            major: 1,
            minor: 1,
            toString: function () {
                return this.major + '.' + this.minor;
            },
        },

        /**
         * @typedef {('LOGGED_OUT', 'LOGGED_IN', 'RELOGIN_REQUIRED')} LoginState
         */

        /**
         * @typedef {Object} EntitlementUserData
         *
         * @property {string} [accessToken]
         * @property {string} [refreshToken]
         * @property {string} [username]
         * @property {string[]} roles
         * @property {LoginState} state
         */

        getUserData: function () {
            return Promise.resolve({ refreshToken: null, username: undefined, accessToken: null });
        },
        /**
         * @typedef {Object} LoginParams
         *
         * @property {string} [username] Required for non-OAuth-based entitlements
         * @property {string} [password] Required for non-OAuth-based entitlements
         */

        /**
         * @param {LoginParams} params
         * @returns {Promise<EntitlementUserData>}
         * @throws {ErrorResult} Possible error codes:
         * - ILLEGAL_ARGUMENTS (username/password missing for non-OAuth entitlement)
         * - INVALID_CREDENTIALS (entitlement error codes: WRONG_PASSWORD_OR_USERNAME(2), AUTHENTICATION_ERROR(4), WRONG_PASSWORD(5))
         * - USER_DEACTIVATED (Username and password are correct, but user is deactivated. Remote error code: 3)
         * - ALREADY_LOGGED_IN
         * - CANCELLED (if OAuth flow was cancelled)
         * - INSTALLATION_LIMIT_EXCEEDED (Login successful but user has reached limit of devices. Remote error code: 6)
         * - CONFIGURATION_ERROR (entitlement error codes: SYSTEM_ERROR_IN_REMOTE_SYSTEM(7), PARAMETER_ERROR_IN_REMOTE_SYSTEM(8))
         * - UNKNOWN (entitlement error codes: UNKNOWN_ERROR(1))
         * @since 1.0
         */
        login: function (params) {},
        /**
         * @returns {Promise<void>}
         * @throws {ErrorResult} Error codes: NOT_LOGGED_IN
         * @since 1.0
         */
        logout: function () {},
    },
    /**
     * @since 1.0
     */
    issue: {
        /**
         * Changelog:
         * Version 1.0: Initial version
         * Version 2.0: Add openFile-API, changed to other APIs to use Promises. Old APIs still available but deprecated.
         */
        version: {
            major: 2,
            minor: 0,
            toString: function () {
                return this.major + '.' + this.minor;
            },
        },
        /**
         * @typedef {Object} IssuePage
         *
         * @property {string} [id]
         * @property {number} pageIndex
         * @property {number} pageNumber
         * @property {string} [pageLabel]
         * @property {string} [title]
         * @property {string} [shortTitle]
         * @property {string} [alias]
         * @property {boolean} showPurchaseSuggestion
         * @property {boolean} placeholder
         * @property {boolean} excludeFromPaging
         * @property {string} [targetURL]
         * @property {string} [thumbnailURL]
         * @property {boolean} sharingEnabled
         * @property {string} [sharingText]
         * @property {string} [sharingURL]
         * @property {string} [customData]
         */

        /**
         * @typedef {Object} GetPagesResult
         *
         * @property {IssuePage[]} issuePages
         */

        /**
         * The getPages method can be used to retrieve all pages.
         *
         * @returns {Promise<GetPagesResult>}
         * @throws {ErrorResult}
         * @since 2.0
         */
        getPages: function () {},
        /**
         * @typedef {Object} TocEntry
         *
         * @property {string} [pageId]
         * @property {string} [pageAlias]
         * @property {string} [title]
         * @property {string} [section]
         * @property {string} [shortTitle]
         * @property {string} [teaser]
         * @property {string} [thumbnailURL]
         */

        /**
         * @typedef {Object} GetTocResult
         *
         * @property {TocEntry[]} tocEntries
         */

        /**
         * The getToc method can be used to retrieve all ToC pages.
         *
         * @returns {Promise<GetTocResult>}
         * @throws {ErrorResult}
         * @since 2.0
         */
        getToc: function () {},
        /**
         * @typedef {('INTERNAL', 'EXTERNAL')} OpenFileTarget
         */
        /**
         * @typedef {('MODAL', 'EMBEDDED')} OpenFileMode
         */
        /**
         * @typedef {Object} OpenFileParams
         *
         * @property {string} url Path to the file
         * @property {OpenFileTarget} [target] Where to open the web url, internal app browser or external system browser. Defaults to INTERNAL.
         * @property {OpenFileMode} [mode] When target is set to internal, this property defines, where to open the url. Defaults to MODAL.
         * @property {boolean} [showNavigation] When target is set to internal, this property defines, if a navigation will be shown. Defaults to false.
         * @property {boolean} [showTitleBar] When target is set to internal, this property defines, if the title bar will be shown. Defaults to true.
         * @property {boolean} [forceStatusBar] When target is set to internal and showTitleBar is disabled, this property defines, if the status bar will be shown. Defaults to false.
         * @property {boolean} [showAppLogo] When target is set to internal and title bar will be shown, this property defines, if the app logo will also be shown. Defaults to false.
         */

        /**
         * Open a file from the content.
         *
         * @param {OpenFileParams} params
         * @returns {Promise<void>}
         * @throws {ErrorResult}
         * @since 2.0
         */
        openFile: function (params) {},
        /**
         * @typedef {Object} GetBaseUrlResult
         *
         * @property {string} baseUrl
         */
        /**
         * Returns the URL to the current issue content root.
         *
         * @returns {Promise<GetBaseUrlResult>}
         * @throws {ErrorResult}
         * @since 2.0
         */
        getBaseUrl: function () {},
    },
    /**
     * @since 2.1
     */
    issuePager: {
        /**
         * Changelog:
         * Version 1.0: Initial version
         * Version 1.1:
         * - Add displayConfig to PagerInfo
         * - Add retryLoad method
         */
        version: {
            major: 1,
            minor: 1,
            toString: function () {
                return this.major + '.' + this.minor;
            },
        },
        /**
         * @typedef {Object} PagerIssuePublication
         *
         * @property {string} id
         * @property {string} name
         * @property {Map<string, string>} properties
         */
        /**
         * @typedef {Object} PagerIssue
         *
         * @property {string} id
         * @property {string} name
         * @property {string} [alias]
         * @property {string} [externalId]
         * @property {Map<string, string>} properties
         * @property {PagerIssuePublication} publication
         */

        /**
         * @typedef {Object} PagerInfo
         *
         * @property {number} visibleIndex The currently visible issue index
         * @property {number} currentIndex The index of the current issue
         *
         * @property {PagerIssue[]} issues Available issues in current pager
         * @property {DisplayConfig} displayConfig The configuration that is used to display the content.
         */

        /**
         * @returns {Promise<PagerInfo>}
         * @since 1.0
         */
        getInfo: function () {},
        /**
         * @typedef {Object} NavigateToParams
         *
         * @property {string} issueId
         */

        /**
         *
         * @param {NavigateToParams} params
         * @returns {Promise<void>}
         * @throws {ErrorResult} NOT_FOUND (Issue not found in issue pager)
         * @since 1.0
         */
        navigateTo: function (params) {},
        /**
         * @typedef {Function} IssueChangeListener
         *
         * @param {PagerInfo} pagerInfo
         */

        /**
         * Add a listener which is called when the currently visible issue has changed.
         *
         * @param {IssueChangeListener} listener
         * @since 1.0
         */
        addIssueChangeListener: function (listener) {},
        /**
         * Remove a previously added {@link IssueChangeListener}.
         *
         * @param {IssueChangeListener} listener
         * @since 1.0
         */
        removeIssueChangeListener: function (listener) {},
        /**
         * Retry loading the current content.
         *
         * NOTE: Only available within the custom HTML error page for content loading errors.
         */
        retryLoad: function () {},
    },
    /**
     * @since 1.0
     */
    media: {
        /**
         * Changelog:
         * Version 1.0: Initial version
         * Version 1.1: Add bufferedTime to PlaybackProgress
         * Version 2.0: Replace legacy APIs with Promise-based APIs
         */
        version: {
            major: 2,
            minor: 0,
            toString: function () {
                return this.major + '.' + this.minor;
            },
        },
        /**
         * @typedef {Object} StartAudioParams
         *
         * @property {string} url
         * @property {string} displayName
         */
        /**
         * Starts a remote audio stream or file.
         *
         * @param {StartAudioParams} params
         * @returns {Promise<void>}
         * @throws {ErrorResult} Possible error codes:
         * - NETWORK: No network available or another network related error
         * - NOT_FOUND: The requested resource was not found
         * The status listener will not be called if the promise fails with an error.
         * The status listener will not reflect errors received in the promises reject callback.
         * @since 2.0
         */
        startAudio: function (params) {},
        /**
         * Pauses the current audio playback.
         *
         * @returns {Promise<void>}
         * @throws {ErrorResult}
         * @since 2.0
         */
        pauseAudio: function () {},
        /**
         * Resumes the current audio playback.
         *
         * @returns {Promise<void>}
         * @throws {ErrorResult}
         * @since 2.0
         */
        resumeAudio: function () {},
        /**
         * Stops the current audio playback.
         *
         * @returns {Promise<void>}
         * @throws {ErrorResult}
         * @since 2.0
         */
        stopAudio: function () {},
        /**
         * @typedef {Object} SeekToParams
         *
         * @property {number} time the desired time, in milliseconds.
         */
        /**
         * Seeks to the given time in the current audio playback.
         *
         * @param {SeekToParams} params
         * @returns {Promise<void>}
         * @throws {ErrorResult} Possible error codes:
         * - NOT_LOADED: No audio is currently loaded
         * @since 2.0
         */
        seekTo: function (params) {},
        /**
         * @typedef {Object} SetPlaybackRateParams
         *
         * @property {number} playbackRate between 0.5 - 2.0
         */

        /**
         * @typedef {Object} SetPlaybackRateResult
         *
         * @property {number} playbackRate the current playback rate (after clipping)
         */
        /**
         * Sets the playback rate to the specified value. Only values between 0.5 and 2.0 are allowed.
         * Values outside these bounds will be clipped to their respective limits.
         * The playback rate gets reset to the default rate of 1.0 on app restarts.
         *
         * @param {SetPlaybackRateParams} params
         * @returns {Promise<SetPlaybackRateResult>}
         * @throws {ErrorResult}
         * @since 2.0
         */
        setPlaybackRate: function (params) {},
        /**
         * @typedef {Object} GetPlaybackRateResult
         *
         * @property {number} playbackRate
         */
        /**
         * Get the current playback rate.
         *
         * @returns {Promise<GetPlaybackRateResult>}
         * @throws {ErrorResult}
         * @since 2.0
         */
        getPlaybackRate: function () {},
        /**
         * @typedef {('NONE'|'LOADING'|'READY'|'PLAYING'|'ERROR')} PlaybackState
         */

        /**
         * @typedef {Object} PlaybackError
         *
         * @property {string} code Either NOT_FOUND, NETWORK or UNKNOWN
         * @property {string} message
         */

        /**
         * @typedef {Object} PlaybackStatus
         *
         * @property {string} [displayName]
         * @property {string} [url]
         * @property {PlaybackState} playbackState
         * @property {PlaybackError} [error]
         */

        /**
         * @typedef {Function} PlaybackStatusListener
         *
         * @param {PlaybackStatus} status
         */

        /**
         * Adds a listener for playback state changes.
         *
         * @param {PlaybackStatusListener} statusListener
         */
        addStatusListener: function (statusListener) {},
        /**
         * Removes a listener for playback state changes.
         *
         * @param {PlaybackStatusListener} statusListener
         */
        removeStatusListener: function (statusListener) {},
        /**
         * @typedef {Object} PlaybackProgress
         *
         * @property {number} currentTime Current playback position, in milliseconds.
         * @property {number} duration The duration of the playback, in milliseconds.
         * @property {number} bufferedTime Position up to which data is buffered, in milliseconds.
         *
         */

        /**
         * @typedef {Function} PlaybackProgressListener
         *
         * @param {PlaybackProgress} progress
         */

        /**
         * Adds a listener for playback progress changes.
         *
         * @param {PlaybackProgressListener} progressListener
         */
        addProgressListener: function (progressListener) {},
        /**
         * Removes listener for playback progress changes.
         *
         * @param {PlaybackProgressListener} progressListener
         */
        removeProgressListener: function (progressListener) {},
    },
    /**
     * @since 1.0
     */
    metadata: {
        /**
         * Changelog:
         * Version 2.0: getMetadata allows querying multiple keys, changed to Promise-based API
         * Version 2.1: getMetadata without params returns all metadata
         */
        version: {
            major: 2,
            minor: 1,
            toString: function () {
                return this.major + '.' + this.minor;
            },
        },
        /**
         * @typedef {string} MetadataKey
         *
         * Keys available in all webviews:
         *  - app_id
         *  - app_version
         *  - bundle_id bundle identifier / package name of the app
         *  - deeplink_scheme
         *  - device_id
         *  - device_model
         *  - device_os
         *  - entitlement_forced_login_enabled
         *  - entitlement_login
         *  - entitlement_mode {'entitlement', 'oauth', 'none'}
         *  - entitlement_refresh_token
         *  - entitlement_token
         *  - firebase_instance_token
         *  - firebase_instance_id
         *  - locale
         *  - manager_base_url
         *  - platform
         *  - preview_app
         *  - push_registration_token
         *
         * Keys available in the entitlement webview only:
         *  - entitlement_login_mode {'login', 'relogin'}
         *
         * Keys available in content webviews and in app browsers opened from content only:
         *  - issue_alias
         *  - issue_id
         *  - issue_name
         *  - publication_id
         *  - publication_name
         * Keys available in content webviews only:
         *  - page_alias
         *  - page_filename
         *  - page_id
         *  - page_index
         *  - page_title
         *
         * Keys available in onboarding webviews only:
         *  - onboarding_mode {'appstart', 'manual'}
         */

        /**
         * @typedef GetMetadataParams
         *
         * @property {MetadataKey[]} keys
         */

        /**
         * Get metadata values.
         *
         * This method changed in version 2.0: The old signature (key, listener) has been replaced by a modern Promise-based API.
         * Old usage of this method is still supported but will log a warning about it's deprecation.
         * If no params are set then all keys and values will be returned.
         *
         * @param {GetMetadataParams} [params] An instance of GetMetadataParams. Alternatively, this can be of type string.
         * If this is the case, the old behavior remains in place (callback function, which is to be provided as second parameter, is called with value for requested single metadata key).
         * @param {Function} [listener] Only used for legacy usage, i.e. single string parameter instead of params object.
         *
         * @returns {Promise<Map<MetadataKey, string>>} an object which maps all requested metadata keys to their values.
         * Unknown metadata keys are ignored. If all requested keys are unknown, en empty object will be returned.
         * @throws {ErrorResult} Promise will return this type in rejection callback. Property 'code' can have values: ERROR_ILLEGAL_ARGUMENTS
         * @since 2.0 Promise-based API
         */
        getMetadata: function (params, listener) {},
    },
    /**
     * @since 2.1
     */
    share: {
        /**
         * Changelog:
         * Version 1.0: Initial version
         */
        version: {
            major: 1,
            minor: 1,
            toString: function () {
                return this.major + '.' + this.minor;
            },
        },
        /**
         * @typedef {Object} ShareParams
         *
         * @property {string} text
         * @property {string} [url]
         */

        /**
         * Starts the native sharing flow with the given text and url.
         *
         * NOTE: The promise resolves when the native share has been opened. This does not necessarily mean that the
         * text/url has been shared with any app.
         *
         * @param {ShareParams} params
         *
         * @returns {Promise<void>}
         * @throws {ErrorResult} If an error occurred while opening the native share dialog
         * @since 1.0
         */
        share: function (params) {},

        /**
         * Starts the native sharing flow with an app sharing url and an app sharing text based on the configuration in
         * the dynamic resources.
         *
         * NOTE: The promise resolves when the native share has been opened. This does not necessarily mean that the
         * text/url has been shared with any app.
         *
         * @returns {Promise<void>}
         * @throws {ErrorResult} If an error occurred while opening the native share dialog
         * @since 1.1
         */
        shareApp: function () {},
        /**
         * @typedef {Object} ShareContentParams
         *
         * @property {string} contentId
         * @property {string} contentName
         */

        /**
         * Starts the native sharing flow with a content sharing url for the given contentId and a sharing text based
         * on the configuration in the dynamic resources and the given contentName.
         *
         * NOTE: The promise resolves when the native share has been opened. This does not necessarily mean that the
         * text/url has been shared with any app.
         *
         * @param {ShareContentParams} params
         *
         * @returns {Promise<void>}
         * @throws {ErrorResult} If an error occurred while opening the native share dialog
         * @since 1.1
         */
        shareContent: function (params) {},
    },
    /**
     * @since 1.0
     */
    state: {
        /**
         * Changelog:
         * Version 1.0: Initial version
         * Version 2.0: Replace legacy APIs with Promise-based APIs
         */
        version: {
            major: 2,
            minor: 0,
            toString: function () {
                return this.major + '.' + this.minor;
            },
        },
        /**
         * @typedef {Object} SetStateParams
         *
         * @property {string} key case-sensitive key
         * @property {string} [value] If the value is null the key gets deleted.
         */
        /**
         * The setState method can be used to store a string value for a string key.
         *
         * @param {SetStateParams} params
         * @returns {Promise<void>}
         * @throws {ErrorResult}
         * @since 2.0
         */
        setState: function (params) {},
        /**
         * @typedef {Object} GetStateParams
         *
         * @property {string} key
         */
        /**
         * @typedef {Object} StateEntry
         *
         * @property {string} key
         * @property {string} value Value of the state, can be null.
         */
        /**
         * The getState method can be used to retrieve a string value for a string key.
         *
         * @param {GetStateParams} params
         * @returns {Promise<StateEntry>}
         * @since 2.0
         */
        getState: function (params) {},
    },
    /**
     * @since 1.0
     */
    store: {
        /**
         * Changelog:
         * Version 1.0: Initial version
         * Version 2.0:
         * * Migrate to {@link Promise}-based APIs.
         * * Add addSubscriptionCode and removeSubscriptionCode
         * * Deprecate addSubscriptionCodes and removeSubscriptionCodes
         * * The old APIs are still available but will log a warning for each usage.
         * Version 2.1:
         * * Add introductory price information to PriceInfo
         * * Add trial period information to PriceInfo
         * Version 2.2:
         * * Add offerId to params for subscribe
         * * Add discounts to PriceInfo
         * * Add openCodeRedemption
         * * Add PurchaseResult to store.purchase / subscribe
         * Version 2.3:
         *  * Add option to allow purchase of repeatable products (consumables)
         * Version 2.4:
         *  * add contentIds, deprecate issueIds in ConsumeOptions
         */
        version: {
            major: 2,
            minor: 4,
            toString: function () {
                return this.major + '.' + this.minor;
            },
        },
        /**
         * @typedef {Object} PurchaseResult
         *
         * @property {string} productId
         * @property {string} [transactionId]
         */

        /**
         * @typedef {Object} ConsumeOptions
         *
         * @property {string[]} [issueIds] List of issues to be unlocked with this purchase. NOTE: issueIds is deprecated in favor of contentIds. If any issueIds are provided they will be added to contentIds.
         * @property {string[]} contentIds List of contents to be unlocked with this purchase.
         */

        /**
         * @typedef {Object} PurchaseParams
         *
         * @property {string} productId
         * @property {ConsumeOptions} [consumeOptions] If provided, contains information for unlocking contents. This will cause the purchase to be consumed and available for repeated purchases.
         */

        /**
         * Purchase to a product
         * NOTE: When used in Catalog-API apps, this call will NOT track any data about the purchase. The HTML storefront
         * needs to manually use the tracking-API to track the purchase.
         *
         * @param {PurchaseParams} params
         * @returns {Promise<PurchaseResult>}
         * @throws {ErrorResult} CANCELLED (User cancelled purchase), NETWORK (Offline or similar errors), ALREADY_OWNED (Product already owned by user), UNKNOWN_PRODUCT (Product not known by store), VALIDATION_FAILED (Purchase could not be validated by Purple backend)
         * @since 2.0 Promise-based API
         */
        purchase: function (params) {},

        /**
         * @typedef {("IMMEDIATE_WITH_TIME_PRORATION"|"IMMEDIATE_AND_CHARGE_PRORATED_PRICE"|"IMMEDIATE_WITHOUT_PRORATION"|"DEFERRED")} ProrationMode
         *
         * IMMEDIATE_WITH_TIME_PRORATION: The subscription is upgraded or downgraded immediately. Any time remaining is adjusted based on the price difference, and credited toward the new subscription by pushing forward the next billing date. This is the default behavior.
         * IMMEDIATE_AND_CHARGE_PRORATED_PRICE: The subscription is upgraded immediately, and the billing cycle remains the same. The price difference for the remaining period is then charged to the user.
         * IMMEDIATE_WITHOUT_PRORATION: The subscription is upgraded or downgraded immediately, and the new price is charged when the subscription renews. The billing cycle remains the same.
         * DEFERRED: The subscription is upgraded or downgraded only when the subscription renews.
         *
         * See https://developer.android.com/google/play/billing/subscriptions#change for more details.
         */

        /**
         * @typedef {Object} ReplaceProductParams
         *
         * @property {string} oldProductId The product ID of the existing subscription.
         * @property {ProrationMode} prorationMode The type of proration used for upgrading or downgrading a user's subscription.
         */

        /**
         * @typedef {Object} SubscribeParams
         *
         * @property {string} productId
         * @property {string} [offerId] iOS only: The promo offer that should be purchased
         * @property {ReplaceProductParams} [replaceProductParams] Android only: Use to up-/downgrade an existing subscription to a new subscription.
         */

        /**
         * Subscribe to a subscription.
         * NOTE: When used in Catalog-API apps, this call will NOT track any data about the purchase. The HTML storefront
         * needs to manually use the tracking-API to track the purchase.
         *
         * @param {SubscribeParams} params
         * @returns {Promise<PurchaseResult>}
         * @throws {ErrorResult} One of:
         * - CANCELLED (User cancelled purchase)
         * - NETWORK (Offline or similar errors)
         * - ALREADY_OWNED (Product already owned by user)
         * - UNKNOWN_PRODUCT (Product not known by store)
         * - UNKNOWN_OFFER (iOS only: Offer not known by store)
         * - VALIDATION_FAILED (Purchase could not be validated by Purple backend)
         * @since 2.0 Promise-based API
         */
        subscribe: function (params) {},
        /**
         * @typedef {Object} GetPricesParams
         *
         * @property {string[]} productIds
         */

        /**
         * @typedef {Object} IntroductoryPriceInfo
         *
         * @property {string} formattedPrice Formatted introductory price of a subscription, including its currency sign, such as €3.99. The price doesn't include tax.
         * @property {number} price The introductory price of the product with two decimals
         * @property {string} pricePeriod The billing period of the introductory price, specified in ISO 8601 format, e.g. P7D equates to seven days.
         * @property {number} priceCycles The number of subscription billing periods for which the user will be given the introductory price, such as 3.
         */

        /**
         * @typedef {Object} DiscountInfo
         *
         * @property {string} offerId
         * @property {string} formattedPrice Formatted price of the discounted subscription period, including its currency sign, such as €3.99. The price doesn't include tax.
         * @property {number} price The price of the discounted subscription period with two decimals
         * @property {string} pricePeriod The period for the discounted price, specified in ISO 8601 format, e.g. P7D equates to seven days.
         * @property {number} priceCycles The number of periods for which the user will be given the discounted price, such as 3.
         */

        /**
         * @typedef {Object} PriceInfo
         *
         * @property {string} productId The product ID of the in-app purchase / subscription
         * @property {string} formattedPrice Returns formatted price, including its currency sign. The price does not include tax.
         * @property {number} price Price of the product with two decimals
         * @property {string} currency The currency of the price
         * @property {IntroductoryPriceInfo} [introductoryPrice] Information about introductory pricing. Only available for subscriptions. Before offering the introductory pricing eligibility needs to be checked against catalog.getSubscriptions.
         * @property {string} [trialPeriod] The billing period of the trial, specified in ISO 8601 format, e.g. P7D equates to seven days. Before offering the trial period eligibility needs to be checked against catalog.getSubscriptions.
         * @property {DiscountInfo[]} [discounts] iOS (12.2+) only: Available discounts for this product. Only available for subscriptions. Use in combination with offerId parameter in subscribe-API.
         */

        /**
         * @typedef {Object} GetPricesResult
         *
         * @property {PriceInfo[]} priceInfos
         */

        /**
         * Get prices for product IDs.
         *
         * @param {GetPricesParams} params
         * @returns {Promise<GetPricesResult>}
         * @throws {ErrorResult} NETWORK (Offline or similar errors)
         * @since 2.0 Promise-based API
         */
        getPrices: function (params) {},
        /**
         * Restores purchases on device. Only available on iOS
         * (Android will resolve Promise immediately).
         *
         * @returns {Promise<void>}
         * @throws {ErrorResult} NETWORK (Offline or similar errors)
         * @since 2.0 Promise-based API
         */
        restorePurchases: function () {},
        /**
         * Set a function as a listener which should be called when
         * the purchase state of a subscription product did change.
         *
         * NOTE: Only supported on iOS for pre-Catalog-API-Apps.
         *
         * @param listener
         * @since 1.0
         * @deprecated Deprecated since 2.0
         */
        setPurchaseStateListener: function (listener) {},
        /**
         * @typedef {Object} GetSubscriptionCodesResult
         *
         * @property {string[]} subscriptionCodes
         */

        /**
         * Get current subscription codes.
         *
         * @returns {Promise<GetSubscriptionCodesResult>}
         * @throws {ErrorResult}
         * @since 2.0 Promise-based API
         */
        getSubscriptionCodes: function () {},
        /**
         * @typedef {Object} AddSubscriptionCodeParams
         *
         * @property {string} code
         */

        /**
         * @param {AddSubscriptionCodeParams} params
         * @returns {Promise<void>}
         * @throws {ErrorResult} with error codes: NETWORK, INVALID_CODE, DUPLICATE_CODE
         * @since 2.0
         */
        addSubscriptionCode: function (params) {},
        /**
         * @typedef {Object} RemoveSubscriptionCodeParams
         *
         * @property {string} code
         */
        /**
         * @returns {Promise<void>}
         * @throws {ErrorResult} with error codes: NETWORK, UNKNOWN_CODE
         * @since 2.0
         */
        removeSubscriptionCode: function (params) {},

        /**
         * Add multiple subscription codes
         * @deprecated Use {@link Promise}-based API: addSubscriptionCode
         */
        addSubscriptionCodes: function (codes, callback) {},
        /**
         * Remove multiple subscription codes
         * @deprecated Use {@link Promise}-based API: removeSubscriptionCode
         */
        removeSubscriptionCodes: function (codes, callback) {},
        /**
         * Opens the native iOS subscription offer code redemption UI.
         * NOTE: Only supported on iOS >= 14.0
         *
         * @returns {Promise<void>}
         * @throws {ErrorResult} NOT_AVAILABLE (not iOS >= 14.0)
         * @since 2.2
         */
        openCodeRedemption: function () {},
    },
    /**
     * @since 1.0
     */
    tracking: {
        /**
         * Changelog:
         * Version 1.0: Initial version
         * Version 1.1: added callback parameter to trackAction, trackView, trackPurchase
         * Version 2.0:
         * - Replace legacy APIs with Promise-based APIs
         * - Add setUserAttribute
         * - transactionId is now optional
         */
        version: {
            major: 2,
            minor: 0,
            toString: function () {
                return this.major + '.' + this.minor;
            },
        },
        /**
         * @typedef {Object} TrackActionParams
         *
         * @property {string} key the event name
         * @property {Map<string, string>} [optionalParams] can be sent with each event if the tracking service supports this.
         * Every key will be included in the event. The values can contain all placeholders supported for the event
         * and will be evaluated (see app tracking) when sending the event to the service.
         */

        /**
         * Track an action, e.g. a tap on a button.
         *
         * @param {TrackActionParams} params
         * @returns {Promise<void>}
         * @throws {ErrorResult}
         * @since 2.0
         */
        trackAction: function (params) {},
        /**
         * @typedef {Object} TrackViewParams
         *
         * @property {string} key the key of the screen event
         * @property {Map<string, string>} [optionalParams] can be sent with each event if the tracking service supports this.
         * Every key will be included in the event. The values can contain all placeholders supported for the event
         * and will be evaluated (see app tracking) when sending the event to the service.
         */

        /**
         * Track a view, e.g. a currently shown screen.
         *
         * @param {TrackViewParams} params
         * @returns {Promise<void>}
         * @throws {ErrorResult}
         * @since 2.0
         */
        trackView: function (params) {},
        /**
         * @typedef {Object} TrackPurchaseParams
         *
         * @property {string} key the purchase event key
         * @property {string} productId
         * @property {number} price
         * @property {string} currencyCode
         * @property {string} [transactionId]
         * @property {Map<string, string>} [optionalParams] can be sent with each event if the tracking service supports this.
         * Every key will be included in the event. The values can contain all placeholders supported for the event
         * and will be evaluated (see app tracking) when sending the event to the service.
         */

        /**
         * Track a purchase.
         *
         * @param {TrackPurchaseParams} params
         * @returns {Promise<void>}
         * @throws {ErrorResult}
         * @since 2.0
         */
        trackPurchase: function (params) {},
        /**
         * @typedef {Object} SetUserAttributeParams
         *
         * Either stringValue or booleanValue can be non-null. Set both to null to remove user attribute.
         *
         * @property {string} key
         * @property {string} [stringValue]
         * @property {boolean} [booleanValue]
         */

        /**
         * Set or remove a user attribute.
         *
         * @param {SetUserAttributeParams} params
         * @returns {Promise<void>}
         * @throws {ErrorResult}
         * @since 2.0
         */
        setUserAttribute: function (params) {},
    },
    experience: {
        router: {
            init: (useTabs) => {},

            /**
             * Get channel id if used with BroadCast Channel API.
             * @returns {Promise<string>}
             * @since 1.0
             */
            getChannelId: () => {},

            /**
             * Get current route path opened in view.
             * Mostly used by widgets to get the current route on init.
             *
             * @returns {Promise<RouteState>}
             * @since 1.0
             */
            getCurrentRoute: () => {
                function getQueryMap(query) {
                    if (typeof query !== 'string') {
                        return null;
                    }

                    const toType = function (a) {
                            return {}.toString
                                .call(a)
                                .match(/\s([a-zA-Z]+)/)[1]
                                .toLowerCase();
                        },
                        map = new Map();

                    // map the hit query into a proper object
                    query.replace(
                        /([^&|\?=]+)=?([^&]*)(?:&+|$)/g,
                        function (match, key, value) {
                            if (map.has(key)) {
                                // the key already exists, so we need to check if it is an array, if not, make it an array and add the new value
                                if (toType(map.get(key)) !== 'array') {
                                    // it's not an array - make it an array
                                    map.set(key, [map.get(key)]);
                                }
                                // push the new value into the array
                                map.get(key).push(value);
                            } else {
                                // put the value into the map
                                map.set(key, value);
                            }
                        },
                    );
                    return map;
                }
                return Promise.resolve({
                    path: window.location.pathname,
                    queryParams: getQueryMap(window.location.search),
                    stateSender: 'client',
                });
            },

            /**
             * Changes the route based on the given params.
             * The brand will be handled internally and does not need to be passed in the path, e.g. changeRoute with { path: "/newsstand" }
             * would navigate on prod to 'web.purplemanager.com/<brand>/<bidirectionalURL>/newsstand'.
             *
             * @param {RouteState} params
             * @returns {Promise<boolean>} true if navigation was successful
             * @throws {ErrorResult}
             * @since 1.0
             */
            changeRoute: (params) => {
                window.dispatchEvent(
                    new RouteChangedEvent('purpleRouterChanges', params),
                );
                return Promise.resolve(true);
            },

            /**
             * Adds a listener for route changes.
             *
             * @param listener
             * @returns {Promise<string>} we can return any param here as string
             * @throws {ErrorResult}
             * @since 1.0
             */
            addRouteChangeListener: (listener) => {
                window.addEventListener('purpleRouterChanges', (event) => {
                    if (event.data.stateSender === 'client') {
                        listener(event.data);
                    }
                });
                return Promise.resolve('');
            },

            /**
             * Removes a listener for route changes.
             *
             * @param args a previously registered listeners' data, could be the listener itself
             * @optional
             * @since 1.0
             */
            removeRouteChangeListener: () => {},
        },
    },
};
