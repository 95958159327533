import { AnalysisCard } from '@components/analysis/analysis-card';
import { CompanyDto } from '@boersenzeitung/shared/dtos/Company.dto';
import { DpaAfxArticleDto } from '@boersenzeitung/shared/dtos/Dpa-afx-article.dto';
import { Empty } from '@components/empty';
import { OVERVIEW_BATCH_SIZE } from '@utils/constants';
import { TeaserText } from '@components/teaser-text';
import { TitledSeparator } from '@components/titled-separator';
import React, { useMemo, useState } from 'react';
import apiClient from '@api/api.client';

export const OverviewAnalysis = ({
    company,
    url,
}: {
    company: CompanyDto;
    url: string;
}) => {
    const [dpaResult, setDpaResult] = useState<DpaAfxArticleDto[]>([]);
    useMemo(() => {
        const loadData = async () => {
            const loadedData = await apiClient.getDpaAfxArticles(
                company.id,
                0,
                OVERVIEW_BATCH_SIZE,
            );
            setDpaResult(loadedData.articles);
        };
        void loadData();
    }, [company.id]);

    const analysisCards: JSX.Element[] = dpaResult.map((item) => (
        <AnalysisCard article={item} key={item.id} />
    ));

    return (
        <div className="mb-4">
            <TitledSeparator
                title="Analysen"
                moreActionTarget={`/aktie/analysen${
                    company.isin && url ? '/' + url + '-' + company.isin : ''
                }`}
            />

            <div>
                <TeaserText>
                    Hier finden Sie die neuesten Aktien-Analysen. Per Klick auf
                    das Analysehaus gelangen Sie zu ausführlichen Studien mit
                    Kursziel, Einstufung, Jahresausblick und weiteren
                    Experten-Meinungen.
                </TeaserText>
                {analysisCards.length ? <div>{analysisCards}</div> : <Empty />}
            </div>
        </div>
    );
};
