import { CheckIcon } from '@heroicons/react/24/solid';
import { Listbox } from '@headlessui/react';
import { classNames } from '@utils/classNames';
import React from 'react';

export function SelectOption({
    option,
    disabled,
    className,
    testId,
    showFullTextOption,
}: {
    option: { label: string; value: string | null };
    disabled?: boolean;
    className?: string;
    testId: string;
    showFullTextOption?: boolean;
}) {
    return (
        <Listbox.Option
            className={({ active }) =>
                classNames(
                    active ? 'text-white bg-red' : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-3 pr-9',
                )
            }
            value={option.value}
            disabled={disabled}
            data-testid={testId}
        >
            {({ selected, active }) => (
                <>
                    <span
                        className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            showFullTextOption ? '' : 'block truncate',
                            className,
                        )}
                        data-cy-state={`${selected}`}
                    >
                        {option.label}
                    </span>
                    {selected && (
                        <span
                            className={classNames(
                                active ? 'text-white' : '',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                            )}
                        >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                    )}
                </>
            )}
        </Listbox.Option>
    );
}
