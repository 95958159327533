import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import apiClient, { ApiClient } from '../api/api.client';

export function useApiClient<T>(
    fetchFunction: (apiClient: ApiClient) => Promise<T>,
    deps: any[] = [],
): { data: T | undefined; error: AxiosError | null; loading: boolean } {
    const [data, setData] = useState<T | undefined>(undefined);
    const [error, setError] = useState<AxiosError | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let canceled = false;
        async function fetchData() {
            setLoading(true);
            try {
                const response = await fetchFunction(apiClient);
                if (!canceled) {
                    setData(response);
                }
            } catch (error) {
                setError(error as AxiosError);
            }
            if (!canceled) {
                setLoading(false);
            }
        }
        void fetchData();
        return () => {
            canceled = true;
        };
    }, deps);

    return { data, error, loading };
}
