import { SearchTermResultDto } from '@boersenzeitung/shared/dtos/email-notification-seatch-term.dto';
import { ReactComponent as Spinner } from '@components/spinner.svg';
import { useApiClient } from '@hooks/useApiClient';
import { useEmailToken } from '@hooks/useEmailToken';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import React from 'react';
import apiClient from '@api/api.client';
export default function ListPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const { emailToken } = useEmailToken();
    const {
        data: resultListing,
        error,
        loading,
    } = useApiClient<SearchTermResultDto>(() => {
        if (emailToken) {
            return apiClient.getResultListings(emailToken);
        } else {
            return Promise.resolve({ totalCount: 0, results: [] });
        }
    }, [emailToken, apiClient]);

    if (loading) {
        return <Spinner />;
    }

    if (error || !resultListing?.results.length) {
        return <p>Es wurden keine Alerts gefunden</p>;
    }

    return (
        <div className="w-full grid grid-cols-2">
            <div className="col-span-2 grid grid-cols-2 font-bold bg-secondary-color mb-4 py-2">
                <p className="mx-4 text-center">Suchbegriff</p>
                <p className="mx-4 text-center">Anzahl der Ergebnisse</p>
            </div>

            {resultListing.results.map((result) => (
                <button
                    key={result.id}
                    className="grid grid-cols-2 mb-2 py-2 bg-background-color hover:cursor-pointer hover:!text-primary-color w-ful col-span-2"
                    onClick={() =>
                        navigate(
                            `/email-notification/list/${result.id}${location.search}`,
                        )
                    }
                    data-testid={`search-term-detail-button-${result.id}`}
                >
                    <p className="mx-4">{result.searchTerm}</p>
                    <p className="mx-4">{result.resultCount}</p>
                </button>
            ))}
        </div>
    );
}
