import { NavigationType } from '@hooks/useInstruments';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '@components/header/header';
import React, { useEffect } from 'react';

const navigation: NavigationType = [
    {
        name: 'Übersicht',
        href: '/fonds/',
        isInstrumentDependent: false,
        overrideHeaderAddition: '',
    },
    {
        name: 'News',
        href: '/fonds/news',
        isInstrumentDependent: false,
    },
    {
        name: 'Suche',
        href: '/fonds/suche',
        isInstrumentDependent: false,
    },
    {
        name: 'Top 5 Ultimo',
        href: '/fonds/top-5-ultimo',
        isInstrumentDependent: false,
    },
    {
        name: 'Finanzanzeigen',
        href: '/fonds/finanzanzeigen',
        isInstrumentDependent: false,
    },
];
export default function FundsIndex() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === '/fonds') {
            navigate({
                pathname: '/fonds/',
                search: location.search,
            });
        }
    });

    return <Header title="Fonds" navigation={navigation} />;
}
