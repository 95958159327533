import { CompanyDto, FlatCompanyDto } from './dtos/Company.dto';
import { InstrumentDto } from './dtos/instrument.dto';

export const parseUrlCompanyNamePart = (companyName?: string) => {
    let url = companyName
        ?.replace(/[üöäÜÖÄßẞ]/g, (match) => {
            switch (match) {
                case 'ü':
                    return 'ue';
                case 'ö':
                    return 'oe';
                case 'ä':
                    return 'ae';
                case 'Ü':
                    return 'Ue';
                case 'Ö':
                    return 'Oe';
                case 'Ä':
                    return 'Ae';
                case 'ß':
                    return 'ss';
                case 'ẞ':
                    return 'Ss';
                default:
                    return match;
            }
        })
        .replace(/[^a-zA-Z0-9]/g, '-');
    if (url && url.charAt(url.length - 1) === '-') {
        url = url.slice(0, -1);
    }
    return url;
};

export const generateCompanyUrl = (
    href: string,
    company?: CompanyDto | FlatCompanyDto,
    instrument?: InstrumentDto,
) => {
    const isin = instrument?.isin ?? company?.isin;
    return generateCompanySlug(href, isin, company?.name);
};

export const generateCompanySlug = (
    href: string,
    isin?: string,
    companyName?: string,
) => {
    const url = parseUrlCompanyNamePart(companyName);
    return `${href}${isin && url ? '/' + url + '-' + isin : ''}`;
};
