import { Disclosure } from '@headlessui/react';
import { InstrumentDto } from '@boersenzeitung/shared/dtos/instrument.dto';
import { NavigationType } from '@hooks/useInstruments';
import { Outlet, useLocation } from 'react-router-dom';
import { ReactComponent as Spinner } from '@components/spinner.svg';
import DesktopNavBar from '@components/header/desktop-nav-bar';
import HeaderTopRow from '@components/header/top-row/header-top-row';
import MobileNavBar from '@components/header/mobile-nav-bar';
import React, { useContext } from 'react';
import UrlDataContext from '../../contexts/urlDataContext';

export type HeaderProps = {
    title?: string;
    instrument?: InstrumentDto;
    navigation: NavigationType;
};

export default function Header({ title, instrument, navigation }: HeaderProps) {
    const { loading } = useContext(UrlDataContext);
    const location = useLocation();
    const isCompanyHeader = location.pathname.startsWith('/aktie');
    // TODO BOERSE-476
    const showPurpleStaticLinks = false;
    // const showPurpleStaticLinks = location.pathname.startsWith('/termine');

    if (loading) {
        return (
            <div className="flex justify-center">
                <Spinner />
            </div>
        );
    }

    return (
        <Disclosure as="nav">
            <>
                <div className="mx-auto max-w-5xl sm:px-6 lg:px-8 px-2">
                    <div className="flex break-words hyphens-auto" lang="de">
                        <div className="flex flex-col">
                            <div className="grow w-[10px] border-[0.3125rem] border-red border-r-0 border-solid" />
                        </div>
                        {!isCompanyHeader || instrument ? (
                            <>
                                <div className="grow mx-2">
                                    <HeaderTopRow
                                        instrument={instrument}
                                        title={title}
                                        navigation={navigation}
                                    />
                                    <div className="flex items-end justify-between md:h-16">
                                        <MobileNavBar
                                            instrument={instrument}
                                            navigation={navigation}
                                            showPurpleStaticLinks={
                                                showPurpleStaticLinks
                                            }
                                        />
                                        <DesktopNavBar
                                            instrument={instrument}
                                            navigation={navigation}
                                            showPurpleStaticLinks={
                                                showPurpleStaticLinks
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                            <h1 className="grow text-center py-8">
                                Kein Unternehmen gefunden
                            </h1>
                        )}
                        <div className="flex flex-col">
                            <div className="grow w-[10px] border-[0.3125rem] border-red border-l-0  border-solid" />
                        </div>
                    </div>
                    <div className="flex flex-col mt-10">
                        <Outlet />
                    </div>
                </div>
            </>
        </Disclosure>
    );
}
