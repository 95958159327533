import { DividendDto } from '@boersenzeitung/shared/dtos/dividend.dto';
import { DividendList } from '@components/dividend-list';
import { Helmet } from 'react-helmet';
import { TeaserText } from '@components/teaser-text';
import { TitledSeparator } from '@components/titled-separator';
import { useDocumentTitle } from '@hooks/useDocumentTitle';
import React, { useContext, useEffect, useState } from 'react';
import UrlDataContext from '../../contexts/urlDataContext';

export const Dividend = () => {
    const { currentInstrument } = useContext(UrlDataContext);

    const [dividendResults, setDividendResult] = useState<
        DividendDto[] | undefined
    >();
    useEffect(() => {
        if (currentInstrument?.company?.isin) {
            setDividendResult(currentInstrument.dividends);
        }
    }, [currentInstrument?.company?.isin, currentInstrument]);

    useDocumentTitle(
        currentInstrument?.company?.name
            ? `${currentInstrument.company.name} Dividenden | Ex-Tag`
            : 'Börsen-Zeitung',
    );

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content={`Aktuelle und historische Dividenden der ${currentInstrument?.company?.name}. Mit allen relevanten Angaben. Jetzt informieren!`}
                />
            </Helmet>
            <div>
                <TeaserText>
                    {`Hier sehen sie die aktuelle und historischen Dividenden der ${currentInstrument?.company?.name}. Zusätzlich werden Ex-Tage und Hauptversammlung (HV)-Termine angezeigt.`}
                </TeaserText>
                <TitledSeparator title="Übersicht der Dividenden" />
                <DividendList dividends={dividendResults ?? []} />
            </div>
        </>
    );
};
