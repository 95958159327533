import { Button } from '@components/button';
import { CompanyAppointmentDto } from '@boersenzeitung/shared/dtos/company-appointment.dto';
import { DATES_BATCH_SIZE } from '@utils/constants';
import { DatesEntry } from '@components/dates-entry';
import React, { useEffect, useState } from 'react';

export const DatesList = ({
    appointments,
    headerVisible = true,
}: {
    appointments: CompanyAppointmentDto[];
    headerVisible?: boolean;
    highlightFutureAppointments?: boolean;
}) => {
    const [bufferedAppointments, setBufferedAppointments] = useState<
        CompanyAppointmentDto[]
    >([]);
    const [shownCompanyAppointments, setShownCompanyAppointments] = useState<
        CompanyAppointmentDto[]
    >([]);
    const [showButton, setShowButton] = useState<boolean>(false);

    useEffect(() => {
        if (appointments.length) {
            if (appointments.length < DATES_BATCH_SIZE) {
                setShownCompanyAppointments(appointments);
            } else {
                setShownCompanyAppointments(
                    appointments.slice(0, DATES_BATCH_SIZE),
                );
                setBufferedAppointments(appointments.slice(DATES_BATCH_SIZE));
                setShowButton(true);
            }
        }
    }, [appointments]);

    const loadMoreAppointments = () => {
        if (bufferedAppointments.length < DATES_BATCH_SIZE) {
            setShownCompanyAppointments(appointments);
            setShowButton(false);
        } else {
            setShownCompanyAppointments([
                ...shownCompanyAppointments,
                ...bufferedAppointments.slice(0, DATES_BATCH_SIZE),
            ]);
            setBufferedAppointments([
                ...bufferedAppointments.slice(DATES_BATCH_SIZE),
            ]);
        }
    };

    return (
        <div>
            <div className="overflow-x-auto w-full">
                <table className="table-auto w-full">
                    {headerVisible && (
                        <thead>
                            <tr>
                                <th className="uppercase font-sans font-bold text-left text-secondary py-2.5 px-2 border-b-2 border-solid border-secondary">
                                    Datum
                                </th>
                                <th className="uppercase font-sans font-bold text-left text-secondary py-2.5 px-2 border-b-2 border-solid border-secondary">
                                    Art
                                </th>
                                <th className="border-b-2 border-solid border-secondary" />
                            </tr>
                        </thead>
                    )}
                    <tbody>
                        {shownCompanyAppointments.map((entry) => (
                            <DatesEntry
                                appointment={entry}
                                type={entry.companyAppointmentType}
                                key={entry.id}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
            {showButton && (
                <div className="flex justify-center">
                    <div className="mt-10">
                        <Button
                            testId="loadMoreAppointmentsButton"
                            title="Weitere Ergebnisse anzeigen"
                            onClick={() => loadMoreAppointments()}
                            canDisplayPendingState={false}
                            className="bg-transparent"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
