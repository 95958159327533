import { Outlet } from 'react-router-dom';
import React from 'react';

export default function EmailNotificationWidget() {
    return (
        <div className="mx-auto max-w-5xl sm:px-6 lg:px-8 px-2">
            <div className="flex mb-4">
                <h1 className="border-primary-color text-primary-color font-semibold px-3 leading-9 border-solid font-sans border-b-2 text-base">
                    E-Mail-Alerts
                </h1>
                <div className="grow border-b-[1px] border-separator-color border-solid" />
            </div>
            <Outlet />
        </div>
    );
}
