import { ReactComponent as Spinner } from '@components/spinner.svg';
import { classNames } from '@utils/classNames';
import React, { LegacyRef, ReactElement, useState } from 'react';

export type ButtonProps = {
    title: string | JSX.Element;
    className?: string;
    onClick: () => void;
    pending?: boolean;
    canDisplayPendingState?: boolean;
    ref?: LegacyRef<any> | undefined;
    testId?: string;
    disabled?: boolean;
};

export function Button({
    title,
    className,
    onClick,
    pending,
    canDisplayPendingState = false,
    ref,
    testId,
    disabled,
}: ButtonProps): ReactElement {
    const [isPending, setPending] = useState(pending);
    const _onClick = () => {
        setPending(true);
        onClick();
    };
    return (
        <button
            data-testid={testId}
            ref={ref}
            onClick={_onClick}
            className={classNames(
                'bz-reset cursor-pointer border border-solid border-black py-1 px-4 rounded-[3px] text-[13px] font-sans font-bold hover:border-darkred hover:text-darkred',
                className,
            )}
            disabled={disabled}
        >
            <div className="flex items-center">
                {canDisplayPendingState && isPending && (
                    <Spinner className="mr-2 scale-[65%]" />
                )}
                <div>{title}</div>
            </div>
        </button>
    );
}
