import { TeaserText } from '@components/teaser-text';
import { TitledSeparator } from '@components/titled-separator';
import React from 'react';

export const OverviewSearch = () => {
    return (
        <>
            <div className="mb-4">
                <TitledSeparator
                    title="Suche"
                    moreActionTarget="/fonds/suche"
                />
                <TeaserText>
                    Recherchieren Sie in unserer Fonds-Suche eine Vielzahl von
                    Wertpapieren aus dem Börsen-Zeitung-Universum. Per Klick auf
                    den Einzelwert gelangen Sie zu einer ausführlichen
                    Detailseite mit Preisen, Performance-Daten/Graphiken,
                    Steuerlichen Daten, Ausschüttungen, Anlagegrundsätzen und
                    Allgemeinen Fondsinformationen.
                </TeaserText>
            </div>
        </>
    );
};
