import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import React, { useLayoutEffect } from 'react';
import qs from 'qs';
export const FreeTextSearchBox = ({
    searchTerm,
    setSearchTerm,
    triggerSearch,
    title = 'Suchbegriff',
}: {
    searchTerm?: string;
    setSearchTerm: (newSearchTerm: string) => void;
    triggerSearch?: (searchTerm?: string) => void;
    title?: string;
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    useLayoutEffect(() => {
        const params = qs.parse(location.search.replace('?', ''));
        const searchTerm = params.Suchbegriff as string | undefined;
        if (searchTerm) {
            setSearchTerm(decodeURIComponent(searchTerm));
        }
    }, []);

    const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const searchTerm = event.target.value as string;
            navigate({
                pathname: location.pathname,
                search: `?${createSearchParams({
                    ...qs.parse(location.search.replace('?', '')),
                    Suchbegriff: searchTerm,
                })}`,
            });
            setSearchTerm(searchTerm);
            if (triggerSearch) {
                triggerSearch(searchTerm);
            }
        }
    };

    return (
        <>
            <div className="grow">
                <p className="text-sm font-semibold mb-2 font-sans">{title}</p>
                <input
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyUp={handleEnter}
                    placeholder={`${title}...`}
                    className="w-full py-2 px-3 font-sans text-sm border border-solid border-spacer focus:ring-red focus:border-red active:ring-red active:border-red outline-none rounded-[2px]"
                    data-testid="free-text-search-input"
                />
            </div>
        </>
    );
};
