import { CustomIcon } from '@components/custom-icon';
import { DpaAfxArticleDto } from '@boersenzeitung/shared/dtos/Dpa-afx-article.dto';
import {
    GERMAN_DATE_FORMAT,
    GERMAN_TIME_FORMAT,
} from '@boersenzeitung/shared/constants';
import { Rating } from '@boersenzeitung/shared/api.types';
import { classNames } from '@utils/classNames';
import React, { useState } from 'react';
import dayjs from '@boersenzeitung/shared/dayjs';

const getTextColorForRatingType = (ratingType: Rating): string => {
    switch (ratingType) {
        case Rating.NEGATIVE:
            return 'text-negativeRed';
        case Rating.NEUTRAL:
            return 'text-neutralBlue';
        case Rating.POSITIVE:
            return 'text-positiveGreen';
        default:
            return '';
    }
};

const getBorderColorForRatingType = (ratingType: Rating): string => {
    switch (ratingType) {
        case Rating.NEGATIVE:
            return 'border-negativeRed';
        case Rating.NEUTRAL:
            return 'border-neutralBlue';
        case Rating.POSITIVE:
            return 'border-positiveGreen';
        default:
            return '';
    }
};

const getCustomIconForRatingType = (ratingType: Rating): string => {
    switch (ratingType) {
        case Rating.NEGATIVE:
            return String.raw`before:content-["\ea29"]`;
        case Rating.NEUTRAL:
            return String.raw`before:content-["\ea2a"]`;
        case Rating.POSITIVE:
            return String.raw`before:content-["\ea2b"]`;
        default:
            return '';
    }
};

const getUnitText = (unit: string): string => {
    switch (unit) {
        case 'y':
            return 'Jahre';
        case 'm':
            return 'Monate';
        case 'd':
            return 'Tage';
        default:
            return '';
    }
};

const getTimeFrameString = (timeString: string): string => {
    const unit = timeString.slice(-1);
    const value = timeString.slice(0, -1);
    return `${value} ${getUnitText(unit)}`;
};

const convertRating = (
    rating: Rating,
): 'POSITIV' | 'NEUTRAL' | 'NEGATIV' | '' => {
    switch (rating) {
        case Rating.NEGATIVE:
            return 'NEGATIV';
        case Rating.NEUTRAL:
            return 'NEUTRAL';
        case Rating.POSITIVE:
            return 'POSITIV';
        default:
            return '';
    }
};

export const AnalysisCard = ({ article }: { article: DpaAfxArticleDto }) => {
    const [isExtended, setIsExtended] = useState<boolean>(false);
    const cardContent = article.text.split('\n\n');
    return (
        <div
            className={classNames(
                'w-full bg-white my-2 px-2.5 border-0 rounded-none',
                isExtended
                    ? `border-solid border-x-2 ${getBorderColorForRatingType(
                          article.newRating,
                      )}`
                    : '',
            )}
            data-testid="analysisCard"
        >
            <button
                className="flex justify-between xs-only:h-[40px] space-x-4 h-[30px] items-center w-full"
                onClick={() => setIsExtended(!isExtended)}
                data-testid="analysisCardHeader"
            >
                <CustomIcon
                    className={classNames(
                        'font-bold',
                        getTextColorForRatingType(article.newRating),
                    )}
                    iconCode={getCustomIconForRatingType(article.newRating)}
                />
                <p className="font-sourceCode text-xs text-lightgray">
                    {dayjs(article.date).format(GERMAN_DATE_FORMAT)}
                </p>
                <p className="font-sourceCode text-xs text-lightgray">
                    {dayjs(article.date).format(GERMAN_TIME_FORMAT)}
                </p>
                <p className="grow font-sans text-sm text-left underline hover:text-red">
                    {article.analystInstitution}
                </p>
                <p className="hidden lg:block text-left lg:min-w-[120px] font-sourceCode text-xs text-lightgray">
                    Kursziel
                </p>
                <p className="hidden lg:block font-sans text-sm text-right font-bold lg:min-w-[80px]">{`${
                    article.newPrice
                } ${article.newPrice ? article.currency : ''}`}</p>
                <p className="hidden lg:block font-sourceCode text-xs text-lightgray lg:min-w-[120px] text-left">
                    Einstufung
                </p>
                <p
                    className={classNames(
                        'hidden lg:block uppercase font-sans text-sm font-bold lg:min-w-[120px] text-right',
                        getTextColorForRatingType(article.newRating),
                    )}
                >
                    {convertRating(article.newRating)}
                </p>
            </button>
            {isExtended && (
                <>
                    <div
                        className="flex lg:flex-row flex-col justify-between lg:space-x-4"
                        data-testid="analysisCardContent"
                    >
                        <div className="hidden lg:block grow" />
                        <div className="lg:hidden flex lg:space-x-4">
                            <p className="text-left min-w-[160px] lg:min-w-[120px] font-sourceCode text-xs text-lightgray">
                                Aktuelles Kursziel
                            </p>
                            <p className="font-sans text-sm text-right font-bold lg:min-w-[80px]">{`${
                                article.newPrice
                            } ${article.newPrice ? article.currency : ''}`}</p>
                        </div>
                        <div className="flex lg:space-x-4">
                            <p className="font-sourceCode text-xs leading-5 text-lightgray min-w-[160px] lg:min-w-[120px]">
                                Altes Kursziel
                            </p>
                            <p className="font-sans text-sm text-right font-bold lg:min-w-[80px]">{`${
                                article.oldPrice
                            } ${article.oldPrice ? article.currency : ''}`}</p>
                        </div>
                        <div className="hidden lg:flex lg:space-x-4">
                            <p className="font-sourceCode text-xs text-lightgray text-left min-w-[120px]">
                                Zeithorizont
                            </p>
                            <p className="font-sans text-sm font-bold lg:min-w-[120px] text-right">
                                {getTimeFrameString(article.horizon)}
                            </p>
                        </div>
                    </div>
                    <div className="flex lg:flex-row flex-col-reverse justify-between lg:space-x-4 pb-12">
                        <div className="grow mr-4 mt-8">
                            <p className="flex mb-4">
                                {`Analyst*in: ${article.analystLastname}, ${article.analystFirstname}`}
                            </p>
                            <div className="font-sans text-xs text-secondary text-left whitespace-pre-wrap">
                                <p className="text-base mb-4">
                                    {cardContent[0]}
                                </p>
                                <p className="mb-4">{cardContent[1]}</p>
                                <p className="">{cardContent[2]}</p>
                            </div>
                        </div>
                        <div className="lg:flex lg:space-x-4">
                            <div className="flex lg:space-x-4">
                                <p className="font-sourceCode text-xs leading-5 text-lightgray min-w-[160px] lg:min-w-[120px]">
                                    Kurs bei Veröff.
                                </p>
                                <p className="font-sans text-sm text-right font-bold lg:min-w-[80px]">{`${
                                    article.price
                                } ${article.price ? article.currency : ''}`}</p>
                            </div>
                            <div className="lg:hidden flex">
                                <p className="font-sourceCode text-xs text-lightgray min-w-[160px]">
                                    Zeithorizont
                                </p>
                                <p className="font-sans text-sm font-bold lg:min-w-[120px] text-right">
                                    {getTimeFrameString(article.horizon)}
                                </p>
                            </div>
                            <div className="lg:hidden flex">
                                <p className=" font-sourceCode text-xs text-lightgray min-w-[160px]">
                                    Aktuelle Einstufung
                                </p>
                                <p
                                    className={classNames(
                                        'uppercase font-sans text-sm font-bold lg:min-w-[120px] text-right',
                                        getTextColorForRatingType(
                                            article.newRating,
                                        ),
                                    )}
                                >
                                    {convertRating(article.newRating)}
                                </p>
                            </div>
                            <div className="flex lg:space-x-4">
                                <p className="font-sourceCode text-xs text-lightgray min-w-[160px] lg:min-w-[120px] text-left">
                                    Alte Einstufung
                                </p>
                                <p
                                    className={classNames(
                                        'uppercase font-sans text-sm font-bold lg:min-w-[120px] text-right',
                                        getTextColorForRatingType(
                                            article.oldRating,
                                        ),
                                    )}
                                >
                                    {convertRating(article.oldRating)}
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
