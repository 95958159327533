import { Select } from '@components/select/select';
import { createSearchParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';
import React, { useEffect, useState } from 'react';
import qs from 'qs';

export type SearchBoxProps = {
    onEntitySwitch: (entity: string | null) => void;
    queryParamName: string;
    entities: string[];
    externalEntity?: string | null;
};

export function FilterBox({
    onEntitySwitch,
    queryParamName,
    entities,
    externalEntity,
}: SearchBoxProps) {
    const [entity, setEntity] = useState<string | null>(null);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (entities.length > 0) {
            const params = qs.parse(location.search.replace('?', ''));
            const entityFilter = params[queryParamName] as string | undefined;
            if (
                entityFilter &&
                entities.includes(decodeURIComponent(entityFilter))
            ) {
                switchEntity(decodeURIComponent(entityFilter));
            }
        }
    }, [entities]);

    const switchEntity = (value: string | null) => {
        setEntity(value);

        navigate({
            pathname: location.pathname,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            search: `?${createSearchParams({
                ...qs.parse(location.search.replace('?', '')),
                [queryParamName]: value ?? 'Alle',
            })}`,
        });
        onEntitySwitch(value);
    };

    return (
        <Select
            options={[
                { value: null, label: 'Alle' },
                ...entities.map((entity) => ({
                    value: entity,
                    label: entity,
                })),
            ]}
            value={externalEntity !== undefined ? externalEntity : entity}
            onChange={switchEntity}
            className="w-full font-sans"
            maxHeight="md:max-h-[40rem]"
        />
    );
}
