import { Empty } from '@components/empty';
import { ReactComponent as Spinner } from '@components/spinner.svg';
import { TeaserText } from '@components/teaser-text';
import { TitledSeparator } from '@components/titled-separator';
import { Top5UltimoDto } from '@boersenzeitung/shared/dtos/top5ultimo.dto';
import { Top5UltimoTable } from '@components/funds/top-5-ultimo-table';
import React, { useLayoutEffect, useState } from 'react';
import apiClient from '@api/api.client';

export const OverviewTop5Ultimo = () => {
    const [loadedTop5Ultimo, setLoadedTop5Ultimo] = useState<Top5UltimoDto[]>(
        [],
    );
    const [loading, setLoading] = useState<boolean>(false);

    useLayoutEffect(() => {
        const fetchTop5Ultimo = async () => {
            setLoading(true);
            const result = await apiClient.getTop5UltimoSingleCategory();
            setLoadedTop5Ultimo(result);

            setLoading(false);
        };

        void fetchTop5Ultimo();
    }, []);

    return (
        <>
            <div className="mb-4">
                <TitledSeparator
                    title="Top 5 Ultimo"
                    moreActionTarget="/fonds/top-5-ultimo"
                />
                <TeaserText>
                    Recherchieren Sie anhand unserer „Top 5 Ultimo“ Fonds nach
                    Performance-Daten innerhalb der Anlagekategorien.
                </TeaserText>
                {loading ? (
                    <div>
                        <Spinner />
                    </div>
                ) : (
                    <Top5UltimoTableWrapper top5Ultimo={loadedTop5Ultimo} />
                )}
            </div>
        </>
    );
};

const Top5UltimoTableWrapper = ({
    top5Ultimo,
}: {
    top5Ultimo: Top5UltimoDto[];
}) => {
    if (top5Ultimo.length === 0) {
        return <Empty />;
    }

    return <Top5UltimoTable top5Ultimos={top5Ultimo} />;
};
