import {
    ALL_OPTION,
    GERMAN_DATE_FORMAT,
} from '@boersenzeitung/shared/constants';
import { AppointmentType } from '@boersenzeitung/shared/api.types';
import {
    DATE_RANGES,
    DEFAULT_SELECTED_DATE_RANGE,
    TOPICS,
    deserializeDateRange,
} from '@components/appointment/utils';
import {
    DateFramePicker,
    buildCustomTimeFrameOptions,
} from '@components/date-frame-picker';
import { Input } from '@components/input';
import { Option, Option as SelectOption } from '@components/select/types';
import { Select } from '@components/select/select';
import { ReactComponent as Spinner } from '@components/spinner.svg';
import { createSearchParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';
import React, {
    PropsWithChildren,
    ReactElement,
    useEffect,
    useLayoutEffect,
    useState,
} from 'react';
import dayjs from '@boersenzeitung/shared/dayjs';
import qs from 'qs';

function FilterItem({
    children,
    title,
}: PropsWithChildren<{ title: string }>): ReactElement {
    return (
        <div className="min-w-[200px] md:max-w-[250px] flex flex-col">
            <div className="hidden md:block text-sm font-semibold mb-2 font-sans">
                <p>{title}</p>
            </div>
            {children}
        </div>
    );
}

export type DateRange = [ReturnType<typeof dayjs>, ReturnType<typeof dayjs>];

export type FilterState = {
    type: AppointmentType | '__ALL__';
    dateRange: SelectOption | DateRange | undefined;
    phrase: string | undefined;
};

type FilterProps = {
    onFilterChanged: (state: FilterState) => void;
    loading: boolean;
    totalCount?: number;
};

export const AppointmentFilter = ({
    loading,
    totalCount,
    onFilterChanged,
}: FilterProps): ReactElement => {
    const navigate = useNavigate();
    const location = useLocation();
    const [filter, setFilter] = useState<FilterState | undefined>();
    const [pending, setPending] = useState(false);
    useEffect(() => {
        setPending(loading);
    }, [loading]);
    useLayoutEffect(() => {
        const params = qs.parse(location.search.replace('?', ''));
        const phraseFilter = params.Text as string | undefined;
        const typeFilter = params.AppointmentType as
            | AppointmentType
            | '__ALL__'
            | undefined;
        const dateRangeFilter = params.Filterdate as string | undefined;
        let dateOption = DATE_RANGES.find(
            (option) =>
                option.label ===
                dateRangeFilter?.replace('-', ' ').replace('ae', 'ä'),
        );
        if (!dateOption) {
            dateOption = DEFAULT_SELECTED_DATE_RANGE;
            const dates = dateRangeFilter?.split('-');
            if (dates?.length === 2) {
                const startDate = dayjs(dates[0], GERMAN_DATE_FORMAT);
                const endDate = dayjs(dates[1], GERMAN_DATE_FORMAT);
                if (startDate.isValid() && endDate.isValid()) {
                    dateOption = buildCustomTimeFrameOptions(
                        startDate,
                        endDate,
                    );
                }
            }
        }
        setFilter({
            phrase: phraseFilter !== '' ? phraseFilter : undefined,
            type: typeFilter ?? ALL_OPTION,
            dateRange: dateOption,
        });
    }, []);
    useEffect(() => {
        if (filter) {
            onFilterChanged({
                ...filter,
                dateRange: (filter.dateRange as SelectOption).value
                    ? deserializeDateRange(
                          (filter.dateRange as SelectOption).value!,
                      )
                    : undefined,
            });
        }
    }, [filter]);
    const updateTopicFilter = (value: string | null) => {
        if (filter) {
            navigate({
                pathname: location.pathname,
                search: `?${createSearchParams({
                    ...qs.parse(location.search.replace('?', '')),
                    AppointmentType: value ?? 'all',
                })}`,
            });
            setFilter({
                ...filter,
                type: value as AppointmentType,
            });
        }
    };

    const updatePhraseFilter = (value: string) => {
        if (filter) {
            navigate({
                pathname: location.pathname,
                search: `?${createSearchParams({
                    ...qs.parse(location.search.replace('?', '')),
                    Text: value,
                })}`,
            });
            setFilter({
                ...filter,
                phrase: value,
            });
        }
    };

    const updateDateRangeFilter = (option: Option) => {
        if (filter) {
            navigate({
                pathname: location.pathname,
                search: `?${createSearchParams({
                    ...qs.parse(location.search.replace('?', '')),
                    Filterdate: option.label
                        .trim()
                        .replace(' ', '-')
                        .replace('ä', 'ae'),
                })}`,
            });
            setFilter({
                ...filter,
                dateRange: option,
            });
        }
    };
    return (
        <div className="flex flex-col md:flex-row md:flex-wrap justify-between">
            {filter && (
                <div className="flex flex-col md:flex-row space-y-4 md:space-x-6 md:space-y-0 md:mr-4">
                    <FilterItem title="Themen">
                        <Select
                            options={TOPICS}
                            value={filter.type}
                            onChange={updateTopicFilter}
                            className="md:max-w-[250px] w-full font-sans"
                        />
                    </FilterItem>
                    <FilterItem title="Zeitraum">
                        <DateFramePicker
                            timeFrames={DATE_RANGES}
                            selectedTimeframe={filter.dateRange as SelectOption}
                            setSelectedTimeframe={updateDateRangeFilter}
                        />
                    </FilterItem>
                    <FilterItem title="Schnellsuche">
                        <Input
                            disabled={pending}
                            defaultValue={filter.phrase}
                            onEnter={updatePhraseFilter}
                            className="md:max-w-[250px] w-full"
                        />
                    </FilterItem>
                </div>
            )}
            <div className="mt-8 flex justify-center">
                {pending ? (
                    <div>
                        <Spinner />
                    </div>
                ) : (
                    <div>
                        {totalCount !== undefined &&
                            `${totalCount} Termin(e) gefunden`}
                    </div>
                )}
            </div>
        </div>
    );
};
