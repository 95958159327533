import { FinancialDisplayDto } from '@boersenzeitung/shared/dtos/financialDisplay.dto';
import { FinancialDisplayEntry } from '@components/financial-display/financial-display-entry';
import { FinancialDisplaySearchFilter } from '@boersenzeitung/shared/api.types';
import { FinancialDisplayTableHeader } from '@components/financial-display/financial-display-table-header';
import React from 'react';

export const FinancialDisplayTable = ({
    shownFinancialDisplays,
    handleSortChange,
    filter,
}: {
    filter: FinancialDisplaySearchFilter;
    shownFinancialDisplays: FinancialDisplayDto[];
    handleSortChange: (sortKey: string) => void;
}) => {
    const tableCellStyle =
        'border-x-2 border-solid border-white align-middle p-2';

    return shownFinancialDisplays.length > 0 ? (
        <table className="w-full border-spacing-2">
            <FinancialDisplayTableHeader
                tableCellStyle={tableCellStyle}
                handleSortChange={handleSortChange}
                filter={filter}
            />
            <tbody>
                {shownFinancialDisplays.map((financialDisplay, index) => {
                    return (
                        <FinancialDisplayEntry
                            financialDisplay={financialDisplay}
                            tableCellStyle={tableCellStyle}
                            index={index}
                            key={financialDisplay.id}
                        />
                    );
                })}
            </tbody>
        </table>
    ) : (
        <p>Keine Ergebnisse gefunden</p>
    );
};
