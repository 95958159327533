import { SearchSuggestion } from '@components/suggestion-input/search-suggestion';
import Autosuggest, { InputProps } from 'react-autosuggest';
import React, { FormEvent, useState } from 'react';

export type SearchInputProps<T> = {
    onSuggestionClicked: (suggestion: T) => void;
    searchTerm: string;
    inputProps: InputProps<string>;
    getResults: (searchString: string) => Promise<T[]>;
};

export function SearchSuggestionField<T extends { name: string }>({
    onSuggestionClicked,
    searchTerm,
    inputProps,
    getResults,
}: SearchInputProps<T>) {
    const [searchSuggestions, setSearchSuggestions] = useState<T[]>([]);

    const onSuggestionsFetchRequested = ({ value }: { value: string }) => {
        const fetchContent = async () => {
            try {
                const dtos = await getResults(value);
                setSearchSuggestions(dtos);
            } catch (ignored) {
                onSuggestionsClearRequested();
            }
        };
        if (value.length > 2) {
            void fetchContent();
        }
    };

    const onSuggestionsClearRequested = () => {
        setSearchSuggestions([]);
    };

    const onSelectionSelected = (
        _event: FormEvent<HTMLElement>,
        { suggestion }: { suggestion: string },
    ) => {
        const result = searchSuggestions.find(
            (dto) => dto.name === suggestion,
        )!;
        onSuggestionClicked(result);
    };

    return (
        <Autosuggest
            suggestions={searchSuggestions.map((dto) => dto.name)}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionSelected={onSelectionSelected}
            getSuggestionValue={(suggestion: string) => suggestion}
            renderSuggestion={(suggestion: string) => (
                <SearchSuggestion
                    suggestion={suggestion}
                    searchTerm={searchTerm}
                />
            )}
            inputProps={inputProps}
        />
    );
}
