import { Link } from 'react-router-dom';
import React from 'react';

export type ErrorProps = {
    title: string;
    description: string;
    actionTitle?: string;
    actionLink?: string;
};

const ErrorPage = ({
    title,
    description,
    actionTitle,
    actionLink,
}: ErrorProps) => {
    return (
        <div className="bg-red text-white p-2.5 mb-20">
            <h1 className="text-xl mb-5 font-serif">{title}</h1>
            <p className="leading-5 mb-5">{description}</p>
            {actionLink ? (
                <Link
                    to={actionLink}
                    className="cursor-pointer border-solid border-white border-2 py-1 px-4 rounded-[3px] text-[13px] font-sans font-bold hover:border-spacer hover:text-spacer"
                >
                    {actionTitle}
                </Link>
            ) : (
                <></>
            )}
        </div>
    );
};

export const NotFoundPage = () => {
    return (
        <ErrorPage
            title={'Diese Seite wurde leider nicht gefunden'}
            description={
                'Bitte entschuldigen Sie, die von Ihnen angeforderte Seite ist leider nicht aufrufbar. Stattdessen finden Sie hier die aktuellsten Beiträge von unserer Startseite.'
            }
        />
    );
};

export const ServerErrorPage = () => {
    return (
        <ErrorPage
            title={
                'Diese Seite kann aktuell wegen einer technischen Störung nicht angezeigt werden'
            }
            description={
                'Bitte entschuldigen Sie, die von Ihnen angeforderte Seite ist leider gerade wegen einer technischen Störung nicht aufrufbar. Wir arbeiten mit Hochdruck an einer Lösung.'
            }
            actionTitle={'Zurück zur Startseite'}
            actionLink={'/termine/suche'}
        />
    );
};
