import { ACCESS_TOKEN_HEADER_NAME } from '@boersenzeitung/shared/constants';
import {
    AppointmentSearchFilter,
    AppointmentSearchResult,
    FinancialDisplaySearchFilter,
    FinancialDisplaySearchResult,
    FundSearchFilter,
    FundSearchResult,
} from '@boersenzeitung/shared/api.types';
import { CompanyDto } from '@boersenzeitung/shared/dtos/Company.dto';
import {
    DetailedFundInformationDto,
    FundDto,
} from '@boersenzeitung/shared/dtos/fund.dto';
import {
    DocumentResultDto,
    ResultDto,
    TickerType,
} from '@boersenzeitung/shared/dtos/news.dto';
import { DpaAfxArticleSearchResult } from '@boersenzeitung/shared/dtos/Dpa-afx-article.dto';
import { Environment } from '../env';
import { InstrumentDto } from '@boersenzeitung/shared/dtos/instrument.dto';
import {
    NewsSearchResultDto,
    SearchTermResultDto,
} from '@boersenzeitung/shared/dtos/email-notification-seatch-term.dto';
import { Top5UltimoDto } from '@boersenzeitung/shared/dtos/top5ultimo.dto';
import { UserDataDto } from '@boersenzeitung/shared/dtos/email-notifications-user-data.dto';
import axios, { RawAxiosRequestHeaders } from 'axios';
import purpleApiAdapter from '@purple/purple-api.adapter';

export class ApiClient {
    private async post<B, RESPONSE>(
        url: string,
        body?: B,
        params?: object,
        headers?: RawAxiosRequestHeaders,
    ): Promise<RESPONSE> {
        return axios
            .post<B, RESPONSE>(url, body, { params, headers })
            .catch((error) => {
                console.error('Error calling API', error);
                throw error;
            });
    }

    private async get<RESPONSE>(
        url: string,
        params: object | null,
        defaultResponse?: RESPONSE,
        headers?: RawAxiosRequestHeaders,
    ): Promise<RESPONSE> {
        try {
            return (await axios.get<RESPONSE>(url, { params, headers })).data;
        } catch (error) {
            console.error('Error calling API', error);
            if (defaultResponse) {
                return defaultResponse;
            } else {
                throw error;
            }
        }
    }

    private async apiGet<RESPONSE>(
        path: string,
        params: object | null,
        defaultResponse?: RESPONSE,
    ): Promise<RESPONSE> {
        const url = `${Environment.API_BASE_URL}${path}`;
        return this.get<RESPONSE>(url, params, defaultResponse);
    }

    private async emailGet<RESPONSE>(
        path: string,
        params: object | null,
        token?: string,
        defaultResponse?: RESPONSE,
    ): Promise<RESPONSE> {
        const url = `${Environment.EMAIL_SERVICE_BASE_URL}${path}`;
        const headers = token
            ? { [ACCESS_TOKEN_HEADER_NAME]: token }
            : undefined;
        return this.get<RESPONSE>(url, params, defaultResponse, headers);
    }

    private async apiPost<B, RESPONSE>(
        path: string,
        body?: B,
        params?: object,
    ): Promise<RESPONSE> {
        const url = `${Environment.API_BASE_URL}${path}`;
        return this.post<B, RESPONSE>(url, body, params);
    }

    private async emailPost<B, RESPONSE>(
        path: string,
        body?: B,
        token?: string,
        params?: object,
    ): Promise<RESPONSE> {
        const url = `${Environment.EMAIL_SERVICE_BASE_URL}${path}`;
        const headers = token
            ? { [ACCESS_TOKEN_HEADER_NAME]: token }
            : undefined;
        return this.post<B, RESPONSE>(url, body, params, headers);
    }

    async findNews(params: {
        first: number;
        after?: string;
        fromDate?: string;
        toDate?: string;
        isin?: string;
        dates?: boolean;
    }): Promise<ResultDto | undefined> {
        const { accessToken: entitlementToken } =
            await purpleApiAdapter.getEntitlementToken();
        return this.apiGet<ResultDto | undefined>('/api/news', {
            ...params,
            entitlementToken,
        });
    }

    async findFundNews(params: {
        first: number;
        after?: string;
        fromDate?: string;
        toDate?: string;
        isin?: string;
        searchTerm?: string;
    }): Promise<ResultDto | undefined> {
        const { accessToken: entitlementToken } =
            await purpleApiAdapter.getEntitlementToken();
        return this.apiGet<ResultDto | undefined>('/api/funds/news', {
            ...params,
            entitlementToken,
        });
    }

    async searchAppointments(
        filter: AppointmentSearchFilter,
    ): Promise<AppointmentSearchResult> {
        return this.apiGet<AppointmentSearchResult>(
            `/api/appointments`,
            filter,
            {
                totalCount: 0,
                items: [],
                page: 0,
            },
        );
    }

    async findTickerNews(params: {
        first: number;
        isin: string;
        tickerType: TickerType;
        after?: string;
        fromDate?: string;
        toDate?: string;
    }): Promise<ResultDto | undefined> {
        const { accessToken: entitlementToken } =
            await purpleApiAdapter.getEntitlementToken();
        return this.apiGet<ResultDto | undefined>('/api/ticker', {
            ...params,
            entitlementToken,
        });
    }

    async getInstrument(isin: string): Promise<InstrumentDto | undefined> {
        return this.apiGet<InstrumentDto | undefined>(
            `/api/instruments/${isin}`,
            {},
        );
    }

    async findCompany(searchString: string): Promise<CompanyDto[]> {
        return this.apiGet<CompanyDto[]>(`/api/companies/search`, {
            searchString,
        });
    }

    async getDpaAfxArticles(
        companyId: string,
        page = 0,
        itemsPerPage = 10,
    ): Promise<DpaAfxArticleSearchResult> {
        return this.apiGet<DpaAfxArticleSearchResult>(
            '/api/dpa-afx-articles/find',
            { companyId, page, itemsPerPage },
        );
    }

    async getInstrumentForTickerId(
        tickerId: string,
    ): Promise<InstrumentDto | undefined> {
        const result = await this.apiGet<InstrumentDto | string>(
            '/api/instruments/byTickerId',
            { tickerId },
        );
        return result === '' ? undefined : (result as InstrumentDto);
    }

    async getFundCompanies(): Promise<string[]> {
        return this.apiGet<string[]>('/api/funds/companies', null);
    }

    async searchFunds(filter: FundSearchFilter): Promise<FundSearchResult> {
        return this.apiGet<FundSearchResult>('/api/funds/search', filter);
    }

    async lookupFunds(searchString: string): Promise<FundDto[]> {
        return this.apiGet<FundDto[]>('/api/funds/lookup', {
            searchString: searchString,
        });
    }

    async getFundByIsin(
        isin: string,
    ): Promise<DetailedFundInformationDto | undefined> {
        return this.apiGet<DetailedFundInformationDto>(
            `/api/funds/get/${isin}`,
            {},
        );
    }

    async getTop5Ultimo(): Promise<Top5UltimoDto[]> {
        return this.apiGet<Top5UltimoDto[]>('/api/funds/top5ultimo', {});
    }

    async getTop5UltimoSingleCategory(): Promise<Top5UltimoDto[]> {
        return this.apiGet<Top5UltimoDto[]>(
            '/api/funds/top5ultimo?entries=1',
            {},
        );
    }

    async searchFinancialDisplays(
        filter: FinancialDisplaySearchFilter,
    ): Promise<FinancialDisplaySearchResult> {
        const { accessToken: entitlementToken } =
            await purpleApiAdapter.getEntitlementToken();
        return await this.apiGet<FinancialDisplaySearchResult>(
            '/api/financialDisplay/search',
            { ...filter, entitlementToken },
        );
    }

    async getKeywords(): Promise<string[]> {
        return await this.apiGet<string[]>('/api/financialDisplay/config', {});
    }

    async getPdfDocuments(): Promise<DocumentResultDto> {
        const { accessToken: entitlementToken } =
            await purpleApiAdapter.getEntitlementToken();
        return await this.apiGet<DocumentResultDto>('/api/funds/documents', {
            entitlementToken,
        });
    }

    async addBookmark(articleId: string) {
        const { accessToken: entitlementToken } =
            await purpleApiAdapter.getEntitlementToken();
        return this.apiPost<
            { articleId: string; entitlementToken?: string },
            void
        >('/api/bookmarks/add', {
            articleId,
            entitlementToken,
        });
    }

    async removeBookmark(articleId: string) {
        const { accessToken: entitlementToken } =
            await purpleApiAdapter.getEntitlementToken();
        return this.apiPost<
            { articleId: string; entitlementToken?: string },
            void
        >('/api/bookmarks/remove', {
            articleId,
            entitlementToken,
        });
    }

    async loginEmailNotification(email: string) {
        return this.emailPost<{ email: string }, void>(
            '/alert-preferences/loginMail',
            {
                email,
            },
        );
    }

    async registerEmailNotification(
        email: string,
        firstName: string,
        lastName: string,
    ) {
        return this.emailPost<
            {
                email: string;
                firstName: string;
                lastName: string;
            },
            void
        >('/alert-preferences/registerMail', {
            email,
            firstName,
            lastName,
        });
    }

    async getEmailNotificationPreferences(token: string) {
        return this.emailGet<UserDataDto | undefined>(
            '/alert-preferences',
            null,
            token,
        );
    }

    async removeEmailNotificationPreference(id: string, token: string) {
        return this.emailPost<{ alertId: string }, void>(
            '/alert-preferences/removeAlertPreference',
            {
                alertId: id,
            },
            token,
        );
    }

    async removeAllEmailNotificationPreferences(token: string) {
        return this.emailPost<null, void>(
            '/alert-preferences/removeAllAlertPreferences',
            null,
            token,
        );
    }

    async addEmailNotificationPreference(
        type: string,
        searchTerm: string,
        token: string,
        companyName?: string,
    ) {
        return this.emailPost<
            {
                notificationType: string;
                searchTerm: string;
                companyName?: string;
            },
            void
        >(
            '/alert-preferences/addAlertPreference',
            {
                notificationType: type,
                searchTerm,
                companyName,
            },
            token,
        );
    }

    async updateServiceActive(serviceActive: boolean, token: string) {
        return this.emailPost<{ serviceActive: boolean }, void>(
            '/alert-preferences/setServiceActive',
            {
                serviceActive,
            },
            token,
        );
    }

    async updateName(token: string, firstName: string, lastName: string) {
        return this.emailPost<
            {
                firstName: string;
                lastName: string;
            },
            void
        >(
            '/alert-preferences/updateName',
            {
                firstName,
                lastName,
            },
            token,
        );
    }

    async getResultListings(token: string): Promise<SearchTermResultDto> {
        return this.emailGet<SearchTermResultDto>(
            '/article-search',
            null,
            token,
        );
    }

    async getSearchTermResults(
        token: string,
        id: string,
        after?: string,
        count?: number,
    ): Promise<NewsSearchResultDto> {
        return this.emailGet<NewsSearchResultDto>(
            '/article-search/searchTerm',
            {
                id,
                after,
                count,
            },
            token,
        );
    }
}

const apiClient = new ApiClient();
export default apiClient;
