import {
    DEFAULT_TIME_FRAME_OPTIONS,
    INITIAL_NEWS_BATCH_SIZE,
    NEWS_BATCH_SIZE,
} from '@utils/constants';
import {
    DateFramePicker,
    buildCustomTimeFrameOptions,
} from '@components/date-frame-picker';
import { Empty } from '@components/empty';
import { FreeTextSearchBox } from '@components/free-text-search-box';
import { GERMAN_DATE_FORMAT } from '@boersenzeitung/shared/constants';
import { LoadMoreButton } from '@components/load-more-button';
import { NewsCard } from '@components/news-card';
import {
    NewsDto,
    ResultDto,
    TickerDto,
    TickerType,
} from '@boersenzeitung/shared/dtos/news.dto';
import { Option } from '@components/select/types';
import { ReactComponent as Spinner } from '@components/spinner.svg';
import { TickerCard } from '@components/ticker-card';
import { TickerTypePicker, typeOptions } from '@components/ticker-type-picker';
import { URLSearchParamsInit, createSearchParams } from 'react-router-dom';
import {
    getDatesFromSelection,
    setQueryParams,
} from '@utils/date-frame-helper';
import { useLocation, useNavigate } from 'react-router';
import React, { useLayoutEffect, useState } from 'react';
import apiClient from '@api/api.client';
import dayjs from '@boersenzeitung/shared/dayjs';
import qs from 'qs';

export enum NewsEntity {
    ticker,
    news,
    funds,
    termineNews,
}

export const NewsList = ({
    isin,
    newsEntity,
}: {
    isin?: string;
    newsEntity: NewsEntity;
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = qs.parse(location.search.replace('?', ''));
    const [bufferedNews, setBufferedNews] = useState<(NewsDto | TickerDto)[]>(
        [],
    );
    const [shownNews, setShownNews] = useState<(NewsDto | TickerDto)[]>([]);
    const [canLoadMore, setCanLoadMore] = useState<boolean>(false);
    const [showLoadMore, setShowLoadMore] = useState<boolean>(false);
    const [selectedTimeframe, setSelectedTimeframe] = useState<
        Option | undefined
    >({ label: 'gesamter Zeitraum', value: 'all' });
    const [selectedTickerType, setSelectedTickerType] = useState<
        Option | undefined
    >(typeOptions[0]);
    const [searchTerm, setSearchTerm] = useState<string | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getDataForNewsEntity = async ({
        first,
        after,
        isin,
        typeOption,
        fromDate,
        toDate,
        searchTerm,
    }: {
        first: number;
        after?: string;
        isin?: string;
        typeOption?: TickerType;
        fromDate?: string;
        toDate?: string;
        searchTerm?: string;
    }) => {
        switch (newsEntity) {
            case NewsEntity.funds:
                return await apiClient.findFundNews({
                    first,
                    after,
                    fromDate,
                    toDate,
                    isin,
                    searchTerm,
                });
            case NewsEntity.news:
                return await apiClient.findNews({
                    first,
                    after,
                    fromDate,
                    toDate,
                    isin,
                });
            case NewsEntity.termineNews:
                return await apiClient.findNews({
                    first,
                    after,
                    fromDate,
                    toDate,
                    isin,
                    dates: true,
                });
            case NewsEntity.ticker:
                if (!typeOption) {
                    throw new Error('need to provide type option for ticker!');
                }
                if (isin) {
                    return await apiClient.findTickerNews({
                        first,
                        after,
                        isin: isin,
                        tickerType: typeOption,
                        fromDate,
                        toDate,
                    });
                } else {
                    return { canLoadMore: false, data: [] };
                }
        }
    };

    useLayoutEffect(() => {
        const dateRangeFilterString = params.Filterdate as string | undefined;
        const tickerTypeFilterString = params.Type as string | undefined;
        const typeOption =
            typeOptions.find(
                (option) =>
                    option.label.trim().replace(' ', '-').replace('Ü', 'Ue') ===
                    tickerTypeFilterString,
            ) ?? typeOptions[0];
        let dateOption = DEFAULT_TIME_FRAME_OPTIONS.find(
            (option) =>
                option.label.trim().replace(' ', '-').replace('ä', 'ae') ===
                dateRangeFilterString,
        );
        if (!dateOption) {
            dateOption = DEFAULT_TIME_FRAME_OPTIONS[0];
            const dates = dateRangeFilterString?.split('-');
            if (dates?.length === 2) {
                const startDate = dayjs(dates[0], GERMAN_DATE_FORMAT);
                const endDate = dayjs(dates[1], GERMAN_DATE_FORMAT);
                if (startDate.isValid() && endDate.isValid()) {
                    dateOption = buildCustomTimeFrameOptions(
                        startDate,
                        endDate,
                    );
                }
            }
        }
        const [fromDate, toDate] = getDatesFromSelection(dateOption);
        setSelectedTimeframe(dateOption);
        setSelectedTickerType(typeOption);
        const getInitialNews = async () => {
            const loadedNews = await getDataForNewsEntity({
                first: INITIAL_NEWS_BATCH_SIZE,
                isin,
                typeOption:
                    (typeOption.value as TickerType | null) ?? undefined,
                fromDate,
                toDate,
            });

            if (loadedNews) {
                resetByNewNewsBase(loadedNews);
            }
            setIsLoading(false);
        };
        void getInitialNews();
    }, [isin]);

    const resetByNewNewsBase = (base: ResultDto) => {
        if (base.data.length > 0) {
            setBufferedNews(
                base.data.slice(NEWS_BATCH_SIZE, INITIAL_NEWS_BATCH_SIZE),
            );
            setShownNews(base.data.slice(0, NEWS_BATCH_SIZE));
        } else {
            setBufferedNews([]);
            setShownNews([]);
        }
        setCanLoadMore(base.canLoadMore);
        setShowLoadMore(base.data.length > NEWS_BATCH_SIZE);
    };

    const handleTimeframeSelection = (option: Option | undefined) => {
        void resetByTimeframeSelection(option);
        setSelectedTimeframe(option);
        setQueryParams(location, option, navigate);
    };

    const handleTickerTypeSelection = (option: Option | undefined) => {
        void resetByTickerTypeSelection(option);
        setSelectedTickerType(option);
        const searchObject = {
            ...qs.parse(location.search.replace('?', '')),
        };
        if (option) {
            searchObject['Type'] = option.label
                .trim()
                .replace(' ', '-')
                .replace('Ü', 'Ue');
        } else {
            delete searchObject['Type'];
        }
        navigate({
            pathname: location.pathname,
            search: `?${createSearchParams(
                searchObject as URLSearchParamsInit,
            )}`,
        });
    };

    const showLoadingOrEmpty = (loading: boolean | undefined) => {
        return loading ? (
            <div>
                <Spinner />
            </div>
        ) : (
            <Empty />
        );
    };

    const resetByTimeframeSelection = async (option: Option | undefined) => {
        const [fromDate, toDate] = getDatesFromSelection(option);
        const loadedNews = await getDataForNewsEntity({
            first: INITIAL_NEWS_BATCH_SIZE,
            isin,
            typeOption:
                (selectedTickerType?.value as TickerType | null) ?? undefined,
            fromDate,
            toDate,
        });
        if (loadedNews) {
            resetByNewNewsBase(loadedNews);
        }
    };

    const resetByTickerTypeSelection = async (option: Option | undefined) => {
        const [fromDate, toDate] = getDatesFromSelection(selectedTimeframe);
        if (isin && option?.value) {
            const loadedNews = await apiClient.findTickerNews({
                first: INITIAL_NEWS_BATCH_SIZE,
                isin: isin,
                tickerType: option.value as TickerType,
                fromDate,
                toDate,
            });
            if (loadedNews) {
                resetByNewNewsBase(loadedNews);
            }
        }
    };

    const resetBySearchTermSelection = async (searchTerm?: string) => {
        const [fromDate, toDate] = getDatesFromSelection(selectedTimeframe);
        const loadedNews = await apiClient.findFundNews({
            first: INITIAL_NEWS_BATCH_SIZE,
            isin: isin,
            fromDate,
            toDate,
            searchTerm: !searchTerm ? undefined : searchTerm,
        });
        if (loadedNews) {
            resetByNewNewsBase(loadedNews);
        }
    };

    const loadMoreNews = async () => {
        const [fromDate, toDate] = getDatesFromSelection(selectedTimeframe);
        setShownNews([...shownNews, ...bufferedNews]);
        if (canLoadMore) {
            const loadedNews = await getDataForNewsEntity({
                first: NEWS_BATCH_SIZE,
                after: bufferedNews[bufferedNews.length - 1].cursor,
                isin,
                typeOption:
                    (selectedTickerType?.value as TickerType | null) ??
                    undefined,
                fromDate,
                toDate,
                searchTerm,
            });
            if (loadedNews) {
                setBufferedNews(loadedNews.data);
                setCanLoadMore(loadedNews.canLoadMore);
                setShowLoadMore(loadedNews.data.length > 0);
            }
        } else {
            setShowLoadMore(false);
        }
    };

    return (
        <>
            <div
                className={
                    newsEntity !== NewsEntity.news
                        ? 'flex justify-between space-x-4'
                        : ''
                }
            >
                {newsEntity === NewsEntity.ticker && (
                    <TickerTypePicker
                        setSelectedType={handleTickerTypeSelection}
                        selectedType={selectedTickerType}
                    />
                )}
                {newsEntity === NewsEntity.funds && (
                    <FreeTextSearchBox
                        searchTerm={searchTerm ?? ''}
                        setSearchTerm={setSearchTerm}
                        triggerSearch={(searchTerm?: string) => {
                            void resetBySearchTermSelection(searchTerm);
                        }}
                    />
                )}
                <div className="mb-10">
                    <p className="text-sm font-semibold mb-2 font-sans">
                        Zeitraum
                    </p>
                    <DateFramePicker
                        selectedTimeframe={selectedTimeframe}
                        setSelectedTimeframe={handleTimeframeSelection}
                    />
                </div>
            </div>
            {shownNews.length > 0 ? (
                <>
                    <div className="flex flex-col space-y-6">
                        {shownNews.map((newsItem) => {
                            if (newsEntity === NewsEntity.ticker) {
                                return (
                                    <TickerCard
                                        tickerItem={newsItem as TickerDto}
                                        key={newsItem.uuid}
                                    />
                                );
                            }
                            return (
                                <NewsCard
                                    newsItem={newsItem as NewsDto}
                                    key={newsItem.uuid}
                                />
                            );
                        })}
                    </div>
                    {showLoadMore && (
                        <LoadMoreButton
                            loadMore={() => void loadMoreNews()}
                            testId="loadMoreNewsButton"
                        />
                    )}
                </>
            ) : (
                showLoadingOrEmpty(isLoading)
            )}
        </>
    );
};
