import AdvancedRealTimeChart from '@components/trading-view/AdvancedRealTimeChart';
import CompanyProfile from '@components/trading-view/CompanyProfile';
import FundamentalData from '@components/trading-view/FundamentalData';
import React from 'react';
import SymbolInfo from '@components/trading-view/SymbolInfo';

export const StockPricePanel = ({ tickerId }: { tickerId: string }) => {
    return (
        <div className="flex flex-col">
            <SymbolInfo
                colorTheme="light"
                locale="de_DE"
                width="100%"
                symbol={tickerId}
            ></SymbolInfo>
            <div className="h-[500px]">
                <AdvancedRealTimeChart
                    symbol={tickerId}
                    interval="D"
                    timezone="Europe/Berlin"
                    theme="light"
                    style="1"
                    locale="de_DE"
                    enable_publishing={false}
                    hide_top_toolbar={true}
                    hide_legend={true}
                    save_image={false}
                    details={true}
                    height={500}
                    width="100%"
                />
            </div>
            <div className="flex flex-1 flex-col md:flex-row md:space-x-4 mt-8">
                <div className="md:w-1/2">
                    <FundamentalData
                        colorTheme="light"
                        height={800}
                        width="100%"
                        symbol={tickerId}
                        locale="de_DE"
                    />
                </div>
                <div className="md:w-1/2">
                    <CompanyProfile
                        colorTheme="light"
                        width="100%"
                        height={800}
                        locale="en"
                        symbol={tickerId}
                    ></CompanyProfile>
                </div>
            </div>
        </div>
    );
};
