import { Button } from '@components/button';
import { CustomIcon } from '@components/custom-icon';
import {
    DEFAULT_TIME_FRAME_OPTIONS,
    FUND_ITEMS_PER_PAGE,
} from '@utils/constants';
import { DateFramePicker } from '@components/date-frame-picker';
import { FilterBox } from '@components/funds/filter-box';
import { FinancialDisplayDto } from '@boersenzeitung/shared/dtos/financialDisplay.dto';
import {
    FinancialDisplaySearchFilter,
    QueryOrder,
} from '@boersenzeitung/shared/api.types';
import { FinancialDisplayTable } from '@components/financial-display/financial-display-table';
import { FreeTextSearchBox } from '@components/free-text-search-box';
import { Helmet } from 'react-helmet';
import { LoadMoreButton } from '@components/load-more-button';
import { Option } from '@components/select/types';
import { ReactComponent as Spinner } from '@components/spinner.svg';
import { TeaserText } from '@components/teaser-text';
import { TitledSeparator } from '@components/titled-separator';
import {
    URLSearchParamsInit,
    createSearchParams,
    useLocation,
} from 'react-router-dom';
import {
    getDatesFromSelection,
    setQueryParams,
} from '@utils/date-frame-helper';
import { useDocumentTitle } from '@hooks/useDocumentTitle';
import { useNavigate } from 'react-router';
import React, { useEffect, useState } from 'react';
import apiClient from '@api/api.client';
import qs from 'qs';

const defaultTimeFrame = DEFAULT_TIME_FRAME_OPTIONS.find(
    (option) => option.label === '1 Jahr',
);

export const FinancialDisplays = () => {
    const [filter, setFilter] = useState<FinancialDisplaySearchFilter>({
        page: 0,
        itemsPerPage: FUND_ITEMS_PER_PAGE,
        dateFrom: getDatesFromSelection(defaultTimeFrame)[0],
    });

    const [shownFinancialDisplays, setShownFinancialDisplays] = useState<
        FinancialDisplayDto[]
    >([]);
    const [bufferedFinancialDisplays, setBufferedFinancialDisplays] = useState<
        FinancialDisplayDto[]
    >([]);
    const [canLoadMore, setCanLoadMore] = useState<boolean>(false);
    const [showLoadMore, setShowLoadMore] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [totalCount, setTotalCount] = useState<number | undefined>();
    const [keywords, setKeywords] = useState<string[]>([]);
    const [selectedTimeframe, setSelectedTimeframe] = useState<
        Option | undefined
    >(defaultTimeFrame);
    const [keyword, setKeyword] = useState<string | null>(null);
    const [company, setCompany] = useState<string>('');
    const navigate = useNavigate();
    const location = useLocation();

    useDocumentTitle('Finanzanzeigen von Fonds | Börsen-Zeitung');

    useEffect(() => {
        const handleFilterChange = async () => {
            setLoading(true);
            let refreshBuffer = canLoadMore;
            if (filter.page === 0) {
                const fundsToShow = await apiClient.searchFinancialDisplays(
                    filter,
                );
                setTotalCount(fundsToShow.totalCount);
                setShownFinancialDisplays(fundsToShow.items);
                refreshBuffer = fundsToShow.totalCount > filter.itemsPerPage!;
            }
            if (refreshBuffer) {
                const bufferPageNumber = (filter.page ?? 0) + 1;
                const fundsToBuffer = await apiClient.searchFinancialDisplays({
                    ...filter,
                    page: bufferPageNumber,
                });
                setTotalCount(fundsToBuffer.totalCount);
                setBufferedFinancialDisplays(fundsToBuffer.items);
                setCanLoadMore(
                    (bufferPageNumber + 1) * filter.itemsPerPage! <=
                        fundsToBuffer.totalCount,
                );
                setShowLoadMore(fundsToBuffer.items.length > 0);
            } else {
                setShowLoadMore(false);
            }
            setLoading(false);
        };
        void handleFilterChange();
    }, [filter]);

    useEffect(() => {
        const getKeywords = async () => {
            setKeywords(await apiClient.getKeywords());
        };
        void getKeywords();
        const searchObject = {
            ...qs.parse(location.search.replace('?', '')),
        } as Record<string, string>;
        if (searchObject.sort) {
            const [sortKey, direction] = searchObject.sort.split('-');
            if (
                ['appearanceDay', 'issuers', 'keywords'].includes(sortKey) &&
                (direction === QueryOrder.ASC || direction === QueryOrder.DESC)
            ) {
                filter.sort = { [sortKey]: direction };
            }
        }
    }, []);

    const onLoadMore = () => {
        setShownFinancialDisplays([
            ...shownFinancialDisplays,
            ...bufferedFinancialDisplays,
        ]);
        setFilter({ ...filter, page: (filter.page ?? 0) + 1 });
    };

    const onKeywordSwitch = (keyword: string | null) => {
        setKeyword(keyword);
        setFilter({
            ...filter,
            page: 0,
            keyword: keyword ?? undefined,
        });
    };

    const handleTimeframeSelection = (option: Option | undefined) => {
        const [fromDate, toDate] = getDatesFromSelection(option);
        setFilter({ ...filter, page: 0, dateTo: toDate, dateFrom: fromDate });
        setSelectedTimeframe(option);
        setQueryParams(location, option, navigate);
    };

    const handleSortChange = (sortKey: string) => {
        const searchObject = {
            ...qs.parse(location.search.replace('?', '')),
        };

        if (filter.sort?.[sortKey]) {
            if (filter.sort[sortKey] === QueryOrder.ASC) {
                searchObject.sort = `${sortKey}-DESC`;
                setFilter({
                    ...filter,
                    page: 0,
                    sort: { [sortKey]: QueryOrder.DESC },
                });
            } else {
                delete searchObject.sort;
                setFilter({
                    ...filter,
                    page: 0,
                    sort: undefined,
                });
            }
        } else {
            searchObject.sort = `${sortKey}-ASC`;
            setFilter({
                ...filter,
                page: 0,
                sort: { [sortKey]: QueryOrder.ASC },
            });
        }
        navigate({
            pathname: location.pathname,
            search: `?${createSearchParams(
                searchObject as URLSearchParamsInit,
            )}`,
        });
    };

    const filterByCompany = () => {
        setFilter({ ...filter, company: company === '' ? undefined : company });
    };

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Alle Pflichtveröffentlichungen von Investmentfonds aus der Börsen-Zeitung. Mit praktischer Suchfunktion."
                />
            </Helmet>
            <div>
                <TeaserText>
                    {`Mit unserer Finanzanzeigen-Suche finden sie alle Pflichtpublikationen von Investmentfonds aus der Börsen-Zeitung. Recherchieren Sie mit hilfreichen Schlagworten nach Ausschüttungen, HV-Einladungen, Fondsdokumenten, Anlegermitteilungen und vielen weiteren Bekanntmachungen.`}
                </TeaserText>
                <TitledSeparator title="Pflichtveröffentlichungen aus der Börsen-Zeitung" />
                <div className="md:flex w-full justify-between gap-4 mb-2">
                    <FreeTextSearchBox
                        setSearchTerm={setCompany}
                        searchTerm={company}
                        triggerSearch={filterByCompany}
                        title="Unternehmen"
                    />
                </div>
                <div className="md:flex w-full justify-between gap-4 mb-8">
                    <div className="grow">
                        <p className="mb-4 text-sm font-semibold font-sans">
                            Wertpapierart:
                        </p>
                        <p className="my-2 md:my-0 text-red">Investmentfonds</p>
                    </div>
                    <div className="grow">
                        <p className="text-sm font-semibold mb-2 font-sans">
                            Schlagwort:
                        </p>
                        <FilterBox
                            onEntitySwitch={onKeywordSwitch}
                            queryParamName="Schlagwort"
                            entities={keywords}
                            externalEntity={keyword}
                        />
                    </div>
                </div>

                <div className="md:flex w-full justify-between gap-4 mb-8">
                    <div className="grow">
                        <p className="text-sm font-semibold mb-2 font-sans">
                            Zeitraumauswahl:
                        </p>
                        <DateFramePicker
                            selectedTimeframe={selectedTimeframe}
                            setSelectedTimeframe={handleTimeframeSelection}
                        />
                    </div>
                </div>

                <div className="md:flex w-full justify-between gap-4 mb-8 items-center">
                    <div className="grow flex gap-4">
                        <Button
                            testId="trigger-search-button"
                            title={
                                <div className="flex items-center px-4 py-1">
                                    <CustomIcon
                                        className="text-white"
                                        iconCode={String.raw`before:content-["\ea22"]`}
                                    />
                                    <p className="text-white">Suche</p>
                                </div>
                            }
                            onClick={() => {
                                setFilter({ page: 0 });
                            }}
                            className="bg-red border-red hover:bg-darkred hover:border-darkred"
                            canDisplayPendingState={false}
                        />
                        <Button
                            testId="reset-search-button"
                            title={
                                <div className="flex items-center px-4 py-1">
                                    <CustomIcon
                                        className="text-white"
                                        iconCode={String.raw`before:content-["\ea22"]`}
                                    />
                                    <p className="text-white">Neue Suche</p>
                                </div>
                            }
                            onClick={() => {
                                setKeyword(null);
                                setCompany('');
                                setSelectedTimeframe(defaultTimeFrame);
                                const searchObject = {
                                    ...qs.parse(
                                        location.search.replace('?', ''),
                                    ),
                                };

                                searchObject['Filterdate'] = '1-Jahr';

                                delete searchObject.Schlagwort;
                                delete searchObject.Suchbegriff;

                                navigate({
                                    pathname: location.pathname,
                                    search: `?${createSearchParams(
                                        searchObject as URLSearchParamsInit,
                                    )}`,
                                });
                                setFilter({ page: 0 });
                            }}
                            canDisplayPendingState={false}
                            className="bg-red border-red hover:bg-darkred hover:border-darkred"
                        />
                    </div>
                    <div className="grow text-right">
                        {loading ? (
                            <div>
                                <Spinner />
                            </div>
                        ) : (
                            <p>{`Anzahl: ${totalCount}`}</p>
                        )}
                    </div>
                </div>
                <FinancialDisplayTable
                    shownFinancialDisplays={shownFinancialDisplays}
                    filter={filter}
                    handleSortChange={handleSortChange}
                />

                {showLoadMore && (
                    <LoadMoreButton
                        loadMore={onLoadMore}
                        title="Weitere Ergebnisse anzeigen"
                        testId="financial-display-load-more"
                    />
                )}
            </div>
        </>
    );
};
