import { InstrumentDto } from '@boersenzeitung/shared/dtos/instrument.dto';
import { splitAtLastOccurrence } from '@utils/split-at-last-occurrence';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import UrlDataContext from '../contexts/urlDataContext';

export type NavigationType = {
    name: string;
    href: string;
    isInstrumentDependent: boolean;
    overrideHeaderAddition?: string;
}[];

export function useInstruments(navigations: NavigationType): InstrumentDto[] {
    const location = useLocation();
    const [instruments, setInstruments] = useState<InstrumentDto[]>([]);
    const { currentInstrument: instrument } = useContext(UrlDataContext);
    useEffect(() => {
        if (instrument) {
            const route = splitAtLastOccurrence(location.pathname, '/')[0];
            if (navigations.find((navigation) => navigation.href === route)) {
                const instruments = instrument.company?.instruments ?? [];
                setInstruments(
                    instruments.map((noRefInstrument) => {
                        noRefInstrument.company = instrument.company;
                        return noRefInstrument;
                    }),
                );
            } else {
                setInstruments([]);
            }
        } else {
            setInstruments([]);
        }
    }, [location, instrument]);
    return instruments;
}
