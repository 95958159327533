import { InstrumentDto } from '@boersenzeitung/shared/dtos/instrument.dto';
import { UrlDataProvider } from './urlDataContext';
import { createSearchParams } from 'react-router-dom';
import { generateCompanyUrl } from '@boersenzeitung/shared/parseUrlCompanyPart';
import { splitAtLastOccurrence } from '@utils/split-at-last-occurrence';
import { useLocation, useNavigate } from 'react-router';
import React, { ReactNode, useEffect, useLayoutEffect, useState } from 'react';
import apiClient from '@api/api.client';
import purpleApiAdapter from '@purple/purple-api.adapter';
import qs from 'qs';

export const UrlDataContextWrapper: React.FC<{
    children: ReactNode | undefined;
}> = (props) => {
    const [currentInstrument, setCurrentInstrument] = useState<
        InstrumentDto | undefined
    >();
    const [currentTickerId, setCurrentTickerId] = useState<
        string | undefined
    >();
    const [currentFundIsin, setCurrentFundIsin] = useState<
        string | undefined
    >();
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const [pathName, setPathname] = useState<string>(location.pathname);

    const relevantValue = splitAtLastOccurrence(pathName, '/')[1];

    const isin = splitAtLastOccurrence(relevantValue, '-')[1];

    useLayoutEffect(() => {
        const getInitialRoute = async () => {
            const route = await purpleApiAdapter.getCurrentRoute();
            setPathname(route.path);
        };
        void getInitialRoute();
    }, []);

    useLayoutEffect(() => {
        const fetchCompanyData = async () => {
            const instrument = await apiClient.getInstrument(isin);
            setCurrentInstrument(instrument);
        };
        const fetchTickerData = async () => {
            const instrument = await apiClient.getInstrumentForTickerId(
                relevantValue,
            );
            if (instrument?.company) {
                setCurrentInstrument(instrument);
                navigate({
                    pathname: generateCompanyUrl(
                        '/aktie/kurse',
                        instrument.company,
                        instrument,
                    ),
                    search: `?${createSearchParams({
                        ...qs.parse(location.search.replace('?', '')),
                        ...{},
                    })}`,
                });
            } else {
                setCurrentInstrument(undefined);
                setCurrentTickerId(relevantValue);
            }
        };
        setLoading(true);
        if (pathName.startsWith('/daten')) {
            void fetchTickerData().finally(() => {
                setLoading(false);
            });
        } else if (pathName.startsWith('/aktie')) {
            void fetchCompanyData().finally(() => {
                setLoading(false);
            });
            setCurrentTickerId(undefined);
        } else if (pathName.startsWith('/fonds/fond')) {
            setCurrentFundIsin(relevantValue);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }, [relevantValue, isin, pathName]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.body.scrollTo({ top: 0, behavior: 'smooth' });
    }, [pathName]);

    purpleApiAdapter.addRouteListener((route) => {
        setPathname(route.path);
    });

    purpleApiAdapter.addCommunicationListener((path) => {
        setPathname(path);
    });

    return (
        <UrlDataProvider
            value={{
                loading,
                currentInstrument,
                setCurrentInstrument,
                currentTickerId,
                setCurrentTickerId,
                currentFundIsin,
                setCurrentFundIsin,
            }}
        >
            {props.children}
        </UrlDataProvider>
    );
};
