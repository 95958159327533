import { isTest } from '../../env';
import Copyright, { CopyrightProps } from './Copyright';
import React, { createRef, useEffect } from 'react';

interface WidgetProps {
    scriptHTML: unknown;
    scriptSRC: string;
    containerId?: string;
    type?: 'Widget' | 'MediumWidget';
    copyrightProps: CopyrightProps;
}

declare const TradingView: any;

const Widget: React.FC<WidgetProps> = ({
    scriptHTML,
    scriptSRC,
    containerId,
    type,
    copyrightProps,
}) => {
    const ref: { current: HTMLDivElement | null } = createRef();

    // eslint-disable-next-line sonarjs/cognitive-complexity
    useEffect(() => {
        let refValue: HTMLDivElement;

        const initWidget = () => {
            if (isTest) {
                return;
            }
            if (ref.current) {
                const script = document.createElement('script');
                script.setAttribute('data-nscript', 'afterInteractive');
                script.src = scriptSRC;
                script.async = true;
                script.type = 'text/javascript';

                if (type === 'Widget' || type === 'MediumWidget') {
                    script.onload = () => {
                        script.innerHTML = JSON.stringify(
                            type === 'Widget'
                                ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
                                  new TradingView.widget(scriptHTML)
                                : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                type === 'MediumWidget'
                                ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
                                  new TradingView.MediumWidget(scriptHTML)
                                : undefined,
                        );
                    };
                } else {
                    script.innerHTML = JSON.stringify(scriptHTML);
                }
                if (
                    !ref.current.querySelector(
                        'script[src="' + scriptSRC + '"]',
                    )
                ) {
                    ref.current.appendChild(script);
                }
                refValue = ref.current;
            }
        };
        requestAnimationFrame(initWidget);

        return () => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (refValue) {
                while (refValue.firstChild) {
                    refValue.removeChild(refValue.firstChild);
                }
            }
        };
    }, [ref, scriptHTML, type, scriptSRC]);

    return (
        <>
            <div ref={ref} id={containerId} />
            <Copyright
                href={copyrightProps.href}
                spanText={copyrightProps.spanText}
                text={copyrightProps.text}
                copyrightStyles={copyrightProps.copyrightStyles}
            />
        </>
    );
};

export default Widget;
