import { CustomIcon } from '@components/custom-icon';
import { Environment } from '../../env';
import { FinancialDisplayDto } from '@boersenzeitung/shared/dtos/financialDisplay.dto';
import { GERMAN_DATE_FORMAT } from '@boersenzeitung/shared/constants';
import { classNames } from '@utils/classNames';
import React from 'react';
import dayjs from '@boersenzeitung/shared/dayjs';

export const FinancialDisplayEntry = ({
    tableCellStyle,
    financialDisplay,
    index,
}: {
    tableCellStyle: string;
    financialDisplay: FinancialDisplayDto;
    index: number;
}) => {
    return (
        <tr
            className={classNames(
                'text-sm py-2 hover:bg-spacer',
                index % 2 === 1 ? 'bg-tablegray' : '',
            )}
            data-testid="financialDisplay-entry"
        >
            <td className="pl-5 border-r-2 border-solid border-white align-middle pr-2">
                {dayjs(financialDisplay.appearanceDay).format(
                    GERMAN_DATE_FORMAT,
                )}
            </td>
            <td className={tableCellStyle}>
                {financialDisplay.issuers.join(', ')}
            </td>
            <td className={tableCellStyle}>{financialDisplay.keywords}</td>
            <td className={tableCellStyle}>Investmentfonds</td>
            <td className={tableCellStyle}>
                {financialDisplay.file && (
                    <a
                        href={`${Environment.FILES_CDN_URL}/${financialDisplay.file}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <CustomIcon
                            className="hover:underline"
                            iconCode={String.raw`before:content-["\ea30"]`}
                        />
                    </a>
                )}
            </td>
        </tr>
    );
};
