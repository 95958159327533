import { CustomIcon } from '@components/custom-icon';
import { GERMAN_DATE_FORMAT } from '@boersenzeitung/shared/constants';
import {
    NotificationType,
    UserDataDto,
} from '@boersenzeitung/shared/dtos/email-notifications-user-data.dto';
import { ReactComponent as Spinner } from '@components/spinner.svg';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import React, { useCallback } from 'react';
import dayjs from '@boersenzeitung/shared/dayjs';

export default function ManageSpecList({
    userData,
    deleteEmailNotificationPreference,
    loading,
}: {
    userData?: UserDataDto;
    deleteEmailNotificationPreference: (id: string) => void;
    loading: boolean;
}) {
    const navigate = useNavigate();
    const location = useLocation();

    const getNotificationTypeLabel = useCallback((type: NotificationType) => {
        switch (type) {
            case NotificationType.FULLTEXT:
                return 'Volltext';
            case NotificationType.HEADLINE:
                return 'Überschrift von Artikeln';
            case NotificationType.COMPANY:
                return 'Unternehmen';
        }
    }, []);

    return (
        <div>
            <div className="grid grid-cols-5">
                <p className="font-bold border-solid border-b-2 border-black">
                    Begriff
                </p>
                <p className="font-bold border-solid border-b-2 border-black">
                    Eingrenzung
                </p>
                <p className="font-bold border-solid border-b-2 border-black">
                    Erstellt
                </p>
                <p className="font-bold border-solid border-b-2 border-black">
                    Status
                </p>
                <p className="font-bold border-solid border-b-2 border-black"></p>
                {loading ? (
                    <div className="col-span-5 flex justify-center">
                        <Spinner />
                    </div>
                ) : (
                    userData?.notificationSettings.map((alert) => (
                        <>
                            <button
                                key={alert.id}
                                className="grid grid-cols-4 col-span-4 hover:text-primary-color hover:cursor-pointer text-left"
                                onClick={() =>
                                    navigate(
                                        `/email-notification/list/${alert.id}${location.search}`,
                                    )
                                }
                                data-testid={`email-notification-preference-${alert.id}`}
                            >
                                <p>
                                    {alert.type === NotificationType.COMPANY
                                        ? alert.companyName ?? alert.searchTerm
                                        : alert.searchTerm}
                                </p>
                                <div>
                                    {getNotificationTypeLabel(alert.type)}
                                </div>
                                <p>
                                    {dayjs(alert.createdAt).format(
                                        GERMAN_DATE_FORMAT,
                                    )}
                                </p>
                                <p>
                                    {userData.alertServiceActive
                                        ? 'aktiv'
                                        : 'inaktiv'}
                                </p>
                            </button>
                            <button
                                key={`delete-${alert.id}`}
                                className="w-4 h-4"
                                onClick={() =>
                                    void deleteEmailNotificationPreference(
                                        alert.id,
                                    )
                                }
                                data-testid={`delete-email-notification-preference-${alert.id}`}
                            >
                                <CustomIcon
                                    className="text-negative-color"
                                    iconCode={String.raw`before:content-["\ea09"]`}
                                />
                            </button>
                        </>
                    ))
                )}
            </div>
            {userData?.notificationSettings.length === 0 && (
                <p className="bg-background-color text-secondary-color my-2 p-2">
                    Sie haben derzeit keine E-Mail-Alerts.
                </p>
            )}
        </div>
    );
}
