import './fonts.css';
import './index.css';

import { RouterProvider } from 'react-router-dom';
import { Section, createRouter } from './routes';
import { createRoot } from 'react-dom/client';
import React from 'react';
import reportWebVitals from './reportWebVitals';

const WIDGET_ID = 'purple-widget-boersenzeitung';

const container = document.getElementById(WIDGET_ID)!;
const section = container.getAttribute('data-section') ?? 'termine';

const router = createRouter(section as Section);

const root = createRoot(container);
root.render(
    <React.StrictMode>
        <div className="bg-background py-8">
            <RouterProvider router={router} />
        </div>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
void reportWebVitals();
