import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { InstrumentDto } from '@boersenzeitung/shared/dtos/instrument.dto';
import { Option } from '@components/select/types';
import { Select } from '@components/select/select';
import { companyNavigation } from '@pages/company';
import { generateCompanyUrl } from '@boersenzeitung/shared/parseUrlCompanyPart';
import { getCurrentNavigationForLocation } from '@utils/getCurrentNavigationForLocation';
import { useLocation, useNavigate } from 'react-router';
import React, { useContext } from 'react';
import urlDataContext from '../contexts/urlDataContext';

const mapInstrumentToOption = (instrument: InstrumentDto): Option => {
    return {
        label: `${instrument.isin}: ${instrument.name}`,
        value: instrument.id,
    };
};

export const InstrumentPicker = ({
    instruments,
}: {
    instruments: InstrumentDto[];
}) => {
    const options = instruments.map(mapInstrumentToOption);
    const { currentInstrument, setCurrentInstrument } =
        useContext(urlDataContext);
    const location = useLocation();
    const navigate = useNavigate();

    const updateInstrument = (instrument: InstrumentDto | undefined) => {
        if (instrument) {
            setCurrentInstrument(instrument);
            if (instrument.company) {
                const nav = getCurrentNavigationForLocation(
                    location,
                    companyNavigation,
                );
                if (nav) {
                    navigate({
                        pathname: generateCompanyUrl(
                            nav.href,
                            instrument.company,
                            instrument,
                        ),
                    });
                }
            }
        }
    };

    const getInstrumentFromOption = (value: string): InstrumentDto => {
        return instruments.find((instrument) => instrument.id === value)!;
    };

    const handleInstrumentSelections = (value: string | null) => {
        updateInstrument(value ? getInstrumentFromOption(value) : undefined);
    };

    return (
        <div
            className="md:flex md:items-center"
            data-testid="InstrumentSelection"
        >
            <p className="mr-4">Wertpapier:</p>
            <Select
                options={options}
                value={currentInstrument?.id ?? null}
                onChange={handleInstrumentSelections}
                className="w-full md:w-[200px] lg:w-[250px] font-sans mt-2 md:mt-0"
                optionFontSize="text-sm"
                showFullTextOption={true}
            />
        </div>
    );
};
