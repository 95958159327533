import { ReactComponent as ArrowDown } from '../../icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../icons/arrow-up.svg';
import {
    FinancialDisplaySearchFilter,
    QueryOrder,
} from '@boersenzeitung/shared/api.types';
import React from 'react';

export const FinancialDisplayTableHeader = ({
    tableCellStyle,
    handleSortChange,
    filter,
}: {
    tableCellStyle: string;
    handleSortChange: (sortKey: string) => void;
    filter: FinancialDisplaySearchFilter;
}) => {
    const getSortArrow = (sortDirection?: QueryOrder) => {
        switch (sortDirection) {
            case QueryOrder.ASC:
                return <ArrowUp className="h-4 w-4 ml-2" />;
            case QueryOrder.DESC:
                return <ArrowDown className="h-4 w-4 ml-2" />;
            default:
                return null;
        }
    };

    return (
        <thead>
            <tr className="text-left font-bold bg-tablegray">
                <th className={tableCellStyle}>
                    <button
                        onClick={() => handleSortChange('appearanceDay')}
                        data-testid="appearanceDay-header-button"
                    >
                        <div className="flex items-center">
                            <p className="hover:underline">Datum</p>
                            {getSortArrow(filter.sort?.appearanceDay)}
                        </div>
                    </button>
                </th>
                <th className={tableCellStyle}>
                    <button
                        onClick={() => handleSortChange('issuers')}
                        data-testid="issuers-header-button"
                    >
                        <div className="flex items-center">
                            <p className="hover:underline">Unternehmen</p>
                            {getSortArrow(filter.sort?.issuers)}
                        </div>
                    </button>
                </th>
                <th className={tableCellStyle}>
                    <button
                        onClick={() => handleSortChange('keywords')}
                        data-testid="keywords-header-button"
                    >
                        <div className="flex items-center">
                            <p className="hover:underline">Schlagwort</p>
                            {getSortArrow(filter.sort?.keywords)}
                        </div>
                    </button>
                </th>
                <th className={tableCellStyle}>Wertpapier</th>
                <th className={tableCellStyle}>PDF</th>
            </tr>
        </thead>
    );
};
