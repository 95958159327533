import { useEmailToken } from '@hooks/useEmailToken';
import ManageAddNotificationSpec from '@pages/email-notification-widget/manage-components/manage-add-notification-spec';
import ManageChangeName from '@pages/email-notification-widget/manage-components/manage-change-name';
import ManageOverview from '@pages/email-notification-widget/manage-components/manage-overview';
import ManageSpecList from '@pages/email-notification-widget/manage-components/manage-spec-list';
import React from 'react';

export default function Manage() {
    const {
        emailToken,
        userData,
        setServiceActive,
        removeAllEmailNotificationPreferences,
        addEmailNotificationPreference,
        deleteEmailNotificationPreference,
        changeName,
        loading,
    } = useEmailToken();

    return (
        <>
            <ManageOverview
                removeAllEmailNotificationPreferences={
                    removeAllEmailNotificationPreferences
                }
                setServiceActive={setServiceActive}
                userData={userData}
                emailToken={emailToken}
                userDataLoading={loading}
            />
            <ManageChangeName updateName={changeName} userData={userData} />
            <ManageAddNotificationSpec
                addEmailNotificationPreference={addEmailNotificationPreference}
                userData={userData}
            />
            <ManageSpecList
                deleteEmailNotificationPreference={
                    deleteEmailNotificationPreference
                }
                userData={userData}
                loading={loading}
            />
        </>
    );
}
