import { GERMAN_DATE_FORMAT } from './constants';
import dayjs from './dayjs';

export async function sleep(ms: number): Promise<void> {
    return new Promise((r) => setTimeout(r, ms));
}

export function format(date: string, format: string): string {
    return dayjs(date).format(format);
}

export function formatGermanDate(date: string): string {
    return format(date, GERMAN_DATE_FORMAT);
}
