import { Empty } from '@components/empty';
import { Helmet } from 'react-helmet';
import { StockPricePanel } from '@components/stock-price-panel';
import { TeaserText } from '@components/teaser-text';
import { TitledSeparator } from '@components/titled-separator';
import { useDocumentTitle } from '@hooks/useDocumentTitle';
import React, { useContext } from 'react';
import UrlDataContext from '../../contexts/urlDataContext';

export const StockPrices = () => {
    const { currentInstrument } = useContext(UrlDataContext);
    useDocumentTitle(
        currentInstrument?.company?.name
            ? `${currentInstrument.company.name} Kurse - Aktienkurse | Chart | Börsenplätze`
            : 'Börsen-Zeitung',
    );
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content={`Aktuelle Aktienkurse der ${currentInstrument?.company?.name}. Verschiedene Börsenplätze zur Auswahl. Jetzt Chart ansehen!`}
                />
            </Helmet>
            <div>
                <TeaserText>
                    {`Diese Seite wird automatisch mit allen neuen Kursen der ${currentInstrument?.company?.name} aktualisiert. Mit unserem Chart sehen sie auf einen Blick die aktuellen Kurse. Wählen Sie einfach ihren bevorzugten Börsenplatz und untersuchen Sie die Aktie der ${currentInstrument?.company?.name}.`}
                </TeaserText>
                <TitledSeparator title="Aktienkurse verschiedener Börsenplätze" />
                {currentInstrument?.germanTicker ? (
                    <StockPricePanel
                        tickerId={currentInstrument.germanTicker}
                    />
                ) : (
                    <Empty />
                )}
            </div>
        </>
    );
};
