import { CompanyDto } from '@boersenzeitung/shared/dtos/Company.dto';
import { Empty } from '@components/empty';
import { OVERVIEW_BATCH_SIZE } from '@utils/constants';
import {
    ResultDto,
    TickerDto,
    TickerType,
} from '@boersenzeitung/shared/dtos/news.dto';
import { TeaserText } from '@components/teaser-text';
import { TickerCard } from '@components/ticker-card';
import { TitledSeparator } from '@components/titled-separator';
import React, { useEffect, useState } from 'react';
import apiClient from '@api/api.client';

export const OverviewTicker = ({
    company,
    url,
}: {
    company: CompanyDto;
    url: string;
}) => {
    const [tickerResult, setTickerResult] = useState<ResultDto>();
    useEffect(() => {
        const getTicker = async () => {
            setTickerResult(
                await apiClient.findTickerNews({
                    first: OVERVIEW_BATCH_SIZE,
                    isin: company.isin,
                    tickerType: TickerType.overview,
                }),
            );
        };
        if (company.isin) {
            void getTicker();
        }
    }, [company.isin]);

    return (
        <div className="mb-4">
            <TitledSeparator
                title={'Ticker / ad-hoc'}
                moreActionTarget={`/aktie/ticker${
                    company.isin && url ? '/' + url + '-' + company.isin : ''
                }`}
            />
            <div>
                <TeaserText>
                    Nutzen Sie aktuelle Ticker- und Ad-hoc-Meldungen von unserem
                    Partner dpa-AFX.
                </TeaserText>
                {tickerResult?.data.length ? (
                    <div className="flex-col space-y-2">
                        {tickerResult.data.map((item) => (
                            <TickerCard
                                tickerItem={item as TickerDto}
                                key={item.uuid}
                            />
                        ))}
                    </div>
                ) : (
                    <Empty />
                )}
            </div>
        </div>
    );
};
