import { DividendDto } from '@boersenzeitung/shared/dtos/dividend.dto';
import {
    GERMAN_DATE_FORMAT,
    ISO_DATE_FORMAT,
} from '@boersenzeitung/shared/constants';
import { classNames } from '@utils/classNames';
import React from 'react';
import dayjs from '@boersenzeitung/shared/dayjs';

export const DividendEntry = ({ dividend }: { dividend: DividendDto }) => {
    const textColor = dayjs(dividend.exDay, ISO_DATE_FORMAT).isAfter(dayjs())
        ? 'text-lightgray italic'
        : 'text-secondary';
    return (
        <tr
            className={classNames(
                textColor,
                'hover:bg-whitetail [&:not(:first-child)]:animate-dividend-animation',
            )}
            data-testid="dividendEntry"
        >
            <td className="border-b border-solid border-spacer py-2.5 px-2">
                {dayjs(dividend.exDay, ISO_DATE_FORMAT).format(
                    GERMAN_DATE_FORMAT,
                )}
            </td>
            <td
                className="border-b border-solid border-spacer py-2.5 px-2"
                data-testid="amount"
            >
                {`${dividend.dividendPaid} ${dividend.currency}`}
            </td>
            <td className="border-b border-solid border-spacer py-2.5 px-2">
                {dividend.type}
            </td>
            <td className="border-b border-solid border-spacer py-2.5 px-2">
                {dividend.period}
            </td>
            <td
                className="border-b border-solid border-spacer py-2.5 px-2"
                data-testid="generalMeeting"
            >
                {dividend.generalMeetingDate
                    ? dayjs(
                          dividend.generalMeetingDate,
                          ISO_DATE_FORMAT,
                      ).format(GERMAN_DATE_FORMAT)
                    : ''}
            </td>
        </tr>
    );
};
