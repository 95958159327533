import { InstrumentDto } from '@boersenzeitung/shared/dtos/instrument.dto';
import { NavigationType } from '@hooks/useInstruments';
import HeaderNavLinks from '@components/header/header-nav-links';
import React from 'react';

export default function DesktopNavBar({
    instrument,
    navigation,
    showPurpleStaticLinks,
}: {
    instrument?: InstrumentDto;
    navigation: NavigationType;
    showPurpleStaticLinks: boolean;
}) {
    return (
        <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start border-b-[1px] border-spacer border-solid">
            <div className="hidden md:block">
                <div className="flex space-x-4">
                    <HeaderNavLinks
                        navigation={navigation}
                        instrument={instrument}
                        showPurpleStaticLinks={showPurpleStaticLinks}
                        activeClassName={'border-red text-red font-semibold'}
                        nonActiveClassName={
                            'border-transparent text-secondary hover:border-red font-normal'
                        }
                        baseClassName={
                            'px-3 leading-9 border-solid font-sans border-b-2'
                        }
                    />
                </div>
            </div>
        </div>
    );
}
