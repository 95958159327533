import { classNames } from '@utils/classNames';
import React from 'react';

const mapSuggestionPart = (
    suggestionPart: string,
    searchTerm: string,
    index: number,
) => {
    return (
        <span
            key={`suggestions-part-${index}`}
            className={classNames(
                '',
                searchTerm === suggestionPart.toLowerCase()
                    ? 'font-semibold'
                    : '',
            )}
        >
            {suggestionPart}
        </span>
    );
};

const getDisplayFragments = (suggestion: string, searchTerm: string) => {
    const cleanedUpUserInput = searchTerm.trim().toLowerCase();
    return suggestion
        .split(new RegExp(`(${cleanedUpUserInput})`, 'ig'))
        .map((suggestionPart, index) =>
            mapSuggestionPart(suggestionPart, cleanedUpUserInput, index),
        );
};

export function SearchSuggestion({
    suggestion,
    searchTerm,
}: {
    suggestion: string;
    searchTerm: string;
}) {
    const displayFragments = getDisplayFragments(suggestion, searchTerm);
    return (
        <p
            className="text-left line-clamp-2 font-light leading-6 my-2"
            data-testid={`suggestion-${suggestion}`}
        >
            {displayFragments}
        </p>
    );
}
