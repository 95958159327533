import { Helmet } from 'react-helmet';
import { NewsEntity, NewsList } from '@components/news-list';
import { TeaserText } from '@components/teaser-text';
import { TitledSeparator } from '@components/titled-separator';
import { useDocumentTitle } from '@hooks/useDocumentTitle';
import React from 'react';

export const News = () => {
    useDocumentTitle('Wichtige Termine der kommenden Woche | Börsen-Zeitung');
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Die wichtigsten Termine der nächsten Woche. Eingeordnet von der Redaktion der Börsen-Zeitung. Jetzt informieren!"
                />
            </Helmet>
            <div>
                <TeaserText>
                    Hier sehen sie News zu relevanten Terminen der nächsten
                    Woche.
                    <br />
                    Jeden Freitag neu eingeordnet von den Redakteurinnen und
                    Redakteuren der Börsen-Zeitung.
                </TeaserText>
                <TitledSeparator title="Nachrichten zu wichtigen Terminen der kommenden Woche" />
                <NewsList newsEntity={NewsEntity.termineNews} />
            </div>
        </>
    );
};
