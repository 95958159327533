import { TitleHeaderProps } from '@components/header/top-row/header-top-row';
import React, { ReactElement } from 'react';

export function AppointmentHeaderTopRow({
    title,
}: TitleHeaderProps): ReactElement {
    return (
        <>
            <h1 className="mt-6 mb-2 font-serif">{title}</h1>
            <div className="flex md:flex-row flex-col justify-between mb-4" />
        </>
    );
}
