import { InstrumentDto } from '@boersenzeitung/shared/dtos/instrument.dto';
import { NavLink, useLocation } from 'react-router-dom';
import { NavigationType } from '@hooks/useInstruments';
import { PurpleStaticLinks } from '@components/header/purple-static-links';
import { classNames } from '@utils/classNames';
import { generateCompanyUrl } from '@boersenzeitung/shared/parseUrlCompanyPart';
import { removeSearchParamsFromQueryString } from '@utils/map-record-transformer';
import React from 'react';

export default function HeaderNavLinks({
    instrument,
    navigation,
    showPurpleStaticLinks,
    activeClassName,
    nonActiveClassName,
    baseClassName,
}: {
    instrument?: InstrumentDto;
    navigation: NavigationType;
    showPurpleStaticLinks: boolean;
    activeClassName: string;
    nonActiveClassName: string;
    baseClassName: string;
}) {
    const params = useLocation();

    return (
        <>
            {navigation.map((item) => (
                <NavLink
                    key={item.name}
                    to={`${generateCompanyUrl(
                        item.href,
                        instrument?.company,
                        instrument,
                    )}${removeSearchParamsFromQueryString(params.search)}`}
                    className={({ isActive }) =>
                        classNames(
                            isActive ? activeClassName : nonActiveClassName,
                            baseClassName,
                        )
                    }
                >
                    {item.name}
                </NavLink>
            ))}
            {showPurpleStaticLinks && (
                <PurpleStaticLinks
                    className={classNames(nonActiveClassName, baseClassName)}
                />
            )}
        </>
    );
}
