import { AppointmentHeaderTopRow } from '@components/header/top-row/appointment-header-top-row';
import { CompanyHeaderTopRow } from '@components/header/top-row/company-header-top-row';
import { HeaderProps } from '@components/header/header';
import { Location, useLocation } from 'react-router-dom';
import { NavigationType } from '@hooks/useInstruments';
import { getCurrentNavigationForLocation } from '@utils/getCurrentNavigationForLocation';
import React from 'react';

export type TitleHeaderProps = { title: string } & Omit<HeaderProps, 'title'>;

export default function HeaderTopRow(props: HeaderProps) {
    const currentLocation = useLocation();
    const totalTitle = generateHeaderTitle({
        title: props.title ?? props.instrument?.company?.name ?? '',
        navigation: props.navigation,
        currentLocation: currentLocation,
    });

    return props.instrument?.company ? (
        <CompanyHeaderTopRow {...props} title={totalTitle} />
    ) : (
        <AppointmentHeaderTopRow {...props} title={totalTitle} />
    );
}

function generateHeaderTitle({
    title,
    currentLocation,
    navigation,
}: {
    title: string;
    currentLocation: Location;
    navigation: NavigationType;
}) {
    const currentNavigation = getCurrentNavigationForLocation(
        currentLocation,
        navigation,
    );

    const subtitle =
        currentNavigation?.overrideHeaderAddition ?? currentNavigation?.name;

    return subtitle ? `${title} - ${subtitle}` : `${title}`;
}
