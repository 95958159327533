import { Location } from 'react-router-dom';
import { NavigationType } from '@hooks/useInstruments';

export const getCurrentNavigationForLocation = (
    currentLocation: Location,
    navigation: NavigationType,
) => {
    return navigation.find((navigationLink) => {
        const linkParts = navigationLink.href.split('/');
        const pathNameParts = currentLocation.pathname.split('/');
        if (
            linkParts.length > pathNameParts.length ||
            pathNameParts.length - linkParts.length > 1
        ) {
            return false;
        }
        return linkParts.every((part, index) => {
            return part === pathNameParts[index];
        });
    });
};
