export const splitAtLastOccurrence = (
    str: string,
    substring: string,
): [before: string, after: string] => {
    const lastIndex = str.lastIndexOf(substring);

    if (lastIndex === -1) {
        return [str, ''];
    }

    const before = str.slice(0, lastIndex);

    const after = str.slice(lastIndex + substring.length);

    return [before, after];
};
