import { CompanyDto } from '@boersenzeitung/shared/dtos/Company.dto';
import { InputProps } from 'react-autosuggest';
import {
    NotificationType,
    UserDataDto,
} from '@boersenzeitung/shared/dtos/email-notifications-user-data.dto';
import { SearchSuggestionField } from '@components/suggestion-input/search-suggestion-field';
import RadioInput from '@pages/email-notification-widget/input-fields/radio-input';
import React, { FormEvent, useCallback, useMemo, useState } from 'react';
import TextInput from '@pages/email-notification-widget/input-fields/text-input';
import apiClient from '@api/api.client';

const inputId = 'email-company-search-input';

export default function ManageAddNotificationSpec({
    addEmailNotificationPreference,
    userData,
}: {
    addEmailNotificationPreference: (
        type: NotificationType,
        searchTerm: string,
        companyName?: string,
    ) => void;
    userData?: UserDataDto;
}) {
    const [searchTerm, setSearchTerm] = React.useState<string | null>(null);
    const [searchDisplayValue, setSearchDisplayValue] = useState<string>('');
    const [filterType, setFilterType] = React.useState<
        NotificationType | undefined
    >(undefined);
    const [companyName, setCompanyName] = React.useState<string | undefined>();

    const addEmailNotification = useCallback(() => {
        if (searchTerm && filterType) {
            addEmailNotificationPreference(filterType, searchTerm, companyName);
        }
    }, [searchTerm, filterType, addEmailNotificationPreference]);

    const onChange = useCallback(
        (
            _event: FormEvent<HTMLElement>,
            { newValue }: { newValue: string },
        ) => {
            setSearchDisplayValue(newValue);
        },
        [searchDisplayValue],
    );

    const inputProps: InputProps<string> & {
        'data-cy': string;
        'data-testid': string;
    } = useMemo(
        () => ({
            'placeholder': 'Unternehmen / ISIN / WKN',
            'value': searchDisplayValue,
            onChange,
            'onInput': (e: FormEvent<HTMLInputElement>) => {
                setSearchTerm(e.currentTarget.value);
                setSearchDisplayValue(e.currentTarget.value);
            },
            'onBlur': () => {
                // do nothing -> calling this function will close the suggestion box
            },
            'className':
                'p-4 pt-1 pb-0 md:pb-1 rounded-none h-[38px] w-full min-w-[210px] max-w-[630px] border border-solid focus:ring-red focus:border-red bg-light-gray border-spacer',
            'data-cy': inputId,
            'data-testid': inputId,
            'id': inputId,
        }),
        [
            searchDisplayValue,
            searchTerm,
            setSearchDisplayValue,
            inputId,
            setSearchTerm,
        ],
    );

    const onSuggestionClicked = useCallback(
        (company: CompanyDto) => {
            setSearchDisplayValue(company.name);
            setSearchTerm(company.isin);
            setCompanyName(company.name);
        },
        [setSearchDisplayValue, setSearchTerm],
    );

    const canAddNotification = !userData
        ? true
        : userData.notificationSettings.length < 10 &&
          !userData.notificationSettings.some(
              (notification) =>
                  notification.searchTerm === searchTerm &&
                  notification.type === filterType,
          );

    return (
        <div className="mb-4">
            <div className="flex my-4">
                <h2 className="border-primary-color text-primary-color font-semibold px-3 leading-9 border-solid font-sans border-b-2 text-base">
                    Hinzufügen
                </h2>
                <div className="grow border-b-[1px] border-separator-color border-solid" />
            </div>
            {filterType !== NotificationType.COMPANY ? (
                <div className="mb-4 font-bold">
                    <p className="mb-2">Begriff</p>
                    <TextInput
                        setValue={setSearchTerm}
                        value={searchTerm ?? ''}
                        testId={'search-term-input'}
                    />
                </div>
            ) : (
                <div className="mb-4 font-bold">
                    <p className="mb-2">Unternehmen</p>
                    <SearchSuggestionField<CompanyDto>
                        onSuggestionClicked={onSuggestionClicked}
                        searchTerm={searchTerm ?? ''}
                        inputProps={inputProps}
                        getResults={(searchString: string) =>
                            apiClient.findCompany(searchString)
                        }
                    />
                </div>
            )}
            <div className="mb-4">
                <p className="font-bold mb-2">Eingrenzen auf</p>
                <div className="flex items-center gap-2">
                    <RadioInput
                        setValue={() =>
                            setFilterType(NotificationType.FULLTEXT)
                        }
                        checked={filterType === NotificationType.FULLTEXT}
                        testId={'fulltext-radio'}
                    />
                    <p>Volltext</p>
                </div>
                <div className="flex items-center gap-2">
                    <RadioInput
                        setValue={() =>
                            setFilterType(NotificationType.HEADLINE)
                        }
                        checked={filterType === NotificationType.HEADLINE}
                        testId={'headline-radio'}
                    />
                    <p>Überschrift von Artikeln</p>
                </div>
                <div className="flex items-center gap-2">
                    <RadioInput
                        setValue={() => setFilterType(NotificationType.COMPANY)}
                        checked={filterType === NotificationType.COMPANY}
                        testId={'company-radio'}
                    />
                    <p>Unternehmen</p>
                </div>
            </div>
            <div className="flex justify-between">
                <button
                    onClick={addEmailNotification}
                    className="bg-primary-color !text-button-text-color px-6 py-2 disabled:bg-secondary-color hover:cursor-pointer"
                    disabled={
                        !searchTerm ||
                        searchTerm.length < 3 ||
                        !filterType ||
                        !canAddNotification
                    }
                    data-testid={'add-email-notification-button'}
                >
                    Hinzufügen
                </button>
                {(searchTerm || filterType) && (
                    <button
                        className="underline"
                        onClick={() => {
                            setSearchTerm(null);
                            setSearchDisplayValue('');
                            setFilterType(undefined);
                            setCompanyName(undefined);
                        }}
                        data-testid={'cancel-email-notification-button'}
                    >
                        Abbrechen
                    </button>
                )}
            </div>
        </div>
    );
}
