import { CompanyDto } from '@boersenzeitung/shared/dtos/Company.dto';
import { Empty } from '@components/empty';
import { NewsCard } from '@components/news-card';
import { NewsDto, ResultDto } from '@boersenzeitung/shared/dtos/news.dto';
import { OVERVIEW_BATCH_SIZE } from '@utils/constants';
import { TeaserText } from '@components/teaser-text';
import { TitledSeparator } from '@components/titled-separator';
import React, { useEffect, useState } from 'react';
import apiClient from '@api/api.client';

export const OverviewNews = ({
    company,
    url,
}: {
    company: CompanyDto;
    url: string;
}) => {
    const [newsResult, setNewsResult] = useState<ResultDto>();
    useEffect(() => {
        const getNews = async () => {
            setNewsResult(
                await apiClient.findNews({
                    first: OVERVIEW_BATCH_SIZE,
                    isin: company.isin,
                }),
            );
        };
        if (company.isin) {
            void getNews();
        }
    }, [company.isin]);

    return (
        <div className="mb-4">
            <TitledSeparator
                title={'News'}
                moreActionTarget={`/aktie/news${
                    company.isin && url ? '/' + url + '-' + company.isin : ''
                }`}
            />

            <div>
                <TeaserText>
                    Im Bereich News finden Sie einen Überblick der aktuellen
                    Berichtserstattung aus der Börsen-Zeitung.
                </TeaserText>
                {newsResult?.data.length ? (
                    <div className="flex flex-col space-y-2">
                        {newsResult.data.map((item) => (
                            <NewsCard
                                newsItem={item as NewsDto}
                                key={item.uuid}
                            />
                        ))}{' '}
                    </div>
                ) : (
                    <Empty />
                )}
            </div>
        </div>
    );
};
