import {
    DetailedFundInformationDto,
    FundDividend,
    FundInformation,
    FundInvestment,
    FundPublication,
    FundTaxData,
} from '@boersenzeitung/shared/dtos/fund.dto';
import { Empty } from '@components/empty';
import { FundAnalysisDiagram } from '@components/funds/fund-analysis-diagram';
import {
    FundPerformanceTable,
    FundPriceTable,
} from '@components/funds/fund-analysis-table';
import { GERMAN_DATE_FORMAT } from '@boersenzeitung/shared/constants';
import { SafeImage } from '@components/image';
import { ReactComponent as Spinner } from '@components/spinner.svg';
import { classNames } from '@utils/classNames';
import React, { useContext, useEffect, useState } from 'react';
import apiClient from '@api/api.client';
import dayjs from '@boersenzeitung/shared/dayjs';
import urlDataContext from '../../contexts/urlDataContext';

const convertToGermanNumberFormat = (
    numberString: string | undefined,
): string | undefined => {
    if (!numberString) {
        return undefined;
    }

    const number = parseFloat(numberString);
    if (isNaN(number)) {
        return undefined;
    }

    return number.toLocaleString('de-DE', {
        maximumFractionDigits: 5,
        minimumFractionDigits: 2,
    });
};

const valueWithCurrency = (value: string, currency: string): string => {
    if (value && currency) {
        return `${convertToGermanNumberFormat(value)} ${currency}`;
    }

    return '';
};

const mapFundInformation = (
    fundInformation: FundInformation,
): Record<string, string | undefined> => {
    return {
        'Fondsname': fundInformation.name,
        'Fondgesellschaft': fundInformation.fundCompany,
        'ISIN': fundInformation.isin,
        'WKN': fundInformation.securityId,
        'Rating Feri': fundInformation.ratingFeri,
        'Ertragsart': fundInformation.typeOfIncome,
        'Auflagedatum': fundInformation.inceptionDate
            ? dayjs(fundInformation.inceptionDate).format(GERMAN_DATE_FORMAT)
            : '',
        'Geschäftsjahresanfang': fundInformation.startOfFinancialYear
            ? dayjs(fundInformation.startOfFinancialYear).format('DD.MM.')
            : '',
        'Börsennotierung': fundInformation.stockExchangeListing ? 'Ja' : 'Nein',
        'XTF-Handel': fundInformation.xtfTrading ? 'Ja' : 'Nein',
        'Erstanlage': `${valueWithCurrency(
            fundInformation.initialInvestment,
            fundInformation.initialInvestmentCurrency,
        )}`,
        'Folgeanlage': `${valueWithCurrency(
            fundInformation.followUpInvestment,
            fundInformation.investmentCurrency,
        )}`,
        'Fondsvolumen in Mio.': `${valueWithCurrency(
            fundInformation.fundVolumeInMillions,
            fundInformation.priceCurrency,
        )}`,
    };
};

const mapTaxData = (
    taxData: FundTaxData,
): Record<string, string | undefined> => {
    const shareOfTotalFundAssetsFormatted = convertToGermanNumberFormat(
        taxData.shareOfTotalFundAssets,
    );
    return {
        'Kapitalbeteiligungsquote': convertToGermanNumberFormat(
            taxData.equityParticipationRate,
        ),
        'Anteil Gesamtfondsvermögen':
            shareOfTotalFundAssetsFormatted !== undefined
                ? `${shareOfTotalFundAssetsFormatted}%`
                : undefined,
        'Aktiengewinn EStG': undefined,
        'Aktiengewinn KStG': undefined,
        'Zwischengewinn': undefined,
        'Immobiliengewinn': undefined,
        'Ausschüttungsgl. Thes. Ertrag': valueWithCurrency(
            taxData.dividendEquivalentReinvestedIncome,
            taxData.dividendEquivalentReinvestedIncomeCurrency,
        ),
        'Zuflusstermin (ATE)':
            taxData.dividendEquivalentReinvestedIncomeInflowDate
                ? dayjs(
                      taxData.dividendEquivalentReinvestedIncomeInflowDate,
                  ).format(GERMAN_DATE_FORMAT)
                : '',
    };
};

const mapDividend = (
    dividendData: FundDividend,
): Record<string, string | undefined> => {
    return {
        'Betrag': `${valueWithCurrency(
            dividendData.amount,
            dividendData.amountCurrency,
        )}`,
        'ZASt-pfl.': undefined,
        'Ex-Tag': dividendData.exDividendDate
            ? dayjs(dividendData.exDividendDate).format(GERMAN_DATE_FORMAT)
            : '',
        'Thes. Ertrag': `${valueWithCurrency(
            dividendData.reinvestedIncome,
            dividendData.amountCurrency,
        )}`,
        'Zuflusstermin': dividendData.reinvestedIncomeInflowDate
            ? dayjs(dividendData.reinvestedIncomeInflowDate).format(
                  GERMAN_DATE_FORMAT,
              )
            : '',
    };
};

const mapInvestment = (
    investment: FundInvestment,
): Record<string, string | undefined> => {
    return {
        'Ursprungsland': investment.countryOfOrigin,
        'Ursprungsland (Kürzel)': investment.countryOfOriginAbbreviation,
        'Fondsklassifizierung Feri': investment.fundClassification,
        'Fonds-Typ': investment.fundType,
        'Fonds-Garantien': investment.fundGuarantees,
        'Fondsart': investment.fundSpecials,
        'Anlageschwerpunkt': investment.investmentFocus,
    };
};

const mapPublication = (
    publication: FundPublication,
): Record<string, string | undefined> => {
    return {
        'Börsen - Zeitung': publication.boersenZeitung,
        'Fondsname B-Z': publication.fundNameBoersenZeitungSort,
        'Überschrift B-Z': publication.headlineBoersenZeitungSort,
    };
};

export const DetailPage = () => {
    const { currentFundIsin } = useContext(urlDataContext);
    const [currentFund, setCurrentFund] = useState<
        DetailedFundInformationDto | undefined
    >();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchFundByIsin = async () => {
            if (currentFundIsin) {
                setLoading(true);
                const result = await apiClient.getFundByIsin(currentFundIsin);
                setCurrentFund(result);
                setLoading(false);
            }
        };

        void fetchFundByIsin();
    }, [currentFundIsin]);

    return (
        <>
            {loading ? (
                <div>
                    <Spinner />
                </div>
            ) : (
                <FundDetail fund={currentFund} />
            )}
        </>
    );
};

const FundDetail = ({ fund }: { fund?: DetailedFundInformationDto }) => {
    if (!fund) {
        return <Empty />;
    }

    const priceObject: Record<string, undefined> = {};
    priceObject[fund.priceCalculation] = undefined;

    const graphData = fund.graphs;

    return (
        <div className="space-y-10">
            <div>
                <SafeImage
                    src={fund.fundCompanyLogo}
                    alt="Fondgesellschaftlogo"
                />
                <p className="text-tableheader font-serif font-bold text-xl -mb-1">
                    {fund.fundInformation.name}
                </p>
                <span className="text-secondary font-bold">ISIN: </span>
                <span className="text-secondary">
                    {fund.fundInformation.isin}
                </span>
            </div>
            <div className="space-y-10">
                <FundPriceTable
                    title="Preise"
                    graphData={graphData}
                    currency={fund.fundInformation.priceCurrency}
                />
                <FundPerformanceTable
                    title="Performance"
                    graphData={graphData}
                />
                <FundAnalysisDiagram graphData={graphData} />
                <div>
                    <p className="text-xs text-right text-secondary">{`*=Stand: ${
                        graphData.priceDate
                            ? dayjs(graphData.priceDate).format(
                                  GERMAN_DATE_FORMAT,
                              )
                            : 'Unbekannt'
                    }`}</p>
                    <div className="h-[1px] bg-spacer"></div>
                </div>
            </div>
            <div>
                <FundTable
                    tableTitle="Fondsinformationen"
                    data={mapFundInformation(fund.fundInformation)}
                />
                <FundTable
                    tableTitle="Steuerliche Daten"
                    data={mapTaxData(fund.taxData)}
                />
                <FundTable
                    tableTitle="Ausschüttungen"
                    data={mapDividend(fund.dividend)}
                />
                <FundTable
                    tableTitle="Anlagegrundsätze"
                    data={mapInvestment(fund.investment)}
                />
                <FundTable
                    tableTitle="Veröffentlichung"
                    data={mapPublication(fund.publication)}
                />
                {fund.priceCalculation && (
                    <FundTable
                        tableTitle="Preisberechnung"
                        data={priceObject}
                        wholeRow={true}
                    />
                )}
            </div>
        </div>
    );
};

const FundTable = ({
    tableTitle,
    data,
    wholeRow = false,
}: {
    tableTitle: string;
    data: Record<string, string | undefined>;
    wholeRow?: boolean;
}) => {
    return (
        <>
            <p className="font-bold text-tableheader mb-2">{tableTitle}</p>
            <table
                className={classNames(
                    wholeRow ? 'table-auto' : 'table-fixed',
                    'w-full text-sm text-secondary mb-10',
                )}
            >
                <tbody>
                    {Object.entries(data).map(([key, value]) => {
                        return (
                            <tr
                                key={key}
                                className="odd:bg-tablegray hover:bg-spacer"
                            >
                                <td className="px-5 py-1 font-bold">{key}</td>
                                <td className="px-2">{value}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};
