import { Empty } from '@components/empty';
import { NewsCard } from '@components/news-card';
import { NewsDto, ResultDto } from '@boersenzeitung/shared/dtos/news.dto';
import { OVERVIEW_BATCH_SIZE } from '@utils/constants';
import { TeaserText } from '@components/teaser-text';
import { TitledSeparator } from '@components/titled-separator';
import React, { useEffect, useState } from 'react';
import apiClient from '@api/api.client';

export const OverviewNews = () => {
    const [newsResult, setNewsResult] = useState<ResultDto>();
    useEffect(() => {
        const getNews = async () => {
            setNewsResult(
                await apiClient.findFundNews({
                    first: OVERVIEW_BATCH_SIZE,
                }),
            );
        };

        void getNews();
    }, []);

    return (
        <>
            <div className="mb-4">
                <TitledSeparator title="News" moreActionTarget="/fonds/news" />
                <TeaserText>
                    Im News-Bereich finden Sie einen Überblick zur aktuellen
                    Berichterstattung der Börsen-Zeitung zum Thema
                    Investmentanteile.
                </TeaserText>
                {newsResult?.data.length ? (
                    <div className="flex flex-col space-y-2">
                        {newsResult.data.map((item) => (
                            <NewsCard
                                newsItem={item as NewsDto}
                                key={item.uuid}
                            />
                        ))}
                    </div>
                ) : (
                    <Empty />
                )}
            </div>
        </>
    );
};
