import { Empty } from '@components/empty';
import { GERMAN_DATE_FORMAT } from '@boersenzeitung/shared/constants';
import { NavLink } from 'react-router-dom';
import { Select } from '@components/select/select';
import {
    Top5UltimoDto,
    Top5UltimoFundDto,
} from '@boersenzeitung/shared/dtos/top5ultimo.dto';
import { classNames } from '@utils/classNames';
import { getColorForNumericValue } from '@pages/funds/utils';
import React from 'react';
import dayjs from '@boersenzeitung/shared/dayjs';

export const Top5UltimoSearchTable = ({
    allCategories,
    category,
    setCategory,
    top5Ultimos,
}: {
    allCategories: string[];
    category: string | null;
    setCategory: (category: string | null) => void;
    top5Ultimos: Top5UltimoDto[];
}) => {
    return top5Ultimos.length > 0 ? (
        <div className="space-y-4">
            <div className="md:max-w-[500px] flex flex-col">
                <p className="text-sm font-semibold mb-2 font-sans">
                    Anlagekategorien
                </p>
                <FilterBox
                    allCategories={allCategories}
                    category={category}
                    setCategory={setCategory}
                />
            </div>
            <div>
                <span className="font-bold leading-5">Stand: </span>
                <span>
                    {dayjs(top5Ultimos[0].lastUpdate).format(
                        GERMAN_DATE_FORMAT,
                    )}
                </span>
            </div>
            <Top5UltimoTable top5Ultimos={top5Ultimos} />
        </div>
    ) : (
        <Empty />
    );
};

export const Top5UltimoTable = ({
    top5Ultimos,
}: {
    top5Ultimos: Top5UltimoDto[];
}) => {
    const tableCellStyle =
        'border-x-2 border-solid border-white align-middle px-2';
    const performanceBorderStyle =
        'border-solid border-black align-middle px-2';
    const textCenterStyle = 'text-center';
    const textRightStyle = 'text-right';

    return (
        <div className="overflow-x-auto">
            <table className="table-auto w-full">
                <tbody className="text-left font-bold bg-tablegray text-sm text-secondary">
                    <tr className="bg-background">
                        <td />
                        <td />
                        <td />
                        <td />
                        <td
                            colSpan={4}
                            className="text-xs text-center bg-tableFundBackground border-solid border-black border-t-[1px] border-x-[1px]"
                        >
                            Performance
                        </td>
                    </tr>
                    <tr>
                        <th className="border-r-2 border-solid border-white align-middle px-2 text-base">
                            Fonds
                        </th>
                        <th
                            className={classNames(
                                tableCellStyle,
                                textCenterStyle,
                            )}
                        >
                            Feri Rating
                        </th>
                        <th
                            className={classNames(
                                tableCellStyle,
                                textCenterStyle,
                            )}
                        >
                            Erstanlage in Fondswähr.
                        </th>
                        <th
                            className={classNames(
                                performanceBorderStyle,
                                textCenterStyle,
                                'border-r-[1px]',
                            )}
                        >
                            Währ.
                        </th>
                        <th
                            className={classNames(
                                performanceBorderStyle,
                                textRightStyle,
                                'border-l-[1px]',
                            )}
                        >
                            1 J.
                        </th>
                        <th
                            className={classNames(
                                tableCellStyle,
                                textRightStyle,
                            )}
                        >
                            3 J.
                        </th>
                        <th
                            className={classNames(
                                tableCellStyle,
                                textRightStyle,
                            )}
                        >
                            5 J.
                        </th>
                        <th
                            className={classNames(
                                performanceBorderStyle,
                                textRightStyle,
                                'border-r-[1px]',
                            )}
                        >
                            YTD
                        </th>
                    </tr>
                    {top5Ultimos.map((entry) => {
                        const category = entry.category;
                        const top5Funds = entry.top5Funds;
                        return (
                            <TableForCategory
                                key={entry.category}
                                category={category}
                                top5Funds={top5Funds}
                            />
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

const TableForCategory = ({
    category,
    top5Funds,
}: {
    category: string;
    top5Funds: Top5UltimoFundDto[];
}) => {
    return (
        <>
            <tr>
                <td
                    colSpan={8}
                    className="border-y-2 border-white border-solid align-middle px-2 bg-tableFundBackground leading-5 py-1 text-base text-black"
                >
                    {category}
                </td>
            </tr>
            {top5Funds.map((fund) => {
                return <FundEntry key={fund.isin} fund={fund} />;
            })}
            <tr className="h-10 bg-background border-t-[1px] border-black border-solid">
                <td colSpan={8} />
            </tr>
        </>
    );
};

const FundEntry = ({ fund }: { fund: Top5UltimoFundDto }) => {
    const tableCellStyle =
        'text-secondary border-x-2 border-solid border-white align-middle px-2';
    const performanceCellStyle =
        'text-secondary border-x-2 border-solid border-white align-middle pl-6 pr-2 text-right';
    const textCenterStyle = 'text-center';

    return (
        <tr className="text-sm font-normal odd:bg-white hover:bg-spacer">
            <td className="underline hover:text-red text-black">
                <NavLink to={`/fonds/fond/${fund.isin}`}>{fund.name}</NavLink>
            </td>

            <td className={classNames(tableCellStyle, textCenterStyle)}>
                {fund.rating}
            </td>
            <td className={classNames(tableCellStyle, 'text-right')}>
                {fund.initialInvestment}
            </td>
            <td className={classNames(tableCellStyle, textCenterStyle)}>
                {fund.currency}
            </td>

            <td
                className={classNames(
                    performanceCellStyle,
                    getColorForNumericValue(fund.oneYearPerformance),
                    'font-bold',
                )}
            >
                {fund.oneYearPerformance}
            </td>
            <td className={performanceCellStyle}>
                {fund.threeYearPerformance}
            </td>
            <td className={performanceCellStyle}>{fund.fiveYearPerformance}</td>
            <td className={performanceCellStyle}>{fund.yearlyPerformance}</td>
        </tr>
    );
};

const FilterBox = ({
    category,
    setCategory,
    allCategories,
}: {
    category: string | null;
    setCategory: (category: string | null) => void;
    allCategories: string[];
}) => {
    return (
        <Select
            options={[
                { value: null, label: 'Alle' },
                ...allCategories.map((category) => ({
                    value: category,
                    label: category,
                })),
            ]}
            value={category}
            onChange={setCategory}
            className="w-full font-sans"
        />
    );
};
