import { UserDataDto } from '@boersenzeitung/shared/dtos/email-notifications-user-data.dto';
import React, { useEffect } from 'react';
import TextInput from '@pages/email-notification-widget/input-fields/text-input';
export default function ManageChangeName({
    userData,
    updateName,
}: {
    userData?: UserDataDto;
    updateName: (firstName: string, lastName: string) => void;
}) {
    const [firstName, setFirstName] = React.useState<string | null>(
        userData?.firstName ?? null,
    );
    const [lastName, setLastName] = React.useState<string | null>(
        userData?.lastName ?? null,
    );

    useEffect(() => {
        setFirstName(userData?.firstName ?? null);
        setLastName(userData?.lastName ?? null);
    }, [userData?.firstName, userData?.lastName]);

    return (
        <div className="mb-4">
            <div className="flex my-4">
                <h2 className="border-primary-color text-primary-color font-semibold px-3 leading-9 border-solid font-sans border-b-2 text-base">
                    Namen ändern
                </h2>
                <div className="grow border-b-[1px] border-separator-color border-solid" />
            </div>
            <div className="mb-4 font-bold">
                <p className="mb-2">Vorname</p>
                <TextInput
                    setValue={setFirstName}
                    value={firstName ?? ''}
                    testId={'first-name-input'}
                />
            </div>
            <div className="mb-4 font-bold">
                <p className="mb-2">Nachname</p>
                <TextInput
                    setValue={setLastName}
                    value={lastName ?? ''}
                    testId={'last-name-input'}
                />
            </div>
            <button
                onClick={() => {
                    if (firstName && lastName) {
                        updateName(firstName, lastName);
                    }
                }}
                className="bg-primary-color !text-button-text-color px-6 py-2 disabled:bg-secondary-color hover:cursor-pointer"
                disabled={
                    !firstName ||
                    !lastName ||
                    (firstName === userData?.firstName &&
                        lastName === userData.lastName)
                }
                data-testid={'change-name-button'}
            >
                Namen ändern
            </button>
        </div>
    );
}
