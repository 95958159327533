import {
    GERMAN_DATE_FORMAT,
    GERMAN_TIME_FORMAT,
    PURPLE_DATE_FORMAT,
} from '@boersenzeitung/shared/constants';
import { TICKER_TRANSLATION_MAP } from '@utils/constants';
import { TickerDto } from '@boersenzeitung/shared/dtos/news.dto';
import { useLocation } from 'react-router-dom';
import React from 'react';
import dayjs from '@boersenzeitung/shared/dayjs';
import purpleApiAdapter from '@purple/purple-api.adapter';

export function TickerCard({ tickerItem }: { tickerItem: TickerDto }) {
    const location = useLocation();
    const dateTime = dayjs(tickerItem.publishedDateTime, PURPLE_DATE_FORMAT);
    return (
        <button
            onClick={() =>
                purpleApiAdapter.redirectToPurpleContent(
                    tickerItem.slug,
                    location.search,
                )
            }
            key={tickerItem.uuid}
            className="flex flex-col bg-white p-5"
            data-testid="tickerCard"
        >
            <div className="hover:cursor-pointer hover:text-red w-full text-left">
                <div className="flex flex-row justify-between text-lightgray text-xs font-sourceCode">
                    <div className="flex flex-row justify-between grow">
                        <div className="flex flex-row space-x-4">
                            <div className="font-bold">
                                {dateTime.format(GERMAN_DATE_FORMAT)}
                            </div>
                            <div className="uppercase font-semibold tracking-[.125rem]">
                                {`Quelle: ${TICKER_TRANSLATION_MAP.get(
                                    tickerItem.source,
                                )}`}
                            </div>
                        </div>
                        <div>{dateTime.format(GERMAN_TIME_FORMAT)}</div>
                    </div>
                </div>
                <div className="text-xl font-semibold font-serif">
                    {tickerItem.title}
                </div>
            </div>
        </button>
    );
}
