import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Option } from '@components/select/types';
import { Select } from '@components/select/select';
import { TICKER_TRANSLATION_MAP } from '@utils/constants';
import { TickerType } from '@boersenzeitung/shared/dtos/news.dto';
import React, { useEffect } from 'react';

export const typeOptions: Option[] = Object.values(TickerType).map(
    (tickerKey: TickerType) => {
        return {
            label: TICKER_TRANSLATION_MAP.get(tickerKey)!,
            value: tickerKey,
        };
    },
);

export const TickerTypePicker = ({
    selectedType,
    setSelectedType,
}: {
    selectedType: Option | undefined;
    setSelectedType: (newOption: Option) => void;
}) => {
    useEffect(() => {
        if (!selectedType) {
            setSelectedType(typeOptions[0]!);
        }
    }, [selectedType]);

    const handleTickerTypeSelect = (value: string | null) => {
        if (value) {
            const newOption = typeOptions.find(
                (option) => option.value === value,
            );
            if (newOption) {
                setSelectedType(newOption);
            }
        }
    };

    return (
        <>
            {selectedType && (
                <div className="mb-10">
                    <p className="text-sm font-semibold mb-2 font-sans">Typ</p>
                    <Select
                        options={typeOptions}
                        value={selectedType.value}
                        onChange={handleTickerTypeSelect}
                        className="w-[250px] font-sans"
                    />
                </div>
            )}
        </>
    );
};
