import { AnalysisCard } from '@components/analysis/analysis-card';
import { AnalysisDiagramm } from '@components/analysis/analysis-diagramm';
import { DpaAfxArticleDto } from '@boersenzeitung/shared/dtos/Dpa-afx-article.dto';
import { Empty } from '@components/empty';
import { Helmet } from 'react-helmet';
import { INITIAL_NEWS_BATCH_SIZE, NEWS_BATCH_SIZE } from '@utils/constants';
import { LoadMoreButton } from '@components/load-more-button';
import { TeaserText } from '@components/teaser-text';
import { TitledSeparator } from '@components/titled-separator';
import { useDocumentTitle } from '@hooks/useDocumentTitle';
import React, { useContext, useMemo, useState } from 'react';
import UrlDataContext from '../../contexts/urlDataContext';
import apiClient from '@api/api.client';

export const Analysis = () => {
    const { currentInstrument: instrument } = useContext(UrlDataContext);
    const [loadedResults, setLoadedResults] = useState<DpaAfxArticleDto[]>([]);
    const [shownResults, setShownResults] = useState<DpaAfxArticleDto[]>([]);
    const [canLoadMore, setCanLoadMore] = useState<boolean>(false);
    const [showLoadMore, setShowLoadMore] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);

    useDocumentTitle(
        instrument?.company?.name
            ? `${instrument.company.name} Aktienanalysen - Prognosen | Analysen | Kursziele`
            : 'Börsen-Zeitung',
    );

    useMemo(() => {
        const loadData = async () => {
            if (instrument?.company) {
                const loadedData = await apiClient.getDpaAfxArticles(
                    instrument.company.id,
                    0,
                    INITIAL_NEWS_BATCH_SIZE,
                );
                setCurrentPage(1);
                setShownResults(loadedData.articles.slice(0, NEWS_BATCH_SIZE));
                setLoadedResults(
                    loadedData.articles.slice(
                        NEWS_BATCH_SIZE,
                        INITIAL_NEWS_BATCH_SIZE,
                    ),
                );
                setCanLoadMore(loadedData.canLoadMore);
                setShowLoadMore(loadedData.articles.length > NEWS_BATCH_SIZE);
            }
        };
        void loadData();
    }, [instrument?.company?.id]);

    const highestRating = useMemo(() => {
        if (!instrument?.company) {
            return -1;
        }
        const { lastSixMonths, lastFourWeeks } = instrument.company.dpaRatings;
        return Math.max(
            lastSixMonths.neutral,
            lastSixMonths.positive,
            lastSixMonths.negative,
            lastFourWeeks.positive,
            lastFourWeeks.neutral,
            lastFourWeeks.negative,
        );
    }, [instrument?.company?.id]);

    const loadMoreArticles = async () => {
        setShownResults([...shownResults, ...loadedResults]);
        if (canLoadMore && instrument?.company) {
            const result = await apiClient.getDpaAfxArticles(
                instrument.company.id,
                currentPage + 1,
                NEWS_BATCH_SIZE,
            );
            setCurrentPage(currentPage + 1);
            setCanLoadMore(result.canLoadMore);
            setLoadedResults(result.articles);
        } else {
            setShowLoadMore(false);
        }
    };
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content={`Aktuelle Übersicht von Aktienanalysen der ${instrument?.company?.name}. Jetzt über Aktienprognosen und Kursziele informieren!`}
                />
            </Helmet>
            <div>
                <TeaserText>
                    {`Die fundierten Aktienanalysen der ${instrument?.company?.name} geben
            Aufschluss über Prognosen, Kursziele und weitere relevante
            Kennziffern, die Sie bei Ihrer Entscheidung über Kauf und Verkauf
            der Aktie unterstützen. Eine Einstufung der ${instrument?.company?.name}
            Analyse erfolgt in regelmäßigen Zyklen.`}
                </TeaserText>
                <TitledSeparator title="Aktienanalysen, Aktienprognosen und Kursziele" />
                {instrument?.company ? (
                    <div className="font-sans mb-8">
                        <p className="font-bold mb-4">
                            Einstufung in den letzten 6 Monaten
                        </p>
                        <AnalysisDiagramm
                            rating={instrument.company.dpaRatings.lastSixMonths}
                            highestRating={highestRating}
                        />
                        <p className="font-bold mt-8 mb-4">
                            Einstufung in den letzten 4 Wochen
                        </p>
                        <AnalysisDiagramm
                            rating={instrument.company.dpaRatings.lastFourWeeks}
                            highestRating={highestRating}
                        />
                    </div>
                ) : (
                    <Empty />
                )}
                {shownResults.map((article) => (
                    <AnalysisCard article={article} key={article.id} />
                ))}
                {showLoadMore && (
                    <LoadMoreButton
                        loadMore={() => void loadMoreArticles()}
                        testId="loadMoreArticlesButton"
                        title="Weitere Ergebnisse anzeigen"
                    />
                )}
            </div>
        </>
    );
};
