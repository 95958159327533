import React from 'react';

export const CustomIcon = ({
    iconCode,
    className,
}: {
    iconCode?: string;
    className?: string;
}) => {
    if (!iconCode) {
        return null;
    }
    return (
        <div
            className={String.raw`font-customIcons relative before:block ${iconCode} ${className}`}
        />
    );
};
