import { NavigationType } from '@hooks/useInstruments';
import Header from '@components/header/header';
import React, { useContext } from 'react';
import UrlDataContext from '../../contexts/urlDataContext';

export const companyNavigation: NavigationType = [
    {
        name: 'Übersicht',
        href: '/aktie',
        isInstrumentDependent: false,
        overrideHeaderAddition: 'Aktie',
    },
    { name: 'Kurse', href: '/aktie/kurse', isInstrumentDependent: true },
    { name: 'News', href: '/aktie/news', isInstrumentDependent: false },
    {
        name: 'Ticker/Ad-hoc',
        href: '/aktie/ticker',
        isInstrumentDependent: false,
    },
    { name: 'Analysen', href: '/aktie/analysen', isInstrumentDependent: false },
    {
        name: 'Dividenden',
        href: '/aktie/dividenden',
        isInstrumentDependent: true,
    },
    { name: 'Termine', href: '/aktie/termine', isInstrumentDependent: true },
];

export default function CompanyIndex() {
    const { currentInstrument } = useContext(UrlDataContext);

    return (
        <Header instrument={currentInstrument} navigation={companyNavigation} />
    );
}
