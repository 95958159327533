import { DocumentDto } from '@boersenzeitung/shared/dtos/news.dto';
import { Empty } from '@components/empty';
import { TeaserText } from '@components/teaser-text';
import { TitledSeparator } from '@components/titled-separator';
import React, { useEffect, useState } from 'react';
import apiClient from '@api/api.client';

export const FullPagePdf = () => {
    const [documents, setDocuments] = useState<DocumentDto[]>([]);

    useEffect(() => {
        const getDocuments = async () => {
            setDocuments((await apiClient.getPdfDocuments()).data);
        };
        void getDocuments();
    }, []);

    return (
        <div>
            <TeaserText>
                {`Mit unseren Ganzseiten-PDFs vom Bereich „Investmentanteile“ aus der Börsen-Zeitung können sie gezielt nach Fonds recherchieren.`}
            </TeaserText>
            <TitledSeparator title="„Investmentanteile“ aus der Börsen-Zeitung" />
            <div className="flex gap-4">
                {documents.length > 0 ? (
                    documents.map((document) => {
                        return (
                            <a
                                href={`https://web.purplemanager.com/boeznews/presenter/${document.id}?actionUrl=purple:%2F%2Fkiosk%2Fissue%2Fcurrent%2Fpage%2Findex%2F8%2Fopen`}
                                target="_blank"
                                rel="noreferrer"
                                key={document.id}
                                className="hover:text-red"
                            >
                                <img
                                    src={document.thumbnailUrl}
                                    alt={document.id}
                                />
                                <p className="text-center">{document.name}</p>
                            </a>
                        );
                    })
                ) : (
                    <Empty />
                )}
            </div>
        </div>
    );
};
