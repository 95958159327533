import { Option } from '@components/select/types';
import { TickerType } from '@boersenzeitung/shared/dtos/news.dto';
import dayjs from '@boersenzeitung/shared/dayjs';
export const INITIAL_NEWS_BATCH_SIZE = 20;
export const NEWS_BATCH_SIZE = INITIAL_NEWS_BATCH_SIZE / 2;
export const DATES_BATCH_SIZE = 10;

export const FUND_ITEMS_PER_PAGE = 200;
export const DATE_ITEMS_PER_PAGE = 200;

export const OVERVIEW_BATCH_SIZE = 3;

export const TICKER_TRANSLATION_MAP = new Map<TickerType, string>([
    [TickerType.overview, 'Übersicht'],
    [TickerType.adHoc, 'Ad-hoc'],
    [TickerType.analysis, 'Analyse'],
    [TickerType.company, 'Unternehmen'],
    [TickerType.topTopic, 'Top-Themen'],
    [TickerType.marketReport, 'Marktberichte'],
]);

export const PURPLE_STATIC_LINK_TARGETS: {
    label: string;
    value: string;
}[] = [
    {
        label: 'Termine des Tages',
        value: '/termine/termine-des-tages',
    },
    { label: 'Podcast', value: '/termine/podcast' },
    {
        label: 'Bilanzfahrplan',
        value: '/termine/bilanzfahrplan',
    },
];

export const DEFAULT_TIME_FRAME_OPTIONS: Option[] = [
    { label: 'gesamter Zeitraum', value: 'all' },
    {
        label: '1 Woche',
        value: dayjs().startOf('day').subtract(1, 'week').toISOString(),
    },
    {
        label: '1 Monat',
        value: dayjs().startOf('day').subtract(1, 'month').toISOString(),
    },
    {
        label: '1 Vierteljahr',
        value: dayjs().startOf('day').subtract(3, 'month').toISOString(),
    },
    {
        label: '1 Halbjahr',
        value: dayjs().startOf('day').subtract(6, 'month').toISOString(),
    },
    {
        label: '1 Jahr',
        value: dayjs().startOf('day').subtract(1, 'year').toISOString(),
    },
    { label: 'Anderen Zeitraum auswählen', value: 'custom' },
];
