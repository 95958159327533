export const getColorForNumericValue = (value?: string): string => {
    if (value) {
        const numericValue = parseFloat(value.replace(',', '.'));
        if (!isNaN(numericValue)) {
            if (numericValue > 0) {
                return 'text-positiveGreen';
            } else if (numericValue < 0) {
                return 'text-negativeRed';
            }
        }
    }
    return 'text-neutralBlue';
};
