import { ISO_DATE_FORMAT } from '@boersenzeitung/shared/constants';
import {
    Location,
    NavigateOptions,
    To,
    URLSearchParamsInit,
    createSearchParams,
} from 'react-router-dom';
import { Option } from '@components/select/types';
import dayjs from '@boersenzeitung/shared/dayjs';
import qs from 'qs';

export const getDatesFromSelection = (
    selectedTimeframe: Option | undefined,
): [string?, string?] => {
    let fromDate;
    let toDate;
    if (selectedTimeframe?.value?.includes(' - ')) {
        const dates = selectedTimeframe.value.split(' - ');
        fromDate = dayjs(dates[0]).format(ISO_DATE_FORMAT);
        toDate = dayjs(dates[1]).format(ISO_DATE_FORMAT);
    } else if (selectedTimeframe?.value !== 'all') {
        fromDate = dayjs(selectedTimeframe?.value).format(ISO_DATE_FORMAT);
    }
    return [fromDate, toDate];
};

export const setQueryParams = (
    location: Location,
    option: Option | undefined,
    navigate: (to: To, options?: NavigateOptions) => void,
) => {
    const searchObject = {
        ...qs.parse(location.search.replace('?', '')),
    };
    if (option) {
        searchObject['Filterdate'] = option.label
            .trim()
            .replace(' ', '-')
            .replace('ä', 'ae');
    } else {
        delete searchObject['Filterdate'];
    }
    navigate({
        pathname: location.pathname,
        search: `?${createSearchParams(searchObject as URLSearchParamsInit)}`,
    });
};
