import { OverviewFinancialAnnouncements } from '@components/fund-overview/overview-financial-announcements';
// import { OverviewFullPagePdf } from '@components/fund-overview/overview-full-page-pdf';
import { Helmet } from 'react-helmet';
import { OverviewNews } from '@components/fund-overview/overview-news';
import { OverviewSearch } from '@components/fund-overview/overview-search';
import { OverviewTop5Ultimo } from '@components/fund-overview/overview-top-5-ultimo';
import { TeaserText } from '@components/teaser-text';
import { useDocumentTitle } from '@hooks/useDocumentTitle';
import React from 'react';

export const Overview = () => {
    useDocumentTitle('Fonds - News | Suche | Top 5 Ultimo');
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Alle relevante Informationen aus der Börsen-Zeitung zum Thema Investmentfonds in der Übersicht."
                />
            </Helmet>
            <div>
                <TeaserText>
                    {`Auf dieser Seite sehen sie alle relevanten Informationen zum Thema Fonds in der Übersicht: Aktuelle Nachrichten, Fondssuche, „Top 5 Ultimo“ Fonds, Finanzanzeigen und Ganzseiten-PDF.`}
                </TeaserText>
                <div className="space-y-10">
                    <OverviewNews />
                    <OverviewSearch />
                    <OverviewTop5Ultimo />
                    <OverviewFinancialAnnouncements />
                </div>
            </div>
        </>
    );
};
