export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends Record<string, unknown>> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Long: any;
};

/** The access level of content. */
export enum Access {
    /** The content is freely available */
    Free = 'FREE',
    /** The content is hidden by default and can only be accessed via an entitlement login. */
    Hidden = 'HIDDEN',
    /** The content needs to be purchased before it can be accessed. */
    Purchasable = 'PURCHASABLE',
}

/** Filter based on the access property. */
export type AccessFilter = {
    negated?: InputMaybe<Scalars['Boolean']>;
    value?: InputMaybe<Access>;
};

/** Type holding metadata of an app. */
export type App = {
    __typename?: 'App';
    /** The ID of the app */
    id: Scalars['ID'];
};

/** The app is active and available. Additional dynamic configuration is available in the config field. */
export type AppAvailable = {
    __typename?: 'AppAvailable';
    /** Additional dynamic configuration. */
    config: AppConfig;
};

/** Additional dynamic configuration of an app. */
export type AppConfig = {
    __typename?: 'AppConfig';
    /** iOS only: If the app was installed via a deferred deep-link the requested deep-link is returned here on first app start. */
    deferredDeeplink?: Maybe<Scalars['String']>;
    /** Information about the current version and location of the dynamic resources of the app. */
    dynamicResources: AppDynamicResourcesInfo;
    /** Optional entitlement configuration. If null: no entitlement is configured. */
    entitlementConfig?: Maybe<AppEntitlementConfig>;
    sharingConfig: SharingConfig;
};

/** Information about the current version and location of the dynamic resources of the app. */
export type AppDynamicResourcesInfo = {
    __typename?: 'AppDynamicResourcesInfo';
    /** The modification timestamp of the dynamic resources since Unix epoch in milliseconds. */
    lastModified: Scalars['Long'];
    /** The download location of the dynamic resources for the current version for the app. */
    url: Scalars['String'];
};

/** Union of all possible entitlement configuration types. */
export type AppEntitlementConfig = EntitlementConfig | OAuthConfig;

/** Input for the apps ID, version, API key and the preview flag. */
export type AppInfo = {
    /** API key of the app. Currently only used for mutations to prevent unauthorized access. */
    apiKey?: InputMaybe<Scalars['String']>;
    /** The ID of the app */
    appId: Scalars['ID'];
    /** The version of the app requesting data */
    appVersion: Scalars['String'];
    /** Preview flag which determines if preview contents are returned. */
    preview?: InputMaybe<Scalars['Boolean']>;
};

/** Union of all possible app status */
export type AppStatus = AppAvailable | AppUnavailable | AppUpdateRequired;

export type AppSubscription = {
    __typename?: 'AppSubscription';
    /** Describes how many back issues are additionally unlocked, period is starting before publication date of current issue as of purchase date. */
    additionalUnlocks: AppSubscriptionAdditionalBackIssueUnlocks;
    /** Current or latest receipt for this subscription. If subscription lapsed it will still provide information about the last subscription period */
    currentReceiptInfo?: Maybe<CurrentReceiptInfo>;
    description: Scalars['String'];
    duration: Duration;
    /** Information about eligibility for trials, introductory pricing and discount offers. */
    eligibilityInfo: EligibilityInfo;
    /** iOS only */
    groupId?: Maybe<Scalars['String']>;
    hidden: Scalars['Boolean'];
    /** Historic data for this subscription. Contains information about the complete history of the subscription. */
    historicReceiptInfo: HistoricReceiptInfo;
    id: Scalars['ID'];
    name: Scalars['String'];
    productId: Scalars['String'];
    properties: Property[];
    publications: Publication[];
    purchased: Scalars['Boolean'];
    /** Thumbnails, the default thumbnail has the kind 'default' */
    thumbnails: Thumbnail[];
    type: SubscriptionType;
    unlocksAllContentDuringPeriod: Scalars['Boolean'];
};

export type AppSubscriptionAdditionalBackIssueUnlocks = {
    __typename?: 'AppSubscriptionAdditionalBackIssueUnlocks';
    count: Scalars['Int'];
    unit: AppSubscriptionAdditionalBackIssueUnlocksUnit;
};

export enum AppSubscriptionAdditionalBackIssueUnlocksUnit {
    Day = 'DAY',
    Month = 'MONTH',
    Week = 'WEEK',
    Year = 'YEAR',
}

/** The app is not available (anymore). It or the team may be deactivated. Additional information may be available in the reason field. */
export type AppUnavailable = {
    __typename?: 'AppUnavailable';
    reason: Scalars['String'];
};

/** The apps version is outdated and an update is required. */
export type AppUpdateRequired = {
    __typename?: 'AppUpdateRequired';
    /** The minimal required version of the app. */
    requiredVersion: Scalars['String'];
    /** The location where an updated version can be found. */
    updateUrl: Scalars['String'];
};

export type AppWebConfiguration = {
    __typename?: 'AppWebConfiguration';
    id: Scalars['ID'];
    loginType: LoginType;
};

export enum AssignLocalPurchasesError {
    /** The provided account token is not valid */
    InvalidAccountToken = 'INVALID_ACCOUNT_TOKEN',
    /** An unknown error occurred */
    Unknown = 'UNKNOWN',
    /** The provided account token is not in a supported format */
    UnsupportedAccountToken = 'UNSUPPORTED_ACCOUNT_TOKEN',
}

export type AssignLocalPurchasesResult = {
    __typename?: 'AssignLocalPurchasesResult';
    errorCode?: Maybe<AssignLocalPurchasesError>;
    errorMessage?: Maybe<Scalars['String']>;
    success: Scalars['Boolean'];
};

export enum AssignmentMode {
    /** Assign purchases to the account while keeping any previous assignments. */
    Assign = 'ASSIGN',
    /** Move all purchases to the new account. Previously assigned accounts will be removed. */
    Reassign = 'REASSIGN',
    /** Only assign the purchases which are not yet assigned to another account. */
    Unassigned = 'UNASSIGNED',
}

/** The author of a Post or Bundle. */
export type Author = {
    __typename?: 'Author';
    /** An optional email of the author. */
    email?: Maybe<Scalars['String']>;
    /** Name of the author. */
    name: Scalars['String'];
};

/** Input type for authorization data. */
export type Authorization = {
    /** Entitlement access token, optional */
    accessToken?: InputMaybe<Scalars['String']>;
    /** Access token for account, needs to be a JWT, optional */
    accountAccessToken?: InputMaybe<Scalars['String']>;
    /** Subscription codes, optional */
    subscriptionCodes?: InputMaybe<Scalars['String'][]>;
};

/** Filter based on authors and their properties. */
export type AuthorsFilter = {
    email?: InputMaybe<StringFilter>;
    name?: InputMaybe<StringFilter>;
    negated?: InputMaybe<Scalars['Boolean']>;
    operation?: InputMaybe<ListOperation>;
};

export type BooleanFilter = {
    value: Scalars['Boolean'];
};

/** A bundle is an ordered collection of posts. */
export type Bundle = Content & {
    __typename?: 'Bundle';
    access: Access;
    alias?: Maybe<Scalars['String']>;
    authors: Author[];
    categories: Scalars['String'][];
    contents: BundledContent[];
    description: Scalars['String'];
    externalId?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    index: Scalars['Int'];
    name: Scalars['String'];
    /** Product ID for single purchases for requesting platform */
    productId?: Maybe<Scalars['String']>;
    /** Custom properties of content */
    properties: Property[];
    publication: Publication;
    /** Date of publication in ISO8601 format */
    publicationDate: Scalars['String'];
    /** Purchase state information */
    purchaseData: ContentPurchaseData;
    tags: Scalars['String'][];
    taxonomies: Taxonomy[];
    thumbnails: Thumbnail[];
    version: Scalars['Int'];
};

export type BundleSearchResult = {
    __typename?: 'BundleSearchResult';
    bundle: Bundle;
    posts: PostSearchResult[];
};

/** This type holds a reference to a Post of a Bundle. */
export type BundledContent = {
    __typename?: 'BundledContent';
    content: Post;
    id: Scalars['ID'];
};

/** Wrapper type for Catalog metadata */
export type Catalog = {
    __typename?: 'Catalog';
    /** List of all active purchase options for this app */
    activePurchases: PurchaseOption[];
    /** The metadata of the requested app */
    app: App;
    /**
     * Curated Collections of the app, with support for filtering, sorting and paging.
     *
     * Follows the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm).
     */
    collectionsConnection: CatalogCollectionsConnection;
    /**
     * Search for contents by search phrase and additional filters
     *
     * Follows the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm).
     */
    contentSearchConnection: CatalogSearchConnection;
    /**
     * Contents of the app, with support for filtering, paging and sorting.
     *
     * Follows the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm).
     */
    contentsConnection: CatalogContentsConnection;
    /**
     * Publications of the app, with support for filtering, paging and sorting.
     *
     * Follows the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm).
     */
    menusConnection: CatalogMenusConnection;
    /**
     * Product of all publications of the app, with support for filtering and paging.
     *
     * Follows the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm).
     */
    publicationProductsConnection: CatalogPublicationProductsConnection;
    /**
     * Publications of the app, with support for filtering, paging and sorting.
     *
     * Follows the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm).
     */
    publicationsConnection: CatalogPublicationsConnection;
    /**
     * Subscriptions of the app, with support for filtering and paging.
     *
     * Follows the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm).
     */
    subscriptionsConnection: CatalogSubscriptionsConnection;
    /**
     * Taxonomies of the app.
     *
     * Follows the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm).
     */
    taxonomiesConnection: CatalogTaxonomiesConnection;
};

/** Wrapper type for Catalog metadata */
export type CatalogCollectionsConnectionArgs = {
    after?: InputMaybe<Scalars['String']>;
    filter?: InputMaybe<CollectionFilter>;
    first?: InputMaybe<Scalars['Int']>;
    sort?: InputMaybe<CollectionComparator[]>;
};

/** Wrapper type for Catalog metadata */
export type CatalogContentSearchConnectionArgs = {
    after?: InputMaybe<Scalars['String']>;
    contentFilter?: InputMaybe<ContentFilter>;
    findAllWords?: InputMaybe<Scalars['Boolean']>;
    first?: InputMaybe<Scalars['Int']>;
    fuzzyMatching?: InputMaybe<Scalars['Boolean']>;
    searchPhrase: Scalars['String'];
    sort?: InputMaybe<ContentSearchResultComparator[]>;
    searchFields?: InputMaybe<Scalars['String'][]>;
};

/** Wrapper type for Catalog metadata */
export type CatalogContentsConnectionArgs = {
    after?: InputMaybe<Scalars['String']>;
    filter?: InputMaybe<ContentFilter>;
    first?: InputMaybe<Scalars['Int']>;
    sort?: InputMaybe<ContentComparator[]>;
};

/** Wrapper type for Catalog metadata */
export type CatalogMenusConnectionArgs = {
    after?: InputMaybe<Scalars['String']>;
    filter?: InputMaybe<MenuFilter>;
    first?: InputMaybe<Scalars['Int']>;
};

/** Wrapper type for Catalog metadata */
export type CatalogPublicationProductsConnectionArgs = {
    after?: InputMaybe<Scalars['String']>;
    filter?: InputMaybe<PublicationProductFilter>;
    first?: InputMaybe<Scalars['Int']>;
    sort?: InputMaybe<PublicationProductComparator[]>;
};

/** Wrapper type for Catalog metadata */
export type CatalogPublicationsConnectionArgs = {
    after?: InputMaybe<Scalars['String']>;
    filter?: InputMaybe<PublicationFilter>;
    first?: InputMaybe<Scalars['Int']>;
    sort?: InputMaybe<PublicationComparator[]>;
};

/** Wrapper type for Catalog metadata */
export type CatalogSubscriptionsConnectionArgs = {
    after?: InputMaybe<Scalars['String']>;
    filter?: InputMaybe<SubscriptionFilter>;
    first?: InputMaybe<Scalars['Int']>;
    sort?: InputMaybe<SubscriptionComparator[]>;
};

/** Wrapper type for Catalog metadata */
export type CatalogTaxonomiesConnectionArgs = {
    after?: InputMaybe<Scalars['String']>;
    filter?: InputMaybe<TaxonomyFilter>;
    first?: InputMaybe<Scalars['Int']>;
    sort?: InputMaybe<TaxonomyComparator[]>;
};

export type CatalogCollectionElementsConnection = {
    __typename?: 'CatalogCollectionElementsConnection';
    edges: CatalogCollectionElementsConnectionEdge[];
    pageInfo: PageInfo;
    /** The total count without paging */
    totalCount: Scalars['Int'];
};

export type CatalogCollectionElementsConnectionEdge = {
    __typename?: 'CatalogCollectionElementsConnectionEdge';
    cursor: Scalars['String'];
    node: CollectionElement;
};

export type CatalogCollectionsConnection = {
    __typename?: 'CatalogCollectionsConnection';
    edges: CatalogCollectionsConnectionEdge[];
    pageInfo: PageInfo;
    /** The total count without paging */
    totalCount: Scalars['Int'];
};

export type CatalogCollectionsConnectionEdge = {
    __typename?: 'CatalogCollectionsConnectionEdge';
    cursor: Scalars['String'];
    node: Collection;
};

export type CatalogContentsConnection = {
    __typename?: 'CatalogContentsConnection';
    edges: CatalogContentsConnectionEdge[];
    pageInfo: PageInfo;
    /** The total count without paging */
    totalCount: Scalars['Int'];
};

export type CatalogContentsConnectionEdge = {
    __typename?: 'CatalogContentsConnectionEdge';
    cursor: Scalars['String'];
    node: Content;
};

export type CatalogMenusConnection = {
    __typename?: 'CatalogMenusConnection';
    edges: CatalogMenusConnectionEdge[];
    pageInfo: PageInfo;
    /** The total count without paging */
    totalCount: Scalars['Int'];
};

export type CatalogMenusConnectionEdge = {
    __typename?: 'CatalogMenusConnectionEdge';
    cursor: Scalars['String'];
    node: Menu;
};

export type CatalogPublicationProductsConnection = {
    __typename?: 'CatalogPublicationProductsConnection';
    edges: CatalogPublicationProductsConnectionEdge[];
    pageInfo: PageInfo;
    /** The total count without paging */
    totalCount: Scalars['Int'];
};

export type CatalogPublicationProductsConnectionEdge = {
    __typename?: 'CatalogPublicationProductsConnectionEdge';
    cursor: Scalars['String'];
    node: PublicationProduct;
};

export type CatalogPublicationsConnection = {
    __typename?: 'CatalogPublicationsConnection';
    edges: CatalogPublicationsConnectionEdge[];
    pageInfo: PageInfo;
    /** The total count without paging */
    totalCount: Scalars['Int'];
};

export type CatalogPublicationsConnectionEdge = {
    __typename?: 'CatalogPublicationsConnectionEdge';
    cursor: Scalars['String'];
    node: Publication;
};

export type CatalogSearchConnection = {
    __typename?: 'CatalogSearchConnection';
    edges: CatalogSearchConnectionEdge[];
    pageInfo: PageInfo;
    /** The total count without paging */
    totalCount: Scalars['Int'];
};

export type CatalogSearchConnectionEdge = {
    __typename?: 'CatalogSearchConnectionEdge';
    cursor: Scalars['String'];
    node: ContentSearchResult;
};

export type CatalogSubscriptionsConnection = {
    __typename?: 'CatalogSubscriptionsConnection';
    edges: CatalogSubscriptionsConnectionEdge[];
    pageInfo: PageInfo;
    /** The total count without paging */
    totalCount: Scalars['Int'];
};

export type CatalogSubscriptionsConnectionEdge = {
    __typename?: 'CatalogSubscriptionsConnectionEdge';
    cursor: Scalars['String'];
    node: AppSubscription;
};

export type CatalogTaxonomiesConnection = {
    __typename?: 'CatalogTaxonomiesConnection';
    edges: CatalogTaxonomiesConnectionEdge[];
    pageInfo: PageInfo;
    /** The total count without paging */
    totalCount: Scalars['Int'];
};

export type CatalogTaxonomiesConnectionEdge = {
    __typename?: 'CatalogTaxonomiesConnectionEdge';
    cursor: Scalars['String'];
    node: Taxonomy;
};

/** A Collection is a manually curated, ordered list of contents. */
export type Collection = {
    __typename?: 'Collection';
    elementsConnection: CatalogCollectionElementsConnection;
    id: Scalars['ID'];
    name: Scalars['String'];
    properties: Property[];
};

/** A Collection is a manually curated, ordered list of contents. */
export type CollectionElementsConnectionArgs = {
    after?: InputMaybe<Scalars['String']>;
    first?: InputMaybe<Scalars['Int']>;
};

/** All properties are mutually-exclusive */
export type CollectionComparator = {
    /** Sort collections by their name */
    name?: InputMaybe<StringComparator>;
    /** Sort collections by a custom property */
    property?: InputMaybe<PropertyComparator>;
};

/** Union of all possible types of Collection elements. Currently only contents are supported. */
export type CollectionElement = ContentElement;

/** Filter for Collections. */
export type CollectionFilter = {
    /** Combine multiple CollectionFilter using a logical AND operation */
    AND?: InputMaybe<CollectionFilter[]>;
    /** Combine multiple CollectionFilter using a logical OR operation */
    OR?: InputMaybe<CollectionFilter[]>;
    /** Filter based on the id of the collection */
    id?: InputMaybe<StringFilter>;
    /** Filter based on the name of the collection */
    name?: InputMaybe<StringFilter>;
    /** Filter based on the custom properties of the collection */
    properties?: InputMaybe<MapFilter>;
};

/** Options  */
export type ConsumeOptions = {
    /** List of IDs of contents which should be entitled with this purchase. */
    contentIds?: Scalars['String'][];
    /** The transaction that should entitle the user to contents. */
    transactionId: Scalars['String'];
};

/**
 * Base interface for different type of contents.
 * Currently supported types are:
 * * Issue
 * * Post
 *
 * See the corresponding types for more information.
 */
export type Content = {
    access: Access;
    alias?: Maybe<Scalars['String']>;
    categories: Scalars['String'][];
    description: Scalars['String'];
    externalId?: Maybe<Scalars['String']>;
    /** Globally unique identifier (UUID) for the content. */
    id: Scalars['ID'];
    index: Scalars['Int'];
    name: Scalars['String'];
    /** Product ID for single purchases for requesting platform */
    productId?: Maybe<Scalars['String']>;
    /** Custom properties of content */
    properties: Property[];
    publication: Publication;
    /** Date of publication in ISO8601 format */
    publicationDate: Scalars['String'];
    /** Purchase state information */
    purchaseData: ContentPurchaseData;
    tags: Scalars['String'][];
    taxonomies: Taxonomy[];
    thumbnails: Thumbnail[];
    version: Scalars['Int'];
};

/** A block of content of a Post. Can be used to render specific parts of articles in different ways depending on the frontend. */
export type ContentBlock = {
    __typename?: 'ContentBlock';
    children: Scalars['ID'][];
    html: Scalars['String'];
    htmlList: Maybe<Scalars['String']>[];
    id: Scalars['ID'];
    level: Scalars['Int'];
    parentId?: Maybe<Scalars['ID']>;
    properties: Property[];
    sequence: Scalars['Int'];
    type: Scalars['String'];
};

/** All properties are mutually-exclusive */
export type ContentComparator = {
    /** Sort contents by their index */
    index?: InputMaybe<IntComparator>;
    /** Sort contents by their name */
    name?: InputMaybe<StringComparator>;
    /** Sort contents by a custom property */
    property?: InputMaybe<PropertyComparator>;
    /** Sort contents by their publication date */
    publicationDate?: InputMaybe<DateComparator>;
};

/** An element in a curated Collection with a reference to a specific content. */
export type ContentElement = {
    __typename?: 'ContentElement';
    content?: Maybe<Content>;
    id: Scalars['ID'];
};

/** All properties are mutually-exclusive */
export type ContentFilter = {
    /** Combine multiple ContentFilter using a logical AND operation */
    AND?: InputMaybe<ContentFilter[]>;
    /** Combine multiple ContentFilter using a logical OR operation */
    OR?: InputMaybe<ContentFilter[]>;
    access?: InputMaybe<AccessFilter>;
    /** Filter based on the alias of the content */
    alias?: InputMaybe<StringFilter>;
    /** Filter based on the authors of the content */
    authors?: InputMaybe<AuthorsFilter>;
    /** Filter based on the categories of the content */
    categories?: InputMaybe<StringListFilter>;
    /** Filter based on the type of the content */
    contentType?: InputMaybe<ContentTypeFilter>;
    /** Filter based on the description of the content */
    description?: InputMaybe<StringFilter>;
    /** Filter based on the externalId of the content */
    externalId?: InputMaybe<StringFilter>;
    /** Filter based on the ID of the content */
    id?: InputMaybe<StringFilter>;
    /** Filter based on the name of the content */
    name?: InputMaybe<StringFilter>;
    /** Filter based on the post type of the content */
    postType?: InputMaybe<StringFilter>;
    /** Filter based on the productId of the content */
    productId?: InputMaybe<StringFilter>;
    /** Filter based on the custom properties of the content */
    properties?: InputMaybe<MapFilter>;
    /** Filter based on the publication of the content */
    publication?: InputMaybe<PublicationFilter>;
    /** Filter based on the publication date of the content */
    publicationDate?: InputMaybe<DateFilter>;
    /** Filter based on purchase status of the issue */
    purchased?: InputMaybe<BooleanFilter>;
    /** Filter based on the tags of the content */
    tags?: InputMaybe<StringListFilter>;
    /** Filter based on the taxonomies of the content */
    taxonomies?: InputMaybe<TaxonomyListFilter>;
};

export type ContentListContentFilter = {
    operation?: InputMaybe<ListOperation>;
    value: ContentFilter;
};

/** Either content or size must be provided. */
export type ContentListFilter = {
    content?: InputMaybe<ContentListContentFilter>;
    negated?: InputMaybe<Scalars['Boolean']>;
    size?: InputMaybe<IntFilter>;
};

/** Holds information about the purchase state of a content. */
export type ContentPurchaseData = {
    __typename?: 'ContentPurchaseData';
    /** The content is free, has been purchased or unlocked by entitlement */
    purchased: Scalars['Boolean'];
    /** The options used for purchase/unlocking of the content */
    purchasedBy: PurchaseOption[];
};

export type ContentSearchResult =
    | BundleSearchResult
    | IssueSearchResult
    | PostSearchResult;

export type ContentSearchResultComparator = {
    /** Sort contents by their publication date */
    publicationDate?: InputMaybe<DateComparator>;
    /** Sort contents by their relevance */
    relevance?: InputMaybe<RelevanceComparator>;
};

/** The available types of the content. */
export enum ContentType {
    /** Bundles are collections of Posts and are also produced using our Purple Hub.  */
    Bundle = 'BUNDLE',
    /** Issues are magazine style contents. They usually have multiple pages and use our Storytelling Engine and can display animations, PDFs and other media. */
    Issue = 'ISSUE',
    /** Posts are (news) article contents produced using the Purple Hub. */
    Post = 'POST',
}

/** Filter based on the type of content. */
export type ContentTypeFilter = {
    negated?: InputMaybe<Scalars['Boolean']>;
    value?: InputMaybe<ContentType>;
};

/** A product that unlocks previously published contents when purchased. */
export type ContentsCompletionProduct = PublicationProduct & {
    __typename?: 'ContentsCompletionProduct';
    description: Scalars['String'];
    hidden: Scalars['Boolean'];
    id: Scalars['ID'];
    /**
     * Flag determining if the latest content of the assigned publications are included.
     * The publication date of all contents of all publications is used to calculate the latest content.
     */
    includesLatestContent: Scalars['Boolean'];
    index: Scalars['Int'];
    name: Scalars['String'];
    productId: Scalars['String'];
    properties: Property[];
    publications: PublicationProductPublication[];
    /** Specific field for ContentsCompletionProduct */
    purchased: Scalars['Boolean'];
    /** Thumbnails, the default thumbnail has the kind 'default' */
    thumbnails: Thumbnail[];
    type: PublicationProductType;
};

export type CurrentReceiptInfo = {
    __typename?: 'CurrentReceiptInfo';
    /** When false the subscription was cancelled by the user and will end after the current subscription period. */
    autoRenewing: Scalars['Boolean'];
    /** For paused subscription, the date when the subscription would resume, in ISO8601 format. NOTE: A paused subscription will have purchased set to false and a non-null value of autoResumeDate. */
    autoResumeDate?: Maybe<Scalars['String']>;
    /** When purchased, the date of expiration for the current period, in ISO8601 format */
    expirationDate: Scalars['String'];
    /** Current subscription period is a introductory pricing period. */
    isIntroOfferPeriod: Scalars['Boolean'];
    /** Current subscription period is a trial period. */
    isTrialPeriod: Scalars['Boolean'];
};

/** Compares date values */
export type DateComparator = {
    direction: Direction;
};

export type DateFilter = {
    negated?: InputMaybe<Scalars['Boolean']>;
    operation?: InputMaybe<DateOperation>;
    /**
     * A date to compare to.\
     * The month, day and time components are optional. Examples:\
     * "2019" => matches everything in 2019 (whole year)\
     * "2019-12" => matches everything in December 2019 (whole month)\
     * "2019-12-31" => matches everything from the 31th of December 2019 (whole day)\
     * "2019-12-31T14:10" => matches everything from the 31th of December 2019, at 14:10 (whole minute)\
     * "2019-12-31T14:10:20" => matches everything from the 31th of December 2019, at 14:10:20
     */
    value: Scalars['String'];
};

export enum DateOperation {
    Equal = 'EQUAL',

    GreaterThan = 'GREATER_THAN',

    GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL',

    LessThan = 'LESS_THAN',

    LessThanOrEqual = 'LESS_THAN_OR_EQUAL',
}

/** Input type for metadata from the requesting device. */
export type DeviceInfo = {
    /** A unique and persistent ID from the device. */
    deviceId: Scalars['String'];
    /** Model name of the device. */
    deviceModel: Scalars['String'];
    /** OS version of the device. */
    deviceOs: Scalars['String'];
    /** The current locale of the device. */
    locale: Scalars['String'];
    /** The platform of the device. */
    platform: Platform;
    /** Android only: Value of smallestScreenWidthDp to determine device class (tablet or phone). */
    smallestScreenWidthDp?: InputMaybe<Scalars['Int']>;
};

/** Available sorting directions. */
export enum Direction {
    Asc = 'ASC',
    Desc = 'DESC',
}

export enum Duration {
    OneMonth = 'ONE_MONTH',
    OneYear = 'ONE_YEAR',
    SevenDays = 'SEVEN_DAYS',
    SixMonths = 'SIX_MONTHS',
    ThreeMonths = 'THREE_MONTHS',
    TwoMonths = 'TWO_MONTHS',
}

export type EligibilityInfo = {
    __typename?: 'EligibilityInfo';
    /** Is user eligible for discounted pricing for this subscription. Discounted pricing is only available when returned in store.getPrice-API (PriceInfo). */
    discountOffers: Scalars['Boolean'];
    /** Is user eligible for introductory pricing for this subscription. Introductory pricing is only available when returned in store.getPrice-API (PriceInfo). */
    introductoryPricing: Scalars['Boolean'];
    /** Is user eligible for trial periods. Returns true/false if the server knows that the user can/cannot use a trial or null if not enough data is available to determine eligibility. Trial is only available when returned in store.getPrice-API (PriceInfo). */
    trial?: Maybe<Scalars['Boolean']>;
};

/** The app is using a username/password-based entitlement. No additional information is available in this case. */
export type EntitlementConfig = {
    __typename?: 'EntitlementConfig';
    dummy?: Maybe<Scalars['String']>;
};

/** Error type for failed entitlement request. Contains a code and message with details about the error. */
export type EntitlementError = Error & {
    __typename?: 'EntitlementError';
    /** The cause of the error. */
    code: EntitlementErrorCode;
    /** Additional information about the error. */
    message: Scalars['String'];
};

/** All possible types of errors */
export enum EntitlementErrorCode {
    /** Error Code 4 */
    AuthenticationError = 'AUTHENTICATION_ERROR',
    /** Error Code 6 */
    InstallationLimitExceeded = 'INSTALLATION_LIMIT_EXCEEDED',
    /** Error Code 8 */
    ParameterErrorInRemoteSystem = 'PARAMETER_ERROR_IN_REMOTE_SYSTEM',
    /** Error Code 7 */
    SystemErrorInRemoteSystem = 'SYSTEM_ERROR_IN_REMOTE_SYSTEM',
    /** Error Code 1 */
    Unknown = 'UNKNOWN',
    /** Error Code 3 */
    UserDeactivated = 'USER_DEACTIVATED',
    /** Error Code 5 */
    WrongPassword = 'WRONG_PASSWORD',
    /** Error Code 2 */
    WrongPasswordOrUsername = 'WRONG_PASSWORD_OR_USERNAME',
}

export type EntitlementResult = EntitlementError | EntitlementUserData;

/** Contains access and refresh tokens as well as the roles for the logged in user. */
export type EntitlementUserData = {
    __typename?: 'EntitlementUserData';
    accessToken: Scalars['String'];
    refreshToken?: Maybe<Scalars['String']>;
    roles: Scalars['String'][];
};

/** Base interface for all types of errors. */
export type Error = {
    /** Additional information about the error. */
    message: Scalars['String'];
};

export type GooglePlayReceipt = {
    packageName: Scalars['String'];
    productId: Scalars['String'];
    purchaseToken: Scalars['String'];
    purchaseType: PurchaseType;
};

export type GooglePlayReceiptsResult = {
    __typename?: 'GooglePlayReceiptsResult';
    hasErrors: Scalars['Boolean'];
    receipts: ValidatedGooglePlayReceipt[];
};

export type HistoricReceiptInfo = {
    __typename?: 'HistoricReceiptInfo';
    /** User has previously used introductory pricing for this subscription. */
    hadIntroductoryPricing: Scalars['Boolean'];
    /** User has purchased this subscription in the past. */
    hadPurchased: Scalars['Boolean'];
    /** User has previously used a trial for this subscription. */
    hadTrial: Scalars['Boolean'];
};

/** Compares integer values */
export type IntComparator = {
    direction: Direction;
};

export type IntFilter = {
    negated?: InputMaybe<Scalars['Boolean']>;
    operation?: InputMaybe<IntOperation>;
    value: Scalars['Int'];
};

export enum IntOperation {
    Equal = 'EQUAL',
    GreaterThan = 'GREATER_THAN',
    LessThan = 'LESS_THAN',
}

/**
 * An issue is a magazine-style content, commonly based on Purple Storytelling Engine with
 * support for complex animations, media (audio and video) and PDFs.
 */
export type Issue = Content & {
    __typename?: 'Issue';
    access: Access;
    alias?: Maybe<Scalars['String']>;
    categories: Scalars['String'][];
    /** Size of all resources in bytes */
    contentLength: Scalars['Long'];
    description: Scalars['String'];
    externalId?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    index: Scalars['Int'];
    name: Scalars['String'];
    numberOfPages: Scalars['Int'];
    /** Size of all previewResources in bytes */
    previewContentLength?: Maybe<Scalars['Long']>;
    previewResources: Resource[];
    /** Product ID for single purchases for requesting platform */
    productId?: Maybe<Scalars['String']>;
    /** Custom properties of content */
    properties: Property[];
    publication: Publication;
    /** Date of publication in ISO8601 format */
    publicationDate: Scalars['String'];
    /** Purchase state information */
    purchaseData: ContentPurchaseData;
    resources: Resource[];
    tags: Scalars['String'][];
    taxonomies: Taxonomy[];
    thumbnails: Thumbnail[];
    version: Scalars['Int'];
};

export type IssuePageComparator = {
    /** Sort pages by their index */
    index?: InputMaybe<IntComparator>;
    /** Sort pages by their relevance */
    relevance?: InputMaybe<RelevanceComparator>;
};

export type IssuePageResult = {
    __typename?: 'IssuePageResult';
    elementAlias?: Maybe<Scalars['String']>;
    excerpt: Scalars['String'];
    pageIndex?: Maybe<Scalars['Int']>;
    pageLabel?: Maybe<Scalars['String']>;
    pageNumber?: Maybe<Scalars['Int']>;
    pageTitle?: Maybe<Scalars['String']>;
};

export type IssueSearchResult = {
    __typename?: 'IssueSearchResult';
    issue: Issue;
    pagesConnection: IssueSearchResultPagesConnection;
};

export type IssueSearchResultPagesConnectionArgs = {
    after?: InputMaybe<Scalars['String']>;
    first?: InputMaybe<Scalars['Int']>;
    sort?: InputMaybe<IssuePageComparator[]>;
};

export type IssueSearchResultPagesConnection = {
    __typename?: 'IssueSearchResultPagesConnection';
    edges: IssueSearchResultPagesConnectionEdge[];
    pageInfo: PageInfo;
    /** The total count without paging */
    totalCount: Scalars['Int'];
};

export type IssueSearchResultPagesConnectionEdge = {
    __typename?: 'IssueSearchResultPagesConnectionEdge';
    cursor: Scalars['String'];
    node: IssuePageResult;
};

export type ItunesPromoOfferSignatureResult = {
    __typename?: 'ItunesPromoOfferSignatureResult';
    keyIdentifier: Scalars['String'];
    nonce: Scalars['String'];
    signature: Scalars['String'];
    timestamp: Scalars['String'];
};

export type ItunesReceiptsResult = {
    __typename?: 'ItunesReceiptsResult';
    success: Scalars['Boolean'];
};

export enum ListOperation {
    Contains = 'CONTAINS',
    ContainsOnly = 'CONTAINS_ONLY',
}

export enum LoginType {
    Coupon = 'COUPON',
    CouponOptional = 'COUPON_OPTIONAL',
    Entitlement = 'ENTITLEMENT',
    EntitlementOauth = 'ENTITLEMENT_OAUTH',
    EntitlementOauthOptional = 'ENTITLEMENT_OAUTH_OPTIONAL',
    EntitlementOptional = 'ENTITLEMENT_OPTIONAL',
    None = 'NONE',
}

export type MapFilter = {
    key: Scalars['String'];
    keyOperation?: InputMaybe<StringOperation>;
    negated?: InputMaybe<Scalars['Boolean']>;
    operation?: InputMaybe<StringOperation>;
    value: Scalars['String'];
};

/** A menu of an app. */
export type Menu = {
    __typename?: 'Menu';
    id: Scalars['ID'];
    items: MenuItem[];
    name?: Maybe<Scalars['String']>;
    properties: Property[];
};

/** All properties are mutually-exclusive */
export type MenuFilter = {
    /** Combine multiple MenuFilter using a logical AND operation */
    AND?: InputMaybe<MenuFilter[]>;
    /** Combine multiple MenuFilter using a logical OR operation */
    OR?: InputMaybe<MenuFilter[]>;
    /** Filter based on the name of the menu */
    name?: InputMaybe<StringFilter>;
    /** Filter based on the custom properties of the menu */
    properties?: InputMaybe<MapFilter>;
};

/** An item in the a menu. */
export type MenuItem = {
    __typename?: 'MenuItem';
    id: Scalars['ID'];
    name: Scalars['String'];
    parentId?: Maybe<Scalars['ID']>;
    properties: Property[];
    sortIndex: Scalars['Int'];
    url: Scalars['String'];
};

export type Mutation = {
    __typename?: 'Mutation';
    /** Assign purchases made on a device to an account. */
    assignLocalPurchases: AssignLocalPurchasesResult;
    /** Upload receipts of purchases made via Google Play. */
    googlePlayReceipts: GooglePlayReceiptsResult;
    /** Generate a signature for an Apple iTunes promotional offer. */
    itunesPromoOfferSignature: ItunesPromoOfferSignatureResult;
    /** Upload a current receipt from Apple iTunes with all purchases of the device / user. */
    itunesReceipts: ItunesReceiptsResult;
    /** Perform an entitlement login using the provided username and optionally password. */
    login: EntitlementResult;
    /** Logout the user using an access token and optionally refresh token. */
    logout: Scalars['Boolean'];
    /** Refresh a OAuth 2.0-based login using the refresh token. */
    refreshAccessToken: EntitlementResult;
    /** Validate a list of subscription (coupon) codes. */
    subscriptionCodes: SubscriptionCodesResult;
    /** Verify that a login is still valid. */
    verify: EntitlementResult;
};

export type MutationAssignLocalPurchasesArgs = {
    accountAccessToken: Scalars['String'];
    appInfo: AppInfo;
    assignmentMode: AssignmentMode;
    deviceInfo: DeviceInfo;
};

export type MutationGooglePlayReceiptsArgs = {
    appInfo: AppInfo;
    authorization?: InputMaybe<Authorization>;
    consumeOptions?: InputMaybe<ConsumeOptions>;
    deviceInfo: DeviceInfo;
    receipts: GooglePlayReceipt[];
};

export type MutationItunesPromoOfferSignatureArgs = {
    appInfo: AppInfo;
    applicationUsername?: InputMaybe<Scalars['String']>;
    deviceInfo: DeviceInfo;
    offerId: Scalars['String'];
    productId: Scalars['String'];
};

export type MutationItunesReceiptsArgs = {
    appInfo: AppInfo;
    authorization?: InputMaybe<Authorization>;
    consumeOptions?: InputMaybe<ConsumeOptions>;
    deviceInfo: DeviceInfo;
    receiptData: Scalars['String'];
};

export type MutationLoginArgs = {
    appInfo: AppInfo;
    deviceInfo: DeviceInfo;
    password?: InputMaybe<Scalars['String']>;
    username: Scalars['String'];
};

export type MutationLogoutArgs = {
    accessToken: Scalars['String'];
    appInfo: AppInfo;
    deviceInfo: DeviceInfo;
    refreshToken?: InputMaybe<Scalars['String']>;
};

export type MutationRefreshAccessTokenArgs = {
    appInfo: AppInfo;
    deviceInfo: DeviceInfo;
    refreshToken: Scalars['String'];
};

export type MutationSubscriptionCodesArgs = {
    appInfo: AppInfo;
    codes: Scalars['String'][];
    deviceInfo: DeviceInfo;
};

export type MutationVerifyArgs = {
    accessToken: Scalars['String'];
    appInfo: AppInfo;
    deviceInfo: DeviceInfo;
};

/** The app is using an OAuth 2.0 entitlement. The url to start the login is available in the loginUrl field. */
export type OAuthConfig = {
    __typename?: 'OAuthConfig';
    /** The url to start the login flow. */
    loginUrl: Scalars['String'];
};

export type PageInfo = {
    __typename?: 'PageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    startCursor?: Maybe<Scalars['String']>;
};

/** The available platforms for clients */
export enum Platform {
    Android = 'ANDROID',
    Ios = 'IOS',
    Web = 'WEB',
}

/** A post is an newspaper or blog article-style content. It uses HTML to display its contents. */
export type Post = Content & {
    __typename?: 'Post';
    access: Access;
    alias?: Maybe<Scalars['String']>;
    authors: Author[];
    bundleId?: Maybe<Scalars['ID']>;
    categories: Scalars['String'][];
    content: ContentBlock[];
    contentHtml: Scalars['String'];
    /** Size of all resources in bytes */
    contentLength: Scalars['Long'];
    description: Scalars['String'];
    externalId?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    index: Scalars['Int'];
    name: Scalars['String'];
    postType: Scalars['String'];
    previewContentBlocks: ContentBlock[];
    previewContentHtml?: Maybe<Scalars['String']>;
    /** Size of all previewResources in bytes */
    previewContentLength?: Maybe<Scalars['Long']>;
    previewResources: Resource[];
    /** Product ID for single purchases for requesting platform */
    productId?: Maybe<Scalars['String']>;
    /** Custom properties of content */
    properties: Property[];
    publication: Publication;
    /** Date of publication in ISO8601 format */
    publicationDate: Scalars['String'];
    /** Purchase state information */
    purchaseData: ContentPurchaseData;
    resources: Resource[];
    tags: Scalars['String'][];
    taxonomies: Taxonomy[];
    thumbnails: Thumbnail[];
    version: Scalars['Int'];
};

export type PostSearchResult = {
    __typename?: 'PostSearchResult';
    excerpt: Scalars['String'];
    post: Post;
};

/** A property is a key-value-mapping a value type. */
export type Property = {
    __typename?: 'Property';
    key: Scalars['String'];
    type: PropertyValueType;
    value: Scalars['String'];
};

/** Compares custom property values */
export type PropertyComparator = {
    direction: Direction;
    key: Scalars['String'];
    valueType: PropertyValueType;
};

/** The type of a property's value. */
export enum PropertyValueType {
    Boolean = 'BOOLEAN',
    Float = 'FLOAT',
    Int = 'INT',
    Json = 'JSON',
    String = 'STRING',
    Timestamp = 'TIMESTAMP',
}

/**
 * The publication is a container for content.
 *
 * All contents are assigned to exactly one publication.
 */
export type Publication = {
    __typename?: 'Publication';
    /**
     * Contents of the publication, with support for filtering, paging and sorting.
     *
     * Follows the [GraphQL Cursor Connections Specification](https://relay.dev/graphql/connections.htm).
     */
    contentsConnection: CatalogContentsConnection;
    /** The ID of the latest for the requesting device / user available content (based on publicationDate) */
    currentContentId?: Maybe<Scalars['String']>;
    description: Scalars['String'];
    id: Scalars['ID'];
    index: Scalars['Int'];
    language?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    /** Properties of publication */
    properties: Property[];
    /** Subscriptions of this publication */
    subscriptionsConnection: CatalogSubscriptionsConnection;
    /** Thumbnails, the default thumbnail has the kind 'default' */
    thumbnails: Thumbnail[];
    /** Toc settings of publication */
    tocSettings: TocSettings;
    type: PublicationType;
};

/**
 * The publication is a container for content.
 *
 * All contents are assigned to exactly one publication.
 */
export type PublicationContentsConnectionArgs = {
    after?: InputMaybe<Scalars['String']>;
    filter?: InputMaybe<ContentFilter>;
    first?: InputMaybe<Scalars['Int']>;
    sort?: InputMaybe<ContentComparator[]>;
};

/**
 * The publication is a container for content.
 *
 * All contents are assigned to exactly one publication.
 */
export type PublicationSubscriptionsConnectionArgs = {
    after?: InputMaybe<Scalars['String']>;
    filter?: InputMaybe<SubscriptionFilter>;
    first?: InputMaybe<Scalars['Int']>;
    sort?: InputMaybe<SubscriptionComparator[]>;
};

/** All properties are mutually-exclusive */
export type PublicationComparator = {
    /** Sort publications by the publication date of the current content */
    currentContentPublicationDate?: InputMaybe<DateComparator>;
    /** Sort publications by their index */
    index?: InputMaybe<IntComparator>;
    /** Sort publications by a custom property */
    property?: InputMaybe<PropertyComparator>;
};

/** All properties are mutually-exclusive */
export type PublicationFilter = {
    /** Combine multiple PublicationFilter using a logical AND operation */
    AND?: InputMaybe<PublicationFilter[]>;
    /** Combine multiple PublicationFilter using a logical OR operation */
    OR?: InputMaybe<PublicationFilter[]>;
    /** Filter based on the contents of the publication */
    contents?: InputMaybe<ContentListFilter>;
    /** Filter based on the ID of the publication */
    id?: InputMaybe<StringFilter>;
    /** Filter based on the language of the publication */
    language?: InputMaybe<StringFilter>;
    /** Filter based on the name of the publication */
    name?: InputMaybe<StringFilter>;
    /** Filter based on the custom properties of the publication */
    properties?: InputMaybe<MapFilter>;
    /** Filter based on the type of the publication */
    type?: InputMaybe<PublicationTypeFilter>;
};

export type PublicationProduct = {
    description: Scalars['String'];
    /** A hidden product should not be displayed in the user interface. It will still be used to calculate purchased contents. */
    hidden: Scalars['Boolean'];
    id: Scalars['ID'];
    index: Scalars['Int'];
    name: Scalars['String'];
    productId: Scalars['String'];
    properties: Property[];
    /** Publications which this product is valid for. */
    publications: PublicationProductPublication[];
    /** Thumbnails, the default thumbnail has the kind 'default' */
    thumbnails: Thumbnail[];
    type: PublicationProductType;
};

/** All properties are mutually-exclusive */
export type PublicationProductComparator = {
    /** Sort publication products by their index */
    index?: InputMaybe<IntComparator>;
    /** Sort publication products by their name */
    name?: InputMaybe<StringComparator>;
    /** Sort publication products by a custom property */
    property?: InputMaybe<PropertyComparator>;
};

/** All properties are mutually-exclusive */
export type PublicationProductFilter = {
    /** Combine multiple BackIssueBundleProductsFilter using a logical AND operation */
    AND?: InputMaybe<PublicationProductFilter[]>;
    /** Combine multiple BackIssueBundleProductsFilter using a logical OR operation */
    OR?: InputMaybe<PublicationProductFilter[]>;
    /** Filter based on the description of the publication product */
    description?: InputMaybe<StringFilter>;
    /** Filter based on the hidden property of the publication product */
    hidden?: InputMaybe<BooleanFilter>;
    /** Filter based on the id of the publication product */
    id?: InputMaybe<StringFilter>;
    /** Filter based on the name of the publication product */
    name?: InputMaybe<StringFilter>;
    /** Filter based on the productId of the publication product */
    productId?: InputMaybe<StringFilter>;
    /** Filter based on the custom properties of the publication product */
    properties?: InputMaybe<MapFilter>;
    /** Filter based on purchase status of the publication product */
    purchased?: InputMaybe<BooleanFilter>;
    /** Filter based on the type of the publication product */
    type?: InputMaybe<PublicationProductTypeFilter>;
};

/** A reference to an assigned publication. */
export type PublicationProductPublication = {
    __typename?: 'PublicationProductPublication';
    id: Scalars['ID'];
    publication: Publication;
    /** This connection can be used to determine which contents can be unlocked with this product for this publication. */
    unlockableContentsConnection: PublicationProductPublicationUnlockableContentsConnection;
};

/** A reference to an assigned publication. */
export type PublicationProductPublicationUnlockableContentsConnectionArgs = {
    after?: InputMaybe<Scalars['String']>;
    contentFilter?: InputMaybe<ContentFilter>;
    first?: InputMaybe<Scalars['Int']>;
};

export type PublicationProductPublicationUnlockableContentsConnection = {
    __typename?: 'PublicationProductPublicationUnlockableContentsConnection';
    edges: PublicationProductPublicationUnlockableContentsConnectionEdge[];
    pageInfo: PageInfo;
    /** The total count without paging */
    totalCount: Scalars['Int'];
};

export type PublicationProductPublicationUnlockableContentsConnectionEdge = {
    __typename?: 'PublicationProductPublicationUnlockableContentsConnectionEdge';
    cursor: Scalars['String'];
    node: UnlockableContent;
};

/** All available types of publication products. */
export enum PublicationProductType {
    /** A product that unlocks previously published contents when purchased. */
    ContentsCompletion = 'CONTENTS_COMPLETION',
    /** A product which can be purchased multiple times.  */
    RepeatableContentPurchase = 'REPEATABLE_CONTENT_PURCHASE',
}

/** Filter based on the type of the product */
export type PublicationProductTypeFilter = {
    negated?: InputMaybe<Scalars['Boolean']>;
    /** The type to match */
    value?: InputMaybe<PublicationProductType>;
};

export enum PublicationType {
    /** Newsfeed publication */
    Channel = 'CHANNEL',
    /** Newsstand publication */
    Kiosk = 'KIOSK',
}

export type PublicationTypeFilter = {
    negated?: InputMaybe<Scalars['Boolean']>;
    value?: InputMaybe<PublicationType>;
};

/** Possible options of how content has been purchased. */
export enum PurchaseOption {
    /** A native app store purchase/subscription which has been assigned to an account. */
    Account = 'ACCOUNT',
    /** A native app store in-app purchase. */
    AppStorePurchase = 'APP_STORE_PURCHASE',
    /** A native app store in-app subscription. */
    AppStoreSubscription = 'APP_STORE_SUBSCRIPTION',
    /** Entitlement of a logged-in user. */
    Entitlement = 'ENTITLEMENT',
    PublicationProduct = 'PUBLICATION_PRODUCT',
    /** Subscription code / coupon code. */
    SubscriptionCode = 'SUBSCRIPTION_CODE',
}

export enum PurchaseType {
    Single = 'SINGLE',
    Subscription = 'SUBSCRIPTION',
}

/** Root type for GraphQL queries. This is the starting point for Catalog queries. */
export type Query = {
    __typename?: 'Query';
    /** Get the app configuration for a domain */
    appConfigurationForDomain?: Maybe<AppWebConfiguration>;
    /** Get the app configuration for a path */
    appConfigurationForPath?: Maybe<AppWebConfiguration>;
    /** Query the status and configuration of an app. */
    appStatus: AppStatus;
    /**
     * Entrypoint for Catalog queries.
     *
     * Inputs are as follows:
     * * appInfo: Which app data is being requested for.
     * * deviceInfo: Metadata about the requesting device.
     * * authorization: Authorization data from the device, to unlock hidden/purchased content.
     */
    catalog: Catalog;
};

/** Root type for GraphQL queries. This is the starting point for Catalog queries. */
export type QueryAppConfigurationForDomainArgs = {
    domainName: Scalars['String'];
};

/** Root type for GraphQL queries. This is the starting point for Catalog queries. */
export type QueryAppConfigurationForPathArgs = {
    path: Scalars['String'];
};

/** Root type for GraphQL queries. This is the starting point for Catalog queries. */
export type QueryAppStatusArgs = {
    appInfo: AppInfo;
    deviceInfo: DeviceInfo;
    firstStart?: InputMaybe<Scalars['Boolean']>;
};

/** Root type for GraphQL queries. This is the starting point for Catalog queries. */
export type QueryCatalogArgs = {
    appInfo: AppInfo;
    authorization?: InputMaybe<Authorization>;
    deviceInfo: DeviceInfo;
};

export type RelevanceComparator = {
    direction: Direction;
};

/**
 * A product which can be purchased multiple times.
 *
 * The apps will consume the purchase once the purchase has been acknowledged by the backend
 * via the googlePlayReceipts or itunesReceipts mutations.
 */
export type RepeatablePurchaseProduct = PublicationProduct & {
    __typename?: 'RepeatablePurchaseProduct';
    description: Scalars['String'];
    hidden: Scalars['Boolean'];
    id: Scalars['ID'];
    index: Scalars['Int'];
    name: Scalars['String'];
    productId: Scalars['String'];
    properties: Property[];
    publications: PublicationProductPublication[];
    /** Thumbnails, the default thumbnail has the kind 'default' */
    thumbnails: Thumbnail[];
    type: PublicationProductType;
};

/** Resources are additional files used in contents. They can be downloaded using the provided url. */
export type Resource = {
    __typename?: 'Resource';
    contentLength: Scalars['Long'];
    id: Scalars['ID'];
    properties: Property[];
    type: ResourceType;
    url: Scalars['String'];
};

/** Available type of resources. */
export enum ResourceType {
    /** Any other type of file. */
    Asset = 'ASSET',
    /** A PKAR content bundle file. */
    ContentBundle = 'CONTENT_BUNDLE',
}

export type SharingConfig = {
    __typename?: 'SharingConfig';
    appUrlTemplate: Scalars['String'];
    contentUrlTemplate: Scalars['String'];
};

/** Compares string values */
export type StringComparator = {
    direction: Direction;
};

export type StringFilter = {
    negated?: InputMaybe<Scalars['Boolean']>;
    operation?: InputMaybe<StringOperation>;
    value?: InputMaybe<Scalars['String']>;
};

export type StringListContentFilter = {
    operation?: InputMaybe<ListOperation>;
    value: StringFilter;
};

/** Either content or size must be provided. */
export type StringListFilter = {
    content?: InputMaybe<StringListContentFilter>;
    negated?: InputMaybe<Scalars['Boolean']>;
    size?: InputMaybe<IntFilter>;
};

export enum StringOperation {
    Contains = 'CONTAINS',
    Equal = 'EQUAL',
    GreaterThan = 'GREATER_THAN',
    GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL',
    LessThan = 'LESS_THAN',
    LessThanOrEqual = 'LESS_THAN_OR_EQUAL',
    Regex = 'REGEX',
    StartsWith = 'STARTS_WITH',
}

export type SubscriptionCodeResult = {
    __typename?: 'SubscriptionCodeResult';
    code: Scalars['String'];
    unlocksContent: Scalars['Boolean'];
    valid: Scalars['Boolean'];
};

export type SubscriptionCodesResult = {
    __typename?: 'SubscriptionCodesResult';
    codes: SubscriptionCodeResult[];
};

/** All properties are mutually-exclusive */
export type SubscriptionComparator = {
    /** Sort subscriptions by their index */
    index?: InputMaybe<IntComparator>;
    /** Sort subscriptions by a custom property */
    property?: InputMaybe<PropertyComparator>;
};

/** All properties are mutually-exclusive */
export type SubscriptionFilter = {
    /** Combine multiple SubscriptionFilter using a logical AND operation */
    AND?: InputMaybe<SubscriptionFilter[]>;
    /** Combine multiple SubscriptionFilter using a logical OR operation */
    OR?: InputMaybe<SubscriptionFilter[]>;
    /** Filter based on the product ID of the subscription */
    productId?: InputMaybe<StringFilter>;
    /** Filter based on the custom properties of the subscription */
    properties?: InputMaybe<MapFilter>;
    /** Filter based on the publication of the subscription */
    publication?: InputMaybe<PublicationFilter>;
    /** Filter based on purchase status of the subscription */
    purchased?: InputMaybe<BooleanFilter>;
};

export enum SubscriptionType {
    Autorenewable = 'AUTORENEWABLE',
    Free = 'FREE',
    Nonautorenewable = 'NONAUTORENEWABLE',
}

/** A taxonomy is a way to classify content, e.g. a category or a tag. */
export type Taxonomy = {
    __typename?: 'Taxonomy';
    /** Issues with this category, with optional sorting */
    contentsConnection: CatalogContentsConnection;
    id: Scalars['ID'];
    name: Scalars['String'];
    parentId?: Maybe<Scalars['String']>;
    properties: Property[];
    /** Thumbnails, the default thumbnail has the kind 'default' */
    thumbnails: Thumbnail[];
    /** The type of the taxonomy. Known values include: category, tag */
    type: Scalars['String'];
};

/** A taxonomy is a way to classify content, e.g. a category or a tag. */
export type TaxonomyContentsConnectionArgs = {
    after?: InputMaybe<Scalars['String']>;
    filter?: InputMaybe<ContentFilter>;
    first?: InputMaybe<Scalars['Int']>;
    sort?: InputMaybe<ContentComparator[]>;
};

/** All properties are mutually-exclusive */
export type TaxonomyComparator = {
    /** Sort taxonomies by their id */
    id?: InputMaybe<StringComparator>;
    /** Sort taxonomies by their name */
    name?: InputMaybe<StringComparator>;
    /** Sort taxonomies by a custom property */
    property?: InputMaybe<PropertyComparator>;
};

export type TaxonomyFilter = {
    /** Combine multiple TaxonomyFilter using a logical AND operation */
    AND?: InputMaybe<TaxonomyFilter[]>;
    /** Combine multiple TaxonomyFilter using a logical OR operation */
    OR?: InputMaybe<TaxonomyFilter[]>;
    /** Filter based on the contents of the taxonomy */
    contents?: InputMaybe<ContentListFilter>;
    /** Filter based on the ID of the taxonomy */
    id?: InputMaybe<StringFilter>;
    /** Filter based on the name of the taxonomy */
    name?: InputMaybe<StringFilter>;
    /** Filter based on the ID of the parent taxonomy */
    parentId?: InputMaybe<StringFilter>;
    /** Filter based on the custom properties of the taxonomy */
    properties?: InputMaybe<MapFilter>;
    /** Filter based on the type of the taxonomy */
    type?: InputMaybe<StringFilter>;
};

/** Filter based on the list of taxonomies of contents. */
export type TaxonomyListContentFilter = {
    operation?: InputMaybe<ListOperation>;
    /** Match taxonomies in the list using the TaxonomyFilter */
    value: TaxonomyFilter;
};

/**
 * Filter based on the taxonomies of contents.
 *
 * Either content or size must be provided.
 */
export type TaxonomyListFilter = {
    /** Filter based on the list of taxonomies of contents. */
    content?: InputMaybe<TaxonomyListContentFilter>;
    negated?: InputMaybe<Scalars['Boolean']>;
    /** Filter based on the amount of taxonomies of contents. */
    size?: InputMaybe<IntFilter>;
};

/** Type holder location and type of media elements. */
export type Thumbnail = {
    __typename?: 'Thumbnail';
    kind: Scalars['String'];
    properties: Property[];
    url: Scalars['String'];
};

/** Type holding configuration of the table of contents. */
export type TocSettings = {
    __typename?: 'TocSettings';
    /** Show page labels in the ToC. */
    pageLabelsEnabled: Scalars['Boolean'];
    /** The style of the ToC entries. */
    style: TocStyle;
};

/** The available styles of ToC entries. */
export enum TocStyle {
    /** Show larger version of the ToC entries. */
    Large = 'LARGE',
    /** Show small version of the ToC entries. */
    Small = 'SMALL',
}

/** A reference to content which can be unlocked with a publication product. */
export type UnlockableContent = {
    __typename?: 'UnlockableContent';
    id: Scalars['ID'];
    name: Scalars['String'];
    productId?: Maybe<Scalars['String']>;
    publicationDate: Scalars['String'];
    publicationId: Scalars['String'];
    /** Thumbnails, the default thumbnail has the kind 'default' */
    thumbnails: Thumbnail[];
};

export type ValidatedGooglePlayReceipt = {
    __typename?: 'ValidatedGooglePlayReceipt';
    /** sha256 of the purchase token */
    purchaseTokenHash: Scalars['String'];
    success: Scalars['Boolean'];
};
