import { BusinessIndicatorDto } from './dtos/business-indicator.dto';
import { CompanyAppointmentDto } from './dtos/company-appointment.dto';
import { FinancialDisplayDto } from './dtos/financialDisplay.dto';
import { FundDto } from './dtos/fund.dto';
import { SeminarDto } from './dtos/seminar.dto';

export type PaginatedResult<T> = {
    totalCount: number;
    page: number;
    items: T[];
};

export enum AppointmentType {
    BUSINESS_INDICATOR = '0-BUSINESS_INDICATOR',
    SEMINAR = '1-SEMINAR',
    COMPANY = '2-COMPANY',
}

export enum CompanyAppointmentType {
    REPORTS_FIGURES = '0-REPORTS_FIGURES',
    DIVIDENDS = '1-DIVIDENDS',
    GENERAL_MEETINGS = '2-GENERAL_MEETINGS',
    CORPORATE_ACTIONS = '3-CORPORATE_ACTIONS',
    EVENTS = '4-EVENTS',
}

export type PaginatedRequest = {
    page?: number;
    itemsPerPage?: number;
};

// needs to conform to YYYY-MM-DD
export type DateDef = string;

export type AppointmentSearchFilter = {
    type?: AppointmentType;
    dateFrom: DateDef;
    dateTo: DateDef;
    phrase?: string;
} & PaginatedRequest;

export type DpaAfxArticleSearchFilter = {
    companyId: string;
} & PaginatedRequest;

export type FundSearchFilter = {
    fundCompany?: string;
} & PaginatedRequest;

export type Top5UltimoFilter = {
    entries?: number;
};

export enum QueryOrder {
    ASC = 'ASC',
    ASC_NULLS_LAST = 'ASC NULLS LAST',
    ASC_NULLS_FIRST = 'ASC NULLS FIRST',
    DESC = 'DESC',
    DESC_NULLS_LAST = 'DESC NULLS LAST',
    DESC_NULLS_FIRST = 'DESC NULLS FIRST',
    asc = 'asc',
    asc_nulls_last = 'asc nulls last',
    asc_nulls_first = 'asc nulls first',
    desc = 'desc',
    desc_nulls_last = 'desc nulls last',
    desc_nulls_first = 'desc nulls first',
}

export type FinancialDisplaySearchFilter = {
    company?: string;
    dateFrom?: DateDef;
    dateTo?: DateDef;
    keyword?: string;
    sort?: Record<string, QueryOrder>;
} & PaginatedRequest;

export type AppointmentsByDate = Record<DateDef, AppointmentDto[]>;

export type FundsByCompany = Record<string, FundDto[]>;

export type AppointmentSearchResult = PaginatedResult<AppointmentDto>;

export type FundSearchResult = PaginatedResult<FundDto>;

export type FinancialDisplaySearchResult = PaginatedResult<FinancialDisplayDto>;

export type AppointmentDto = {
    id: string;
    type: AppointmentType;
    subType?: string;
    title: string;
    details: string;
    dateFrom: string;
    dateTo: string;
    data: SeminarDto | BusinessIndicatorDto | CompanyAppointmentDto;
};

export enum Rating {
    POSITIVE = 'POSITIVE',
    NEUTRAL = 'NEUTRAL',
    NEGATIVE = 'NEGATIVE',
}
