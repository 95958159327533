import { DpaRatingsDto } from '@boersenzeitung/shared/dtos/Dpa-afx-article.dto';
import React from 'react';

export const AnalysisDiagramm = ({
    rating,
    highestRating,
}: {
    rating: DpaRatingsDto;
    highestRating: number;
}) => {
    return (
        <>
            <div className="flex flex-col w-full px-2 md:px-0 md:w-1/3 space-y-2 text-lightgray">
                <div className="flex space-x-4">
                    <p className="min-w-[51px]">Positiv</p>
                    <div
                        className="bg-positiveGreen"
                        style={{
                            width: `${
                                rating.positive / (highestRating / 100)
                            }%`,
                        }}
                    />
                    <p className="!ml-8 grow text-right">{rating.positive}</p>
                </div>
                <div className="flex space-x-4">
                    <p className="min-w-[51px]">Neutral</p>
                    <div
                        className="bg-neutralBlue"
                        style={{
                            width: `${rating.neutral / (highestRating / 100)}%`,
                        }}
                    />
                    <p className="!ml-8 grow text-right">{rating.neutral}</p>
                </div>
                <div className="flex space-x-4">
                    <p className="min-w-[51px]">Negativ</p>
                    <div
                        className="bg-negativeRed"
                        style={{
                            width: `${
                                rating.negative / (highestRating / 100)
                            }%`,
                        }}
                    />
                    <p className="!ml-8 grow text-right">{rating.negative}</p>
                </div>
            </div>
        </>
    );
};
