import { Button } from '@components/button';
import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';

export function OverlaySection({
    title,
    description,
}: {
    title?: string;
    description: string | ReactNode;
}): ReactElement {
    return description ? (
        <div key={title}>
            {title ? (
                <div className="font-semibold font-sans">{title}</div>
            ) : (
                <></>
            )}
            <div>{description}</div>
        </div>
    ) : (
        <></>
    );
}

export function Overlay({
    children,
    setVisible,
    visible = false,
}: PropsWithChildren<{
    visible: boolean;
    setVisible: (newVisible: boolean) => void;
}>): ReactElement {
    return visible ? (
        <div className="absolute xs-only:left-1/2 xs-only:-translate-y-1/2 xs-only:-translate-x-1/2 xs-only:transform sm:right-[26px] z-10 w-screen max-w-xs px-2 sm:px-0">
            <div className="overflow-hidden rounded-sm shadow-md ring-1 ring-spacer ring-opacity-20">
                <div className="relative grid gap-2 bg-white p-2 sm:p-4">
                    {children}
                    <div className="flex justify-center mt-4">
                        <Button
                            title="Schließen"
                            onClick={() => setVisible(false)}
                            className="bg-transparent"
                        />
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
}
