import {
    CompanyAppointmentDto,
    CompanyEvent,
} from '@boersenzeitung/shared/dtos/company-appointment.dto';
import { CompanyAppointmentType } from '@boersenzeitung/shared/api.types';
import { CustomIcon } from '@components/custom-icon';
import {
    GERMAN_DATE_FORMAT,
    ISO_DATE_FORMAT,
} from '@boersenzeitung/shared/constants';
import { classNames } from '@utils/classNames';
import { getDateOfAppointment } from '@pages/company/dates';
import React from 'react';
import dayjs from '@boersenzeitung/shared/dayjs';

export const DatesEntry = ({
    appointment,
    type,
}: {
    appointment: CompanyAppointmentDto;
    type: CompanyAppointmentType;
}) => {
    const appointmentData = appointment.data as CompanyEvent;
    return (
        <tr
            className={classNames(
                'hover:bg-white',
                dayjs(getDateOfAppointment(appointment)).isAfter(dayjs())
                    ? 'text-lightgray italic'
                    : 'text-secondary',
            )}
            data-testid="dateEntry"
        >
            <td className="border-b border-solid border-spacer py-2.5 px-2">
                {dayjs(
                    getDateOfAppointment(appointment),
                    ISO_DATE_FORMAT,
                ).format(GERMAN_DATE_FORMAT)}
            </td>
            <td className="border-b border-solid border-spacer py-2.5 px-2">
                {appointmentData.keyword}
            </td>
            <td className="border-b border-solid border-spacer py-2.5 px-2">
                {appointmentData.url &&
                type !== CompanyAppointmentType.DIVIDENDS ? (
                    <a
                        href={appointmentData.url}
                        target="_blank"
                        rel="noreferrer"
                        className="flex items-center"
                    >
                        <p>mehr ...</p>
                        <CustomIcon
                            className="pl-1 text-sm"
                            iconCode={String.raw`after:content-["\ea10"]`}
                        />
                    </a>
                ) : (
                    ''
                )}
            </td>
        </tr>
    );
};
