import { Link } from 'react-router-dom';
import { classNames } from '@utils/classNames';
import React, { ReactElement } from 'react';

export function TitledSeparator({
    title,
    className,
    moreActionTarget,
}: {
    title: string;
    className?: string;
    moreActionTarget?: string;
}): ReactElement {
    return (
        <div
            className={classNames(
                'border-b border-spacer border-solid flex justify-between mb-7',
                className,
            )}
        >
            <h2 className="uppercase text-red text-sm font-bold font-sourceCode leading-[.875rem] tracking-[.09375rem] border-b-2 border-red border-solid pb-2 w-fit -mb-[1px]">
                {title}
            </h2>
            {moreActionTarget && (
                <Link
                    to={moreActionTarget}
                    className="text-xs opacity-50 font-sourceCode uppercase hover:opacity-100"
                >
                    Mehr {' >'}
                </Link>
            )}
        </div>
    );
}
