import { ChevronDownIcon } from '@heroicons/react/24/solid';
import {
    HierarchicalOption,
    MultiProps,
    SelectProps,
    SingleProps,
} from '@components/select/types';
import { Listbox, Transition } from '@headlessui/react';
import { SelectOption } from '@components/select/select-option';
import { classNames } from '@utils/classNames';
import { compact } from 'lodash';
import React, { Fragment } from 'react';

/**
 * This component originates from here: https://tailwindui.com/components/application-ui/forms/select-menus
 */
export function Select({
    options,
    value,
    onChange,
    className = '',
    isDisabled = false,
    bgColor = 'bg-white',
    title,
    multiple,
    customIcon,
    optionFontSize,
    showFullTextOption,
    maxHeight,
}: SelectProps &
    (SingleProps | MultiProps) & {
        optionFontSize?: string;
        showFullTextOption?: boolean;
        maxHeight?: string;
    }) {
    return (
        <Listbox value={value} onChange={onChange} multiple={multiple}>
            {({ open }) => (
                <div className={classNames('relative', className)}>
                    <Listbox.Button
                        className={classNames(
                            bgColor,
                            'relative w-full rounded-[2px] border-solid border border-spacer shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue focus:border-blue text-sm',
                        )}
                        data-testid="ListboxButton"
                    >
                        <span className="block truncate">
                            {title ?? findFirstSelectedOption(options, value)}
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            {customIcon ? (
                                customIcon
                            ) : (
                                <ChevronDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            )}
                        </span>
                    </Listbox.Button>

                    <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options
                            className={classNames(
                                isDisabled ? 'hidden' : '',
                                showFullTextOption ? '' : 'w-full',
                                maxHeight
                                    ? `${maxHeight} overflow-scroll`
                                    : 'overflow-auto',
                                'absolute z-10 mt-1 bg-white shadow-lg rounded-[2px] py-1 ring-1 ring-black ring-opacity-5 focus:outline-none text-sm',
                            )}
                        >
                            {compact(options).map((option, index) =>
                                renderOption(
                                    option,
                                    !multiple,
                                    index,
                                    optionFontSize,
                                ),
                            )}
                        </Listbox.Options>
                    </Transition>
                </div>
            )}
        </Listbox>
    );
}

function renderOption(
    option: HierarchicalOption,
    highlightSelected: boolean,
    parentIndex: number,
    optionFontSize?: string,
): React.ReactElement {
    if ('children' in option) {
        return (
            <>
                <SelectOption
                    key={`${option.label}`}
                    option={{ label: option.label, value: null }}
                    disabled
                    className="!font-semibold"
                    testId={parentIndex.toString()}
                />
                {option.children.map((child, index) =>
                    renderOption(
                        child,
                        highlightSelected,
                        index * 10 + parentIndex,
                    ),
                )}
            </>
        );
    } else {
        return (
            <SelectOption
                key={`${option.value} ${option.label}`}
                option={option}
                className={classNames(
                    'pl-4',
                    highlightSelected ? '' : '!font-normal',
                    optionFontSize ?? '',
                )}
                testId={parentIndex.toString()}
            />
        );
    }
}

function findFirstSelectedOption(
    options: HierarchicalOption[],
    selectedValue: string[] | string | null,
): string {
    return (
        flattenOptions(options).find(
            (option) =>
                'value' in option &&
                (option.value === selectedValue ||
                    (Array.isArray(selectedValue) &&
                        selectedValue.includes(option.value as string))),
        )?.label ?? ''
    );
}

function flattenOptions(
    options: HierarchicalOption[],
    acc: HierarchicalOption[] = [],
): HierarchicalOption[] {
    compact(options).forEach((option) => {
        acc.push(option);
        if ('children' in option) {
            flattenOptions(option.children, acc);
        }
    });
    return acc;
}
