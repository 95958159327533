import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { SearchTermResultDto } from '@boersenzeitung/shared/dtos/email-notification-seatch-term.dto';
import { ReactComponent as Spinner } from '@components/spinner.svg';
import { UserDataDto } from '@boersenzeitung/shared/dtos/email-notifications-user-data.dto';
import { useApiClient } from '@hooks/useApiClient';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import React from 'react';
import apiClient from '@api/api.client';

export default function ManageOverview({
    userData,
    setServiceActive,
    removeAllEmailNotificationPreferences,
    emailToken,
    userDataLoading,
}: {
    userData?: UserDataDto;
    setServiceActive: (active: boolean) => void;
    removeAllEmailNotificationPreferences: () => void;
    emailToken: string | null | undefined;
    userDataLoading: boolean;
}) {
    const navigate = useNavigate();
    const location = useLocation();

    const {
        data: resultListing,
        error,
        loading: searchTermResultLoading,
    } = useApiClient<SearchTermResultDto>(() => {
        if (emailToken) {
            return apiClient.getResultListings(emailToken);
        } else {
            return Promise.resolve({ totalCount: 0, results: [] });
        }
    }, [emailToken, apiClient, userData?.notificationSettings.length]);

    return (
        <>
            <p>
                Bei den kostenlosen E-Mail-Alerts der Börsen-Zeitung können Sie
                bis zu zehn Suchbegriffe eingeben und erhalten morgens um 6:00
                Uhr eine E-Mail mit den aktuellen Artikeln der Börsen-Zeitung zu
                diesen Themen. Die E-Mail-Alerts sind Teil des Angebots myBZ und
                können jederzeit pausiert bzw. wieder aktiviert werden.
            </p>
            <p className="mb-4">
                Der Suchbegriff kann im Volltext, in der Überschrift oder in der
                Unternehmensverschlagwortung vorkommen, auch Kombinationen sind
                möglich. Eine Trefferliste mit den letzten Treffern zu Ihren
                Suchbegriffen können Sie sich ebenfalls anzeigen lassen.
            </p>
            <div className="grid grid-cols-3 max-w-[450px]">
                <p className="font-bold">Anzahl:</p>
                <p className="grow">
                    {userDataLoading ? (
                        <Spinner />
                    ) : (
                        `${
                            userData?.notificationSettings.length ?? 0
                        } von 10 Alerts`
                    )}
                </p>
                <button
                    className="underline text-left"
                    onClick={removeAllEmailNotificationPreferences}
                    data-testid="remove-all-email-notification-preferences"
                >
                    alle Alerts löschen
                </button>
                <p className="font-bold">Status:</p>
                <div className="grow flex">
                    {userDataLoading ? (
                        <Spinner />
                    ) : (
                        <>
                            {userData?.alertServiceActive ? (
                                <div className="text-positive-color h-[24px] w-[24px]">
                                    <CheckIcon />
                                </div>
                            ) : (
                                <div className="text-negative-color h-[24px] w-[24px]">
                                    <XMarkIcon />
                                </div>
                            )}
                            <p>
                                {userData?.alertServiceActive
                                    ? 'aktiv'
                                    : 'inaktiv'}
                            </p>
                        </>
                    )}
                </div>
                <button
                    className="underline text-left"
                    onClick={() =>
                        setServiceActive(!userData?.alertServiceActive)
                    }
                    data-testid="toggle-service-active"
                >
                    {`Dienst ${
                        userData?.alertServiceActive
                            ? 'pausieren'
                            : 'aktivieren'
                    }`}
                </button>
                <p className="font-bold">Treffer:</p>
                <p className="grow">
                    {searchTermResultLoading ? (
                        <Spinner />
                    ) : (
                        `${error ? 0 : resultListing?.totalCount ?? 0} Artikel`
                    )}
                </p>
                <button
                    className="underline text-left"
                    onClick={() =>
                        navigate(`/email-notification/list${location.search}`)
                    }
                >
                    zur Trefferliste
                </button>
            </div>
        </>
    );
}
