import { TeaserText } from '@components/teaser-text';
import { TitledSeparator } from '@components/titled-separator';
import React from 'react';

export const OverviewFinancialAnnouncements = () => {
    return (
        <>
            <div className="mb-4">
                <TitledSeparator
                    title="Finanzanzeigen"
                    moreActionTarget="/fonds/finanzanzeigen"
                />
                <TeaserText>
                    Finden Sie mit unserer Finanzanzeigen-Suche alle
                    Finanzanzeigen aus der Börsen-Zeitung zum Thema
                    Investmentfonds. Recherchieren Sie mit Hilfe einer Vielzahl
                    von Schlagworten wie etwa „Dividendenbekanntmachung“ oder
                    „Nachtrag auf Verkaufsprospekte“.
                </TeaserText>
            </div>
        </>
    );
};
