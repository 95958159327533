import { Helmet } from 'react-helmet';
import { ReactComponent as Spinner } from '@components/spinner.svg';
import { TeaserText } from '@components/teaser-text';
import { TitledSeparator } from '@components/titled-separator';
import { Top5UltimoDto } from '@boersenzeitung/shared/dtos/top5ultimo.dto';
import { Top5UltimoSearchTable } from '@components/funds/top-5-ultimo-table';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useDocumentTitle } from '@hooks/useDocumentTitle';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import apiClient from '@api/api.client';
import qs from 'qs';

export const Top5Ultimo = () => {
    const [loadedTop5Ultimo, setLoadedTop5Ultimo] = useState<Top5UltimoDto[]>(
        [],
    );
    const [categoryFilter, setCategoryFilter] = useState<string | null>(null);
    const [allCategories, setAllCategories] = useState<string[]>([]);
    const [showTop5Ultimo, setShowTop5Ultimo] = useState<Top5UltimoDto[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();

    useDocumentTitle('Top 5 Ultimo Fonds | Börsen-Zeitung');

    const setFilter = (category: string | null) => {
        navigate({
            pathname: location.pathname,
            search: `?${createSearchParams({
                ...qs.parse(location.search.replace('?', '')),
                Kategorie: category ?? 'Alle',
            })}`,
        });
        setCategoryFilter(category);
    };

    useLayoutEffect(() => {
        const fetchTop5Ultimo = async () => {
            setLoading(true);
            const result = await apiClient.getTop5Ultimo();
            setLoadedTop5Ultimo(result);
            const categories = result.map((entry) => entry.category);

            const params = qs.parse(location.search.replace('?', ''));
            const category = params.Kategorie as string;
            if (categories.find((elem) => elem === category)) {
                setCategoryFilter(category);
            }

            setAllCategories(categories);
            setLoading(false);
        };

        void fetchTop5Ultimo();
    }, []);

    useEffect(() => {
        const filteredTop5Ultimo = categoryFilter
            ? loadedTop5Ultimo.filter(
                  (entry) => entry.category === categoryFilter,
              )
            : loadedTop5Ultimo;
        setShowTop5Ultimo(filteredTop5Ultimo);
    }, [categoryFilter, loadedTop5Ultimo]);

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Die Fonds mit der besten Performance zum Monatsende. Kategorisiert nach den relevantesten Anlagekategorien."
                />
            </Helmet>
            <div>
                <TeaserText>
                    {
                        'Hier sehen sie eine Übersicht der Fonds mit der besten Ultimo-Performance, kategorisiert nach den relevantesten Anlagekategorien. Der Ultimo ist im Bankwesen der letzte Bankarbeitstag eines Monats. Bei dem Rating werden die Fonds in eine Skala von A (am besten) bis E (am schlechtesten) eingestuft. Dabei wird berücksichtigt welchen Anteil das Fondsmanagement an der Performance hat und wie groß der Beitrag zur Risikoverminderung ist. Die Beurteilung erfolgt im Vergleich zu Konkurrenzprodukten aus der gleichen Kategorie.'
                    }
                </TeaserText>
                <TitledSeparator title="Die 5 besten Fonds nach Anlagekategorie" />
                {loading ? (
                    <div>
                        <Spinner />
                    </div>
                ) : (
                    <Top5UltimoSearchTable
                        allCategories={allCategories}
                        setCategory={setFilter}
                        category={categoryFilter}
                        top5Ultimos={showTop5Ultimo}
                    />
                )}
            </div>
        </>
    );
};
