import { classNames } from '@utils/classNames';
import React, { useCallback } from 'react';

export default function TextInput({
    value,
    setValue,
    type = 'text',
    disabled = false,
    placeholder,
    key,
    className,
    testId,
}: {
    value?: string;
    setValue: (value: string) => void;
    type?: string;
    disabled?: boolean;
    placeholder?: string;
    className?: string;
    key?: string;
    testId?: string;
}) {
    const onChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setValue(event.target.value);
        },
        [setValue],
    );
    return (
        <input
            type={type}
            value={value}
            onChange={onChange}
            disabled={disabled}
            placeholder={placeholder}
            key={key}
            className={classNames(
                'p-4 pt-1 pb-0 md:pb-1 rounded-none h-[38px] w-full max-w-[630px] border border-solid focus:ring-primary-color focus:border-primary-color border-separator-color',
                className,
            )}
            data-testid={testId}
        />
    );
}
