import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import React from 'react';

export default function ExpiredEmailToken() {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <>
            <p className="mb-4">
                Ihr Link ist abgelaufen. Wir haben ihnen eine E-Mail mit einem
                neuen Link zugesendet. Bitte überprüfen Sie Ihre E-Mails und
                nutzen den neuen Link um sich erneut zu verifizieren.
            </p>

            <p>
                Falls Sie keine E-Mail erhalten haben, können Sie sich{' '}
                <button
                    className="hover:cursor-pointer underline"
                    onClick={() =>
                        navigate(`/email-notification${location.search}`)
                    }
                    data-testid="expired-login-button"
                >
                    hier erneut anmelden
                </button>{' '}
                um eine neue E-Mail anzufordern.
            </p>
        </>
    );
}
