import { NavigationType } from '@hooks/useInstruments';
import Header from '@components/header/header';
import React from 'react';

const navigation: NavigationType = [
    { name: 'Suche', href: '/termine/suche', isInstrumentDependent: false },
    { name: 'News', href: '/termine/news', isInstrumentDependent: false },
];

export default function DatesIndex() {
    return <Header title="Termine" navigation={navigation} />;
}
