import { Helmet } from 'react-helmet';
import { NewsEntity, NewsList } from '@components/news-list';
import { TeaserText } from '@components/teaser-text';
import { TitledSeparator } from '@components/titled-separator';
import { useDocumentTitle } from '@hooks/useDocumentTitle';
import React from 'react';

export const News = () => {
    useDocumentTitle('News über Fonds | Börsen-Zeitung');
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Aktuelle News-Artikel der Börsen-Zeitung zum Thema Fonds. Informieren Sie sich jetzt über die neuesten Fonds-Nachrichten!"
                />
            </Helmet>
            <div>
                <TeaserText>
                    {`Hier sehen sie einen Überblick der aktuellen News zum Thema Fonds aus der Börsen-Zeitung. Mit dem Filter können Sie die Fonds-News zeitlich eingrenzen.`}
                </TeaserText>
                <TitledSeparator title="Aktuelle Nachrichten und Schlagzeilen" />
                <NewsList newsEntity={NewsEntity.funds} />
            </div>
        </>
    );
};
