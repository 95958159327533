import { ALL_OPTION, ISO_DATE_FORMAT } from '@boersenzeitung/shared/constants';
import {
    AppointmentFilter,
    DateRange,
    FilterState,
} from '@components/appointment/appointment-filter';
import { AppointmentListing } from '@components/appointment/appointment-listing';
import {
    AppointmentSearchFilter,
    AppointmentSearchResult,
} from '@boersenzeitung/shared/api.types';
import { DATE_ITEMS_PER_PAGE } from '@utils/constants';
import { Helmet } from 'react-helmet';
import { TeaserText } from '@components/teaser-text';
import { TitledSeparator } from '@components/titled-separator';
import { isEqual } from 'lodash';
import { useApiClient } from '@hooks/useApiClient';
import { useDocumentTitle } from '@hooks/useDocumentTitle';
import React, { useState } from 'react';
import apiClient from '@api/api.client';
import dayjs from '@boersenzeitung/shared/dayjs';

const TODAY = dayjs().format(ISO_DATE_FORMAT);

export const Search = () => {
    const [filter, setFilter] = useState<AppointmentSearchFilter | undefined>();
    const [isSilent, setSilent] = useState<boolean>(false);

    useDocumentTitle('Termin-Suche | Börsen-Zeitung');

    const onFilterSelectionChanged = ({
        type,
        phrase,
        dateRange,
    }: FilterState) => {
        const newFilter = {
            type: type === ALL_OPTION ? undefined : type,
            phrase,
            dateFrom: dateRange
                ? (dateRange as DateRange)[0].format(ISO_DATE_FORMAT)
                : TODAY,
            dateTo: dateRange
                ? (dateRange as DateRange)[1].format(ISO_DATE_FORMAT)
                : TODAY,
        };
        if (!filter) {
            setFilter({
                ...newFilter,
                itemsPerPage: DATE_ITEMS_PER_PAGE,
                page: 0,
            });
        } else {
            const oldFilter: Partial<AppointmentSearchFilter> = {
                type: filter.type,
                phrase: filter.phrase,
                dateFrom: filter.dateFrom,
                dateTo: filter.dateTo,
            };
            setFilter({
                ...filter,
                ...newFilter,
                itemsPerPage: DATE_ITEMS_PER_PAGE,
                page: isEqual(oldFilter, newFilter) ? filter.page : 0,
            });
        }
    };

    const {
        data: pageResults,
        error,
        loading,
    } = useApiClient<AppointmentSearchResult>(() => {
        if (filter) {
            return apiClient.searchAppointments(filter);
        } else {
            return Promise.resolve({
                page: 0,
                items: [],
                totalCount: 0,
            });
        }
    }, [filter]);

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Recherchieren Sie die wichtigsten Informationen zu Hauptversammlungen, Dividenden, Kapitalmaßnahmen, Konjunkturindikatoren & Seminaren."
                />
            </Helmet>
            <div>
                <TeaserText>
                    Mit unserer Terminsuche können sie wichtige Informationen zu
                    Terminen recherchieren: Hauptversammlungen, Dividenden,
                    Kapitalmaßnahmen, Konjunkturindikatoren und Seminare.
                    Filter-Optionen und Schnellsuche helfen Ihnen dabei ihre
                    Termine zu finden.
                </TeaserText>
                <TitledSeparator title="Suchfunktion mit wichtigen Terminen" />
                <div className="mb-8">
                    <AppointmentFilter
                        loading={!isSilent && loading}
                        totalCount={pageResults?.totalCount}
                        onFilterChanged={onFilterSelectionChanged}
                    />
                </div>
                <div className="sm:mb-16 min-h-[300px]">
                    {error && (
                        <div className="mt-24 text-center">
                            Beim Laden der Termine ist ein Fehler aufgetreten.
                            <br />
                            Bitte versuchen Sie es erneut.
                        </div>
                    )}
                    {filter && !!pageResults?.items.length && (
                        <div className="mt-10">
                            <AppointmentListing
                                pageResults={pageResults}
                                onLoadMore={(silent: boolean) => {
                                    setSilent(silent);
                                    setFilter({
                                        ...filter,
                                        page: (filter.page ?? 0) + 1,
                                    });
                                }}
                                loading={!isSilent && loading}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
