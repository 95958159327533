import { injectedEnvironment } from './injected-env';
export const isTest = process.env.NODE_ENV === 'test';

export const Environment = {
    API_BASE_URL:
        injectedEnvironment['REACT_APP_API_BASE_URL'] ??
        process.env.REACT_APP_API_BASE_URL,
    EMAIL_SERVICE_BASE_URL:
        injectedEnvironment['REACT_APP_EMAIL_SERVICE__BASE_URL'] ??
        process.env.REACT_APP_EMAIL_SERVICE_BASE_URL,
    FILES_CDN_URL:
        injectedEnvironment['REACT_APP_FILES_CDN_URL'] ??
        process.env.REACT_APP_FILES_CDN_URL,
};
