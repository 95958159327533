import { ALL_OPTION, ISO_DATE_FORMAT } from '@boersenzeitung/shared/constants';
import { AppointmentType } from '@boersenzeitung/shared/api.types';
import { Option } from '@components/select/types';
import dayjs from '@boersenzeitung/shared/dayjs';

const DELIMITER = ' - ';

export function serializeDateRange(
    from: ReturnType<typeof dayjs>,
    to: ReturnType<typeof dayjs>,
) {
    return `${from.format(ISO_DATE_FORMAT)}${DELIMITER}${to.format(
        ISO_DATE_FORMAT,
    )}`;
}

export function deserializeDateRange(
    dateRange: string,
): [ReturnType<typeof dayjs>, ReturnType<typeof dayjs>] | undefined {
    const ranges = dateRange.split(DELIMITER);
    if (ranges.length === 2) {
        return [
            ranges[0].length > 10
                ? dayjs(ranges[0])
                : dayjs(ranges[0], ISO_DATE_FORMAT),
            ranges[0].length > 10
                ? dayjs(ranges[1])
                : dayjs(ranges[1], ISO_DATE_FORMAT),
        ];
    }
    return undefined;
}

export const DATE_RANGES: Option[] = [
    {
        label: 'heute',
        value: serializeDateRange(dayjs(), dayjs()),
    },
    {
        label: 'morgen',
        value: serializeDateRange(
            dayjs().add(1, 'days'),
            dayjs().add(1, 'days'),
        ),
    },
    {
        label: 'aktuelle Woche',
        value: serializeDateRange(
            dayjs().startOf('week'),
            dayjs().endOf('week'),
        ),
    },
    {
        label: 'nächste Woche',
        value: serializeDateRange(
            dayjs().endOf('week').add(1, 'days'),
            dayjs().endOf('week').add(1, 'days').endOf('week'),
        ),
    },
    {
        label: 'aktueller Monat',
        value: serializeDateRange(
            dayjs().startOf('month'),
            dayjs().endOf('month'),
        ),
    },
    {
        label: 'nächster Monat',
        value: serializeDateRange(
            dayjs().endOf('month').add(1, 'days'),
            dayjs().endOf('month').add(1, 'days').endOf('month'),
        ),
    },
    {
        label: 'aktuelles Jahr',
        value: serializeDateRange(
            dayjs().startOf('year'),
            dayjs().endOf('year'),
        ),
    },
    {
        label: 'verbleibendes Jahr',
        value: serializeDateRange(dayjs(), dayjs().endOf('year')),
    },
    { label: 'beliebiger Zeitraum', value: 'custom' },
];

export const DEFAULT_SELECTED_DATE_RANGE = DATE_RANGES[0];

export const TOPICS: Option[] = [
    {
        label: 'Alle',
        value: ALL_OPTION,
    },
    {
        label: 'Konjunkturindikatoren',
        value: AppointmentType.BUSINESS_INDICATOR,
    },
    {
        label: 'Seminare',
        value: AppointmentType.SEMINAR,
    },
    {
        label: 'Unternehmen / Aktien',
        value: AppointmentType.COMPANY,
    },
];
