import { StockPricePanel } from '@components/stock-price-panel';
import React, { useContext } from 'react';
import UrlDataContext from '../../contexts/urlDataContext';

export default function StockPricesIndex() {
    const { currentTickerId } = useContext(UrlDataContext);

    return (
        <>
            {currentTickerId && (
                <div className="m-4">
                    <StockPricePanel tickerId={currentTickerId} />
                </div>
            )}
        </>
    );
}
