import { FundDto } from '@boersenzeitung/shared/dtos/fund.dto';
import { InputProps } from 'react-autosuggest';
import { SearchSuggestionField } from '@components/suggestion-input/search-suggestion-field';
import React, { FormEvent, useState } from 'react';
import apiClient from '@api/api.client';

export type FundSearchBoxProps = {
    onSuggestionSelected: (fund: FundDto) => void;
};

export function FundSearchBox({ onSuggestionSelected }: FundSearchBoxProps) {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchDisplayValue, setSearchDisplayValue] = useState<string>('');
    const inputId = 'fund-search-input-header';

    const onChange = (
        _event: FormEvent<HTMLElement>,
        { newValue }: { newValue: string },
    ) => {
        setSearchDisplayValue(newValue);
    };

    const inputProps: InputProps<string> & {
        'data-cy': string;
        'data-testid': string;
    } = {
        'placeholder': 'Fond / ISIN / WKN',
        'value': searchDisplayValue,
        onChange,
        'onInput': (e: FormEvent<HTMLInputElement>) => {
            setSearchTerm(e.currentTarget.value);
            setSearchDisplayValue(e.currentTarget.value);
        },
        'onBlur': () => {
            // do nothing -> calling this function will close the suggestion box
        },
        'className':
            'p-4 pt-1 pb-0 md:pb-1 rounded-none h-[38px] w-full min-w-[210px] border border-solid focus:ring-red focus:border-red bg-light-gray border-spacer',
        'data-cy': inputId,
        'data-testid': inputId,
        'id': inputId,
    };

    const onSuggestionClicked = (fund: FundDto) => {
        setSearchDisplayValue('');
        setSearchTerm('');
        onSuggestionSelected(fund);
    };
    return (
        <SearchSuggestionField<FundDto>
            onSuggestionClicked={onSuggestionClicked}
            searchTerm={searchTerm}
            inputProps={inputProps}
            getResults={(searchString: string) =>
                apiClient.lookupFunds(searchString)
            }
        />
    );
}
