import { FUND_ITEMS_PER_PAGE } from '@utils/constants';
import { FilterBox } from '@components/funds/filter-box';
import { FundDto } from '@boersenzeitung/shared/dtos/fund.dto';
import { FundSearchBox } from '@components/funds/fund-search-box';
import {
    FundSearchFilter,
    FundsByCompany,
} from '@boersenzeitung/shared/api.types';
import { Helmet } from 'react-helmet';
import { LoadMoreButton } from '@components/load-more-button';
import { NavLink, useLocation } from 'react-router-dom';
import { SafeImage } from '@components/image';
import { ReactComponent as Spinner } from '@components/spinner.svg';
import { TeaserText } from '@components/teaser-text';
import { TitledSeparator } from '@components/titled-separator';
import { classNames } from '@utils/classNames';
import { getColorForNumericValue } from './utils';
import { removeSearchParamsFromQueryString } from '@utils/map-record-transformer';
import { useDocumentTitle } from '@hooks/useDocumentTitle';
import { useNavigate } from 'react-router';
import React, { useLayoutEffect, useState } from 'react';
import apiClient from '@api/api.client';

export const Search = () => {
    const [filter, setFilter] = useState<FundSearchFilter>({
        page: 0,
        itemsPerPage: FUND_ITEMS_PER_PAGE,
    });

    const [shownFunds, setShownFunds] = useState<FundDto[]>([]);
    const [bufferedFunds, setBufferedFunds] = useState<FundDto[]>([]);
    const [canLoadMore, setCanLoadMore] = useState<boolean>(false);
    const [showLoadMore, setShowLoadMore] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [totalCount, setTotalCount] = useState<number | undefined>();
    const [companies, setCompanies] = useState<string[]>([]);
    const params = useLocation();
    const navigate = useNavigate();

    useDocumentTitle('Fonds-Suche | Börsen-Zeitung');

    useLayoutEffect(() => {
        const handleFilterChange = async () => {
            setLoading(true);
            let refreshBuffer = canLoadMore;
            if (filter.page === 0) {
                const fundsToShow = await apiClient.searchFunds(filter);
                setTotalCount(fundsToShow.totalCount);
                setShownFunds(fundsToShow.items);
                refreshBuffer = fundsToShow.totalCount > filter.itemsPerPage!;
            }
            if (refreshBuffer) {
                const bufferPageNumber = (filter.page ?? 0) + 1;
                const fundsToBuffer = await apiClient.searchFunds({
                    ...filter,
                    page: bufferPageNumber,
                });
                setTotalCount(fundsToBuffer.totalCount);
                setBufferedFunds(fundsToBuffer.items);
                setCanLoadMore(
                    (bufferPageNumber + 1) * filter.itemsPerPage! <=
                        fundsToBuffer.totalCount,
                );
                setShowLoadMore(fundsToBuffer.items.length > 0);
            } else {
                setShowLoadMore(false);
            }
            setLoading(false);
        };
        void handleFilterChange();
    }, [filter]);

    const onLoadMore = () => {
        setShownFunds([...shownFunds, ...bufferedFunds]);
        setFilter({ ...filter, page: (filter.page ?? 0) + 1 });
    };

    const onSuggestionSelected = (fund: FundDto) => {
        navigate(
            `/fonds/fond/${fund.isin}${removeSearchParamsFromQueryString(
                location.search,
            )}`,
        );
    };

    const onCompanySwitch = (company: string | null) => {
        setFilter({
            page: 0,
            itemsPerPage: FUND_ITEMS_PER_PAGE,
            fundCompany: company ?? undefined,
        });
    };

    useLayoutEffect(() => {
        const getCompanyOptions = async () => {
            setCompanies(await apiClient.getFundCompanies());
        };
        void getCompanyOptions();
    }, []);

    const fundsPerCompany = shownFunds.reduce<FundsByCompany>((acc, cur) => {
        const existing = (acc[cur.fundCompany] as FundDto[] | undefined) ?? [];
        acc[cur.fundCompany] = [...existing, cur];
        return acc;
    }, {} as FundsByCompany);

    const tableCellStyle =
        'border-x-2 border-solid border-white align-middle px-2';

    const tableCellStyleWithRightTextAlgin = `${tableCellStyle} text-right`;

    const tryToFormatNumberWithCommas = (
        input: string | undefined,
    ): string | undefined => {
        if (input === undefined) {
            input = '';
        }
        // Remove any commas (thousands separators) from the input string before parsing
        const num = parseFloat(input.replace(/,/g, ''));

        if (isNaN(num)) {
            return undefined;
        }

        return num.toLocaleString('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Recherchieren Sie nach einer Vielzahl von Wertpapieren aus dem Börsen-Zeitung-Universum."
                />
            </Helmet>
            <div>
                <TeaserText>
                    {
                        'Mit unserer Suchfunktion können sie alle Fonds aus dem Börsen-Zeitung-Universum recherchieren. Via Klick auf einen Einzelwert gelangen Sie zu einer Detailseite mit Preisen, Performance-Angaben, Steuerlichen Daten, Ausschüttungen, Anlagegrundsätzen und Allgemeinen Fondsinformationen.'
                    }
                </TeaserText>
                <TitledSeparator title="Suchfunktion für Fonds" />
                <div className="md:flex w-full justify-between gap-4 mb-8">
                    <div>
                        <p className="my-2 md:my-0">Fonds-Suche</p>
                        <FundSearchBox
                            onSuggestionSelected={onSuggestionSelected}
                        />
                    </div>
                    <div className="grow">
                        <p className="my-2 md:my-0">Auswahl</p>
                        <FilterBox
                            entities={companies}
                            onEntitySwitch={onCompanySwitch}
                            queryParamName="Firma"
                        />
                    </div>
                    <div className="mt-8 md:flex justify-center">
                        {loading ? (
                            <div>
                                <Spinner />
                            </div>
                        ) : (
                            <div>
                                {totalCount !== undefined &&
                                    `Anzahl: ${totalCount}`}
                            </div>
                        )}
                    </div>
                </div>
                <table className="w-full border-spacing-2">
                    <thead>
                        <tr className="bg-background">
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                            <td
                                colSpan={3}
                                className="text-xs text-center bg-spacer"
                            >
                                Performance
                            </td>
                            <td />
                        </tr>
                        <tr className="text-left font-bold bg-tablegray">
                            <th className="border-r-2 border-solid border-white align-middle px-2">
                                Fonds
                            </th>
                            <th className={tableCellStyle}>
                                Feri
                                <br />
                                Rating
                            </th>
                            <th className={tableCellStyle}>Währ.</th>
                            <th className={tableCellStyle}>Datum</th>
                            <th className={tableCellStyleWithRightTextAlgin}>
                                Rückn.
                                <br />
                                -preis
                            </th>
                            <th className={tableCellStyleWithRightTextAlgin}>
                                YTD
                            </th>
                            <th className={tableCellStyleWithRightTextAlgin}>
                                1 J.
                            </th>
                            <th className={tableCellStyleWithRightTextAlgin}>
                                3 J.
                            </th>
                            <th className="border-l-2 border-solid border-white align-middle px-2 text-right">
                                Ausg.
                                <br />
                                -preis
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(fundsPerCompany).map(
                            (company, indexCompany) => {
                                return (
                                    <React.Fragment key={company}>
                                        <tr className="pt-4 pb-2 font-bold block">
                                            <td>
                                                <SafeImage
                                                    src={
                                                        fundsPerCompany[
                                                            company
                                                        ][0].image
                                                    }
                                                    alt="Fondgesellschaftlogo"
                                                />
                                                {company}
                                            </td>
                                        </tr>
                                        {fundsPerCompany[company].map(
                                            (fund, indexFund) => {
                                                return (
                                                    <tr
                                                        key={fund.id}
                                                        className={classNames(
                                                            'text-sm py-2 hover:bg-spacer',
                                                            (indexFund +
                                                                indexCompany) %
                                                                2 ===
                                                                1
                                                                ? 'bg-tablegray'
                                                                : '',
                                                        )}
                                                        data-testid="fund-entry"
                                                    >
                                                        <td
                                                            className="pl-5 border-r-2 border-solid border-white align-middle pr-2
                                                    underline hover:text-red"
                                                        >
                                                            <NavLink
                                                                to={`/fonds/fond/${
                                                                    fund.isin
                                                                }${removeSearchParamsFromQueryString(
                                                                    params.search,
                                                                )}`}
                                                            >
                                                                {fund.name}
                                                            </NavLink>
                                                        </td>
                                                        <td
                                                            className={
                                                                tableCellStyle
                                                            }
                                                        >
                                                            {fund.feriRating}
                                                        </td>
                                                        <td
                                                            className={
                                                                tableCellStyle
                                                            }
                                                        >
                                                            {fund.currency}
                                                        </td>
                                                        <td
                                                            className={
                                                                tableCellStyle
                                                            }
                                                        >
                                                            {fund.date}
                                                        </td>
                                                        <td
                                                            className={
                                                                tableCellStyleWithRightTextAlgin
                                                            }
                                                        >
                                                            {tryToFormatNumberWithCommas(
                                                                fund.repurchasePrice,
                                                            ) ?? ''}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                tableCellStyleWithRightTextAlgin,
                                                                getColorForNumericValue(
                                                                    fund.yearlyPerformance,
                                                                ),
                                                            )}
                                                        >
                                                            {tryToFormatNumberWithCommas(
                                                                fund.yearlyPerformance,
                                                            ) ?? ''}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                tableCellStyleWithRightTextAlgin,
                                                                getColorForNumericValue(
                                                                    fund.oneYearPerformance,
                                                                ),
                                                            )}
                                                        >
                                                            {tryToFormatNumberWithCommas(
                                                                fund.oneYearPerformance,
                                                            ) ?? ''}
                                                        </td>
                                                        <td
                                                            className={classNames(
                                                                tableCellStyleWithRightTextAlgin,
                                                                getColorForNumericValue(
                                                                    fund.threeYearPerformance,
                                                                ),
                                                            )}
                                                        >
                                                            {tryToFormatNumberWithCommas(
                                                                fund.threeYearPerformance,
                                                            ) ?? ''}
                                                        </td>
                                                        <td className="border-l-2 border-solid border-white align-middle px-2 text-right">
                                                            {tryToFormatNumberWithCommas(
                                                                fund.originalPrice,
                                                            ) ?? ''}
                                                        </td>
                                                    </tr>
                                                );
                                            },
                                        )}
                                    </React.Fragment>
                                );
                            },
                        )}
                    </tbody>
                </table>
                {showLoadMore && (
                    <LoadMoreButton
                        loadMore={onLoadMore}
                        title="Weitere Ergebnisse anzeigen"
                        testId="funds-search-load-more"
                    />
                )}
            </div>
        </>
    );
};
